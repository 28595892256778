import styled, { css } from 'styled-components';

import { Button, FocusRingVariants, minWidth } from '@calm-web/design-system';

export const Wrapper = styled(Button).attrs({
	focusRingVariant: FocusRingVariants.Dark,
})<{ $noPadding: boolean }>`
	align-items: center;
	display: flex;
	height: 48px;
	margin-bottom: 12px;
	width: 100%;
	${p =>
		p.$noPadding &&
		css`
			padding: 0;
		`}

	${minWidth('tablet')} {
		height: 56px;
	}
`;
