import { Wrapper } from './styles';

interface Props {
	gradient: string;
	mobileGradient?: string;
	className?: string;
}

/**
 * Displays an overlay over the window
 */
function FullscreenOverlay({ gradient, mobileGradient, className }: Props): JSX.Element {
	return <Wrapper className={className} gradient={gradient} mobileGradient={mobileGradient} />;
}

export default FullscreenOverlay;
