import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text as _Text } from '@calm-web/design-system';

export const Divider = styled.div`
	background: ${palette('black')};
	height: 1px;
	margin: 1rem 0;
	width: 100%;
`;

export const TotalDueTodayContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
`;

export const TermsText = styled(_Text)<{
	userDirectSub: boolean;
}>`
	visibility: ${p => (p.userDirectSub ? '' : 'hidden')};
`;
