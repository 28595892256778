import { useRouter } from 'next/router';
import querystring, { ParsedUrlQuery } from 'querystring';

const useQueryParams = (): ParsedUrlQuery => {
	const router = useRouter();

	try {
		const query =
			typeof window !== 'undefined'
				? querystring.parse(window.location.search.replace('?', ''))
				: router.query;

		return query;
	} catch {
		return {};
	}
};

export default useQueryParams;
