import { SignupScreens } from '../../PreSignupProvider/types';

// This is the configuration for the signup payment hybrid screens
// it uses the account setup from the legacy flow and the payment form from the simplified flow

export const signupPaymentHybridScreens: SignupScreens = {
	start: {
		componentName: 'AccountSetup',
		analyticsName: 'Login Form',
		backButtonHidden: true,
		nextScreen: 'upsell',
		showNav: true,
		bgColor: 'gray0',
		canExit: true,
		URLKey: 'su',
	},
	upsell: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'pay',
	},
};
