const MAX_RETRIES = 10;
const RETRY_DELAY = 200;

export async function getAppleSignInToken(retries = 0): Promise<
	| {
			code: string;
			user: string;
	  }
	| { error: 'cancelled' }
	| null
> {
	if (retries > MAX_RETRIES) return null;

	if (window?.AppleID) {
		try {
			const data = await window.AppleID.auth.signIn();

			const {
				authorization: { code },
				user,
			} = data;
			return {
				code,
				user: JSON.stringify(user),
			};
		} catch (err) {
			return { error: 'cancelled' };
		}
	}

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			getAppleSignInToken(retries + 1)
				.then(resolve)
				.catch(reject);
		}, RETRY_DELAY);
	});
}
