import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

const absoluteBackground = css`
	height: 100vh;
	left: 0;
	top: 0;
	width: 100%;
`;

export const Wrapper = styled.div`
	${absoluteBackground}
	position: fixed;
	z-index: -1;

	&:after {
		${absoluteBackground}
		background: rgba(0, 0, 0, 0.18);
		content: '';
		position: absolute;
	}
`;

export const SceneGradient = styled.div<{
	gradientStart: string;
	gradientEnd: string;
	isVisible: boolean;
}>`
	${absoluteBackground}
	background: ${p => `linear-gradient(180deg, ${p.gradientStart} 0%, ${p.gradientEnd} 100%)`};
	content: '';
	opacity: ${p => (p.isVisible ? '0.9' : '0')};
	position: absolute;
	transition: ${theme('animation.transition')};
`;
