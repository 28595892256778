import { defineMessages } from 'react-intl';

export default defineMessages({
	upsellFooterAriaLabel: {
		id: 'app.ui.upsellFooter.aria',
		defaultMessage: 'Calm Premium Offer Footer',
		description: 'The label description for the upsell footer',
	},
	upsellCopy: {
		id: 'app.ui.upsellFooter.copy',
		defaultMessage: 'Get notified of new Calm content, special offers, helpful tips, and more.',
		description: 'The copy for the upsell footer',
	},
	upsellButton: {
		id: 'app.ui.upsellFooter.copy.CTA',
		defaultMessage: 'Sign up today',
		description: 'The copy for the upsell footer button',
	},
});
