import { defineMessages } from 'react-intl';

export default defineMessages({
	ccFormCardTypes: {
		id: 'creditCardForm.ccFormCardTypes',
		defaultMessage: 'Visa, Mastercard, AMEX, Discover',
		description: 'The different card types we accept',
	},
	cvvHelpModalTitle: {
		id: 'redesign.purchase.creditCardForm.cvvHelpModalTitle',
		defaultMessage: 'What is a card security code?',
		description: 'Title for help text modal for credit card security code',
	},
});
