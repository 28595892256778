import { defineMessages } from 'react-intl';

const messages = defineMessages({
	appStoreLabel: {
		id: 'spotify.handoff.appStoreLabel',
		defaultMessage: 'Button to download Calm in the app store',
		description: 'Heading for recommended sleep',
	},
	subHeading: {
		id: 'newMemberOffer.welcome.handoff.subHeading2',
		defaultMessage:
			'You’re in. Now download the Calm app to discover the full range of Calm’s features and benefits.',
		description: 'Heading for recommended sleep',
	},
	handoffHeader: {
		id: 'newMemberOffer.welcome.title',
		defaultMessage: 'Welcome to Calm Premium!',
		description: 'Welcome message for members with Calm Premium subscriptions',
	},
	contentInMinutes: {
		id: 'valueProp.content.inMinutes',
		defaultMessage: 'Access 50,000+ minutes of additional guided content to explore at your own pace',
		description: 'Informing the user of the amount of content Calm has',
	},
	contentWithCelebs: {
		id: 'valueProp.content.celebs2',
		defaultMessage:
			'Exclusive content from celebrities like Matthew McConaughey and Camila Cabello, and beloved characters like The Minions and Peppa Pig',
		description: 'Informing the user of the amount of content Calm has',
	},
	goals: {
		id: 'valueProp.personalized.goals',
		defaultMessage: 'Personalized recommendations to help you achieve your specific goals',
		description: 'Informing the user of the feature of Calm',
	},
	motivation: {
		id: 'valueProp.content.motivation',
		defaultMessage: 'Motivating features like reminders and streaks to help you maintain your progress',
		description: 'Informing the user of the feature of Calm',
	},
	valuePropsHeader: {
		id: 'valueProp.spotify.header',
		defaultMessage: "What you'll find <u>only</u> on the Calm app:",
		description: 'Informing the user of the feature of Calm',
	},
	limitedAccessSpotify: {
		id: 'valueProp.spotify.access',
		defaultMessage: 'You will have limited access to Calm Premium content on Spotify.',
		description: 'Informing the user of the feature of Calm',
	},
	continueHere: {
		id: 'valueProp.spotify.continue2',
		defaultMessage: 'Listen on Spotify',
		description: 'CTA for the user to go back to Spotify',
	},
	qrCodeInstructions: {
		id: 'handoff.spotify.qrCodeInstructions',
		defaultMessage: 'Scan this QR code to download Calm’s mobile app.',
		description: 'Instructions for the user to download via QR code',
	},
	toAppStore: {
		id: 'handoff.simplifiedSignup.toAppStore',
		defaultMessage: 'Go to the App Store',
		description: 'aria label for button for the user to go to the App Store',
	},
});

export default messages;
