import { defineMessages } from 'react-intl';

export const messages = defineMessages({
	specialOffer: {
		id: 'share.modal.specialOffer',
		defaultMessage: 'Special Offer',
		description: 'Indicating that this promotion is a limited offer',
	},
	heading: {
		id: 'share.modal.heading',
		defaultMessage: 'Sign up now and get {num} days free of Calm Premium.',
		description: 'Heading that tells the user how many free days of Calm they get',
	},
	subheading: {
		id: 'share.modal.subheading',
		defaultMessage: 'No credit card required.',
		description: 'Explaining that a credit card does not need to be entered to try Calm',
	},
	bullet1: {
		id: 'share.modal.bullet1',
		defaultMessage: 'Calm your mind quickly with meditations, breathing exercises, and music',
		description: 'Bullet point explaining benefits of Calm',
	},
	bullet2: {
		id: 'share.modal.bullet2new',
		defaultMessage: 'Sleep more soundly with exclusive Sleep Stories, music, and soundscapes',
		description: 'Bullet point explaining benefits of Calm',
	},
	bullet3: {
		id: 'share.modal.bullet3',
		defaultMessage: 'Relax your body and improve your wellbeing with mindful stretches',
		description: 'Bullet point explaining benefits of Calm',
	},
	cta: {
		id: 'share.modal.cta',
		defaultMessage: 'Try Calm Premium',
		description: 'Button text that brings user to sign up page',
	},
});
