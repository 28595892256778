import { useBrowserLanguageState, usePricesState } from '@/hooks/store';
import { calmLogger } from '@/utils/calmLogger';
import { getCalmLanguage } from '@/utils/language';

export const formatCurrency = (priceInCents: number, currency: string, browserLanguage: string | null) => {
	const price = priceInCents / 100;

	if (!currency) {
		calmLogger.warn('Attempting to format without prices.pricing_format.currency');
	}

	const options = {
		style: 'currency',
		currency: currency || 'USD',
	};
	const numberFormat = (() => {
		try {
			const formatter = new Intl.NumberFormat(browserLanguage ?? undefined, options);
			return formatter;
		} catch (err) {
			const fallbackLanguage = getCalmLanguage(browserLanguage ?? 'en');
			return new Intl.NumberFormat(fallbackLanguage, options);
		}
	})();

	return numberFormat.format(price);
};

export function useFormattedCurrency(): (priceInCents: number, currency?: string) => string {
	const browserLanguage = useBrowserLanguageState();
	const prices = usePricesState();

	return (priceInCents: number, _currency?: string) =>
		formatCurrency(priceInCents, _currency ?? prices?.pricing_format?.currency, browserLanguage);
}
