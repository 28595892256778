import { useCallback, useEffect, useRef } from 'react';

export function useTimeout(callback: () => void, timeout = 0): () => void {
	const timer = useRef<NodeJS.Timeout | number | undefined>(undefined);

	const cancelTimer = useCallback(() => {
		const currentTimer = timer.current;
		if (currentTimer) {
			timer.current = undefined;
			clearTimeout(currentTimer as NodeJS.Timeout);
		}
	}, [timer]);

	useEffect(() => {
		timer.current = setTimeout(callback, timeout);
		return cancelTimer;
	}, [callback, timeout, cancelTimer]);

	return cancelTimer;
}
