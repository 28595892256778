import { extendedTrialPromotions } from '@/components/PreSignupFlow/ConfigData/MessageSets';
import { isSamsungPromotion, samsungPromotionsData } from '@/components/subscribe/Samsung/types';
import { Prices } from '@/store/prices/types';
import { GiftPrice, PurchaseParams, PurchaseType } from '@/store/purchaseParams/types';
import { Subscription, User } from '@/store/user/types';
import { calmLogger } from '@/utils/calmLogger';
import { userCanSubscribe } from '@/utils/subscriptions';

export const ALREADY_TRIALING = { type: 'already-trialing' } as const;
export const ALREADY_SUBSCRIBED = { type: 'already-subscribed' } as const;
export const PREVIOUSLY_TRIALED = { type: 'previously-trialed' } as const;

const calmPromotions = ['guest_pass', '14_day_free_trial', '30_day_free_trial'] as const;

export const CalendarUnits = {
	MONTHS: 'months',
	DAYS: 'days',
} as const;

interface PurchaseArgs extends Omit<PurchaseParams, 'purchaseType'> {
	abTests: { [testName: string]: string };
	prices: Prices;
	user: User | null;
	giftPrice?: GiftPrice;
}

type PurchaseValues = Omit<PurchaseArgs, 'prices' | 'user'>;

export default class Purchase {
	values: PurchaseValues;

	prices: Prices;

	user: User | null;

	constructor({
		plan,
		coupon,
		promotion,
		calmjwt,
		samsungGUID,
		guestPass,
		is30DayOverride,
		abTests,
		prices,
		user,
		giftPrice,
		trialLengthOverride,
	}: PurchaseArgs) {
		this.values = {
			plan,
			coupon,
			promotion,
			calmjwt,
			samsungGUID,
			guestPass,
			is30DayOverride,
			abTests,
			giftPrice,
			trialLengthOverride,
		};
		this.prices = prices;
		this.user = user;
	}

	generateSubscriptionDetails(details: PurchaseType): PurchaseType {
		if (userCanSubscribe(this.user)) {
			return details;
		}

		if (details.type === 'freetrial') {
			if (this.subscription?.in_free_trial_window && this.subscription?.valid) {
				return ALREADY_TRIALING;
			}
			if (this.subscription?.valid) {
				return ALREADY_SUBSCRIBED;
			}
			if (this.subscription?.has_ever_done_free_trial) {
				return PREVIOUSLY_TRIALED;
			}
		}

		if (details.type === 'lifetime') {
			if (this.subscription?.valid && this.subscription?.is_lifetime) {
				return ALREADY_SUBSCRIBED;
			}
		}

		if (details.type === 'subscribe') {
			if (!this.values.giftPrice && this.subscription?.valid) {
				if (this.subscription?.is_lifetime || this.subscription?.will_renew) {
					return ALREADY_SUBSCRIBED;
				}
			}
		}

		return details;
	}

	get subscription(): Subscription | undefined {
		return this.user?.subscription;
	}

	get properties(): PurchaseValues {
		return this.values;
	}

	get purchaseType(): PurchaseType {
		// Promotions first for subscription purchases
		if (this.properties.promotion) {
			// Samsung first
			if (isSamsungPromotion(this.properties.promotion)) {
				if (this.properties.promotion in samsungPromotionsData) {
					return this.generateSubscriptionDetails({
						modifier: 'samsung_health',
						price: this.prices.current.yearly,
						promotion: this.properties.promotion,
						...samsungPromotionsData[this.properties.promotion].subscriptionDetails,
					});
				}

				// Fallback on no offers at all
				calmLogger.warn('No promotion data found for Samsung Health promotion', {
					promotion: this.properties.promotion,
				});
				return this.generateSubscriptionDetails({
					type: 'subscribe',
					modifier: 'samsung_health',
					price: this.prices.current.yearly,
				});
			}
			// Not part of the promotions enum, as we don't want them used outside of the Amazon Prime page
			if (this.properties.promotion === 'amazon_prime_student') {
				if (this.properties.plan === 'yearly') {
					return {
						type: 'freetrial',
						modifier: 'amazon_prime_student',
						duration: 3,
						units: CalendarUnits.MONTHS,
						price: this.prices.current.yearly,
					};
				}
			}
			// Calm second
			if (calmPromotions.includes(this.properties.promotion as (typeof calmPromotions)[number])) {
				if (this.properties.promotion === 'guest_pass') {
					return this.generateSubscriptionDetails({
						type: 'freetrial',
						modifier: 'guest_pass',
						duration: 30,
						units: CalendarUnits.DAYS,
						price: this.prices.current.yearly,
					});
				}
				if (this.properties.promotion === '14_day_free_trial') {
					return this.generateSubscriptionDetails({
						type: 'freetrial',
						duration: 14,
						units: CalendarUnits.DAYS,
						price: this.prices.current.yearly,
					});
				}
				if (this.properties.promotion === '30_day_free_trial') {
					return this.generateSubscriptionDetails({
						type: 'freetrial',
						duration: 30,
						units: CalendarUnits.DAYS,
						price: this.prices.current.yearly,
					});
				}
				return this.generateSubscriptionDetails({
					type: 'freetrial',
					duration: 7,
					units: CalendarUnits.DAYS,
					price: this.prices.current.yearly,
				});
			}

			if (extendedTrialPromotions.includes(this.properties.promotion)) {
				const trialLength = Number(this.properties.promotion.slice(0, 2));
				return this.generateSubscriptionDetails({
					type: 'freetrial',
					duration: trialLength,
					units: CalendarUnits.DAYS,
					price: this.prices.current.yearly,
				});
			}
		}
		if (
			this.properties.plan === 'yearly' &&
			this.properties.trialLengthOverride &&
			this.properties.guestPass // trial length is only used with guest pass as of now
		) {
			// Slight duplicate of above, but that's okay. This covers the case of not having
			// the promotion set and instead just using the query param
			return this.generateSubscriptionDetails({
				type: 'freetrial',
				modifier: 'guest_pass',
				duration: this.properties.trialLengthOverride,
				units: CalendarUnits.DAYS,
				price: this.prices.current.yearly,
			});
		}
		if (this.properties.plan === 'yearly' && this.properties.coupon === 'freetrial') {
			if (this.properties.is30DayOverride) {
				return this.generateSubscriptionDetails({
					type: 'freetrial',
					duration: 30,
					units: CalendarUnits.DAYS,
					price: this.prices.current.yearly,
				});
			}
			return this.generateSubscriptionDetails({
				type: 'freetrial',
				duration: 14,
				units: CalendarUnits.DAYS,
				price: this.prices.current.yearly,
			});
		}
		if (this.properties.plan === 'monthly') {
			return this.generateSubscriptionDetails({
				type: 'monthly',
				price: this.prices.current.monthly,
			});
		}
		if (this.properties.plan === 'lifetime') {
			return this.generateSubscriptionDetails({
				type: 'lifetime',
				price: this.prices.current.lifetime,
			});
		}
		// Default to a yearly subscription without a coupon
		// This happens when the class isn't setup properly - a plan needs to be selected
		return this.generateSubscriptionDetails({
			type: 'subscribe',
			price: this.prices.current.yearly,
		});
	}
}
