import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics';
import { useAuthFormModeState } from '@/hooks/store';
import { User } from '@/store/user/types';
import { AppleAuthArgs, postAppleAuth } from '@/utils/endpoints/postAppleAuth';
import { formatError } from '@/utils/formatErrors';
import { getAppleSignInToken } from '@/utils/forms/getAppleSignInToken';
import { parseLoginError } from '@/utils/forms/parseLoginError';
import getUrl from '@/utils/getUrl';
import { CalmAuthMethods } from '@/utils/privacyCookies';
import { JSONValue } from '@/utils/types';

import { useSetupUserAfterAuth } from './useSetupUserAfterAuth';

declare global {
	interface Window {
		AppleID: {
			auth: {
				init: (data: Record<string, string | boolean | undefined>) => void;
				signIn: () => Promise<{
					authorization: { code: string };
					user: JSONValue;
				}>;
			};
		};
	}
}

export function useAuthAppleUser(redirectUri?: string) {
	const { formatMessage } = useIntl();

	const { logEvent } = useAnalytics();
	const authFormMode = useAuthFormModeState();
	const authFormModeForAnalytics = authFormMode || 'unknown';
	const setupUserAfterAuth = useSetupUserAfterAuth();

	async function authUser({
		code,
		user,
		marketingOptIn,
		guestPassCode,
	}: AppleAuthArgs): Promise<{ success: User } | { error: string }> {
		try {
			const { data } = await postAppleAuth({
				code,
				user,
				marketingOptIn,
				guestPassCode,
				redirectUri,
			});

			logEvent({
				eventName: 'Login Form : Apple : Success',
				eventProps: {
					mode: authFormModeForAnalytics,
				},
			});

			await setupUserAfterAuth(data, CalmAuthMethods.Apple);

			return { success: data };
		} catch (err) {
			const error = err?.data?.error;
			logEvent({
				eventName: 'Login Form : Apple : Error',
				eventProps: {
					mode: authFormModeForAnalytics,
					...formatError(error),
				},
			});
			return { error: formatMessage(parseLoginError(error)) };
		}
	}

	return authUser;
}

export function useAppleSubmit(): (
	marketingOptIn: boolean,
	guestPassCode?: string | undefined,
) => Promise<{ success: User } | { error: string } | undefined> {
	const { logEvent } = useAnalytics();
	const authFormMode = useAuthFormModeState();
	const authFormModeForAnalytics = authFormMode || 'unknown';
	const authUser = useAuthAppleUser();

	function initializeAppleSDK(): void {
		if (
			typeof window?.AppleID !== 'undefined' &&
			process.env.NEXT_PUBLIC_APPLE_CLIENT_ID &&
			process.env.NEXT_PUBLIC_APPLE_REDIRECT_PATH
		) {
			const appleRedirectUri = `${getUrl('www')}${process.env.NEXT_PUBLIC_APPLE_REDIRECT_PATH}`;
			window.AppleID.auth.init({
				clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
				redirectURI: appleRedirectUri,
				scope: 'email name',
				state: 'user sign in authentication',
				usePopup: true,
			});
		}
	}

	async function onSubmit(
		marketingOptIn: boolean,
		guestPassCode?: string | undefined,
	): Promise<{ success: User } | { error: string } | undefined> {
		logEvent({
			eventName: 'Login Form : Apple : Clicked',
			eventProps: {
				mode: authFormModeForAnalytics,
			},
		});

		const data = await getAppleSignInToken();

		if (!data || 'error' in data) {
			logEvent({
				eventName: 'Login Form : Apple : Cancelled',
				eventProps: {
					mode: authFormModeForAnalytics,
				},
			});
			return undefined;
		}

		const { code, user } = data;
		const userAuth = await authUser({ code, user, marketingOptIn, guestPassCode });
		return userAuth;
	}

	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_APPLE_CLIENT_ID) return;

		const script = document.createElement('script');
		script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
		script.async = true;
		script.defer = true;
		script.onload = initializeAppleSDK;
		document.body.appendChild(script);
	}, []);

	return onSubmit;
}
