import { AnyAction } from 'redux';

import { Notification, SET_NOTIFICATION } from './types';

const initialState: Notification | null = null;

export function notificationReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): Notification | null {
	switch (action.type) {
		case SET_NOTIFICATION:
			return action.payload;
		default:
			return state;
	}
}
