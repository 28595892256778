import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PacksContext } from '@/components/app/layout/PacksProvider';
import { useUserState } from '@/hooks/store';
import genericMessages from '@/messages/messages';
import { userCanTrial } from '@/utils/subscriptions';

const messages = defineMessages({
	shareHeading: {
		id: 'player.share.heading.v2',
		defaultMessage: 'Offer Today Only',
		description: 'Heading for receipient that receives a Calm session via share',
	},
	noPreviewHeading: {
		id: 'player.share.noPreviewHeading',
		defaultMessage: 'This video is not available for preview.',
		description: "Heading for content that isn' available to the user",
	},
	shareSubheading: {
		id: 'player.share.subheading.v2',
		defaultMessage: '{name} sent you an extended 30-day trial of Calm Premium.',
		description: 'Subheading for receipient that receives a Calm session via share',
	},
	shareHeadingIneligible: {
		id: 'player.share.ineligible.heading',
		defaultMessage: 'Welcome back to Calm!',
		description: 'Heading for receipient that receives a Calm session via share',
	},
	shareSubheadingIneligible: {
		id: 'player.share.subheading.ineligible2',
		defaultMessage: "You're not eligible for a trial but you can subscribe to Calm Premium for more.",
		description: 'Subheading for receipient that receives a Calm session via share but is ineligible',
	},
	shareCta: {
		id: 'player.share.cta.2',
		defaultMessage: 'Continue',
		description: 'Button that takes user to sign up for 30 free days of Calm',
	},
	shareSidebarCta: {
		id: 'player.share.cta.sidebar',
		defaultMessage: 'Claim Offer',
		description: 'Button that takes user to sign up for 30 free days of Calm',
	},
	nameFallback: {
		id: 'player.share.cta.sender.namefallback',
		defaultMessage: 'Your friend',
		description: 'A friendly name in place of the senders real first name',
	},
});

export const useShareCopy = (shareSenderFirstName?: string) => {
	const { formatMessage } = useIntl();
	const user = useUserState();
	const isEligible = userCanTrial(user);
	const { currentGuide } = useContext(PacksContext);

	function getShareSubheading() {
		if (isEligible) {
			return formatMessage(messages.shareHeading);
		}
		return formatMessage(messages.shareHeadingIneligible);
	}

	const name = shareSenderFirstName ?? formatMessage(messages.nameFallback);
	const cta = isEligible ? formatMessage(messages.shareCta) : formatMessage(genericMessages.subscribe);
	const sidebarCta = isEligible
		? formatMessage(messages.shareSidebarCta)
		: formatMessage(genericMessages.subscribe);
	const heading = currentGuide?.capabilities?.non_shareable
		? formatMessage(messages.noPreviewHeading)
		: getShareSubheading();
	const subheading = isEligible
		? formatMessage(messages.shareSubheading, {
				name,
		  })
		: formatMessage(messages.shareSubheadingIneligible);

	return { cta, heading, subheading, sidebarCta };
};
