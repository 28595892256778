export const REQUIRE_GDPR_MODAL = 'require_gdpr_modal';
export const IS_HIPAA = 'hipaa_compliant';
export const NO_RESTRICTIONS = 'no_restrictions';
export const IS_GDPR_LIMITED = 'gdpr_limited_data';
export const IS_LIMITED = 'limited_data';

export type ComplianceLevel =
	| typeof REQUIRE_GDPR_MODAL
	| typeof IS_HIPAA
	| typeof IS_LIMITED
	| typeof IS_GDPR_LIMITED
	| typeof NO_RESTRICTIONS;

export const SET_COMPLIANCE_LEVEL = 'SET_COMPLIANCE_LEVEL';

export interface SetComplianceLevelAction {
	type: typeof SET_COMPLIANCE_LEVEL;
	payload: ComplianceLevel;
}
