import styled from 'styled-components';
import { palette, withProp } from 'styled-tools';

import { CalmTheme, minWidth, fontWeight, FontWeights } from '@calm-web/design-system';

export const Wrapper = styled.div<{
	halfWidth?: boolean;
}>`
	margin-bottom: 8px;
	width: ${p => (p.halfWidth ? 'calc(50% - 8px)' : '100%')};

	${minWidth('tablet')} {
		margin-bottom: 16px;
	}
`;

export const InputWrapper = styled.div<{
	isValid: boolean;
	isError?: boolean;
	isFocused: boolean;
}>`
	background-color: ${palette('white')};
	border: 1px solid ${palette('inputBorderFocus')};
	border-radius: 6px;
	position: relative;
	height: 3em;
	border-width: ${p => (p.isFocused && !p.isError ? '2px' : '1px')};
	border-color: ${withProp(['isError', 'isFocused', 'isValid'], (isError, isFocused, isValid) => {
		if (isError) {
			return palette('inputBorderError');
		}
		if (isFocused) {
			return palette('inputBorderFocus');
		}
		if (isValid) {
			return palette('inputBorderFull');
		}
		return palette('inputBorderEmpty');
	})};

	${minWidth('mobileLarge')} {
		height: ${28 / 9}em;
	}

	.StripeElement {
		padding: 20px 12px 12px;
	}
`;

export const Label = styled.label<{ isFocused: boolean }>`
	color: ${p => (p.isFocused ? palette('inputLabel') : palette('inputPlaceholder'))};
	${fontWeight(FontWeights.Medium)};
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transform-origin: 0 0;
	transition: all 0.15s ease-out;
	z-index: 1;
	transform: ${p => {
		// +1 for the border
		const x = 12;
		const y = (48 - 24) / 2; // (height - lineHeight) / 2
		// Note: in monorepo, due to our different handling of font sizes, the y values are 7px or (y+3)px respectively
		return p.isFocused ? `translate3d(${x}px, 5px, 0) scale(0.66)` : `translate3d(${x}px, ${y}px, 0)`;
	}};

	${minWidth('mobileLarge')} {
		transform: ${p => {
			// +1 for the border
			const x = 12;
			const y = (56 - 28) / 2; // (height - lineHeight) / 2
			// Note: in monorepo, due to our different handling of font sizes, the y values are 10px or (y+4)px respectively
			return p.isFocused ? `translate3d(${x}px, 4px, 0) scale(0.66)` : `translate3d(${x}px, ${y + 2}px, 0)`;
		}};
	}
`;

export const ErrorContainer = styled.div`
	color: ${palette('errorRed')};
	font-size: 14px;
	padding-right: 1rem;
	width: 100%;
	text-align: left;
	padding-top: 4px;
`;

export const INPUT_OPTIONS = {
	style: {
		base: {
			iconColor: CalmTheme.palette.black,
			color: CalmTheme.palette.gray7,
			fontWeight: '500',
			fontFamily: 'Avenir Next, Arial',
			fontSize: '18px',
			fontSmoothing: 'antialiased',
			'::placeholder': {
				color: 'rgba(255, 255, 255, 0.0)',
			},
		},
		invalid: {
			iconColor: CalmTheme.palette.errorRed,
			color: CalmTheme.palette.errorRed,
		},
		complete: {},
	},
};
