import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import { useFormattedCurrency } from '@/hooks/purchase';
import { useAppLoadedState, useLocaleState, usePricesState, useUserState } from '@/hooks/store';
import { Campaign } from '@/store/campaign/types';
import { Prices } from '@/store/prices/types';
import { User } from '@/store/user/types';
import redirect from '@/utils/legacy/redirect';
import isValidLifetimeCoupon from '@/utils/prices/isValidLifetimeCoupon';
import { userCanSubscribe } from '@/utils/subscriptions';

import MessageBannerRow from './MessageBannerRow';
import messages from './messages';
import { Background, ContentWrapper, Grid, HeroSubText, HeroText, Overlay, Wrapper } from './styles';

export const getPodcastRedirectUrl = (
	user: User | null,
	prices: Prices,
	hasLifeTimeUpsellCoupon: boolean,
) => {
	const isSubscribed = user?.subscription?.valid ?? false;
	const isLoggedIn = Boolean(user);
	const isFreeTrial = !(
		hasLifeTimeUpsellCoupon ||
		isSubscribed ||
		user?.subscription?.has_ever_done_free_trial ||
		isLoggedIn ||
		prices.coupons?.yearly
	);

	// Default case, users who can't subscribe have access already
	if (!userCanSubscribe(user)) {
		return '/meditate';
	} else if (userCanSubscribe(user) && !isFreeTrial) {
		return '/subscribe';
	}

	return '/signup-flow?;';
};

const CampaignLayout: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
	const { formatMessage } = useIntl();
	const formatCurrency = useFormattedCurrency();
	const locale = useLocaleState();
	const prices = usePricesState();
	const user = useUserState();
	const appLoaded = useAppLoadedState();
	const hasLifeTimeUpsellCoupon = isValidLifetimeCoupon(prices);

	const renderTitle = () => {
		if (hasLifeTimeUpsellCoupon) return formatMessage(messages.heroUpgrade);
		return formatMessage(messages.hero);
	};

	const renderSubtitle = () => {
		if (hasLifeTimeUpsellCoupon) return formatMessage(messages.heroSubtitleUpgrade);
		return formatMessage(messages.heroSubtitle);
	};

	const renderButtonText = () => {
		if (campaign.is_ge === 'true')
			return formatMessage(messages.signupPriceHeading, {
				amount: formatCurrency(prices.current?.yearly),
			});
		if (hasLifeTimeUpsellCoupon)
			return formatMessage(messages.signupPriceHeading, {
				amount: formatCurrency(prices.current?.lifetime),
			});
		if (!prices.coupons?.yearly) {
			return 'Subscribe Now';
		}
		return formatMessage(messages.signupDiscountHeading, {
			percentOff: prices.coupons?.yearly.percent_off,
		});
	};

	const onCtaClick = () => {
		const url = getPodcastRedirectUrl(user, prices, hasLifeTimeUpsellCoupon);

		redirect(url);
	};

	const isSubscribed = user?.subscription?.valid ?? false;
	const messageBannerMessage = isSubscribed
		? campaign.subscribed_banner_string
		: campaign.not_subscribed_banner_string;

	return (
		<Wrapper>
			<Background src="/_n/images/jasper-lake-hi.webp" />
			<Overlay gradient="gradientHome" mobileGradient="gradientHomeMobile" />
			<Grid columns={1} id={MAIN_CONTENT_ID}>
				<ContentWrapper $centerContent>
					{messageBannerMessage && (
						<MessageBannerRow bannerMessage={messageBannerMessage} bannerLogo={campaign.banner_logo} />
					)}
					<HeroText $textSize={locale === 'ja' ? 'small' : 'large'}>{renderTitle()}</HeroText>
					<HeroSubText>{renderSubtitle()}</HeroSubText>
					<Button
						size="lg"
						backgroundColor="gradientCalmBrand"
						fullWidth
						maxWidth="400px"
						onPress={onCtaClick}
						isLoading={!appLoaded || !prices?.current}
					>
						{renderButtonText()}
					</Button>
				</ContentWrapper>
			</Grid>
		</Wrapper>
	);
};

export default CampaignLayout;
