import { defineMessages } from 'react-intl';

export default defineMessages({
	neverSubsAndChurnedUsersWithoutTrials: {
		id: 'upsell.banner.neverSubsAndChurnedUsersWithoutTrials',
		defaultMessage:
			"You're missing out on the full Calm experience. Subscribe with 40% off to unlock 50,000+ minutes of content.",
		description: 'Title text on the partner landing page',
	},
	churnedUsersWithTrial: {
		id: 'upsell.banner.neverSubsAndChurnedUsersWithoutTrials',
		defaultMessage:
			"You're missing out on the full Calm experience. Subscribe with 40% off to unlock 50,000+ minutes of content.",
		description: 'Title text on the partner landing page',
	},
	userLoggedOut: {
		id: 'upsell.banner.neverSubsAndChurnedUsersWithoutTrials',
		defaultMessage:
			"You're missing out on the full Calm experience. Subscribe with 40% off to unlock 50,000+ minutes of content.",
		description: 'Title text on the partner landing page',
	},
	firstButtonLoggedOut: {
		id: 'upsell.banner.firstButtonLoggedOut.2',
		defaultMessage: 'Log In',
		description: 'First button text on the upsell banner',
	},
	secondButtonLoggedOut: {
		id: 'upsell.banner.secondButtonLoggedOut.2',
		defaultMessage: 'Try Calm for Free',
		description: 'Second button text on the upsell banner',
	},
	firstButtonNeverTrialed: {
		id: 'upsell.banner.firstButtonNeverTrialed',
		defaultMessage: 'Use Mobile App',
		description: 'First button text on the upsell banner',
	},
	secondButtonNeverTrialed: {
		id: 'upsell.banner.secondButtonNeverTrialed.2',
		defaultMessage: 'Try Calm for Free',
		description: 'Second button text on the upsell banner',
	},
	firstButtonChurnedAndTrialed: {
		id: 'upsell.banner.firstButtonChurnedAndTrialed',
		defaultMessage: 'Use Mobile App',
		description: 'First button text on the upsell banner',
	},
	secondButtonChurnedAndTrialed: {
		id: 'upsell.banner.secondButtonChurnedAndTrialed',
		defaultMessage: 'Get 40% off',
		description: 'Second button text on the upsell banner',
	},
	secondButtonPremiumUser: {
		id: 'upsell.banner.secondButtonPremiumUser',
		defaultMessage: 'Use Mobile App',
		description: 'Second button text on the upsell banner',
	},
});
