import { defineMessages } from 'react-intl';

export default defineMessages({
	samsungPurchaseHeader: {
		id: 'samsungHealth.purchase.header',
		defaultMessage: 'Calm in Samsung Health',
		description: 'Header for Calm in Samsung Health',
	},
	samsungPurchase7dayTitle: {
		id: 'samsungHealth.purchase.7day.title',
		defaultMessage: '7-Day Free Trial',
		description: '7 day free trial text',
	},
	samsungPurchase6MonthTitle: {
		id: 'samsungHealth.purchase.6month.title',
		defaultMessage: '6 Month Free Trial',
		description: '6 month free trial text',
	},
	samsungPurchase1MoTitle: {
		id: 'samsungHealth.purchase.1mo.title',
		defaultMessage: '1 Month Free Trial',
		description: '1 month free trial text',
	},
	samsungPurchase2MoTitle: {
		id: 'samsungHealth.purchase.2mo.title',
		defaultMessage: '2 Month Free Trial',
		description: '2 month free trial text',
	},
	samsungPurchase3MoTitle: {
		id: 'samsungHealth.purchase.3mo.title',
		defaultMessage: '3 Month Free Trial',
		description: '3 month free trial text',
	},
	samsungPurchase6MoTitle: {
		id: 'samsungHealth.purchase.6mo.title',
		defaultMessage: '6 Month Free Trial',
		description: '6 month free trial text',
	},
	samsungPurchasePricing: {
		id: 'samsungHealth.purchase.pricing',
		defaultMessage: 'then <boldtext>{price}</boldtext> billed annually, cancel anytime.',
		description: 'Paragraph showing price of subscription after free trial',
	},
	samsungAlreadySubscribedTitle: {
		id: 'samsungHealth.alreadySubscribed.title',
		defaultMessage: 'Your Calm Premium subscription is active',
		description: 'Text to let user know their subscription is active',
	},
	samsungAlreadyLinkedSubtitle: {
		id: 'samsungHealth.alreadyLinked.subtitle',
		defaultMessage:
			"This account is already linked to Samsung Health. You have full access to Calm's entire content library.",
		description: 'Text to let user know they already linked their account to Samsung Health',
	},
	deeplinkButton: {
		id: 'samsungHealth.subscribeSuccess.deeplinkButton',
		defaultMessage: 'Back To Samsung Health',
		description: 'Button to go back to Samsung Health app',
	},
	successTitle: {
		id: 'samsungHealth.subscribeSuccess.title',
		defaultMessage: 'Congrats, {firstName}',
		description: 'Congrats heading with first name',
	},
	promotionSubtitle: {
		id: 'samsungHealth.subscribeSuccess.promotionSubtitle',
		defaultMessage: 'Your 6-Month Free Trial to Calm in Samsung Health has been activated',
		description: 'Text to let user know they activated a 6 month free trial',
	},
	samsung1MoTrialSubtitle: {
		id: 'samsungHealth.subscribeSuccess.1moTrialSubtitle',
		defaultMessage: 'Your 1-Month Free Trial to Calm in Samsung Health has been activated',
		description: 'Text to let user know they activated a 1 month free trial',
	},
	samsung2MoTrialSubtitle: {
		id: 'samsungHealth.subscribeSuccess.2moTrialSubtitle',
		defaultMessage: 'Your 2-Month Free Trial to Calm in Samsung Health has been activated',
		description: 'Text to let user know they activated a 2 month free trial',
	},
	samsung3MoTrialSubtitle: {
		id: 'samsungHealth.subscribeSuccess.3moTrialSubtitle',
		defaultMessage: 'Your 3-Month Free Trial to Calm in Samsung Health has been activated',
		description: 'Text to let user know they activated a 3 month free trial',
	},
	samsung6MoTrialSubtitle: {
		id: 'samsungHealth.subscribeSuccess.6moTrialSubtitle',
		defaultMessage: 'Your 6-Month Free Trial to Calm in Samsung Health has been activated',
		description: 'Text to let user know they activated a 6 month free trial',
	},
	samsungSuccessSubtitle: {
		id: 'samsungHealth.subscribeSuccess.subtitle',
		defaultMessage: 'Your yearly subscription has been activated',
		description: 'Text to let user know they activated a yearly subscription',
	},
	samsung1MoTrialPurchaseTerms: {
		id: 'redesign.purchase.creditCard.disclaimers.samsungHealthCoupon1MO',
		defaultMessage:
			"Your 1-Month free trial starts after you enter payment details, and you will have unlimited access to Calm's entire library of meditations, Sleep Stories, and Music in Samsung Health.",
		description: 'Disclaimers for the Samsung Health coupon (1-month version)',
	},
	samsung2MoTrialPurchaseTerms: {
		id: 'redesign.purchase.creditCard.disclaimers.samsungHealthCoupon2MO',
		defaultMessage:
			"Your 2-Month free trial starts after you enter payment details, and you will have unlimited access to Calm's entire library of meditations, Sleep Stories, and Music in Samsung Health.",
		description: 'Disclaimers for the Samsung Health coupon (2-month version)',
	},
	samsung3MoTrialPurchaseTerms: {
		id: 'redesign.purchase.creditCard.disclaimers.samsungHealthCoupon3MO',
		defaultMessage:
			"Your 3-Month free trial starts after you enter payment details, and you will have unlimited access to Calm's entire library of meditations, Sleep Stories, and Music in Samsung Health.",
		description: 'Disclaimers for the Samsung Health coupon (3-month version)',
	},
	samsung6MoTrialPurchaseTerms: {
		id: 'redesign.purchase.creditCard.disclaimers.samsungHealthCoupon',
		defaultMessage:
			"Your 6-Month free trial starts after you enter payment details, and you will have unlimited access to Calm's entire library of meditations, Sleep Stories, and Music in Samsung Health.",
		description: 'Disclaimers for the Samsung Health coupon (6-month version)',
	},
	successRedirect: {
		id: 'samsungHealth.subscribeSuccess.redirect',
		defaultMessage: 'Please click the button below to redirect back to Calm in Samsung Health.',
		description: 'Text to inform user to click the button to go back to Samsung Health app',
	},
	loggedInAs: {
		id: 'samsungHealth.alreadyLinked.loggedIn',
		defaultMessage: 'Logged in as:',
		description: 'Text to let user know who they are logged in as',
	},
	logout: {
		id: 'samsungHealth.alreadyLinked.logout',
		defaultMessage: 'Log out',
		description: 'Text button to log user out of Calm',
	},
	genericError: {
		id: 'samsungHealth.subscribeError',
		defaultMessage: 'Oops! Something went wrong. Please try again later.',
		description: 'A generic error message',
	},
	subscribeTitle: {
		id: 'samsungHealth.subscribe.title',
		defaultMessage: '12 months of Calm Premium',
		description: 'The length of a yearly Calm sub',
	},
});
