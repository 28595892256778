import { User } from '@/store/user/types';
import { getMe } from '@/utils/endpoints/getMe';

export async function initUser(): Promise<User | null> {
	try {
		const { data } = await getMe();
		return data;
	} catch (err) {
		return null;
	}
}
