import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import purchaseMessages from '../messages';
import messages from './messages';
import { CVVImage, Wrapper } from './styles';
import { CvcHelpModalProps } from './types';

const CVVAmex = 'https://assets.calm.com/7c20b3b432b094002355b2c941b40f20.png';
const CVVCard = 'https://assets.calm.com/aa69e077e39255dd082b9c964b9d0528.png';

const CvcHelpModal = ({ isCvcModalShown, setIsCvcModalShown }: CvcHelpModalProps) => {
	const { formatMessage } = useIntl();
	return (
		<Modal
			isOpen={isCvcModalShown}
			closeModal={() => setIsCvcModalShown(false)}
			canClose
			aria-label={formatMessage(purchaseMessages.cvvHelpModalTitle)}
		>
			<Wrapper>
				{formatMessage(messages.cvvHelpText)}
				<CVVImage src={CVVCard} alt="security code example: front" />
				<CVVImage src={CVVAmex} alt="security code example: back" />
			</Wrapper>
		</Modal>
	);
};

export default CvcHelpModal;
