import { AxiosPromise } from 'axios';

import apiRequest from '@/utils/apiRequest';

type PollResponse = {
	poll_name: string;
	question: string;
	answers: string[];
};

export function postQuestionnairePoll(poll_responses: PollResponse[]): AxiosPromise {
	return apiRequest({
		endpoint: 'poll-responses',
		method: 'POST',
		body: {
			poll_responses,
		},
	});
}
