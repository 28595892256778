/* eslint-disable no-param-reassign */

import getUrl from '../getUrl';

/* eslint-disable prefer-destructuring */
function constructMapping(baseUrl: string, queryParamMapping: Record<string, string>, params: string[]) {
	params.forEach(param => {
		const paramKeyValue = param.split('=');
		if (paramKeyValue.length > 1 && typeof paramKeyValue[1] === 'string') {
			if (paramKeyValue[1].replace('%2F', '') !== baseUrl.replace('/', '')) {
				queryParamMapping[paramKeyValue[0]] = paramKeyValue[1];
			}
		}
	});
}

const _getRedirectUrl = (newUrl: string, paramsToRemove: string[] = []): string => {
	if (typeof window === 'undefined') {
		return newUrl;
	}

	const queryParams = (() => {
		if (!window.location.search) {
			return [];
		}
		const currentQueryParams = window.location.search.slice(1);
		return currentQueryParams.split('&').filter(param => {
			if (typeof param === 'string' && param.split('=').length <= 1) {
				return false;
			}
			return true;
		});
	})();

	const splitUrl = newUrl.split('?');

	const redirectUrl = (() => {
		return splitUrl[0];
	})();

	const newQueryParams = (() => {
		if (splitUrl.length <= 1) {
			return [];
		}

		return splitUrl[1].split('&');
	})();

	// Take only the latest duplicate query param from old/new params
	const queryParamMapping: Record<string, string> = {};
	if (queryParams) {
		constructMapping(redirectUrl, queryParamMapping, queryParams);
	}
	constructMapping(redirectUrl, queryParamMapping, newQueryParams);

	// Flatten out the object back into the array
	const flattenedQueryParams = Object.keys(queryParamMapping)
		.filter(param => !paramsToRemove.includes(param))
		.map(param => {
			return `${param}=${queryParamMapping[param]}`;
		});

	if (queryParams.length + flattenedQueryParams.length < 1) {
		return redirectUrl;
	}

	const redirectUrlWithParams = (() => {
		const paramPart = flattenedQueryParams.join('&');
		return `${redirectUrl}?${paramPart}`;
	})();

	return redirectUrlWithParams;
};

/**
 * When redirecting to api.calm.com, none of our cookies will be sent,
 * as our NEXT_PUBLIC_COOKIE_DOMAIN does not match api.calm.com.
 * So, if we want to send along cookies, we have to go through our proxy
 * which rewrites NEXT_PUBLIC_API_ENDPOINT to api.calm.com under the hood,
 * but NEXT_PUBLIC_API_ENDPOINT does match NEXT_PUBLIC_COOKIE_DOMAIN
 *
 * @param redirectUrl The redirectUrl, which may or may not be our prod API URL
 * @returns The redirectUrl, but replacing api.calm.com with process.env.NEXT_PUBLIC_API_ENDPOINT
 */
export const rewriteProdApiToProxy = (redirectUrl: string): string => {
	if (!redirectUrl.startsWith('https://api.calm.com')) {
		return redirectUrl;
	}
	return redirectUrl.replace('https://api.calm.com', `${getUrl('api')}`);
};

export const getRedirectUrl = (newUrl: string, paramsToRemove: string[] = []): string => {
	const redirectUrl = _getRedirectUrl(newUrl, paramsToRemove);
	return rewriteProdApiToProxy(redirectUrl);
};

const redirect = (newUrl: string, paramsToRemove?: string[]): void => {
	const redirectUrl = getRedirectUrl(newUrl, paramsToRemove);
	if (typeof window !== 'undefined') {
		window.location.href = redirectUrl;
	}
};

export default redirect;

const openRedirectAllowedDomains = [/.*\.calm\.com/i];

/**
 *
 * @param target The desired redirect URL
 * @returns the target URL, if allowed. Otherwise, redirects user to the home page
 */
export const protectOpenRedirect = (target: string): string => {
	// Path redirects are safe
	if (target.startsWith('/')) {
		return target;
	}
	const parsedUrl = new URL(target);
	const isAllowed = openRedirectAllowedDomains.some(allowedDomainRegexp =>
		allowedDomainRegexp.test(parsedUrl.hostname),
	);
	if (!isAllowed) {
		return '/';
	}
	return target;
};
