import { MessageDescriptor } from 'react-intl';

import {
	MentalHealthSectionTitle,
	MentalHealthSlide,
} from '@/components/homepageRefresh/homepage/MentalHealthTools/types';
import { CopyItem } from '@/components/valuePropList';

import { onboardingMessages, sleepMessages } from '../../ConfigData/MessageSets';
import {
	sleepCurrentStateTiles,
	howOftenTiles,
	sleepIdealStateTiles,
} from '../../ConfigData/QuestionTileSets';
import SleepFAQs from '../../ConfigData/SleepFAQs.mdx';
import { SignupScreen, SignupScreens } from '../../PreSignupProvider/types';

const marqueValueProps: Array<MessageDescriptor> = [
	sleepMessages.heroBodyValue0,
	sleepMessages.heroBodyValue1,
	sleepMessages.heroBodyValue2,
	sleepMessages.heroBodyValue3,
];

export const sections: Record<MentalHealthSectionTitle, MentalHealthSlide> = {
	sleepStories: {
		title: sleepMessages.slide1Title,
		heading: sleepMessages.slide1Title,
		copy: sleepMessages.slide1Copy,
		imageUrl: '/_n/images/content/Thumbnail_Sleep-Story.png',
		audioSrc: '/_n/audio/subpages/SLEP_0184_DreamWithMe_EN_2CH_MK_20230802_HomepageCutdown.mp3',
	},
	meditations: {
		title: sleepMessages.slide2Title,
		heading: sleepMessages.slide2Title,
		copy: sleepMessages.slide2Copy,
		contentUrl: '/app/player/QgbYQrASjO',
		imageUrl: '/_n/images/content/Thumbnail_Meditation.png',
		audioSrc: 'https://assets.calm.com/98705f6f8075e674c63e23aa61e32029.m4a',
	},
	music: {
		title: sleepMessages.slide3Title,
		heading: sleepMessages.slide3Title,
		copy: sleepMessages.slide3Copy,
		contentUrl: '/app/player/uvqyM--Byq',
		imageUrl: '/_n/images/content/Thumbnail_Music.png',
		isPreview: true,
		audioSrc: 'https://assets.calm.com/d3a3be2415119a17e3fd615bfbb9fdaa.m4a',
	},
	soundscapes: {
		title: sleepMessages.slide4Title,
		heading: sleepMessages.slide4Title,
		copy: sleepMessages.slide4Copy,
		imageUrl: '/_n/images/content/Thumbnail_Soundscapes.png',
		audioSrc: '/_n/audio/subpages/SSPS_0020_RainOnLeaves_2CH_MK_20230802_HomepageCutdown.mp3',
	},
};

export const sleepValueProps: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: sleepMessages.valueProp1Head,
		body: sleepMessages.valueProp1Body,
	},
	{
		id: 'valueProp2',
		header: sleepMessages.valueProp2Head,
		body: sleepMessages.valueProp2Body,
	},
	{
		id: 'valueProp3',
		header: sleepMessages.valueProp3Head,
		body: sleepMessages.valueProp3Body,
	},
];

const startingPageConfig: SignupScreen = {
	componentName: 'FocusedLandingPage',
	analyticsName: 'FTUE : Transition',
	transitionType: 'welcome_screen',
	nextScreen: 'q1',
	isFullScreen: true,
	heroBgImage: '/_n/images/subpages/sleep-hero.webp',
	heroHeading: sleepMessages.heroHeading,
	heroBody: sleepMessages.heroBody,
	heroHeaderColor: 'blue7',
	showNav: true,
	canExit: false,
	heroSecondaryCTAButtonProperties: {
		label: sleepMessages.heroSecondaryCTA,
	},
	hasSkip: true,
	skipToEndOverride: 'su',
	URLKey: 'landing',
	marqueValueProps,
	toolsHeading: sleepMessages.toolsHeading,
	toolsSubheading: sleepMessages.toolsSubheading,
	toolSections: sections,
	toolCTAButtonProperties: {
		label: onboardingMessages.unlockMore,
	},
	faqs: SleepFAQs,
};

export const sleepQuestionnaireScreens: SignupScreens = {
	start: { ...startingPageConfig },
	landing: { ...startingPageConfig },
	q1: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q2',
		tiles: howOftenTiles,
		question: sleepMessages.question1,
		questionSubtitle: sleepMessages.question1sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 1,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q1',
	},
	q2: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q3',
		tiles: sleepCurrentStateTiles,
		question: sleepMessages.question2,
		questionSubtitle: sleepMessages.question2sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 2,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q2',
	},
	q3: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		tiles: sleepIdealStateTiles,
		question: sleepMessages.question3,
		questionSubtitle: sleepMessages.question3sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 3,
		totalQuestions: 3,
		nextScreen: 'su',
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q3',
	},
	su: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'su',
	},
	pay: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'pay',
	},
};
