import { AriaHidden, CookiePreferencesBannerPlaceholder, FixedBottomContainer } from './styles';

/*
 * We want to create a space for the cookie preferences banner to be below the content when the user scrolls down
 * The real component is rendered in the LayoutWrapper component so that it is correctly ordered for accessibility reasons
 */
export interface FixedBottomProps {
	canBeShown: boolean;
	isForciblyShown: boolean;
	innerRef?: React.Ref<HTMLDivElement>;
	isWebAppLayout?: boolean;
}
export const FixedBottom = ({
	canBeShown,
	isForciblyShown,
	innerRef,
	isWebAppLayout = false,
}: FixedBottomProps) => (
	<FixedBottomContainer ref={innerRef} $isWebAppLayout={isWebAppLayout}>
		{canBeShown && (
			<AriaHidden>
				<CookiePreferencesBannerPlaceholder willForceShow={isForciblyShown} />
			</AriaHidden>
		)}
	</FixedBottomContainer>
);
