import Image from '@/components/image/NextImage';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { formatResizedUrl } from '@/utils/app/formatResizedUrl';
import { AmplitudeExperimentNames } from '@/utils/experiments/amplitudeExperiment';

import { BlackOverlay, BlurredBackground, Wrapper } from './styles';
import { ProgramBackgroundProps } from './types';

const ProgramBackground = ({ backgroundImage, alt }: ProgramBackgroundProps) => {
	const { isEnrolled, isLoading } = useAmplitudeExperiment(AmplitudeExperimentNames.WEB_APP_REDESIGN_2);
	const widthToHeightRatio =
		backgroundImage?.width && backgroundImage?.height ? backgroundImage.width / backgroundImage.height : 1;

	if (isLoading) {
		return null;
	}

	if (isEnrolled) {
		return <BlurredBackground img={formatResizedUrl(backgroundImage.url, 1920, 1920 * widthToHeightRatio)} />;
	}

	return (
		<Wrapper>
			<Image
				layout="fill"
				objectFit="cover"
				src={formatResizedUrl(backgroundImage.url, 1920, 1920 * widthToHeightRatio)}
				alt={`${alt} Background Image`}
			/>
			<BlackOverlay />
		</Wrapper>
	);
};

export default ProgramBackground;
