import { AnyAction } from 'redux';

import { Program, SET_PROGRAM } from './types';

const initialState: Program | null = null;

export function programReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): Program | null {
	switch (action.type) {
		case SET_PROGRAM:
			return action.payload;
		default:
			return state;
	}
}
