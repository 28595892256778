import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import { ProgressBar, RangeBar, UnfinishedProgressBar, Wrapper } from './styles';
import { SeekBarProps } from './types';

const SeekBar = ({
	trackProgress,
	onScrub,
	duration,
	currentTime,
	onEndScrub,
	playerTheme,
	disabled = false,
}: SeekBarProps) => {
	const { formatMessage } = useIntl();
	const percentProgress = duration && duration !== 0 ? (currentTime / duration) * 100 : 0;

	const onClick = (e: MouseEvent<HTMLDivElement>) => e.stopPropagation();

	return (
		<Wrapper sizeTheme={playerTheme} onClick={onClick}>
			<UnfinishedProgressBar>
				<ProgressBar percentProgress={percentProgress} />
			</UnfinishedProgressBar>
			<RangeBar
				type="range"
				value={trackProgress}
				step="1"
				min="0"
				max={duration ?? `${duration}`}
				className="progress"
				onMouseUp={onEndScrub}
				onChange={onScrub}
				aria-label={formatMessage(messages.seekBarLabel)}
				disabled={disabled}
			/>
		</Wrapper>
	);
};

export default SeekBar;
