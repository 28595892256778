import { defineMessages } from 'react-intl';

export default defineMessages({
	preheading: {
		id: 'app.components.DiscountModal.preheading',
		defaultMessage: 'Special welcome offer!',
		description: 'Describes that there is a special discount offer',
	},
	heading: {
		id: 'app.components.DiscountModal.heading',
		defaultMessage: 'Get 40% off - Today only',
		description: 'Specifics about offer',
	},
	discountButtonText: {
		id: 'app.components.DiscountModal.discountButtonText',
		defaultMessage: 'Get discount',
		description: 'Cta to direct user to payment screen',
	},
	skipButtonText: {
		id: 'app.components.DiscountModal.skipButtonText',
		defaultMessage: 'No thanks, skip welcome offer',
		description: 'Cta for user to close modal',
	},
});
