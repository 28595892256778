import styled from 'styled-components';

import { minWidth } from '@calm-web/design-system';

export const Banner = styled.div`
	height: 190px;
	margin-bottom: 16px;
	position: relative;

	${minWidth('tablet')} {
		height: 240px;
		margin-bottom: 32px;
	}
`;
export const Container = styled.div`
	padding: 0 16px 32px;
	text-align: center;

	${minWidth('tablet')} {
		padding: 0 48px 48px;
	}
`;
export const Copy = styled.div`
	border-bottom: 1px #d6d6d6 solid;
	display: grid;
	grid-row-gap: 10px;
	margin-bottom: 24px;
	padding-bottom: 24px;
	text-align: center;

	${minWidth('tablet')} {
		margin-bottom: 32px;
		padding-bottom: 32px;
	}
`;
export const Bullets = styled.div`
	margin-bottom: 32px;
`;
