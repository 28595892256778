import { useIntl } from 'react-intl';

import { Icon, Wrapper } from './styles';
import { UpsellBulletProps } from './types';

const UpsellBullet = ({ color, copy, textColor, tabIndex = 0 }: UpsellBulletProps) => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper aria-label={formatMessage(copy)} tabIndex={tabIndex} $color={color} $textColor={textColor}>
			<Icon />
			{formatMessage(copy)}
		</Wrapper>
	);
};

export default UpsellBullet;
