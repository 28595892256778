import fromPairs from 'lodash/fromPairs';
import { MessageDescriptor } from 'react-intl';

import { LegacyCalmError } from '@/hooks/api/types';
import errorMessages from '@/messages/errorMessages';
import { JSONObject } from '@/utils/types';

export function formatError(err: LegacyCalmError): JSONObject {
	if (!err) {
		return {};
	}

	const fullResult = {
		error_did_timeout: err.didTimeout,
		error_calm_code: err.calmCode,
		error_code: err.code,
		error_status_code: err.httpCode || err.status,
		error_message: err.message,
		error_info: err.info,
		error_calm_message: err.calmMessage,
		error_is_rate_limit: err.isRateLimit,
	};
	return fromPairs(Object.entries(fullResult).filter(entry => typeof entry[1] !== 'undefined')) as JSONObject;
}

export function getLocalizedErrorMessage(
	err: LegacyCalmError,
	formatMessage: (
		descriptor: MessageDescriptor,
		values?: Record<string, string | number | boolean | null | undefined | Date>,
	) => string,
): string {
	if (!err) {
		return formatMessage(errorMessages.generic);
	}
	if (err.calmMessage) return err.calmMessage;
	const errorCode = err.calmCode || err.code;
	if (errorCode && errorCode in errorMessages) {
		return formatMessage((errorMessages as Record<string, MessageDescriptor>)[errorCode]);
	}
	return formatMessage(errorMessages.generic);
}
