import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

export interface QRCodeHandoffContextState {
	isHandoffOpen: boolean;
	setIsHandoffOpen: Dispatch<SetStateAction<boolean>>;
}

export const QRCodeHandoffContext = createContext<QRCodeHandoffContextState>({
	isHandoffOpen: false,
	setIsHandoffOpen: () => {},
});

export const useQRCodeHandoffContext = (): QRCodeHandoffContextState => useContext(QRCodeHandoffContext);

const QRCodeHandoffContextProvider = ({ children }: { children: ReactNode }) => {
	const [isHandoffOpen, setIsHandoffOpen] = useState(false);
	const value = useMemo(
		() => ({
			isHandoffOpen,
			setIsHandoffOpen,
		}),
		[isHandoffOpen],
	);

	return <QRCodeHandoffContext.Provider value={value}>{children}</QRCodeHandoffContext.Provider>;
};

export default QRCodeHandoffContextProvider;
