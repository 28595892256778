import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@calm-web/design-system';

import partnerMessages from '@/components/SimplifiedSignup/configs/Partnerships/messages';

const AuthIneligible = () => {
	const { formatMessage } = useIntl();

	return (
		<div>
			<Text el="h4">{formatMessage(partnerMessages.sorry)}</Text>
			<Text el="p">
				{formatMessage(partnerMessages.userHasSubscription, {
					managesubscriptionlink: (...chunks: ReactNode[]) => (
						<>
							<br />
							<a href="/profile/manage-subscription" target="_blank" rel="noopener noreferrer">
								{chunks}
							</a>
						</>
					),
				})}
			</Text>
		</div>
	);
};

export default AuthIneligible;
