import { defineMessages } from 'react-intl';

const messages = defineMessages({
	valuePropHeader: {
		id: 'partner.account.valueproplist.header',
		defaultMessage: 'Calm your mind. Change your life.',
		description: 'Value props of calm on the account screen',
	},
	month: {
		id: 'partner.welcome.offerDuration.month2',
		defaultMessage: 'first {quantity, plural, one {month} other {{quantity} months}}',
		description: 'Text indicating the one month duration for the offer',
	},
	year: {
		id: 'partner.welcome.offerDuration.year2',
		defaultMessage: 'first {quantity, plural, one {year} other {{quantity} years}}',
		description: 'Text indicating the year duration for the offer',
	},
	aarp_membership: {
		id: 'partner.aarp.membership.login',
		defaultMessage: '<join>Join</join> or <renew>renew</renew> your AARP Membership today!',
		description: 'Text indicating the year duration for the offer',
	},
	dtcPartnershipCalmTerms: {
		id: 'partner.dtc.calmterms',
		defaultMessage: "Offer not available if you've previously tried Calm Premium.",
		description:
			"Text informing the user they'd only be eligible to claim if they have not tried or trialed with Calm",
	},
	loginSignupTerms: {
		id: 'partner.fullAuthterms2',
		defaultMessage:
			"Offer not available if you've previously tried Calm Premium. Offer valid through {date}. When your offer ends you will automatically renew at {originalPrice} every year until canceled. One redemption per offer per account. <a>Terms & Conditions Apply.</a>",
		description: 'Full terms for partnership offers',
	},
	loginSignupTerms_noTrial: {
		id: 'partner.fullAuthterms.noTrial2',
		defaultMessage:
			'Offer valid through {date}. When your offer ends you will automatically renew at {originalPrice} every year until canceled. One redemption per offer per account. <a>Terms & Conditions Apply.</a>',
		description: 'Full terms for partnership offers',
	},
	sorry: {
		id: 'partner.error',
		defaultMessage: 'Oops! Something went wrong.',
		description: 'Error sorry',
	},
	userHasSubscription: {
		id: 'partner.error.userAlreadySubscribed',
		defaultMessage:
			'You already have a Calm Premium subscription, so you can’t activate this offer right now. <managesubscriptionlink>You can learn more about your own subscription here.</managesubscriptionlink>',
		description: 'Error message that shows when a user is trying to redeem when they are already subscribed',
	},
});

export default messages;
