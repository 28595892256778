import { defineMessages } from 'react-intl';

export default defineMessages({
	orDivider: {
		id: 'login.orDivider',
		defaultMessage: 'OR',
		description:
			"A divider shown between the login input buttons and a facebook login button, indicating 'this or that'",
	},
});
