import { ModalWidth } from '@calm-web/design-system';

import SimplifiedSignup from '@/components/SimplifiedSignup';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { ActiveScreenConfig } from '@/components/SimplifiedSignup/Context/types';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useDeviceState } from '@/hooks/store';

import { SimplifiedSignupModalComponent } from './styles';

type SimplifiedSignupModalProps = {
	handleModalClose?: (activeScreenConfig: ActiveScreenConfig) => Promise<void>;
};

const SimplifiedSignupModal = ({ handleModalClose }: SimplifiedSignupModalProps) => {
	const { activeScreenConfig } = useSimplifiedSignupContext();
	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const device = useDeviceState();

	return (
		<SimplifiedSignupModalComponent
			isOpen
			canClose
			closeModal={async () => {
				await handleModalClose?.(activeScreenConfig);
				setIsModalOpen?.(false);
			}}
			aria-labelledby="signup-modal"
			width={ModalWidth.Super}
			backgroundColor="gray0"
			borderColor="gray0"
			noPadding={device.isMobile}
		>
			<SimplifiedSignup />
		</SimplifiedSignupModalComponent>
	);
};

export default SimplifiedSignupModal;
