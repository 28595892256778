export type Validator = (type: string, value: string) => boolean;

export function validate(type: string, value: string): boolean {
	if (typeof value === 'string') {
		switch (type) {
			case 'name':
				return !value.includes('://') && Boolean(value.match(/^(.|\s){1,100}$/));
			case 'email':
				return (
					Boolean(value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i)) &&
					value.length < 100
				);
			case 'student-email':
				return Boolean(value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[-\w.]+\.edu$/i)) && value.length < 100;
			case 'password':
				return Boolean(value.match(/^(.|\s){6,2048}$/));
			case 'newPassword':
				return Boolean(value.match(/^(.|\s){8,2048}$/));
			case 'url':
				return Boolean(value.match(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,30})([/\w .-]*)*\/?$/i));
			case 'number':
				return Boolean(value.match(/\d+/));
			case 'gift-code':
				return Boolean(value.match(/^([A-Za-z0-9 ]){2,2048}/));
			case 'zip':
				return Boolean(value.match(/^\d{5}$/));
			case 'postal':
				return Boolean(
					value.match(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i),
				);
			case 'province':
				return value.length > 0 && value !== '';
			case 'country':
				return value.length > 0 && value !== '';
			case 'team-slug':
				return Boolean(value.match(/^[a-zA-Z0-9._-]{3,}$/));
			case 'team-name':
				return Boolean(value.match(/^(.|\s){1,2048}$/));
			case 'any':
				return true;
			default:
				return false;
		}
	}
	return false;
}
