import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

import { CalmLogo } from '@calm-web/design-system';

import messages from './messages';

export interface LogoPropsWithOptionalAriaLabel extends Omit<ComponentProps<typeof CalmLogo>, 'aria-label'> {
	'aria-label'?: string;
}

const CalmLogoWrapper = (props: LogoPropsWithOptionalAriaLabel) => {
	const { formatMessage } = useIntl();
	return <CalmLogo aria-label={formatMessage(messages.calmLogoAriaLabel)} {...props} />;
};

export default CalmLogoWrapper;
