import { AxiosResponse } from 'axios';

import apiRequest from '@/utils/apiRequest';

export function postDependents(subscriptionId: string | null): Promise<AxiosResponse> {
	return apiRequest({
		endpoint: `b2b/dependents`,
		method: 'POST',
		body: {
			b2b_partner_subscription_id: subscriptionId,
		},
	});
}
