import useSWR from 'swr';

import { useApi } from '../api';
import { usePaymentFormValues } from './usePaymentFormValues';

const UNSUPPORTED_PAYPAL_CURRENCIES = ['IDR', 'ZAR', 'TRY', 'KRW', 'COP'];

export const usePaypalClientToken = () => {
	const apiRequest = useApi();
	const { paymentFormCurrency } = usePaymentFormValues();

	const isSupported = !UNSUPPORTED_PAYPAL_CURRENCIES.includes(paymentFormCurrency);

	const { data, isLoading } = useSWR('subscription/generate-client-token', async (endpoint: string) => {
		if (!isSupported) return null;

		const response = await apiRequest<{ clientToken: string }>({
			endpoint,
			method: 'GET',
		});
		if (!response.data) {
			throw new Error('Not able to fetch Paypal client token.');
		}
		return response.data;
	});

	return {
		isLoading,
		isSupported,
		clientToken: data?.clientToken,
	};
};
