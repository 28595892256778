import { noop } from 'lodash';
import { useRouter } from 'next/router';
import { createContext, useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { isOfferValid } from '@/components/SimplifiedSignup/utils/partnership';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics, useExperimentClient } from '@/hooks/analytics';
import {
	useDeviceState,
	usePartnerState,
	usePricesState,
	usePurchaseParamsState,
	useUserState,
} from '@/hooks/store';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setPrices, updatePrice } from '@/store/prices/actions';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import { getPrices } from '@/utils/endpoints/getPrices';
import { userCanTrial } from '@/utils/subscriptions';

import {
	ActiveScreenConfig,
	FlowConfig,
	FlowScreenNames,
	SimplifiedSignupContextValue,
	SimplifiedSignupProviderProps,
} from './types';

export const SimplifiedSignupContext = createContext<SimplifiedSignupContextValue>({
	activeScreenKey: 'account',
	activeScreenConfig: {} as ActiveScreenConfig,
	name: '',
	pageName: '',
	screens: {},
	hasFreeTrial: true,
	isOrganicTraffic: true,
	promotion: '',
	coupon: '',
	offerCountries: null,
	offerCurrencies: null,
	plan: 'yearly',
	setCoupon: () => {},
	logPageViewEvent: () => {},
	setFlowConfig: () => {},
});

const EXP_KEY_7_DAY_TRIAL = 'www-7-day-trial';

export const useSimplifiedSignupContext = (): SimplifiedSignupContextValue =>
	useContext(SimplifiedSignupContext);

export const SimplifiedSignupContextProvider: React.FC<SimplifiedSignupProviderProps> = ({
	children,
	flowConfig: initFlowConfig,
}) => {
	const [flowConfig, setFlowConfig] = useState<FlowConfig>(initFlowConfig);

	const {
		activeScreenKey: activeScreenKeyConfig,
		allowChurned = false,
		coupon,
		discount,
		hasFreeTrial,
		fauxAuth = false,
		freeTrialLength,
		freeTrialLengthUnit,
		plan,
		pageName,
		name,
		promotion,
		screens,
		offerCountries,
		offerCurrencies,
		isWithinModal,
		useServerSidePurchaseParams,
	} = flowConfig;

	const user = useUserState();
	const prices = usePricesState();

	const purchaseParams = usePurchaseParamsState();
	const device = useDeviceState();
	const canTrial = userCanTrial(user) || allowChurned;
	const dispatch = useDispatch();
	const { query, pathname } = useRouter();
	const { logEvent } = useAnalytics();
	const routerPush = useRouterPush();
	const partner = usePartnerState();
	const experimentClient = useExperimentClient();

	const isOrganicTraffic = query?.utm_medium !== 'paid';
	const skip7DayTrialTest = pathname !== '/signup-flow' || !isOrganicTraffic;
	const isSecondFreeTrial = query?.utm_medium === 'second_free_trial';
	const email = query?.email;

	const [activeScreenKey, setActiveScreenKey] = useState<FlowScreenNames>(activeScreenKeyConfig);
	const activeScreenConfig: ActiveScreenConfig = useMemo(
		() => ({ ...screens[activeScreenKey], isWithinModal }),
		[activeScreenKey, screens, isWithinModal],
	);
	const ActiveScreen = useMemo(() => activeScreenConfig.component, [activeScreenConfig]);
	const showHeader = useMemo(() => screens[activeScreenKey]?.showHeader || true, [activeScreenKey, screens]);
	const { setIsModalOpen } = useSimplifiedSignupModalContext();

	// Short Circuit the experiment if the user is not coming from organic traffic
	const { isEnrolled: is7DayTrialTest, isLoading: isLoading7DayTrialTest } = useAmplitudeExperiment(
		EXP_KEY_7_DAY_TRIAL,
		skip7DayTrialTest,
	);

	const coBranded = useMemo(() => flowConfig.coBranded || undefined, [flowConfig.coBranded]);

	/**
	 * Check if the offer is valid for the current user
	 */
	const isOfferCountry = useCallback((): boolean => {
		// setting both of these to null opens up the offer to all countries and currencies
		if (!offerCurrencies && !offerCountries) return true;

		return device?.ip_country && device?.ip_country !== 'unknown'
			? (offerCountries ?? []).includes(device?.ip_country || '')
			: (offerCurrencies ?? []).includes(prices.pricing_format.currency);
	}, [device, offerCountries, offerCurrencies, prices.pricing_format.currency]);

	/**
	 * Route to the next screen in the flow - this is the main method for navigating the flow
	 */

	const routeToScreen = useCallback((screen: FlowScreenNames | null): void => {
		if (screen) {
			setActiveScreenKey(screen);
		}
	}, []);

	/**
	 * This is where the majority of the pricing logic (and bugs) can be found.
	 *
	 * This is a great area to refactor and clean up the pricing logic. My plan with Sisu 1.5 was to
	 * introduce a middleware pattern to process the configuration and handle the pricing logic on the
	 * next web server. This would allow us to have a single source of truth for pricing and reduce the
	 * complexity of the pricing logic in the client.
	 *
	 */

	const setCoupon = useCallback(
		async (_plan: 'yearly' | 'monthly' | 'quarterly' | 'lifetime' = 'yearly') => {
			if (useServerSidePurchaseParams) return;

			// This is messy - originally the trial was not available for monthly plans but we needed to support it
			// for other flows. This is a hack to not allow the trial for monthly plans in the main signup flow.
			const shouldGiveTrialMonthly = !(_plan === 'monthly' && pathname === '/signup-flow');

			function calculateDiscount() {
				if (!discount) return {};
				return discount < 1 ? { percentOff: discount * 100 } : { fixedDiscount: discount };
			}

			// This is a bit of a mess - the trial details are calculated based on a number of factors but
			// we should have what we need to calculate the trial details on the next server and pass it down
			function calculateTrialDetails(): { units?: string; duration?: number } {
				if (!hasFreeTrial) return {};
				if (!userCanTrial(user) && !isSecondFreeTrial && !shouldGiveTrialMonthly) return {};
				if (promotion === '30_day_free_trial') return { duration: 30, units: 'day' };
				if (promotion === '7_day_free_trial') return { duration: 7, units: 'day' };
				if (!isOrganicTraffic) return { duration: 7, units: 'day' };
				if (freeTrialLength) return { duration: freeTrialLength, units: freeTrialLengthUnit || 'day' };
				return { duration: 14, units: 'day' };
			}

			const trialDetails = calculateTrialDetails();

			const shouldApplyCoupon = (coupon && canTrial && hasFreeTrial) || allowChurned;
			const appliedCoupon = shouldApplyCoupon ? coupon : null;

			/**
			 * Since this function fires in useEffects, we need to make sure the coupon from the configuration
			 * is applied to the purchaseParams while avoiding an infinite loop by returning after verifying details
			 */
			if (purchaseParams.coupon === appliedCoupon) {
				// This is checking that, if there is a trial with the flow but the purchaseParams are not
				// reflecting the trial, we need to update the purchaseParams to reflect the trial.
				if (
					trialDetails.duration &&
					trialDetails.units &&
					purchaseParams.purchaseType?.type !== 'freetrial' &&
					purchaseParams.purchaseType?.type !== 'monthly' &&
					purchaseParams.purchaseType?.duration !== trialDetails.duration &&
					shouldGiveTrialMonthly
				) {
					dispatch(
						setPurchaseParams({
							...purchaseParams,
							...(promotion ? { promotion } : {}),
							purchaseType: {
								type: 'freetrial',
								isFreeTrial: true,
								...calculateTrialDetails(),
							},
						}),
					);
				}
				return;
			}

			/**
			 * This is where we update the prices based on the coupon and the plan.
			 * It introduces another API call to get the prices but this is necessary to get the correct prices.
			 *
			 * Moving this to the server would allow us to calculate the prices on the server and pass them down.
			 */
			const { data } = await getPrices(typeof appliedCoupon === 'string' ? { coupon: appliedCoupon } : {});

			if (data) {
				const trialConditions = canTrial && hasFreeTrial && shouldGiveTrialMonthly;
				dispatch(setPrices(data));
				dispatch(
					setPurchaseParams({
						...purchaseParams,
						plan: _plan,
						...(promotion ? { promotion } : {}),
						coupon: appliedCoupon,
						...calculateDiscount(),
						purchaseType: {
							...(purchaseParams?.purchaseType || {}),
							type: trialConditions ? 'freetrial' : _plan === 'monthly' ? _plan : 'subscribe',
							isFreeTrial: trialConditions,
							...calculateTrialDetails(),
						},
					}),
				);
			}
		},
		[
			canTrial,
			discount,
			dispatch,
			user,
			isSecondFreeTrial,
			purchaseParams,
			pathname,
			promotion,
			hasFreeTrial,
			coupon,
			freeTrialLength,
			freeTrialLengthUnit,
			allowChurned,
			isOrganicTraffic,
			useServerSidePurchaseParams,
		],
	);

	// If the flowConfig's plan changes, we need to update the coupon and prices

	useEffect(() => {
		setCoupon(flowConfig.plan)
			.then(() => {})
			.catch(() => {});
	}, [flowConfig.plan, setCoupon]);

	/**
	 * This is the main logic for the flow navigation.
	 * It determines where the user should go based on their subscription status
	 */

	useEffect(() => {
		// // If the offer is no longer valid, inform the user and offer 40% off offer
		if (!isOfferValid(partner)) {
			return noop();
		}
		// Logged in and active Calm - send to wherever config dictates
		// Can be a function that triggers a side effect such as SheerID
		if (user?.subscription?.valid) {
			// if the user has a Calm premium account but is trying to claim a
			// partner offer, let the account page handle the error
			if (partner && activeScreenConfig.name === 'account') {
				noop();
			} else if (activeScreenConfig.hasPremiumSubCallback) {
				activeScreenConfig.hasPremiumSubCallback(routerPush, setIsModalOpen);
			} else if (typeof activeScreenConfig.nextScreen === 'function') {
				activeScreenConfig.nextScreen({ routeToScreen, user });
			} else {
				routeToScreen(activeScreenConfig.nextScreen);
			}
		}

		// Logged in but not active Calm - send to Payment Page
		if (user && !user?.subscription?.valid) {
			if (typeof activeScreenConfig.nextScreen === 'function') {
				const _query = Object.keys(query).length ? query : undefined;
				if (!isOfferCountry()) {
					routerPush('/subscribe')
						.then(() => {})
						.catch(() => {});
				} else {
					activeScreenConfig.nextScreen({
						routeToScreen,
						user,
						_query,
						setFlowConfig,
						experimentClient,
						device,
					});
				}
			} else {
				routeToScreen('payment');
			}
		}

		/**
		 * This supports the fauxAuth flow where the user is not logged in but we have their email.
		 * Presenting them with a purchase screen and allowing them to claim the offer.
		 */

		if (!user) {
			if (fauxAuth && email) {
				routeToScreen('payment');
			} else {
				routeToScreen('account');
			}
		}
	}, [
		user,
		device,
		activeScreenConfig,
		query,
		routeToScreen,
		isOfferCountry,
		routerPush,
		partner,
		setIsModalOpen,
		email,
		experimentClient,
		fauxAuth,
	]);

	/**
	 * This is more pricing logic that should be refactored to the server.
	 *
	 * Here we're checking if the current price === the original price and if there is a discount. If so, we update the price.
	 */
	useEffect(() => {
		if (prices.original?.yearly === prices.current?.yearly && discount) {
			const newYearly = Number(
				discount < 1 ? Math.round(prices.original.yearly - prices.original.yearly * discount) : discount,
			);
			dispatch(
				updatePrice({
					current: {
						...prices.current,
						yearly: newYearly,
					},
				}),
			);
		}
	}, [prices, discount, dispatch]);

	/**
	 * This logic is mostly for the UA traffic to ensure that the user is on the correct trial.
	 *
	 * Since this is a useEffect, we have found that the user can sometimes be on the wrong trial.
	 */

	useEffect(() => {
		if (isLoading7DayTrialTest || !hasFreeTrial) return;
		if (is7DayTrialTest && !partner) {
			if (purchaseParams.plan === 'monthly' && purchaseParams.promotion) {
				dispatch(
					setPurchaseParams({
						...purchaseParams,
						promotion: undefined,
					}),
				);
			}

			if (purchaseParams.purchaseType?.duration !== 7) {
				dispatch(
					setPurchaseParams({
						...purchaseParams,
						promotion: '7_day_free_trial',
						purchaseType: {
							...purchaseParams.purchaseType,
							type: 'freetrial',
							duration: 7,
							units: 'days',
						},
					}),
				);
			}
		} else if (!is7DayTrialTest && isOrganicTraffic && purchaseParams.purchaseType?.duration === 7) {
			dispatch(
				setPurchaseParams({
					...purchaseParams,
					...(promotion ? { promotion } : {}),
					purchaseType: {
						...purchaseParams.purchaseType,
						type: 'freetrial',
						duration: 14,
						units: 'days',
					},
				}),
			);
		}
	}, [
		isOrganicTraffic,
		hasFreeTrial,
		dispatch,
		purchaseParams,
		user,
		is7DayTrialTest,
		isLoading7DayTrialTest,
		partner,
		promotion,
	]);

	const logPageViewEvent = useCallback(
		(screen: FlowScreenNames | null) => {
			logEvent({
				eventName: 'Page : Viewed',
				eventProps: {
					page_name: `${pageName}-${screen}`,
					source: name,
					...(name === 'spotify' && { content_source: query.spotifyShowUri ?? 'organic' }),
				},
			});
		},
		[logEvent, name, pageName, query],
	);

	const value = useMemo(
		() => ({
			activeScreenKey,
			ActiveScreen,
			activeScreenConfig,
			coBranded,
			name,
			pageName,
			user,
			plan,
			coupon,
			purchaseParams,
			isOfferCountry,
			offerCurrencies: flowConfig.offerCurrencies,
			offerCountries: flowConfig.offerCountries,
			setCoupon,
			screens: flowConfig.screens,
			hasFreeTrial: flowConfig.hasFreeTrial,
			promotion: flowConfig.promotion,
			showHeader,
			isOrganicTraffic,
			routeToScreen,
			logPageViewEvent,
			setFlowConfig,
			flowConfig,
			fauxAuth,
		}),
		[
			routeToScreen,
			activeScreenKey,
			activeScreenConfig,
			ActiveScreen,
			coBranded,
			setCoupon,
			name,
			pageName,
			user,
			plan,
			coupon,
			purchaseParams,
			isOfferCountry,
			showHeader,
			isOrganicTraffic,
			logPageViewEvent,
			setFlowConfig,
			flowConfig,
			fauxAuth,
		],
	);

	return <SimplifiedSignupContext.Provider value={value}>{children}</SimplifiedSignupContext.Provider>;
};
