/* eslint-disable @calm/react-intl/missing-formatted-message */

import { ComponentProps, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Loader as DesignSystemLoader } from '@calm-web/design-system';

import messages from './messages';

const Loader = (props: ComponentProps<typeof DesignSystemLoader>): ReactElement => {
	const { formatMessage } = useIntl();

	return (
		<DesignSystemLoader
			announcement={formatMessage(messages.announcement)}
			title={formatMessage(messages.title)}
			description={formatMessage(messages.description)}
			{...props}
		/>
	);
};
export default Loader;
