import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import ControlFlow from '@/components/SimplifiedSignup/configs/ControlModal/Flow';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useThemeState, usePurchaseParamsState } from '@/hooks/store';
import useQueryParams from '@/hooks/utils/useQueryParams';

import { AnalyticsContext } from '../../layout/AnalyticsContextProvider';
import { useMediaPlayerContext } from '../MediaPlayerProvider';
import messages from './messages';
import { Title, TrialText, Wrapper } from './styles';
import { PlayerUpsellProps } from './types';

const PlayerUpsell = ({ children, program, isVideo = false }: PlayerUpsellProps) => {
	const { formatMessage } = useIntl();
	const theme = useThemeState();
	const { logEvent } = useAnalytics();
	const { isAnalyticsInitialized } = useContext(AnalyticsContext);
	const purchaseParams = usePurchaseParamsState();
	const { isPreviewMode } = useMediaPlayerContext();
	const { setFlowConfig } = useSimplifiedSignupContext();
	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const query = useQueryParams();
	const isOrganicTraffic = query?.utm_medium !== 'paid';

	const logUpsellEvent = (eventName: string) => {
		logEvent({
			eventName,
			eventProps: {
				meditation_type: program?.meditation_type as string,
				program_id: program?.id as string,
				program_title: program?.title as string,
			},
		});
	};

	const onUpsellClick = async () => {
		logUpsellEvent('Upsell Session Preview : Upsell : Clicked');
		if (!isOrganicTraffic) {
			logEvent({
				eventName: 'Landing Page : Signup Flow CTA : Clicked',
				eventProps: {
					meditation_type: program?.meditation_type as string,
					program_id: program?.id as string,
					program_title: program?.title as string,
					location: 'Player CTA',
				},
			});
		}
		setFlowConfig(ControlFlow);
		setIsModalOpen(true);
	};

	useEffect(
		() => {
			if (isPreviewMode) {
				logUpsellEvent('Upsell Session Preview : Landed');
			}
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	if (!isPreviewMode || theme.webAppLayout !== 'player') {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{children}</>;
	}

	return (
		<Wrapper>
			<Title>{formatMessage(isVideo ? messages.videoTitle : messages.upsellTitle)}</Title>
			{children}
			{isAnalyticsInitialized && (
				<TrialText>
					{formatMessage(messages.trialLength2, {
						trail_length: purchaseParams?.purchaseType?.duration,
					})}
				</TrialText>
			)}
			<Button fullWidth onPress={onUpsellClick} textColor="gray8" backgroundColor="white">
				{formatMessage(messages.buttonText)}
			</Button>
		</Wrapper>
	);
};

export default PlayerUpsell;
