import { defineMessages } from 'react-intl';

export default defineMessages({
	freeTrialTerms: {
		id: 'purchaseTerms.freeTrialTerms',
		defaultMessage:
			'By clicking "Start Your Trial", you agree to be automatically billed for Calm Premium at {price} after your trial ends and every year until cancelled. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is starting free trial',
	},
	trialFlowDefault: {
		id: 'redesign.purchase.purchaseForm.freeTrial.trialFlowDefault',
		defaultMessage:
			'By clicking "Start Your Trial", you agree to be automatically billed for Calm Premium at {price} after your trial ends and every year until cancelled. <cancellink>Cancel anytime</cancellink>.',
		description: 'Explains that a user will be charged after their free trial ends',
	},
});
