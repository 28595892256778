import styled, { css } from 'styled-components';

import { Button, FocusRingVariants, minWidth } from '@calm-web/design-system';

import googleIcon from 'icons/google-auth.svg';

export const Wrapper = styled(Button).attrs({
	focusRingVariant: FocusRingVariants.Dark,
})<{ $noPadding: boolean }>`
	align-items: center;
	display: flex;
	height: 48px;
	margin-bottom: 12px;
	width: 100%;
	${p =>
		p.$noPadding &&
		css`
			padding: 0;
		`}

	${minWidth('tablet')} {
		height: 56px;
	}
`;

export const Icon = styled(googleIcon)<{ $noMargin: boolean }>`
	height: 24px;
	width: 24px;
	margin-right: ${p => (p.$noMargin ? '0' : '14px')};

	${minWidth('tablet')} {
		width: 30px;
		height: 30px;
	}
`;
