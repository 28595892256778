import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Campaign } from './types';

const initialState: Campaign = {};

const campaignSlice = createSlice({
	name: 'campaign',
	initialState,
	reducers: {
		setCampaign(state, action: PayloadAction<Campaign>) {
			(Object.keys(action.payload) as (keyof Campaign)[]).forEach(<K extends keyof Campaign>(key: K) => {
				state[key] = action.payload[key];
			});
		},
	},
});

export const { setCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
