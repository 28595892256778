import Link from 'next/link';
import { useIntl } from 'react-intl';

import messages from './messages';
import { Copy, Icon, Wrapper } from './styles';

const BackNavButton = ({ href }: { href: string }) => {
	const { formatMessage } = useIntl();

	return (
		<Link passHref href={href} legacyBehavior>
			<Wrapper>
				<Icon />
				<Copy>{formatMessage(messages.copy)}</Copy>
			</Wrapper>
		</Link>
	);
};

export default BackNavButton;
