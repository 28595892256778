import { GiftPrice } from '@/store/purchaseParams/types';

const annualGiftCoupons: string[] = [];

const ORIGINAL_ANNUAL_PRICE = 6999;
const DISCOUNT_ANNUAL_PRICE = 4199;

const lifetimeGiftCoupons: string[] = [];

const ORIGINAL_LIFETIME_PRICE = 39999;
const DISCOUNT_LIFETIME_PRICE = 15999;

export const isGiftCoupon = (coupon: string): boolean => {
	return annualGiftCoupons.includes(coupon) || lifetimeGiftCoupons.includes(coupon);
};

export const initGiftPricing = (type: 'annual' | 'lifetime', coupon?: string | null): GiftPrice => {
	if (type === 'annual') {
		const hasAnnualCoupon = coupon && annualGiftCoupons.includes(coupon);

		return {
			originalPrice: ORIGINAL_ANNUAL_PRICE,
			price: hasAnnualCoupon ? DISCOUNT_ANNUAL_PRICE : ORIGINAL_ANNUAL_PRICE,
		};
	}

	if (type === 'lifetime') {
		const hasLifetimeCoupon = coupon && lifetimeGiftCoupons.includes(coupon);

		return {
			originalPrice: ORIGINAL_LIFETIME_PRICE,
			price: hasLifetimeCoupon ? DISCOUNT_LIFETIME_PRICE : ORIGINAL_LIFETIME_PRICE,
		};
	}

	return null;
};
