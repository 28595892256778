import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { PreSignupFlowContext } from '@/components/PreSignupFlow/PreSignupProvider';
import { usePurchaseParamsState } from '@/hooks/store';

import messages from './messages';
import { QuizResultsWrapper, RecDisplayWrapper, RecDescriptionWrapper } from './styles';

export default function QuizResults() {
	const { formatMessage } = useIntl();
	const { query } = useRouter();
	const { focusedQuizMap = {} } = useContext(PreSignupFlowContext);
	const { purchaseType } = usePurchaseParamsState();
	return (
		<QuizResultsWrapper>
			<Text weight={FontWeights.Medium} align="center" size={FontSizes['2xl']} el="p">
				{formatMessage(messages.quizResultsHeading, {
					focus: focusedQuizMap[query.focus as string].header,
					trialLengthDays: purchaseType?.duration || 14,
				})}
			</Text>
			<RecDisplayWrapper>
				<RecDescriptionWrapper>
					<Text size={FontSizes.base} el="p">
						{formatMessage(messages.quizRecommendationOpener)}
					</Text>
					<em>
						<Text weight={FontWeights.Medium} size={FontSizes.lg} el="p">
							{focusedQuizMap[query.focus as string].recommendedContent}
						</Text>
					</em>
				</RecDescriptionWrapper>
				<Image
					src={focusedQuizMap[query.focus as string].image}
					alt={formatMessage(messages.recImage)}
					height={148}
					width={184}
				/>
			</RecDisplayWrapper>
		</QuizResultsWrapper>
	);
}
