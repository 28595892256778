import { PartnerDetails } from '@/components/dtcPartnerships/shared/types';

export function isOfferValid(partner: PartnerDetails | null): boolean {
	// If there is no partner, return true since this is only for partners
	if (!partner) return true;

	// Ensure that offer details is present and that promotion_active is present. If so, return the value of promotion_active
	if (partner.offer_details && typeof partner.offer_details.promotion_active !== 'undefined') {
		return partner.offer_details.promotion_active;
	}

	// If there is no promotion_active, return true. Promotion_active should be present for all partners but this is a safety check
	return true;
}
