import { LogEventArgs } from '@/hooks/analytics/types';
import { JSONObject } from '@/utils/types';

declare global {
	interface Window {
		dataLayer: {
			push: (data: Record<string, string | JSONObject | undefined>) => void;
		};
	}
}

export function logGtmEvent({ eventName, eventProps }: LogEventArgs): void {
	if (window?.dataLayer) {
		window.dataLayer.push({
			...eventProps, // we spread event props out so that GTM can access specific keys from the dataLayer
			eventProps, // GTM also needs ALL eventProps sent as an object to forward to other partners
			event: eventName,
			eventLabel: eventProps?.is_free_trial ? 'freetrial' : 'subscribe',
		});
	}
}
