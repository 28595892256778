import { useMemo } from 'react';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';

import { AppInitialState, rootReducer } from '@/store';

let store: Store | undefined;

const initStore = (preloadedState: AppInitialState) => {
	return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(reduxThunk)));
};

export const initializeStore = (preloadedState: AppInitialState): Store => {
	let _store = store ?? initStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState,
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store;
	// Create the store once in the client
	if (!store) store = _store;

	return _store;
};

export const useStore = (initialState: AppInitialState): Store => {
	const memoizedStore = useMemo(() => initializeStore(initialState), [initialState]);
	return memoizedStore;
};
