import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { ApiAsset } from '@/components/app/cards/PacksList/types';
import { useApi } from '@/hooks/api';
import { useThemeState } from '@/hooks/store';

export interface Scene {
	audio: ApiAsset;
	created_at: string;
	english_title_for_analytics: string;
	gradient_end_color: string;
	gradient_start_color: string;
	id: string;
	image: ApiAsset;
	offsets: {
		x: number;
		y: number;
	};
	time_period: string;
	title: string;
	updated_at: string;
	video: ApiAsset;
	is_default?: boolean;
}

export const SELECTED_SCENE_ID_KEY = 'selectedSceneId';

export const getSelectedScene = (scenes: Scene[]) => {
	const storedSceneId = localStorage.getItem(SELECTED_SCENE_ID_KEY);

	const selectedScene = scenes.find(scene => (storedSceneId ? scene.id === storedSceneId : scene.is_default));

	return selectedScene;
};

export const useSelectedScene = () => {
	const apiRequest = useApi();
	const theme = useThemeState();
	const [selectedScene, setSelectedScene] = useState<Scene>();

	const { data: scenes } = useSWR<Scene[]>(
		typeof theme.webAppLayout === 'string' ? 'scenes' : null,
		async (endpoint: string) => {
			const res = await apiRequest<{ scenes: Scene[] }>({
				endpoint,
				method: 'GET',
			});

			return res.data.scenes;
		},
		{ revalidateOnFocus: false, errorRetryCount: 0 },
	);

	useEffect(() => {
		if (scenes && scenes.length > 0) {
			setSelectedScene(getSelectedScene(scenes));
		}
	}, [scenes]);

	return {
		scenes: scenes ?? [],
		selectedScene,
		setSelectedScene,
	};
};
