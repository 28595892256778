import styled from 'styled-components';

import { Card, minWidth } from '@calm-web/design-system';

export const QuizResultsWrapper = styled(Card)`
	padding: 20px 16px;
	border-radius: 20px;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	border: none;
	p {
		margin-top: 0;
	}

	${minWidth('desktop')} {
		padding: 32px;
	}
`;

export const RecDisplayWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	flex-direction: column;
	align-items: center;
`;

export const RecDescriptionWrapper = styled.div`
	max-width: 412px;
	text-align: center;
`;
