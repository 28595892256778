import { ConfigResponse } from '@zoom/appssdk';

export const SET_ZOOM = 'SET_ZOOM';

export type Zoom = {
	isSharingApp?: boolean;
	config?: ConfigResponse | undefined;
	meetingParticipantCount?: number;
	isSoundReminderVisible?: boolean;
};

export interface SetZoomConfigAction {
	type: typeof SET_ZOOM;
	payload: Zoom;
}

export type SetZoomConfig = SetZoomConfigAction;
