import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights } from '@calm-web/design-system';

import { PreSignupFlowContext } from '@/components/PreSignupFlow/PreSignupProvider';
import { useIsMobile } from '@/hooks/ui/useIsMobile';

import { useSimplifiedSignupContext } from '../Context';
import { PaymentScreenConfig } from '../Payment/types';
import {
	BasicPropsList,
	PremiumPropsList,
	ValuePropComparisonContainer,
	ValuePropsList,
	Wrapper,
} from './styles';

const PaymentPropsList = ({ isForMobile = false }: { isForMobile?: boolean }) => {
	const { formatMessage } = useIntl();
	const [isMobile] = useIsMobile();
	const { activeScreenConfig } = useSimplifiedSignupContext();

	const { skippedToEnd = false, ActiveScreen, quizResults = false } = useContext(PreSignupFlowContext);
	const {
		upsellBullets = [],
		basicLineHeader,
		basicLineItems = [],
		premiumLineItems = [],
		premiumLineHeader,
		isWithinModal,
		contentBasedImage,
	} = activeScreenConfig as PaymentScreenConfig;

	const showContentBasedImage =
		contentBasedImage || (ActiveScreen && ActiveScreen.upsellImage && skippedToEnd && !quizResults);

	const upsellCopy = isMobile && (isWithinModal || !showContentBasedImage) ? undefined : upsellBullets;

	return (
		<Wrapper $isForMobile={isForMobile}>
			{premiumLineHeader && basicLineHeader ? (
				<ValuePropComparisonContainer>
					<BasicPropsList
						title={formatMessage(basicLineHeader, {
							br: <br></br>,
						})}
						copy={basicLineItems}
						buttonColor="gray4"
						textColor="black"
						propPadding={false}
						propSize={FontSizes.sm}
						fontWeight={FontWeights.Regular}
					/>
					<PremiumPropsList
						title={formatMessage(premiumLineHeader, {
							br: <br></br>,
						})}
						copy={premiumLineItems}
						buttonColor="blue3"
						textColor="black"
						propPadding={false}
						propSize={FontSizes.sm}
						fontWeight={FontWeights.Regular}
					/>
				</ValuePropComparisonContainer>
			) : (
				<ValuePropsList
					propSize={FontSizes.sm}
					copy={upsellCopy}
					buttonColor="blue3"
					textColor="black"
					fontWeight={FontWeights.Medium}
					gapSize="20px"
				/>
			)}
		</Wrapper>
	);
};

export default PaymentPropsList;
