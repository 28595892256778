import { StaticImageData } from 'next/image';

import CalmLogoGradient from '@/../public/_n/images/calm-logo-gradient.png';
import CalmLogoWhite from '@/../public/_n/images/calm-logo.png';
import Image from '@/components/image/NextImage';

import { BrandingWrapper, ImageContainer } from './styles';

type BrandingProps = {
	backgroundColor?: string;
	coBranded?: {
		src: StaticImageData | string;
		alt: string;
	};
};

const Branding = ({ backgroundColor, coBranded }: BrandingProps) => {
	const CalmLogo = backgroundColor !== 'gray0' ? CalmLogoWhite : CalmLogoGradient;

	return (
		<BrandingWrapper>
			<ImageContainer>
				<Image alt="Calm Logo" src={CalmLogo} layout="fill" objectFit="contain" />
			</ImageContainer>
			{coBranded && (
				<>
					{typeof coBranded.src === 'string' ? (
						<ImageContainer>
							<Image src={coBranded.src} alt={coBranded.alt} layout="fill" objectFit="contain" />
						</ImageContainer>
					) : (
						<Image src={coBranded.src} alt={coBranded.alt} objectPosition="bottom" />
					)}
				</>
			)}
		</BrandingWrapper>
	);
};

export default Branding;
