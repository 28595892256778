import { useCallback, useEffect, useState } from 'react';

import { ContentType, MediaEl, PlayerMode } from '@/components/app/contentPlayer/MediaPlayerProvider/types';
import { getISOTimestamp } from '@/utils/app/getISOTimestamp';
import { getCurrentTime } from '@/utils/media';

export type TimesListenedList = {
	start: number;
	end?: number;
	time_started: string;
}[];

export const useTimesListened = (mediaEl: MediaEl, playerMode: PlayerMode, contentType: ContentType) => {
	const [timesListened, setTimesListened] = useState<TimesListenedList>([]);

	const startNewTimeListened = useCallback((start = 0, timeStarted = new Date(), end = undefined) => {
		setTimesListened(prevTimesListened => [
			...prevTimesListened,
			{
				start: start < 1 ? Math.floor(start) : start,
				end: end ?? undefined,
				time_started: getISOTimestamp(timeStarted),
			},
		]);
	}, []);

	const clearTimesListened = useCallback(() => {
		setTimesListened([]);
	}, []);

	const updateTimeListenedWithEnd = useCallback((endTime: number) => {
		setTimesListened(previousTimesListened => {
			const currentTimeListened = previousTimesListened[previousTimesListened.length - 1];

			if (currentTimeListened) {
				const updatedTimesListened = [
					...previousTimesListened.slice(0, previousTimesListened.length - 1),
					{
						...currentTimeListened,
						end: endTime,
					},
				];
				return updatedTimesListened;
			}

			return previousTimesListened;
		});
	}, []);

	const finalizeTimesListened = (timesListenedAry: TimesListenedList, endTime: number) => {
		const currentTimeListened = timesListenedAry[timesListenedAry.length - 1];
		const updatedTimesListened = [
			...timesListenedAry.slice(0, timesListenedAry.length - 1),
			{
				...currentTimeListened,
				end: endTime,
			},
		];
		return updatedTimesListened;
	};

	useEffect(() => {
		if (mediaEl) {
			const currentTime = getCurrentTime(mediaEl, contentType);
			if (currentTime) {
				updateTimeListenedWithEnd(currentTime);
			}

			if (playerMode === 'playing') {
				startNewTimeListened(currentTime);
			}
		}
	}, [contentType, mediaEl, playerMode, startNewTimeListened, updateTimeListenedWithEnd]);

	return {
		timesListened,
		startNewTimeListened,
		clearTimesListened,
		updateTimeListenedWithEnd,
		finalizeTimesListened,
	};
};
