import { AuthFormMode, SET_AUTH_FORM_MODE, SetAuthFormModeAction } from './types';

export function setAuthFormMode(authFormMode: AuthFormMode): SetAuthFormModeAction {
	return {
		type: SET_AUTH_FORM_MODE,
		payload: authFormMode,
	};
}

export type SetAuthFormMode = typeof setAuthFormMode;
