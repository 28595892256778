import { useRouter } from 'next/router';
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { usePacksContext } from '@/components/app/layout/PacksProvider';
import { SHARE_FREE_ACCESS_MODAL_COOKIE } from '@/components/app/layout/ShareContentModal';
import { ShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import { useAnalytics } from '@/hooks/analytics';
import { hasAutoplaySupport, useAutoplay } from '@/hooks/app/useAutoplay';
import { useTimesListened } from '@/hooks/app/useTimesListened';
import { useTrackSession } from '@/hooks/app/useTrackSession';
import { useZenMode } from '@/hooks/app/useZenMode';
import { useProgramState, useThemeState, useUserState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setGuide } from '@/store/guide/actions';
import { Guide } from '@/store/guide/types';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';
import { calmLogger } from '@/utils/calmLogger';
import { setCookie } from '@/utils/cookies';
import { postDevice } from '@/utils/endpoints';
import { getCurrentTime, isAudioMediaEl, isVideoMediaEl } from '@/utils/media';

import {
	ContentType,
	LogSession,
	MediaEl,
	MediaPlayerContextProps,
	OnAudioEnd,
	OnAudioScrub,
	OnAudioStart,
	OnEndScrub,
	OnVideoStart,
	OnPlay,
	PlayerMode,
	SetMediaEl,
	TransportClickHandler,
	MediaPlayerOverrides,
} from './types';

type OnPlayAsync = (...args: Parameters<OnPlay>) => Promise<void>;

export const MediaPlayerContext = createContext<MediaPlayerContextProps>({
	mediaEl: null,
	setMediaEl: () => {},
	isPreviewMode: false,
	trackProgress: 0,
	setTrackProgress: () => {},
	onAudioScrub: () => {},
	onBack15: () => {},
	onForward15: () => {},
	onPause: () => {},
	onPlay: () => {},
	onAudioStart: () => {},
	onVideoStart: () => {},
	onStop: async () => {},
	onEndScrub: () => {},
	currentTime: 0,
	duration: 0,
	playerMode: 'stopped',
	setPlayerMode: (mode: PlayerMode) => {},
	contentType: null,
	isZenMode: false,
	setOverrides: (overrides: MediaPlayerOverrides) => null,
	hideControls: false,
	setHideControls: (b: boolean) => {},
	autoplayOnLoad: true,
	setAutoplayOnLoad: (b: boolean) => {},
	shouldPreventAppNav: false,
	setShouldPreventAppNav: (b: boolean) => {},
	onComplete: async () => {},
	onCancel: () => {},
});

export const useMediaPlayerContext = ({
	hideControls,
	autoplayOnLoad,
	shouldPreventAppNav,
}: {
	hideControls?: boolean;
	autoplayOnLoad?: boolean;
	shouldPreventAppNav?: boolean;
} = {}): MediaPlayerContextProps => {
	const value = useContext(MediaPlayerContext);
	const { setHideControls, setAutoplayOnLoad, setShouldPreventAppNav } = value;

	useEffect(() => {
		if (hideControls !== undefined) {
			setHideControls(hideControls);
		}
		if (autoplayOnLoad !== undefined) {
			setAutoplayOnLoad(autoplayOnLoad);
		}
		if (shouldPreventAppNav !== undefined) {
			setShouldPreventAppNav(shouldPreventAppNav);
		}
	}, [
		hideControls,
		autoplayOnLoad,
		shouldPreventAppNav,
		setHideControls,
		setAutoplayOnLoad,
		setShouldPreventAppNav,
	]);

	return value;
};

const MediaPlayerProvider = ({ children }: { children?: ReactNode }) => {
	const { logEvent } = useAnalytics();
	const [overrides, setOverrides] = useState<MediaPlayerOverrides>({});
	const [hideControls, setHideControls] = useState(false);
	const [autoplayOnLoad, setAutoplayOnLoad] = useState(true);
	const [shouldPreventAppNav, setShouldPreventAppNav] = useState(false);
	const user = useUserState();
	const theme = useThemeState();
	const router = useRouter();
	const routerPush = useRouterPush();
	const dispatch = useDispatch();
	const program = useProgramState();
	const shouldStayOnGuidePage = shouldPreventAppNav && !program;

	const { currentProgram, setCurrentProgram, currentGuide, setCurrentGuide } = usePacksContext();
	const { onShowSharePayment, isShareContentRecipient, isFetchingShare, shareToken } =
		useContext(ShareContext);

	const mediaEl = useRef<MediaEl>(null);
	const [isPreviewMode, setIsPreviewMode] = useState(false);
	const [trackProgress, setTrackProgress] = useState(0);
	const [playerMode, setPlayerMode] = useState<PlayerMode>('stopped');
	const [contentType, setContentType] = useState<ContentType>(null);
	const { utm_medium } = router?.query || {};
	const isOrganicTraffic = utm_medium !== 'paid';

	const intervalRef = useRef<ReturnType<typeof setInterval>>();

	const isZenMode = useZenMode({
		playerMode,
		guide: currentGuide,
		program: currentProgram,
	});
	const trackSession = useTrackSession(currentGuide, currentProgram);

	const {
		timesListened,
		startNewTimeListened,
		clearTimesListened,
		updateTimeListenedWithEnd,
		finalizeTimesListened,
	} = useTimesListened(mediaEl.current, playerMode, contentType);

	const { isTeamsApp } = useGetIsTeamsApp();
	const handleTeamsAppOnStopOnCompleteNav = useCallback(async () => {
		if (overrides.stop) {
			return;
		}
		if (router.pathname.includes('/app')) {
			router.back();
			return;
		}
		await routerPush('/app');
		return;
	}, [overrides, router, routerPush]);

	const resetTrackProgress = () => {
		setTrackProgress(0);
	};

	const setMediaEl: SetMediaEl = useCallback(newMediaEl => {
		if (mediaEl.current && contentType === 'audio') {
			mediaEl.current.pause();
		}
		mediaEl.current = newMediaEl;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearMediaEl = useCallback(() => {
		if (mediaEl.current) {
			mediaEl.current.pause();
			setMediaEl(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaEl]);

	// Calculates how far into the media track the user has listened
	const getPercentComplete = useCallback(
		(currentProgress: number) => {
			const guideAssetDuration = currentGuide?.asset?.duration;

			return currentProgress > 0 && guideAssetDuration
				? Math.round((currentProgress / guideAssetDuration) * 100)
				: 0;
		},
		[currentGuide],
	);

	// Clears the current media track progress
	const clearTimer = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	};

	// Begins a state passed progress count
	const startTimer = useCallback((newMediaEl: MediaEl, contentType: ContentType) => {
		clearTimer();

		intervalRef.current = setInterval(() => {
			const currentTime = getCurrentTime(newMediaEl, contentType);
			setTrackProgress(currentTime);
		}, 1000);
	}, []);

	const logSession: LogSession = useCallback(
		(eventName, currentProgress, nextGuide, nextProgram, eventProps) => {
			const guide = nextGuide ?? currentGuide;
			const program = nextProgram ?? currentProgram;
			const guideTitle = guide?.short_title || guide?.title;
			const sessionType = program?.meditation_type;
			const partnerId = user?.subscription?.b2b_details?.partner_id as string;

			logEvent({
				eventName,
				eventProps: {
					...(guide?.id ? { guide_id: guide.id } : {}),
					percent_completed: getPercentComplete(currentProgress ?? trackProgress),
					...(guideTitle ? { guide_title: guideTitle } : {}),
					...(guide?.is_free ? { guide_is_free: guide.is_free } : {}),
					...(program?.id ? { program_id: program.id } : {}),
					...(program?.title ? { program_title: program.title } : {}),
					...(sessionType ? { session_type: sessionType } : {}),
					...(isPreviewMode ? { is_preview_mode: true } : {}),
					...(partnerId ? { partner_id: partnerId } : {}),
					...eventProps,
				},
			});
		},
		[logEvent, getPercentComplete, trackProgress, currentGuide, currentProgram, isPreviewMode, user],
	);

	// Calculates whether the user stopped playing the track
	// within 10 seconds of it completing
	const getWasCompleted = useCallback((): boolean => {
		if (!mediaEl.current) return false;

		const currentTime = getCurrentTime(mediaEl.current, contentType);
		const duration = isAudioMediaEl(mediaEl.current)
			? mediaEl.current.duration
			: currentGuide?.asset?.duration ?? 0;
		return duration - currentTime <= 10;
	}, [contentType, currentGuide?.asset?.duration]);

	// Called when a free content user pressed Stop or session ends - direct to upsell
	const shareContentOnStop = useCallback(async () => {
		try {
			resetTrackProgress();
			clearTimer();
			mediaEl.current?.pause();
			setPlayerMode('paused');
			if (mediaEl?.current?.currentTime) {
				mediaEl.current.currentTime = 0;
			}
			logEvent({
				eventName: 'Session : Stopped',
				eventProps: {
					source: 'Share Link',
					share_token: shareToken || null,
				},
			});
			await onShowSharePayment();
		} catch (e) {
			calmLogger.error('Error in MediaPlayerProvider shareContentOnStop', {}, e);
		}
	}, [onShowSharePayment, logEvent, shareToken]);

	// Called when user clicks the play transport button
	const onPlay: OnPlayAsync = useCallback(
		async (nextGuide, nextProgram, currentProgress) => {
			if (mediaEl.current) {
				try {
					if (overrides.play) {
						overrides.play();
					} else {
						await mediaEl.current.play();
					}
					startTimer(mediaEl.current, nextGuide?.asset?.type ?? contentType);
					setPlayerMode('playing');
					logSession('Session : Began', currentProgress, nextGuide, nextProgram, {
						source: 'Share Link',
						share_token: shareToken || null,
					});
					logSession('Session : Played', currentProgress, nextGuide, nextProgram);
				} catch (err) {
					setPlayerMode('paused');
					// this is likely going to fail on first load
					// browsers don't allow audio to autoplay
				}
			}
		},
		[startTimer, contentType, logSession, shareToken, overrides],
	);

	// Called when user clicks the pause transport button
	const onPause: TransportClickHandler = useCallback(() => {
		if (mediaEl) {
			if (overrides.pause) {
				overrides.pause();
			} else {
				mediaEl.current?.pause();
			}
			setPlayerMode('paused');
			logSession('Session : Paused');
		}
	}, [logSession, overrides]);

	// Called when the media element reaches its end
	const onComplete = useCallback(async () => {
		logSession('Session : Completed');
		logEvent({
			eventName: 'Session : Completed',
			eventProps: {
				source: 'Share Link',
				share_token: shareToken || null,
			},
		});

		if (isShareContentRecipient) {
			await shareContentOnStop();
			return;
		}

		if (overrides.stop) {
			overrides.stop();
		}

		if (shouldStayOnGuidePage) {
			setPlayerMode('paused');
			return;
		}

		if (currentProgram && hasAutoplaySupport(currentProgram) && !isShareContentRecipient) return;

		// Close player and clear guide state
		dispatch(setGuide(null));
		setPlayerMode('stopped');
		setContentType(null);
		setCurrentGuide(null);

		if (theme.webAppLayout !== 'player') return;

		// Sadly, our Teams app currently sets isZoomApp to true.
		// TODO: fix that, then you can remove the extraneous !isTeamsApp check
		if (theme.isZoomApp && !isTeamsApp) {
			await routerPush('/zoom/app', {
				completedGuideId: currentProgram?.guides[0]?.id ?? 'default',
			});
			return;
		}

		if (isTeamsApp) {
			return handleTeamsAppOnStopOnCompleteNav();
		}

		if (isShareContentRecipient) {
			setCookie(SHARE_FREE_ACCESS_MODAL_COOKIE, 'true', { expires: 1 });
			await routerPush('/app');
		} else if (currentProgram?.should_play_as_guide) {
			await routerPush('/app');
		} else if (currentProgram) {
			// If content is part of a program send user back
			// to program screen to choose a new guide
			await routerPush(`/app/program/${currentProgram.id}`);
		}
	}, [
		logSession,
		logEvent,
		shareToken,
		isShareContentRecipient,
		currentProgram,
		dispatch,
		setCurrentGuide,
		theme.webAppLayout,
		theme.isZoomApp,
		isTeamsApp,
		shareContentOnStop,
		routerPush,
		overrides,
		shouldStayOnGuidePage,
		handleTeamsAppOnStopOnCompleteNav,
	]);

	// Called when user scrubs through the video seek bar
	const onVideoScrub = useCallback(() => {
		if (mediaEl.current) {
			const currentTime = getCurrentTime(mediaEl.current, contentType);
			clearTimer();
			setTrackProgress(currentTime);
			startTimer(mediaEl.current, contentType);
			logSession('Session : Seek', currentTime);
		}
	}, [contentType, logSession, startTimer]);

	// Called if a user prematurely stops the media content
	// or interrupts it with a new piece of content
	const onCancel = useCallback(() => {
		logSession('Session : Cancelled');

		if (shouldStayOnGuidePage) return;

		// Close player and clear guide state
		dispatch(setGuide(null));
		setPlayerMode('stopped');
	}, [dispatch, logSession, shouldStayOnGuidePage]);

	const onStop = useCallback(
		async (disableAutomaticRouting = !isOrganicTraffic) => {
			// Log relevant session properties
			const wasCompleted = getWasCompleted();
			const currentTime = getCurrentTime(mediaEl.current, contentType);

			await trackSession({
				timesListened: finalizeTimesListened(timesListened, currentTime),
				wasCompleted,
				duration: mediaEl.current?.duration as number,
			});

			if (isShareContentRecipient) {
				await shareContentOnStop();
				return;
			}

			if (wasCompleted) {
				await onComplete();
			} else {
				onCancel();
			}

			// Reset timer and clear media element
			resetTrackProgress();
			clearTimer();

			if (overrides.stop) {
				overrides.stop();
			}

			if (shouldStayOnGuidePage) {
				setPlayerMode('paused');
				return;
			}

			clearMediaEl();

			logSession('Session : Stopped');

			setPlayerMode('stopped');
			setContentType(null);
			setCurrentGuide(null);

			// Send user to either previous screen or app homepage if
			// they quit the session on the player screen
			if (!disableAutomaticRouting && theme.webAppLayout === 'player') {
				const isExternalApp = theme.isZoomApp || isTeamsApp;
				if (isExternalApp) {
					// ZoomApp: Only go back when not completed as `onComplete` redirects to end screen
					// Sadly, our Teams app currently sets isZoomApp to true.
					// TODO: fix that, then you can remove the extraneous !isTeamsApp check
					if (!isTeamsApp && !wasCompleted) {
						router.back();
						return;
					}

					if (isTeamsApp) {
						return handleTeamsAppOnStopOnCompleteNav();
					}
				} else {
					if (document.referrer.includes('/app')) {
						router.back();
						return;
					} else {
						await routerPush('/app');
						return;
					}
				}
			}
		},
		[
			getWasCompleted,
			contentType,
			trackSession,
			finalizeTimesListened,
			timesListened,
			clearMediaEl,
			logSession,
			setCurrentGuide,
			theme.webAppLayout,
			theme.isZoomApp,
			isTeamsApp,
			overrides,
			onComplete,
			onCancel,
			router,
			routerPush,
			isShareContentRecipient,
			shareContentOnStop,
			shouldStayOnGuidePage,
			handleTeamsAppOnStopOnCompleteNav,
			isOrganicTraffic,
		],
	);

	// Adds 15 seconds to current playhead
	const onForward15: TransportClickHandler = useCallback(() => {
		if (mediaEl.current && isAudioMediaEl(mediaEl.current)) {
			if (overrides.seekTo) {
				overrides.seekTo(mediaEl.current.currentTime + 15);
			} else {
				mediaEl.current.currentTime += 15.0;
			}
		}
	}, [overrides]);

	// Subtracts 15 seconds from current playhead
	const onBack15: TransportClickHandler = useCallback(() => {
		if (overrides.seekTo) {
			const time = getCurrentTime(mediaEl.current, contentType);
			overrides.seekTo(time - 15);
		} else {
			if (mediaEl.current && isAudioMediaEl(mediaEl.current)) {
				mediaEl.current.currentTime -= 15.0;
			}
		}
	}, [contentType, overrides]);

	// Called when user scrubs through the audio seek bar
	const onAudioScrub: OnAudioScrub = useCallback(
		e => {
			e.stopPropagation();
			const { value } = e.target;
			clearTimer();
			if (mediaEl.current) {
				const newTime = Number(value);
				if (overrides.seekTo) {
					overrides.seekTo(newTime);
				} else {
					mediaEl.current.currentTime = newTime;
					const elementTime = getCurrentTime(mediaEl.current, contentType);
					setTrackProgress(elementTime);
				}
			}
			startTimer(mediaEl.current, contentType);
		},
		[contentType, overrides, startTimer],
	);

	// Called when a user stops seeking
	const onEndScrub: OnEndScrub = useCallback(
		e => {
			e.stopPropagation();
			if (mediaEl.current) {
				logSession('Session : Seek');
				const currentTime = getCurrentTime(mediaEl.current, contentType);
				updateTimeListenedWithEnd(currentTime);
				startNewTimeListened(currentTime);
			}
		},
		[contentType, logSession, startNewTimeListened, updateTimeListenedWithEnd],
	);

	const postDeviceIfNeedSignedCookie = async (guide?: Guide) => {
		if (guide?.content_policies.some(policy => policy.capabilities?.signedCookie)) {
			// We need to get the Cloudfront signed cookie before we can play the content
			await postDevice().catch(err =>
				calmLogger.error('Error in MediaPlayerProvider postDeviceIfNeedSignedCookie', {}, err),
			);
		}
	};

	// Called when a piece of content starts
	const onAudioStart: OnAudioStart = useCallback(
		async (e, nextGuide, nextProgram) => {
			if (e) e.stopPropagation();

			await postDeviceIfNeedSignedCookie(nextGuide);

			if (playerMode === 'playing') {
				await onStop(true);
			}

			setContentType('audio');
			clearMediaEl();
			resetTrackProgress();

			if (nextGuide) {
				setCurrentGuide(nextGuide);
				const audioEl = new Audio();

				if (nextGuide.assets?.length > 0) {
					const sources = nextGuide.assets.map(asset => {
						const src = document.createElement('source');
						src.setAttribute('src', asset.url);
						src.setAttribute('type', asset.content_type);
						return src;
					});
					audioEl.append(...sources);
				} else if (nextGuide.asset) {
					audioEl.setAttribute('src', nextGuide.asset.url);
				}

				setMediaEl(audioEl);
			}
			if (nextProgram) setCurrentProgram(nextProgram);

			if (autoplayOnLoad) {
				await onPlay(nextGuide, nextProgram, 0);
			} else {
				setPlayerMode('paused');
			}
			clearTimesListened();
		},
		[
			playerMode,
			clearMediaEl,
			setCurrentProgram,
			autoplayOnLoad,
			clearTimesListened,
			onStop,
			setCurrentGuide,
			setMediaEl,
			onPlay,
		],
	);

	// Called when a soundscape restarts
	const onRestart = useCallback(async () => {
		logSession('Session : Began');
		await onPlay();
		if (mediaEl.current?.duration && isAudioMediaEl(mediaEl.current)) {
			updateTimeListenedWithEnd(mediaEl.current.duration);
			startNewTimeListened();
		}
	}, [logSession, onPlay, startNewTimeListened, updateTimeListenedWithEnd]);

	const onVideoEnd = useCallback(async () => {
		if (!mediaEl.current) return;

		const duration = currentGuide?.assets[0]?.duration ?? 0;
		await trackSession({
			timesListened: finalizeTimesListened(timesListened, getCurrentTime(mediaEl.current, contentType)),
			wasCompleted: true,
			duration,
		});
		await onComplete();
	}, [contentType, currentGuide?.assets, finalizeTimesListened, onComplete, timesListened, trackSession]);

	// Called when a user reaches the end of piece of audio content
	const onAudioEnd: OnAudioEnd = useCallback(
		async audioEl => {
			if (!mediaEl.current) return;

			const playerMode = shouldStayOnGuidePage ? 'paused' : 'stopped';
			setPlayerMode(playerMode);

			if (currentProgram?.meditation_type === 'soundscape') {
				// If soundscape, restart
				logSession('Session : Completed');
				await onRestart();
			} else {
				await trackSession({
					timesListened: finalizeTimesListened(timesListened, getCurrentTime(mediaEl.current, contentType)),
					wasCompleted: true,
					duration: audioEl?.duration as number,
				});
				await onComplete();
			}
		},
		[
			contentType,
			currentProgram?.meditation_type,
			finalizeTimesListened,
			logSession,
			onComplete,
			onRestart,
			timesListened,
			trackSession,
			shouldStayOnGuidePage,
		],
	);

	useAutoplay(mediaEl.current, onAudioStart, isShareContentRecipient);

	const onVideoStart: OnVideoStart = useCallback(
		async (nextGuide, nextProgram, nextPlayerMode = 'playing', logEvents = true) => {
			if (playerMode === 'playing' && contentType === 'audio') {
				await onStop(true);
			}

			await postDeviceIfNeedSignedCookie(nextGuide);
			setContentType('video');

			if (nextGuide) setCurrentGuide(nextGuide);
			if (nextProgram) setCurrentProgram(nextProgram);

			resetTrackProgress();
			setPlayerMode(nextPlayerMode);
			if (nextPlayerMode === 'playing') {
				setPlayerMode('playing');

				if (logEvents) {
					logSession('Session : Began', 0, nextGuide, nextProgram);
					logSession('Session : Played', 0, nextGuide, nextProgram);
				}
			}
			if (overrides.play) {
				overrides.play();
			}
		},
		[contentType, logSession, onStop, playerMode, setCurrentGuide, setCurrentProgram, overrides],
	);

	const onVideoPlay = useCallback(async () => {
		const currentTime = getCurrentTime(mediaEl.current, contentType);
		if (currentTime === 0) {
			startTimer(mediaEl.current, 'video');
			onVideoStart();
		} else {
			await onPlay();
		}
	}, [contentType, onPlay, onVideoStart, startTimer]);

	const onVideoPause = useCallback(() => {
		onPause();
	}, [onPause]);

	// Binds the relevant video player event listeners
	useEffect(() => {
		if (mediaEl.current && contentType === 'video' && isVideoMediaEl(mediaEl.current)) {
			mediaEl.current.on('play', onVideoPlay);
			mediaEl.current.on('pause', onVideoPause);
			mediaEl.current.on('seeked', onVideoScrub);
			mediaEl.current.on('ended', onVideoEnd);
		}

		return () => {
			if (mediaEl.current && contentType === 'video' && isVideoMediaEl(mediaEl.current)) {
				mediaEl.current.off('play', onVideoPlay);
				mediaEl.current.off('pause', onVideoPause);
				mediaEl.current.off('seeked', onVideoScrub);
				mediaEl.current.off('ended', onVideoEnd);
			}
		};
	}, [mediaEl, playerMode, contentType, onVideoPlay, onVideoPause, onVideoScrub, onVideoEnd]);

	// Binds keyboard event listeners
	useEffect(() => {
		// Applies key events that allow the user to pause
		// or play media content with their space bar
		const handleKeyEvents = async (e: KeyboardEvent) => {
			// If the target of the key event is an input element, return immediately
			if ((e.target as HTMLElement)?.tagName === 'INPUT') {
				return;
			}

			if (e.code === 'Space') {
				e.preventDefault();
				if (playerMode === 'playing') {
					onPause();
				} else if (playerMode === 'paused') {
					await onPlay();
				}
			}
		};

		if (mediaEl && contentType === 'audio') {
			window.addEventListener('keydown', handleKeyEvents);
		}

		return () => {
			if (mediaEl && contentType === 'audio') {
				window.removeEventListener('keydown', handleKeyEvents);
			}
		};
	}, [onPause, onPlay, playerMode, contentType]);

	// Trigger audio end function when user reaches end of content
	useEffect(() => {
		if (
			playerMode === 'playing' &&
			contentType === 'audio' &&
			mediaEl.current &&
			isAudioMediaEl(mediaEl.current) &&
			getCurrentTime(mediaEl.current, contentType) >= mediaEl.current?.duration
		) {
			onAudioEnd(mediaEl.current);
		}
	}, [contentType, onAudioEnd, playerMode]);

	// Stops the content after 30 seconds if user is in preview mode
	useEffect(() => {
		if (!mediaEl.current) return;

		const currentTime = getCurrentTime(mediaEl.current, contentType);
		if (isPreviewMode && mediaEl.current && currentTime > 30 && playerMode === 'playing') {
			onStop().catch(error => calmLogger.error('Error in MediaPlayerProvider onStop', {}, error));
		}
	}, [contentType, isPreviewMode, onStop, playerMode]);

	// Listens for user or guide changes to determine whether user should be
	// served preview mode
	useEffect(() => {
		function evalIsPreviewMode(): boolean {
			// Teams app uses hideControls when guest mode is used
			if (hideControls) return false;

			const isValidContentSub = !currentGuide?.is_free && !user?.subscription?.valid;
			if (shareToken && !isFetchingShare) {
				return isValidContentSub && !isShareContentRecipient;
			}
			return isValidContentSub;
		}
		setIsPreviewMode(evalIsPreviewMode());
	}, [currentGuide, user, isFetchingShare, isShareContentRecipient, shareToken, hideControls]);

	const value = useMemo(
		() => ({
			trackProgress,
			setTrackProgress,
			mediaEl: mediaEl ? mediaEl.current : null,
			setMediaEl,
			isPreviewMode,
			onAudioScrub,
			onBack15,
			onPause,
			onAudioStart,
			onVideoStart,
			onForward15,
			onStop,
			onEndScrub,
			currentTime: mediaEl.current ? getCurrentTime(mediaEl.current, contentType) : 0,
			duration: isPreviewMode ? 30 : currentGuide?.asset?.duration ?? 0,
			playerMode,
			contentType,
			isZenMode,
			onPlay,
			setOverrides,
			hideControls,
			setHideControls,
			autoplayOnLoad,
			setAutoplayOnLoad,
			shouldPreventAppNav,
			setShouldPreventAppNav,
			setPlayerMode,
			onComplete,
			onCancel,
		}),
		[
			trackProgress,
			setTrackProgress,
			setMediaEl,
			isPreviewMode,
			onAudioScrub,
			onBack15,
			onPause,
			onAudioStart,
			onVideoStart,
			onForward15,
			onStop,
			onEndScrub,
			currentGuide?.asset?.duration,
			playerMode,
			contentType,
			isZenMode,
			onPlay,
			setOverrides,
			hideControls,
			setHideControls,
			autoplayOnLoad,
			setAutoplayOnLoad,
			shouldPreventAppNav,
			setShouldPreventAppNav,
			setPlayerMode,
			onComplete,
			onCancel,
		],
	);

	return <MediaPlayerContext.Provider value={value}>{children}</MediaPlayerContext.Provider>;
};

export default MediaPlayerProvider;
