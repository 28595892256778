import { useEffect, useState } from 'react';
import { useIdle } from 'react-use';

import { PlayerMode } from '@/components/app/contentPlayer/MediaPlayerProvider/types';
import { ZEN_MODE_KEY } from '@/components/app/scenes/ZenModeSelector';
import { useDeviceState } from '@/hooks/store';
import { Guide } from '@/store/guide/types';
import { Program } from '@/store/program/types';

interface UseZenMode {
	({
		playerMode,
		guide,
		program,
	}: {
		playerMode: PlayerMode;
		guide: Guide | null;
		program: Program | null;
	}): boolean;
}

export const useZenMode: UseZenMode = ({ playerMode, guide }) => {
	const [isZenMode, setIsZenMode] = useState(false);
	const device = useDeviceState();

	const timeoutForZenMode = 15e3; // 15 seconds idle timeout
	const isIdle = useIdle(timeoutForZenMode);

	const assetType = guide?.asset?.type;

	useEffect(() => {
		const isAvailable = () => {
			const isPlayingVideo = playerMode === 'playing' && assetType === 'video';

			// detect silk browser https://docs.aws.amazon.com/silk/latest/developerguide/detecting-silk-ua.html
			const isSilkBrowser = /(?:; ([^;)]+) Build\/.*)?\bSilk\/([0-9._-]+)\b(.*\bMobile Safari\b)?/.exec(
				window.navigator.userAgent,
			);
			if (
				localStorage.getItem(ZEN_MODE_KEY) === 'true' &&
				!isSilkBrowser &&
				!isPlayingVideo &&
				!device.isZoomApp
			) {
				return true;
			}

			return false;
		};

		setIsZenMode(isAvailable() && isIdle);
	}, [assetType, device.isZoomApp, isIdle, playerMode]);

	return isZenMode;
};
