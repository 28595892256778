import styled from 'styled-components';

import CookiePreferencesBanner from '@/components/layout/CookiePreferencesBanner';

export const APP_WRAPPER_BOTTOM_MARGIN = 104;

export const AriaHidden = styled.div.attrs({
	'aria-hidden': true,
})`
	visibility: hidden;
`;

export const FixedBottomContainer = styled.div<{ $isWebAppLayout: boolean }>`
	position: ${p => (p.$isWebAppLayout ? 'fixed' : 'static')};
	z-index: 10;
	bottom: 0;
	left: 0;
	right: 0;
`;

export const CookiePreferencesBannerPlaceholder = styled(CookiePreferencesBanner)`
	position: static;
`;
