import styled from 'styled-components';
import { prop } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.div<{ allowOverflow: boolean; alignLeft?: boolean }>`
	display: flex;
	justify-content: ${p => (p.alignLeft ? 'start' : 'center')};
	overflow: ${p => (p.allowOverflow ? 'visible' : 'hidden')};
	width: 100%;
`;

export const Grid = styled.div<{
	columns: string;
}>`
	display: grid;
	grid-column-gap: 16px;
	grid-row-gap: 0;
	grid-template-columns: 1fr;
	margin: 0 16px;
	width: 100%;

	${minWidth('mobileLarge')} {
		grid-column-gap: 24px;
		margin: 0 24px;
	}

	${minWidth('tablet')} {
		grid-template-columns: ${prop('columns')};
	}

	${minWidth('desktop')} {
		grid-column-gap: 36px;
		max-width: 960px;
		margin: 0 36px;
	}

	${minWidth('desktopLarge')} {
		grid-column-gap: 48px;
		max-width: 1168px;
		margin: 0 48px;
	}
`;
