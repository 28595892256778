import { AnyAction } from 'redux';

import { PartnerDetails as Partner } from '@/components/b2b/shared/types';

import { SET_PARTNER } from './types';

const initialState: Partner | null = null;

export function partnerReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): Partner | null {
	switch (action.type) {
		case SET_PARTNER:
			return action.payload || {};
		default:
			return state;
	}
}
