import { useContext } from 'react';

import { AnalyticsContext, AnalyticsContextProps } from '@/components/app/layout/AnalyticsContextProvider';

// Legacy hook wrapper to provide the functions from the Context
export function useAnalytics(): Omit<AnalyticsContextProps, 'experimentClient'> {
	const { logEvent, logEventAsync, logPurchase, isAnalyticsInitialized } = useContext(AnalyticsContext);
	return { logEvent, logEventAsync, logPurchase, isAnalyticsInitialized };
}

export const useExperimentClient = () => {
	const { experimentClient } = useContext(AnalyticsContext);

	return experimentClient;
};
