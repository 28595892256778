import styled, { css } from 'styled-components';
import { palette, theme } from 'styled-tools';

import { units } from '@calm-web/design-system';

import { FlyoutMenuProps } from './types';

const appStyles = css`
	background: linear-gradient(180deg, #3a8cbd 0%, #3b399c 100%);
	padding: 64px 0 0;
	top: 0;

	&:after {
		background: rgba(0, 0, 0, 0.2);
		content: '';
		opacity: 0.9;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000;
		height: 100vh;
	}
`;

const marketingStyles = css`
	background: ${palette('gradientCalmBrand')};
	padding: 64px 0;
	top: 0;

	&:after {
		background: rgba(0, 0, 0, 0.2);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}

	ul {
		padding: 0;
		padding-left: ${units(2)};
	}
	li {
		margin-bottom: ${units(4)};
	}
`;

type WrapperProps = Omit<FlyoutMenuProps, 'label'>;
export const Wrapper = styled.nav<WrapperProps>`
	z-index: 1;
	align-items: flex-start;
	bottom: 0;
	color: ${palette('white')};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	opacity: ${(props): string => (props.isOpen ? '1' : '0')};
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	height: 100vh;
	overflow: scroll;
	transition: ${theme('animation.transition')};
	transform: ${(props): string => (props.isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)')};
	visibility: ${p => (!p.isOpen ? 'hidden' : 'visible')};
	z-index: 999;
	${p => (p.isWebApp ? appStyles : marketingStyles)}
`;
