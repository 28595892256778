import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Button, IconButton, units } from '@calm-web/design-system';

import close from '../../icons/close.svg';

export const HandoffWrapperWrapper = styled.div<{ isOpen: boolean; audioPlayerAllowance: string }>`
	width: 240px;
	position: absolute;
	left: 0;
	right: 0;
	overflow: hidden;
	pointer-events: ${p => (p.isOpen ? '' : 'none')};
	bottom: ${props => props.audioPlayerAllowance};
	transition: ${theme('animation.transition')};
`;

export const HandoffExpandedWrapper = styled.div<{ isOpen: boolean }>`
	width: 240px;
	display: flex;
	flex-direction: column;
	align-items: self-end;
	padding: 18px;
	background: white;
	border-radius: 20px 20px 0 0;

	opacity: ${props => (props.isOpen ? '1' : '0')};
	transform: translate3d(0, ${p => (p.isOpen ? '0' : '100%')}, 0);
	transition: ${theme('animation.transition')};
	visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

	span {
		max-width: 180px;
		margin: auto;
	}
`;

export const CloseButton = styled(IconButton).attrs({
	Icon: close,
})`
	min-height: 0;
	min-width: 0;
	padding: ${units(1.5)} !important;
	margin: ${units(-1, -1.5)};
	height: 100%;
`;

export const QRCode = styled.img`
	width: 140px;
	height: auto;
	margin: auto;
	margin-top: 10px;
	margin-bottom: 24px;
`;

export const NavItem = styled(Button)`
	padding-top: 12px !important;
	padding-bottom: 12px !important;
	padding-left: 12px !important;
	height: 64px;

	&:before {
		border-radius: 10px !important;
	}

	> div {
		height: 40px;
		width: auto;
	}
	border-radius: 10px !important;
`;

export const CalmAppLogo = styled.img.attrs({
	alt: 'Calm logo',
	src: '/_n/images/calm-logo-square.png',
})`
	height: 40px;
`;
