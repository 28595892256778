import SHA256 from 'crypto-js/sha256';
import { useRouter } from 'next/router';
import uuid from 'uuid/v4';

import { PaymentType } from '@/components/purchase/PurchaseForm/types';
import { useAnalytics } from '@/hooks/analytics';
import { ProductInfo } from '@/hooks/analytics/types';
import { usePurchaseParamsState, usePartnerState, usePricesState, useUserState } from '@/hooks/store';
import { Subscription, User } from '@/store/user/types';

import { useFormattedCurrency } from './useFormattedCurrency';
import { usePaymentFormValues } from './usePaymentFormValues';

export interface OnPurchaseSuccessArgs {
	productInfo?: ProductInfo;
	paymentType?: PaymentType;
	transactionId?: string | null;
	analyticsPrefix?: string;
	newUser: User | null;
}

interface OnPurchaseSuccess {
	({
		productInfo,
		paymentType,
		transactionId,
		analyticsPrefix,
		newUser,
	}: OnPurchaseSuccessArgs): Promise<void>;
}

interface UsePurchaseSuccess {
	(): OnPurchaseSuccess;
}

export const initLoggableSubscriptionDetails = (subscription?: Subscription) => {
	const subscriptionTypeDetails = subscription?.type ? subscription[`${subscription?.type}_details`] : null;
	return {
		...(subscription
			? {
					subscription_began_at: subscription.began,
					subscription_has_ever_done_free_trial: subscription.has_ever_done_free_trial,
					subscription_in_free_trial_window: subscription.in_free_trial_window,
					subscription_is_canceled: subscription.is_canceled,
					subscription_is_refunded: subscription.is_refunded,
					subscription_type: subscription.type,
					subscription_will_renew: subscription.will_renew,
			  }
			: {}),
		...(subscriptionTypeDetails && 'renews_at' in subscriptionTypeDetails
			? { subscription_renews_at: subscriptionTypeDetails.renews_at }
			: {}),
	};
};

export const usePurchaseSuccess: UsePurchaseSuccess = () => {
	const partner = usePartnerState();
	const { plan, purchaseType, promotion } = usePurchaseParamsState();
	const prices = usePricesState();
	const user = useUserState();
	const { query } = useRouter();
	const { logEventAsync, logPurchase } = useAnalytics();
	const formatCurrency = useFormattedCurrency();
	const { paymentFormCurrency } = usePaymentFormValues();

	const onPurchaseSuccess: OnPurchaseSuccess = async ({
		productInfo,
		transactionId,
		analyticsPrefix = 'Subscribe : Purchase : Form',
		paymentType = 'credit_card',
		newUser,
	}) => {
		const _user = newUser ?? user;
		const transactionInfo = { id: transactionId ?? _user?.id ?? null };
		const _productInfo = productInfo ?? {
			name: 'web_subscription',
			sku: plan,
			unit_price: purchaseType?.price ? purchaseType.price / 100.0 : 0,
			quantity: 1,
		};

		logPurchase({ productInfo: _productInfo, transactionInfo });

		const shouldLogSubDetails = productInfo?.sku !== 'gift_card';
		const subscriptionDetails = initLoggableSubscriptionDetails(
			shouldLogSubDetails ? _user?.subscription : undefined,
		);

		const partnerSpecificAnalytics = {
			partner_id: partner?.id,
			partner_type: [partner?.category?.toLowerCase() || null],
		};

		const trackingProperties = {
			name: _productInfo.name,
			unit_price: _productInfo.unit_price,
			quantity: _productInfo.quantity,
			plan: _productInfo.sku,
			promotion: promotion ?? null,
			amp_order_id: uuid(),
			hashed_email: _user?.email ? SHA256(_user?.email).toString() : null,
			currency: paymentFormCurrency,
			local_price: formatCurrency(prices.current[plan]),
			is_free_trial: purchaseType?.type === 'freetrial',
			isFreeTrial: purchaseType?.type === 'freetrial',
			payment_type: paymentType,
			source: query?.source ?? productInfo?.source ?? '',
			...subscriptionDetails,
			...(partner && partnerSpecificAnalytics),
		};

		await logEventAsync({
			eventName: `${analyticsPrefix} : Success`,
			eventProps: trackingProperties,
		});
	};

	return onPurchaseSuccess;
};
