import { useIntl } from 'react-intl';

import messages from './messages';
import { Line, Wrapper } from './styles';
import { HamburgerMenuProps } from './types';

const HamburgerMenu = ({ onClick, isOpen, isDark = false }: HamburgerMenuProps) => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper
			isDark={isDark}
			onPress={onClick}
			isOpen={isOpen}
			aria-label={isOpen ? formatMessage(messages.closeButtonLabel) : formatMessage(messages.openButtonLabel)}
			aria-expanded={isOpen}
			aria-controls="mobile-menu"
		>
			<Line />
			<Line />
			<Line />
		</Wrapper>
	);
};

export default HamburgerMenu;
