import { defineMessages } from 'react-intl';

export default defineMessages({
	loginToggle: {
		id: 'login.loginToggleText2',
		defaultMessage: "Don't have a Calm account?",
		description:
			"Text shown on the login form with the text 'Sign up' right after it to switch to the signup form",
	},
	signupToggle: {
		id: 'login.b2bSignupToggleText2',
		defaultMessage: 'Have a Calm account?',
		description:
			"Text shown on the signup form with the text 'Log in' right after to switch to the login form",
	},
});
