import Cookies, { CookieAttributes } from 'js-cookie';

const getCookieOptions = (): CookieAttributes => ({
	domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
	secure: typeof window !== 'undefined' ? window.location.protocol === 'https:' : false,
	expires: 365,
});

type Key = string;
type Value = string;

// 🍪🍪🍪
export const setCookie = (key: Key, value: Value, options: CookieAttributes = {}): void => {
	Cookies.set(key, value, { ...getCookieOptions(), ...options });
};

// 🍪🍪🍪
export const getCookie = (key: Key): Value | undefined => {
	return Cookies.get(key);
};

// 🍪🍪🍪
export const getAllCookies = (): Record<string, Value> => {
	return Cookies.get();
};

// 🍪🍪🍪
export const removeCookie = (key: Key, options: CookieAttributes = {}): void => {
	Cookies.remove(key, { ...getCookieOptions(), ...options });
};
