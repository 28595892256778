import { defineMessages } from 'react-intl';

export const messages = defineMessages({
	tooltipMessage: {
		id: 'shareButton.tooltip.message',
		defaultMessage: 'Share this with a friend and they can listen for free!',
		description: 'Explaining to the user how the sharing functionality works',
	},
	shareModalMessage: {
		id: 'shareModal.body',
		defaultMessage: 'Share free access to this content by copying the link below!',
		description: 'Explaining to the user how the sharing functionality works',
	},
	share: {
		id: 'shareModal.header',
		defaultMessage: 'Share',
		description: 'Title of share modal.',
	},
	copy: {
		id: 'shareModal.copy.text',
		defaultMessage: 'Click to copy',
		description: 'The word Copy to describe copying the url',
	},
	linkCopyConfirm: {
		id: 'shareModal.copy.confirm',
		defaultMessage: 'Link copied to clipboard.',
		description: 'Confirm to the user the link has been copied.',
	},
	shareLinkText: {
		id: 'shareModal.copy.shareText',
		defaultMessage: "I think you'd like this session from Calm. You can listen to it for free.",
		description: 'Additional text for link for mobile native share sheet',
	},
});
