import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

const absoluteBackground = css`
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
`;

export const Wrapper = styled.div`
	${absoluteBackground}
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

export const BlackOverlay = styled.div`
	${absoluteBackground}
	background: ${palette('black')};
	content: '';
	opacity: 0.5;
	position: absolute;
`;

const BLUR = 25;
export const BlurredBackground = styled.div<{ img: string }>`
	height: calc(100vh + ${2 * BLUR}px);
	left: ${-1 * BLUR}px;
	top: ${-1 * BLUR}px;
	width: calc(100vw + ${2 * BLUR}px);
	position: absolute;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
		url(${p => p.img}) lightgray 50% / cover no-repeat;
	filter: blur(${BLUR}px);
`;
