import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, fontWeight, FontSizes, FontWeights } from '@calm-web/design-system';

export const Disclaimer = styled.div`
	${fontSize(FontSizes.sm)}
	${fontWeight(FontWeights.Regular)};
	margin-top: 1rem;
`;

export const DisclaimerWrapper = styled.div`
	color: ${p => (p.theme.isDark ? palette('white') : palette('gray6'))};
	margin-top: 0.5rem;
`;

export const CancelLink = styled.a`
	color: ${palette('blue2')};
	text-decoration: underline;
`;
