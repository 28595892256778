import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';

import { FormStateContext } from '@/context/FormStateContextProvider';
import { useAuth } from '@/hooks/auth/useAuth';
import { FORM_ACTIONS, useFacebookSubmit } from '@/hooks/forms';
import { useAuthFormModeState } from '@/hooks/store';
import messages from '@/messages/messages';
import { useIsTeamsNativeApp } from '@/utils/app/msftTeams';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import FacebookAuthButtonTeams from './FacebookAuthButtonTeams';
import { FBIcon, Wrapper } from './styles';
import { FacebookAuthButtonProps, FacebookButtonProps } from './types';

const FacebookButton = ({
	className = '',
	buttonText,
	contentAlignment,
	hideMessage = false,
	onClick,
}: FacebookButtonProps) => {
	const { isAuth0Enabled } = useAuth();
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();

	const getButtonCta = () => {
		if (buttonText) {
			return buttonText;
		}

		if (isAuth0Enabled) {
			return authFormMode === 'login'
				? formatMessage(messages.facebookSignInMessage)
				: formatMessage(messages.continueFacebookSignupMessage);
		}

		return formatMessage(messages.continueFacebookSignupMessage);
	};

	const getContentAlignment = () => {
		if (isAuth0Enabled) {
			return 'center';
		}

		return contentAlignment ?? hideMessage ? 'center' : 'left';
	};

	const buttonCta = getButtonCta();
	const _contentAlignment = getContentAlignment();
	const bgColor = isAuth0Enabled ? 'white' : 'facebookBlue';
	const textColor = isAuth0Enabled ? 'black' : 'white';
	const borderColor = isAuth0Enabled ? 'gray3' : 'gray2';
	const iconColor = isAuth0Enabled ? 'facebookBlue' : 'white';

	return (
		<Wrapper
			backgroundColor={bgColor}
			className={className}
			contentAlignment={_contentAlignment}
			data-testid="facebook-button"
			onPress={onClick}
			role="link"
			$noPadding={hideMessage}
			textColor={textColor}
			borderColor={borderColor}
		>
			<FBIcon $noMargin={hideMessage} $iconColor={iconColor} />
			{hideMessage ? <VisuallyHidden>{buttonCta}</VisuallyHidden> : buttonCta}
		</Wrapper>
	);
};

/**
 * @deprecated This will be removed when auth0 migration is completed in favor of FacebookAuthButtonBrowser.
 */
const FacebookAuthButtonBrowserDeprecated = ({
	className = '',
	contentAlignment,
	buttonText,
	hideMessage = false,
	marketingOptIn,
	onAuthSuccess,
}: FacebookAuthButtonProps) => {
	const { formDispatch, setFormError, setFormSuccess } = useContext(FormStateContext);

	const onFacebookSubmit = useFacebookSubmit();

	async function onClick(): Promise<void> {
		formDispatch({ type: FORM_ACTIONS.requestSent });

		const data = await onFacebookSubmit(marketingOptIn);

		if (data) {
			if ('success' in data) {
				formDispatch({ type: FORM_ACTIONS.requestSuccess });
				if (onAuthSuccess) {
					onAuthSuccess(data.success);
				}
			} else if ('error' in data) {
				setFormError(data?.error);
				formDispatch({ type: FORM_ACTIONS.requestFailure });
			}
		} else {
			setFormSuccess(null);
			setFormError(null);
			formDispatch({ type: FORM_ACTIONS.requestCancelled });
		}
	}

	if (!process.env.NEXT_PUBLIC_FACEBOOK_APP_ID) {
		return null;
	}

	return (
		<FacebookButton
			className={className}
			contentAlignment={contentAlignment}
			buttonText={buttonText}
			hideMessage={hideMessage}
			onClick={onClick}
		/>
	);
};

const FacebookAuthButtonBrowser = (props: FacebookAuthButtonProps) => {
	const { className, contentAlignment, buttonText, hideMessage, onAuthSuccess, marketingOptIn } = props;

	const { authenticate, isAuth0Enabled } = useAuth();

	if (!isAuth0Enabled) {
		return <FacebookAuthButtonBrowserDeprecated {...props} />;
	}

	const onClick = (): void => {
		authenticate({ authMethod: CalmAuthMethods.Facebook, marketingOptIn, onAuthSuccess });
	};

	return (
		<FacebookButton
			className={className}
			contentAlignment={contentAlignment}
			buttonText={buttonText}
			hideMessage={hideMessage}
			onClick={onClick}
		/>
	);
};

function FacebookAuthButton(props: FacebookAuthButtonProps) {
	const { isTeamsNativeApp, isLoading: isTeamsLoading } = useIsTeamsNativeApp();
	if (isTeamsLoading) {
		return <Loader color="gray1" />;
	}
	if (isTeamsNativeApp) {
		return <FacebookAuthButtonTeams {...props} />;
	}
	return <FacebookAuthButtonBrowser {...props} />;
}

export default FacebookAuthButton;
