import messages from '@/messages/messages';

import { NavItemProps } from '../DesktopNav/types';

export const loggedInNavItems: NavItemProps[] = [
	{
		title: messages.meditate,
		href: '/app/meditate',
	},
	{
		title: messages.sleepStories,
		href: '/app/sleep',
	},
	{
		title: messages.music,
		href: '/app/music',
	},
	{
		title: messages.wisdom,
		href: '/app/wisdom',
		supportedLocales: 'en',
	},
	{
		title: messages.movement,
		href: '/app/movement',
		supportedLocales: ['en', 'de', 'fr'],
	},
];
