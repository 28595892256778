const slugEventProps = {
	'signup-flow': {
		source: 'FTUE',
		is_free_trial: true,
	},
	b2b: {
		source: 'B2B',
	},
	americanexpress: {
		subscription_free_trial_length_in_days: 365,
		promotion: 'amex_card_member',
		source: 'amex',
	},
	americanexpressglobal: {
		subscription_free_trial_length_in_days: 365,
		promotion: 'amex_card_member',
		source: 'amex',
	},
	'special-welcome-offer': {
		source: 'Web App',
	},
	family: {
		source: 'family',
	},
	jay: {
		active_flow: 'jay',
		source: 'jay',
	},
	dailyjay: {
		active_flow: 'daily_jay',
		source: 'daily_jay',
	},
} as const;
export type SlugEventPropsKey = keyof typeof slugEventProps;
export const isSlugEventPropsKey = (value: string): value is SlugEventPropsKey => value in slugEventProps;

export default slugEventProps;
