import apiRequest from '@/utils/apiRequest';
import { getCookie } from '@/utils/cookies';
import { ACCEPT_GDPR_COOKIES_KEY } from '@/utils/privacyCookies';

export interface DeviceConsent {
	ip_country: string | undefined | null;
	requires_gdpr: boolean;
	requires_gdpr_choice: boolean;
	was_server_maintenance_error?: boolean;
}

export const initDeviceConsent = async (): Promise<DeviceConsent> => {
	try {
		const { data } = await apiRequest<DeviceConsent>({
			endpoint: 'device/consent',
			method: 'GET',
		});

		return {
			...data,
			requires_gdpr_choice: data.requires_gdpr,
			requires_gdpr:
				getCookie(ACCEPT_GDPR_COOKIES_KEY) !== undefined
					? !getCookie(ACCEPT_GDPR_COOKIES_KEY)
					: data.requires_gdpr,
		};
	} catch (error) {
		return {
			ip_country: null,
			requires_gdpr: false,
			requires_gdpr_choice: false,
		};
	}
};
