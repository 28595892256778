import { Wrapper } from './styles';
import { FlyoutMenuProps } from './types';

const FlyoutMenu = ({ isOpen, isWebApp, children, label }: FlyoutMenuProps) => {
	return (
		<Wrapper id="mobile-menu" isWebApp={isWebApp} isOpen={isOpen} aria-label={label} aria-expanded={isOpen}>
			{children}
		</Wrapper>
	);
};

export default FlyoutMenu;
