import AgentKeepAlive from 'agentkeepalive';
import { AxiosPromise } from 'axios';

import apiRequest, { UserData } from '@/utils/apiRequest';

type PostDeviceReturn = {
	id: string;
	is_new: boolean;
	platform: string;
	version: string;
	calm_identifier: string;
};

export function postDevice(
	{
		userData,
		agents,
		excludedHeaders,
	}: {
		userData: UserData | null;
		agents: {
			httpAgent: AgentKeepAlive;
			httpsAgent: AgentKeepAlive.HttpsAgent;
		} | null;
		excludedHeaders?: string[];
	} = { userData: null, agents: null },
): AxiosPromise<PostDeviceReturn> {
	return apiRequest<PostDeviceReturn>({
		endpoint: 'device',
		method: 'POST',
		userData,
		agents,
		customHeaders: {
			'x-device-capabilities': 'v1;signedCookie;streamOnly',
		},
		excludedHeaders,
	});
}
