import { SignupScreens } from '../PreSignupProvider/types';
import { secondFreeTrialSignupScreens } from './Bespoke/Second-Free-Trial';
import { signupPaymentHybridScreens } from './Bespoke/Signup-Payment-Hybrid';
import { sleepQuestionnaireScreens } from './Bespoke/Sleep-Questionnaire';
import { sleepQuestionnaireScreens_NoLanding } from './Bespoke/Sleep-Questionnaire_NoLanding';
import { simplifiedSleepSignupScreens } from './Bespoke/Sleep-SimplifiedSignup';
import { stressQuestionnaireScreens } from './Bespoke/Stress-Questionnaire';
import { stressQuestionnaireScreens_NoLanding } from './Bespoke/Stress-Questionnaire_NoLanding';
import { simplifiedStressSignupScreens } from './Bespoke/Stress-SimplifiedSignup';
import { goalSelectionScreen } from './Control/Goals-Control';
import { signupPaymentScreens } from './Control/Signup-Payment-Control';
import { sleepPreSignupScreens } from './Control/Sleep-Control';
import { stressPreSignupScreens } from './Control/Stress-Control';

const FlowMap: Record<string, SignupScreens> = {
	// Control flows here
	goals: goalSelectionScreen,
	sleep: sleepPreSignupScreens,
	stress: stressPreSignupScreens,
	simplified_signup: signupPaymentScreens,
	// Add bespoke flows here
	sleep_simplified: simplifiedSleepSignupScreens,
	stress_simplified: simplifiedStressSignupScreens,
	second_free_trial: secondFreeTrialSignupScreens,
	stress_questionnaire: stressQuestionnaireScreens,
	sleep_questionnaire: sleepQuestionnaireScreens,
	stress_questionnaireB: stressQuestionnaireScreens_NoLanding,
	sleep_questionnaireB: sleepQuestionnaireScreens_NoLanding,
	signup_payment_hybrid: signupPaymentHybridScreens,
};

export default FlowMap;
