import { defineMessages } from 'react-intl';

export default defineMessages({
	postalCodeInput: {
		id: 'purchase.billingInfoForm.postalCodeInput',
		defaultMessage: 'Postal Code',
		description: 'Name for input to collect postal code',
	},
	postalCodeError: {
		id: 'purchase.billingInfoForm.postalCodeError',
		defaultMessage: 'Please enter a valid postal code',
		description: 'Error message if user has not inputted a valid postal code',
	},
});
