export const formatTime = (time: number, onlyMinutes = false): string => {
	if (!time) {
		return '0:00';
	}
	const minutes = Math.floor(time / 60);

	if (onlyMinutes) {
		return minutes.toString();
	}

	const seconds: string = (() => {
		const s = Math.floor(time - minutes * 60);
		return s < 10 ? `0${s}` : s.toString();
	})();
	return `${minutes}:${seconds}`;
};
