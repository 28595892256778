import styled from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

import { APP_SIDEBAR_INDEX } from '@/utils/ui/zIndices';

export const Wrapper = styled.aside<{ $bottomOffset?: number; isBannerVisible?: boolean }>`
	background: ${palette('transparentBlackBackground')};
	bottom: 0;
	top: 0;
	display: none;
	padding: 40px 24px;
	margin-bottom: ${p => p.$bottomOffset}px;
	margin-top: ${ifProp('isBannerVisible', theme('app.mobileBannerHeight'), '0')};
	position: fixed;
	width: ${theme('app.sidebarWidth')};
	z-index: ${APP_SIDEBAR_INDEX};
	backdrop-filter: blur(12px);

	${minWidth('desktop')} {
		display: flex;
		flex-direction: column;
	}
	overflow-y: auto;

	${minWidth('tablet')} {
		margin-top: ${ifProp('isBannerVisible', theme('app.bannerHeight'), '0')};
	}
`;

export const MenuItems = styled.nav`
	margin: 40px 0;
	ul {
		padding: 0;
		li {
			list-style: none;
		}
	}
`;

export const PrivacyPolicy = styled.a`
	color: ${palette('white')};
	margin-top: auto;
	text-decoration: none;
`;
