import { defineMessages } from 'react-intl';

export default defineMessages({
	emailSignInCta: {
		id: 'auth.emailSignInCta.cta',
		defaultMessage: 'Sign in with Email',
		description: 'Cta for email sign in button',
	},
	emailContinueCta: {
		id: 'auth.emailContinue.cta',
		defaultMessage: 'Continue with Email',
		description: 'Cta for email continue button',
	},
});
