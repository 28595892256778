import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { useFormattedCurrency } from '@/hooks/purchase';
import { PlanSku } from '@/hooks/purchase/usePlanSkuToggle';
import { usePricesState, useUserState } from '@/hooks/store';
import { Coupon } from '@/store/prices/types';
import { PlanCadence, isValidPlan } from '@/store/purchaseParams/types';
import { userCanTrial as userCanTrialEval } from '@/utils/subscriptions';

import messages from './messages';
import { Wrapper, PlanSkuInterior, PlanSkuLeft, PlanSkuRight, TrialBadge } from './styles';

export type PlanSkuItemProps = PlanSku & {
	onClick?: (id: PlanSkuItemProps['id']) => void;
};

const PlanSkuItem = ({
	duration,
	id,
	originalPrice,
	price,
	pricePerMonth,
	active,
	onClick = () => {},
	trialLength,
}: PlanSkuItemProps) => {
	const prices = usePricesState();
	const user = useUserState();
	const formatCurrency = useFormattedCurrency();
	const userCanTrial = userCanTrialEval(user);
	const { formatMessage } = useIntl();

	const getCouponByPlan = (plan: PlanCadence): Coupon | undefined =>
		isValidPlan(plan) ? prices?.coupons?.[plan] : undefined;

	return (
		<Wrapper
			role="radio"
			name="plan sku"
			isActive={active}
			onPress={() => onClick(id)}
			activeBackgroundColor="white"
			inactiveBackgroundColor="white"
			aria-checked={active}
			focusRingVariant="dark"
			borderWidth="2px"
			borderColor={active ? 'focusRingDark' : 'inputBgDisabled'}
		>
			<PlanSkuInterior>
				<PlanSkuLeft>
					<Text size={FontSizes.base} weight={FontWeights.Demi}>
						{duration}
						{originalPrice && <br />}
					</Text>
					<Text size={FontSizes.sm}>
						{originalPrice !== price && (
							<>
								<s>{originalPrice && formatCurrency(originalPrice)}</s>{' '}
							</>
						)}
						{originalPrice && `${formatCurrency(price)}/${formatMessage(messages.yearlyAbbreviation)}`}
					</Text>
				</PlanSkuLeft>
				<PlanSkuRight>
					{formatCurrency(pricePerMonth)}/{formatMessage(messages.monthlyAbbreviation)}
				</PlanSkuRight>
			</PlanSkuInterior>
			{getCouponByPlan(id) ? (
				<TrialBadge>
					<Text size={FontSizes.sm} color="white">
						{formatMessage(messages.couponLabel, {
							amount: getCouponByPlan(id)?.percent_off,
						})}
					</Text>
				</TrialBadge>
			) : (
				id === 'yearly' &&
				userCanTrial && (
					<TrialBadge>
						<Text size={FontSizes.sm} color="white">
							{trialLength
								? formatMessage(messages.customTrialLabel, {
										amount: trialLength,
								  })
								: formatMessage(messages.trialLabel)}
						</Text>
					</TrialBadge>
				)
			)}
		</Wrapper>
	);
};

export default PlanSkuItem;
