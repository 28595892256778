import { calmLogger } from './calmLogger';

export async function copyText(text: string): Promise<boolean> {
	try {
		if (navigator.clipboard?.writeText) {
			await navigator.clipboard.writeText(text);
			return true;
		}
		// make a textarea out of viewport
		const textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.position = 'fixed';
		textArea.style.left = '-999999px';
		textArea.style.top = '-999999px';
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		await new Promise<void>((resolve, reject) => {
			const copied = document.execCommand('copy');
			textArea.remove();
			if (copied) {
				resolve();
			} else {
				reject();
			}
		});
		return true;
	} catch (err) {
		calmLogger.error('Unable to copy text to user clipboard', {}, err);
		return false;
	}
}
