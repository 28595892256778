import { useIntl } from 'react-intl';

import { Text, FontSizes, FontWeights, Button } from '@calm-web/design-system';

import messages from './messages';
import { OfferHasEndedWrapper } from './styles';

const OfferHasEnded = () => {
	const { formatMessage } = useIntl();
	return (
		<OfferHasEndedWrapper>
			<Text align="center" el="h1" size={FontSizes.lg} weight={FontWeights.Regular}>
				{formatMessage(messages.offerHasEnded, {
					br: (
						<>
							<br />
							<br />
						</>
					),
				})}
			</Text>
			<br />
			<Button fullWidth backgroundColor="accessibleBrandGradient" href="/blog-discount-offer">
				{formatMessage(messages.getCalm)}
			</Button>
		</OfferHasEndedWrapper>
	);
};

export default OfferHasEnded;
