import { ReactNode } from 'react';

import { PaletteColor } from '@calm-web/design-system';

import { Wrapper } from './styles';

const LimitedTimeCallout = ({
	children,
	className,
	color = 'blue3',
}: {
	children?: ReactNode;
	color?: PaletteColor;
	className?: string;
}) => {
	return (
		<Wrapper className={className} $color={color}>
			{children}
		</Wrapper>
	);
};

export default LimitedTimeCallout;
