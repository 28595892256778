import { defineMessages } from 'react-intl';

export default defineMessages({
	acceptAll: {
		id: 'shared.gdprLightboxV2.acceptAll',
		defaultMessage: 'Accept All',
		description: 'Text for the accept all cookies button',
	},
	cookiePolicy: {
		id: 'shared.gdprLightboxV2.cookiePolicy',
		defaultMessage: 'Cookie Policy',
		description: 'A link to the cookie policy',
	},
	privacyPolicy: {
		id: 'shared.gdprLightboxV2.privacyPolicy',
		defaultMessage: 'Privacy Policy',
		description: 'A link to the privacy policy',
	},
	body: {
		id: 'shared.gdprLightboxV2.cookiesCta',
		defaultMessage:
			'Calm uses cookies to understand the way you use our website and help us to improve it, as well as to personalise content and target ads, including by working with third party analytics partners. By clicking ‘Accept All’ below, you consent to our use of cookies. You can withdraw your consent or learn more information on our {cookiePolicy}.',
		description: 'A description of why the website uses cookies',
	},
	bodyNonGDPR: {
		id: 'shared.gdprLightboxV2.bodyNonGDPR',
		defaultMessage:
			'Calm and its third-party advertising and analytics partners use cookies to provide a better experience for you. By using our site, you agree to the use of cookies as described in our {privacyPolicy}.',
		description: 'A description of why the website uses cookies for non-GDPR users',
	},
	essentialOnly: {
		id: 'shared.gdprLightboxV2.essentialOnly',
		defaultMessage: 'Essential Cookies Only',
		description: 'Text for the essential cookies only button',
	},
	title: {
		id: 'shared.gdprLightboxV2.title',
		defaultMessage: 'Cookie Preferences',
		description: 'Title for the cookie essential/all banner',
	},
});
