import useSWR from 'swr';

import { useApi } from '@/hooks/api';

interface Fave {
	guide_id?: string;
	program_id?: string;
	faved_at?: Date;
	unfaved_at?: Date;
}

export const useFaves = () => {
	const apiRequest = useApi();

	const { data, error, mutate } = useSWR<Fave[]>(
		'faves',
		async (endpoint: string) => {
			const res = await apiRequest<{ faves: Fave[] }>({
				endpoint,
				method: 'GET',
				noCache: true,
			});
			return res.data.faves;
		},
		{ revalidateOnFocus: false },
	);

	return {
		isFetching: !data && !error,
		faves: data,
		error,
		mutate,
	};
};
