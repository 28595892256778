import { AxiosPromise } from 'axios';

import apiRequest from '@/utils/apiRequest';

export function getMe(): AxiosPromise {
	return apiRequest({
		endpoint: 'v2/me',
		method: 'GET',
		noCache: true,
	});
}
