import { useEffect, useRef, useState } from 'react';

export function useDelayedToggle(timeout = 250): [boolean, () => Promise<boolean>] {
	const timer = useRef<NodeJS.Timeout | number | undefined>(undefined);
	const [isUnmounted, setIsUnmounted] = useState(false);

	async function triggerUnmount(): Promise<boolean> {
		setIsUnmounted(true);
		await new Promise(resolve => {
			timer.current = setTimeout(resolve, timeout);
		});
		setIsUnmounted(false);
		return true;
	}

	useEffect(() => {
		return clearTimeout(timer.current as NodeJS.Timeout);
	}, []);

	return [isUnmounted, triggerUnmount];
}
