import { Children } from 'react';

import { Grid, Wrapper } from './styles';
import { GridWrapperProps } from './types';

const GridWrapper = ({
	el = 'div',
	gridEl = 'div',
	id,
	columns,
	className,
	children,
	allowOverflow = false,
	alignLeft = false,
}: GridWrapperProps) => {
	const numColumns = columns || Children.count(children);
	const gridTemplateColumns = `repeat(${numColumns}, minmax(0, 1fr))`;

	return (
		<Wrapper alignLeft={alignLeft} allowOverflow={allowOverflow} as={el} id={id}>
			<Grid className={className} columns={gridTemplateColumns} as={gridEl}>
				{children}
			</Grid>
		</Wrapper>
	);
};

export default GridWrapper;
