import { defineMessages } from 'react-intl';

const messages = defineMessages({
	skipToMainCta: {
		id: 'player.share.skipToMainContent',
		defaultMessage: 'Skip to main content',
		description:
			'Provides a link at the top of the page that, when activated, jumps the user to the beginning of the main content area',
	},
});

export default messages;
