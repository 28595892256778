import { useEffect, useRef, useState } from 'react';

export function useAsyncDelay(timeout = 200): [boolean, () => Promise<void>, () => void] {
	const timer = useRef<NodeJS.Timeout | number | undefined>(undefined);
	const promiseResFunc = useRef<undefined | ((value: unknown) => void)>(undefined);
	const [isAsyncDelayTriggered, setIsAsyncDelayTriggered] = useState(false);

	function clearCurrentTimeoutAndResolveCurrentPromise() {
		const currentTimer = timer.current;
		const currentPromiseResolveFunc = promiseResFunc.current;

		clearTimeout(currentTimer as NodeJS.Timeout);
		if (currentPromiseResolveFunc) {
			currentPromiseResolveFunc(null);
		}

		timer.current = undefined;
		promiseResFunc.current = undefined;
	}

	// This function is to be awaited by the caller to pause execution of a function
	// for timeout duration (ex. awaiting for an animation of timeout duration to occur
	// before dismounting).
	async function triggerAsyncDelay(): Promise<void> {
		clearCurrentTimeoutAndResolveCurrentPromise(); // Guard against back-to-back non-awaited calls (current state cleanup).
		setIsAsyncDelayTriggered(true);
		await new Promise(resolve => {
			promiseResFunc.current = resolve;
			timer.current = setTimeout(resolve, timeout);
		});
	}

	// Required to reset the state of the hook if triggerAsyncDelay
	// is to be called again from within the component.
	function resetAsyncDelay() {
		clearCurrentTimeoutAndResolveCurrentPromise();
		setIsAsyncDelayTriggered(false);
	}

	useEffect(() => {
		return resetAsyncDelay;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [isAsyncDelayTriggered, triggerAsyncDelay, resetAsyncDelay];
}
