import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FULLSCREEN_LOADER_INDEX } from '@/utils/ui/zIndices';

export const FullScreenLoaderWrapper = styled('div')`
	position: fixed;
	inset: 0;
	z-index: ${FULLSCREEN_LOADER_INDEX};
	background-color: ${palette('blackAlpha80')};
`;
