import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { Checkbox, CalmTheme, Colors } from '@calm-web/design-system';

import messages from './messages';
import { Wrapper } from './styles';

export const ZEN_MODE_KEY = 'isZenModeEnabled';

const ZenModeSelector = () => {
	const { formatMessage } = useIntl();
	const [isChecked, setIsChecked] = useState<boolean | null>(null);

	const onChange = () => {
		const nextIsChecked = !isChecked;
		localStorage.setItem(ZEN_MODE_KEY, nextIsChecked.toString());
		setIsChecked(nextIsChecked);
	};

	useEffect(() => {
		setIsChecked(localStorage.getItem(ZEN_MODE_KEY) === 'true');
	}, []);

	return (
		<Wrapper>
			{isChecked !== null && (
				<ThemeProvider
					theme={{
						palette: {
							...CalmTheme.palette,
							checkboxSelectedBg: Colors.blue2,
							checkboxSelectedBorder: Colors.blue2,
							focusRingDark: Colors.white,
						},
					}}
				>
					<Checkbox name="zen-mode-toggle" value="zen-mode-toggle" onChange={onChange} checked={isChecked}>
						{formatMessage(messages.zenModeSelector)}
					</Checkbox>
				</ThemeProvider>
			)}
		</Wrapper>
	);
};

export default ZenModeSelector;
