import { MessageDescriptor } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useQRCodeHandoffContext } from '@/components/QRCodeHandoff/QRCodeHandoffContext';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import ControlFlow from '@/components/SimplifiedSignup/configs/ControlModal/Flow';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState } from '@/hooks/store';
import useQueryParams from '@/hooks/utils/useQueryParams';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setAuthFormMode } from '@/store/authFormMode/actions';

import { useUpsellUserState } from '../useUpsellBannerContent/useUpsellUserState';
import messages from './messages';

type ButtonConfig = {
	copy: MessageDescriptor;
	onPress?: () => void | Promise<void>;
};

export default function useHeaderButtons() {
	const userUpsellState = useUpsellUserState();
	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const { setFlowConfig } = useSimplifiedSignupContext();
	const { setIsHandoffOpen, isHandoffOpen } = useQRCodeHandoffContext();
	const dispatch = useDispatch();
	const device = useDeviceState();
	const routerPush = useRouterPush();
	const { logEvent } = useAnalytics();
	const query = useQueryParams();
	const isOrganicTraffic = query?.utm_medium !== 'paid';

	const onAuthClick = (mode: 'login' | 'signup' = 'signup') => {
		setFlowConfig(ControlFlow);
		if (mode) {
			dispatch(setAuthFormMode(mode));
		}
		setIsModalOpen(true);
		logEvent({ eventName: 'Header : Auth : Click', eventProps: { mode } });
		if (!isOrganicTraffic) {
			logEvent({
				eventName: 'Landing Page : Signup Flow CTA : Clicked',
				eventProps: {
					location: 'Header CTA',
				},
			});
		}
	};

	async function goToMobileAppAction() {
		logEvent({ eventName: 'Header : Go To App : Click' });
		if (device.isMobile) {
			await routerPush('https://calm.onelink.me/Pf5F/oislygnz');
		} else {
			setIsHandoffOpen(!isHandoffOpen);
		}
	}

	const getButtonConfig = (): { firstButton?: ButtonConfig; secondButton: ButtonConfig } => {
		switch (userUpsellState) {
			case 'loggedOut':
				return {
					firstButton: {
						copy: messages.firstButtonLoggedOut,
						onPress: () => onAuthClick('login'),
					},
					secondButton: {
						copy: messages.secondButtonLoggedOut,
						onPress: () => onAuthClick('signup'),
					},
				};
			case 'neverTrialed':
				return {
					firstButton: {
						copy: messages.firstButtonNeverTrialed,
						onPress: async () => {
							await goToMobileAppAction();
						},
					},
					secondButton: {
						copy: messages.secondButtonNeverTrialed,
						onPress: () => onAuthClick('signup'),
					},
				};
			case 'churnedAndTrialed':
				return {
					firstButton: {
						copy: messages.firstButtonChurnedAndTrialed,
						onPress: () => goToMobileAppAction(),
					},
					secondButton: {
						copy: messages.secondButtonChurnedAndTrialed,
						onPress: () => onAuthClick('signup'),
					},
				};
			case 'activeCalmPremiumUser':
				return {
					secondButton: {
						copy: messages.secondButtonPremiumUser,
						onPress: () => goToMobileAppAction(),
					},
				};
			default:
				return {
					firstButton: {
						copy: messages.firstButtonLoggedOut,
						onPress: () => onAuthClick('signup'),
					},
					secondButton: {
						copy: messages.secondButtonLoggedOut,
						onPress: () => onAuthClick('signup'),
					},
				};
		}
	};

	return {
		buttonConfig: getButtonConfig(),
	};
}
