import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useFaves } from './useFaves';

const useIsFaved = (guideId: string | undefined): [boolean, Dispatch<SetStateAction<boolean>>] => {
	const [isFaved, setIsFaved] = useState(false);

	const { faves } = useFaves();

	useEffect(() => {
		if (!guideId) return;

		const isGuideFaved = faves
			? faves.filter(fave => fave.guide_id === guideId).some(fave => 'faved_at' in fave)
			: false;

		setIsFaved(isGuideFaved);
	}, [faves, guideId]);

	return [isFaved, setIsFaved];
};

export default useIsFaved;
