import { defineMessages } from 'react-intl';

export default defineMessages({
	cardName: {
		id: 'redesign.purchase.creditCardForm.cardName',
		defaultMessage: 'Name on Credit Card',
		description: 'CC Name Prompt',
	},
	email: {
		id: 'generics.email',
		defaultMessage: 'Email',
		description: 'CC Email Prompt',
	},
	yourEmail: {
		id: 'generics.yourEmail',
		defaultMessage: 'Your Email',
		description: 'CC Your Email',
	},
	emptyEmailError: {
		id: 'generics.emptyEmail',
		defaultMessage: 'Email is required!',
		description: 'CC Email Required',
	},
	firstName: {
		id: 'redesign.purchase.creditCardForm.nameOnCard',
		defaultMessage: 'Name on Card',
		description: 'CC Name on Card',
	},
	firstNameError: {
		id: 'generics.nameOnCardError',
		defaultMessage: 'Name on Card is required!',
		description: 'CC Name Required',
	},
	emailError: {
		id: 'giftCertificate.purchaseForm.emailError',
		defaultMessage: 'Please enter a valid email address.',
		description: 'CC Email Error',
	},
	expiryPlaceholder: {
		id: 'redesign.purchase.creditCardForm.expiryPlaceholder',
		defaultMessage: 'Exp (MM / YY)',
		description: 'CC Expiry Prompt',
	},
	cvcPlaceholder: {
		id: 'redesign.purchase.creditCardForm.cvcPlaceholder',
		defaultMessage: 'CVC',
		description: 'CC CVC Prompt',
	},
	postalCode: {
		id: 'redesign.purchase.creditCardForm.postalCode',
		defaultMessage: 'Billing Zip Code',
		description: 'CC Zip Prompt',
	},
	failedCaptcha: {
		id: 'redesign.purhcase.creditCardForm.failedCaptcha',
		defaultMessage: 'Oops! Something went wrong. Please try again.',
		description: 'CC Captcha Failed',
	},
	startFreeTrial: {
		id: 'redesign.purchase.creditCardForm.button.startFreeTrial',
		defaultMessage: 'Start Free Trial',
		description: 'Default credit card form button text',
	},
	useCard: {
		id: 'redesign.purchase.creditCardForm.button.useCard',
		defaultMessage: 'Get Calm Premium',
		description: 'Button text for already subbed',
	},
	success: {
		id: 'redesign.purchase.creditCardForm.button.success',
		defaultMessage: 'Success',
		description: 'Button text for successful purchase',
	},
	samsungSubscribeLink: {
		id: 'redesign.purchase.creditCardForm.samsungErrorLink',
		defaultMessage: 'Redeem Regular Subscription',
		description: 'A link to go to a subscribe page without a promotional price',
	},
	promotionTrialDurationSevenDays: {
		id: 'promotionOptions.promotionTrialDurationSevenDays',
		defaultMessage: 'Seven Day',
		description: 'Description of a trial length of seven days',
	},
	promotionTrialDurationFourteenDays: {
		id: 'promotionOptions.promotionTrialDurationFourteenDays',
		defaultMessage: 'Fourteen Day',
		description: 'Description of a trial length of fourteen days',
	},
	promotionTrialDurationOneMonth: {
		id: 'promotionOptions.promotionTrialDurationOneMonth',
		defaultMessage: 'One Month',
		description: 'Description of a trial length of one month',
	},
	promotionTrialDurationTwoMonths: {
		id: 'promotionOptions.promotionTrialDurationTwoMonths',
		defaultMessage: 'Two Month',
		description: 'Description of a trial length of two months',
	},
	promotionTrialDurationThreeMonths: {
		id: 'promotionOptions.promotionTrialDurationThreeMonths',
		defaultMessage: 'Three Month',
		description: 'Description of a trial length of three months',
	},
	promotionTrialDurationSixMonths: {
		id: 'promotionOptions.promotionTrialDurationSixMonths',
		defaultMessage: 'Six Month',
		description: 'Description of a trial length of six months',
	},
	paymentMethodHeader: {
		id: 'purchase.paymentTypeSelector',
		defaultMessage: '2. Choose your payment method',
		description: 'Instructions to the user to select payment method',
	},
	freeTrialTerms: {
		id: 'purchaseTerms.freeTrialTerms',
		defaultMessage:
			'By clicking "Start Your Trial", you agree to be automatically billed for Calm Premium at {price} after your trial ends and every year until cancelled. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is starting free trial',
	},
	freeTrialAndOfferTerms: {
		id: 'purchaseTerms.freeTrialAndOfferTerms2',
		defaultMessage:
			'By clicking “Start Free Trial”, you agree to be automatically billed for your first year of Calm Premium at the offer rate of {price} when your trial period ends, which will then automatically renew at {originalPrice} every year until canceled. Offer available to Spotify users who have not tried Calm Premium before. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is starting free trial with an offer',
	},
	countrySpecificOfferSpotify: {
		id: 'purchaseTerms.spotify_offer',
		defaultMessage:
			'50% off annual subscription available to residents of United States, Canada, United Kingdom, Republic of Ireland, Australia, and New Zealand only.',
		description: 'Terms for if user is starting free trial with an offer',
	},
	noTrialTerms: {
		id: 'purchaseTerms.noFreeTrialTerms',
		defaultMessage:
			'By clicking "Get Calm Premium", you agree to be automatically billed for Calm Premium at {price} {cadence} until cancelled. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is not eligible for trial',
	},
	noTrialTermsEligibility: {
		id: 'purchaseTerms.noTrialTermsEligibility',
		defaultMessage:
			'By clicking "Get Calm Premium", you agree to be automatically billed for Calm Premium at {price} {cadence}  as long as you verify your eligibility. If you are no longer eligible at time of renewal, you will automatically renew at {originalPrice}/year and every year until cancelled. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is not eligible for trial',
	},
	yearly: {
		id: 'generics.yearly',
		defaultMessage: 'yearly',
		description: 'A duration for a (typically paid) subscription to Calm',
	},
	monthly: {
		id: 'generics.monthly',
		defaultMessage: 'monthly',
		description: 'A duration for a (typically paid) subscription to Calm',
	},
	quarterly: {
		id: 'generics.quarterly',
		defaultMessage: 'quarterly',
		description: 'A duration for a (typically paid) subscription to Calm',
	},
	lifetime: {
		id: 'profile.manageSubscription.lifetime',
		defaultMessage: 'lifetime',
		description: 'A duration for a (typically paid) subscription to Calm. Lifetime means it never expires',
	},
	confirmPlanNoToggle: {
		id: 'purchase.sku.notoggle.header',
		defaultMessage: "Here's Your Plan.",
		description: 'Showing the user the details of their plan',
	},
	confirmSubtitle: {
		id: 'purchase.sku.toggle.subheader',
		defaultMessage: "Don't worry, you can cancel at any time.",
		description: 'Instructions informing the user they can cancel their sub',
	},
});
