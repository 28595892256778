import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

import {
	Button as DesignSystemButton,
	minWidth,
	fontWeight,
	FontWeights,
	PrimaryButton,
} from '@calm-web/design-system';

import ProfileSvg from 'icons/profile-outline.svg';

import { NavButtonProps } from './types';

export const Wrapper = styled.nav`
	display: none;
	justify-content: space-around;
	justify-self: end;
	flex: 1;

	${minWidth('tablet')} {
		display: flex;
	}
`;

export const LoggedOutNav = styled.ul`
	align-items: center;
	display: flex;
	margin-left: auto;
	padding: 0;
`;

export const NavItem = styled.a`
	align-items: center;
	display: flex;
	margin: 0 14px;
	text-decoration: none;

	${minWidth('desktop')} {
		margin: 0 24px;
	}
`;

export const LoggedInNav = styled.ul<{ $hideProfile?: boolean }>`
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0;
	li:first-child {
		flex-grow: 1;
		& > * {
			float: right;
		}
	}
	${p => {
		const nthFromLast = p.$hideProfile ? 1 : 2;
		return css`
			li:nth-last-child(${nthFromLast}) {
				flex-grow: 1;
			}
		`;
	}}
`;

export const ProfileWrapper = styled.a`
	display: flex;
	width: 44px;
	height: 44px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 22px;
	margin-left: 8px;
	padding: 8px;
`;

export const ProfileIcon = styled(ProfileSvg)`
	width: 30px;
	height: 30px;
	fill: inherit;

	& path {
		fill: inherit;
		stroke: inherit;
	}
`;

export const NavButton = styled(DesignSystemButton)<NavButtonProps>`
	display: none;
	${fontWeight(FontWeights.Medium)};
	margin: 0 8px;
	transition: ${theme('animation.transition')};

	${minWidth('tablet')} {
		display: flex;
	}
`;

export const FreeTrialNavButton = styled(DesignSystemButton)<NavButtonProps>`
	border: ${({ isFreeCta, isDark }): string =>
		isFreeCta ? `2px ${isDark ? '#fff' : '#000'} solid` : '2px transparent solid'};
	&:focus {
		border: 2px transparent solid;
	}
	font-weight: 600;
	margin-left: 16px;
	transition: ${theme('animation.transition')};
`;

export const FreeTrialNavPrimaryButton = styled(PrimaryButton)<NavButtonProps>`
	&:focus {
		border: 2px transparent solid;
	}
	transition: ${theme('animation.transition')};
`;
