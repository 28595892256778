import { JSONValue } from '@/utils/types';

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';

export const FEATURE_FLAGS = {
	GDPR_OPT_OUT_BY_DEFAULT_FLAG: 'gdpr-opt-out-by-default',
	B2B_REQUEST_ACCESS_FLOW: 'b2b-request-access-flow',
	B2B_TERMS_AND_CONDITIONS: 'b2b-terms-and-conditions',
	ZOOM_APP: 'zoom-app',
	AMAZON_PAYPAL_SUPPORT: 'amazon-paypal-support',
	AMAZON_APPLEPAY_SUPPORT: 'amazon-applepay-support',
	DTC_MONTHLY_PRICING: 'dtc-monthly-pricing',
};

export type FeatureFlags = Record<string, JSONValue>;

interface SetFeatureFlagsAction {
	type: typeof SET_FEATURE_FLAGS;
	payload: FeatureFlags;
}

export type FeatureFlagsActionType = SetFeatureFlagsAction;
