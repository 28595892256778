import { ReactNode } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import { useLocale } from '@/hooks/locale/useLocale';
import deMessages from '@/lang/de.json';
import enMessages from '@/lang/en.json';
import esMessages from '@/lang/es.json';
import frMessages from '@/lang/fr.json';
import jaMessages from '@/lang/ja.json';
import koMessages from '@/lang/ko.json';
import ptMessages from '@/lang/pt.json';

const cache = createIntlCache();

export const localeMessages: Record<string, Record<string, string>> = {
	de: deMessages,
	en: enMessages,
	es: esMessages,
	fr: frMessages,
	ja: jaMessages,
	ko: koMessages,
	pt: ptMessages,
};

const IntlWrapper = ({ children }: { children?: ReactNode }) => {
	const { calmLanguage } = useLocale();

	const messages =
		process.env.NODE_ENV === 'development' ? {} : localeMessages[calmLanguage] || localeMessages.en;

	const intl = createIntl(
		{
			locale: calmLanguage,
			messages,
		},
		cache,
	);

	return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default IntlWrapper;
