import { defineMessages } from 'react-intl';

export default defineMessages({
	nameInvalid: {
		id: 'login.errors.nameInvalid',
		defaultMessage: 'Please input your name',
		description: 'An error that shows if a user tries to sign up with an invalid name.',
	},
	emailTaken: {
		id: 'login.errors.emailTaken',
		defaultMessage: 'That email is already registered. Try logging in with it.',
		description: 'An error that shows if a user tries to sign up with an email that is already taken.',
	},
	emailInvalid: {
		id: 'login.errors.emailInvalid',
		defaultMessage: 'That email address doesn’t look right',
		description: 'An error that shows on the login form when a user enters a malformed email address.',
	},
	passwordInvalid: {
		id: 'login.errors.passwordInvalid',
		defaultMessage: 'Your password must be at least 6 characters',
		description: "An error that shows on the login form when a user's password is too short.",
	},
	badCredentials: {
		id: 'login.errors.badCredentials',
		defaultMessage: 'That email and password don’t match',
		description: 'An error that shows on the login form when login credentials are invalid.',
	},
	noUser: {
		id: 'login.errors.noUser',
		defaultMessage: 'We couldn’t find a user with that email',
		description: 'An error that shows on the login form when the email entered is not found in our system.',
	},
	facebookError: {
		id: 'login.errors.facebook',
		defaultMessage: 'There was an error while logging in with Facebook',
		description: 'A login error when Facebook login is not working properly.',
	},
	appleError: {
		id: 'login.errors.apple',
		defaultMessage: 'There was an error while logging in with Apple',
		description: 'A login error when Apple login is not working properly.',
	},
	noEmail: {
		id: 'login.errors.noEmail',
		defaultMessage: 'Please provide a valid email first',
		description:
			"An error that shows on the login form when a user clicks the 'forgot password?' button but didn't enter an email yet.",
	},
	passwordTooCommon: {
		id: 'login.errors.passwordTooCommon',
		defaultMessage: 'Password is too common',
		description: 'An error message shown when a user signup password is a commonly used word/phrase',
	},
	passwordShortError: {
		id: 'login.errors.passwordTooShort',
		defaultMessage: 'Password too short - must be at least 8 characters',
		description: 'An error shown when the password is too short',
	},
	generic: {
		id: 'generic.defaultError',
		defaultMessage: 'An error has occured',
		description: "A catch-all error message to show when we don't know what happened",
	},
	timeout: {
		id: 'generic.timeout',
		defaultMessage: 'The request took too long. Please try again when you have faster internet',
		description: 'A message shown to the user when a request times out and cannot be completed',
	},
});
