export const baseRequest = {
	apiVersion: 2,
	apiVersionMinor: 0,
};

export const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
	type: 'CARD',
	parameters: {
		allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
		allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
	},
};

export const cardPaymentMethod: google.payments.api.PaymentMethodSpecification = {
	...baseCardPaymentMethod,
	tokenizationSpecification: {
		type: 'PAYMENT_GATEWAY',
		parameters: {
			gateway: 'stripe',
			'stripe:version': '2018-10-31',
			'stripe:publishableKey': process.env.NEXT_PUBLIC_STRIPE_TOKEN as string,
		},
	},
};
