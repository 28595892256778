import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Theme } from './types';

const initialState: Theme = {
	header: 'dark',
	footer: 'dark',
	webAppLayout: null,
	isZoomApp: false,
};

const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme(state, action: PayloadAction<Partial<Theme>>) {
			(Object.keys(action.payload) as (keyof Theme)[]).forEach(<K extends keyof Theme>(key: K) => {
				state[key] = action.payload[key];
			});
		},
	},
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { setTheme } = themeSlice.actions;

// Export the slice reducer as the default export
export default themeSlice.reducer;
