export type Locale = 'en' | 'fr' | 'de' | 'es' | 'ja' | 'ko' | 'pt';
export const locales = ['en', 'fr', 'de', 'es', 'ja', 'ko', 'pt'];

export const SET_LOCALE = 'SET_LOCALE';

interface SetLocaleAction {
	type: typeof SET_LOCALE;
	payload: Locale;
}

export type LocaleActionTypes = SetLocaleAction;
