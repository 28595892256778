import { calmLogger } from './calmLogger';

// Only grab the last reference to the query param
function getParamAsString(queryParam: string | string[]): string;
function getParamAsString(queryParam?: undefined): null;
function getParamAsString(queryParam?: string | string[]): string | null;
function getParamAsString(queryParam?: string | string[]): string | null {
	if (typeof queryParam === 'undefined') {
		return null;
	}
	const singleQueryParam = Array.isArray(queryParam) ? queryParam[queryParam.length - 1] : queryParam;
	try {
		return decodeURIComponent(singleQueryParam);
	} catch (err) {
		calmLogger.error('Failed to decode URI component', { singleQueryParam }, err);
		return null;
	}
}

export default getParamAsString;
