export interface PxScript {
	appId: string;
	jsClientSrc: string;
	firstPartyEnabled: boolean;
	vid: string;
	uuid: string;
	hostUrl: string;
	blockScript: string;
}

// for px testing, turn this env var to true
const isPxBlockingTest =
	process.env.NEXT_PUBLIC_IS_PX_BLOCKING_TEST === '1' ||
	process.env.NEXT_PUBLIC_IS_PX_BLOCKING_TEST === 'true';

const apiUrl = process.env.NEXT_PUBLIC_DIRECT_API_ENDPOINT;

// setting te following headers in an api request to api will result in px blocking response
// this is useful for development and testing
export const pxTestHeaders = isPxBlockingTest
	? {
			'x-px-block': '1',
			'User-Agent': 'PhantomJS',
	  }
	: undefined;

/*
Example payload from PX
{
  "appId": "PXxJvF5499",
  "jsClientSrc": "/xJvF5499/init.js",
  "firstPartyEnabled": true,
  "uuid": "eec74640-ac11-11ea-9d38-21a314267527",
  "hostUrl": "/xJvF5499/xhr",
  "blockScript": "/xJvF5499/captcha/captcha.js?a=c&u=eec74640-ac11-11ea-9d38-21a314267527&v=&m=0"
}
 */
export function isPxResponse({
	data,
	status,
}: {
	status: number;
	data: { blockScript: string; appId: string };
}): boolean {
	return status === 403 && !!data?.blockScript && data?.appId?.substring(0, 2) === 'PX';
}

// The PX blocking response is missing the hostname for jsClientSrc, hostUrl and blockScript.
// Append apiUrl for now since that's hte only place where we are doing the blocking
// but we need to change this to use other hostname if we are blocking from other hosts
export function injectHostDomainToPxResponse(body: PxScript): PxScript | null {
	if (!body) {
		return null;
	}

	return {
		...body,
		jsClientSrc: `${apiUrl}${body?.jsClientSrc}`,
		hostUrl: `${apiUrl}${body?.hostUrl}`,
		blockScript: `${apiUrl}${body?.blockScript}`,
	};
}
