import { defineMessages } from 'react-intl';

const messages = defineMessages({
	totalDueToday: {
		id: 'discountSignup.purchaseForm.totalDue',
		defaultMessage: 'Total due today',
		description: 'Price label indicating the charge today',
	},
	totalDueAfterTrial: {
		id: 'discountSignup.purchaseForm.totalDueAfterTrial',
		defaultMessage: 'Total due after trial',
		description: 'Price label indicating the charge after trial',
	},
	costPerMonth: {
		id: 'discountSignup.purchaseForm.costPerMonth',
		defaultMessage: 'Cost per month',
		description: 'Price label indicating what the price is per month',
	},
	monthlyPriceAfterTrial: {
		id: 'discountSignup.purchaseForm.monthlyPriceAfterTrial',
		defaultMessage: '*billed monthly after trial',
		description:
			'Price label indicating that the price is charged monthly, and it is charged after a trial period',
	},
	monthlyPriceUntilCancelled: {
		id: 'discountSignup.purchaseForm.monthlyPriceUntilCancelled',
		defaultMessage: '*billed monthly until cancelled',
		description: 'Indicates the user is charged for a monthly subscription',
	},
	annualPrice: {
		id: 'discountSignup.purchaseForm.annualPriceNoTrial',
		defaultMessage: '*{value} billed annually',
		description: 'Price label indicating that the price is only charged annually',
	},
	annualPriceWithDiscount: {
		id: 'discountSignup.purchaseForm.annualPriceWithDiscount',
		defaultMessage: '*{discountValue} for one year, renewing at {regularValue} billed annually',
		description:
			'Price label indicating that the price is only charged annually, and it is charged after a trial period',
	},
	annualPriceAfterTrial: {
		id: 'discountSignup.purchaseForm.annualPriceNew',
		defaultMessage: '{value} automatically billed after trial. Cancel anytime.',
		description:
			'Price label indicating that the price is only charged annually, and it is charged after a trial period',
	},
	annualPriceWithDiscountAfterTrial: {
		id: 'discountSignup.purchaseForm.annualPriceWithDiscountAfterTrial',
		defaultMessage: '*{discountValue} billed after trial, renewing at {regularValue} billed annually',
		description:
			'Price label indicating that the price is only charged annually, and it is charged after a trial period',
	},
	subtotal: {
		id: 'discountSignup.purchaseForm.preDiscountPrice',
		defaultMessage: 'Subtotal',
		description: "Price label indicating what the price was before the user's discount was applied",
	},
	subtotalPerMonth: {
		id: 'discountSignup.purchaseForm.preDiscountPricePerMonth',
		defaultMessage: 'Original cost per month',
		description:
			"Price label indicating what the price was (on a monthly basis) before the user's discount was applied",
	},
	discount: {
		id: 'discountSignup.purchaseForm.discount',
		defaultMessage: 'Discount',
		description: 'Price label indicating discount the user is receiving',
	},
	lifetimeCost: {
		id: 'discountSignup.purchaseForm.lifetimeCost',
		defaultMessage: 'Cost',
		description: 'Price label indicating what the price is for the lifetime subscription',
	},
	lifetimePriceAfterTrial: {
		id: 'discountSignup.purchaseForm.lifetimePriceAfterTrial',
		defaultMessage: '*billed once after trial',
		description:
			'Price label indicating that the price is only charged once ever, and it is charged after a trial period',
	},
	costPerYear: {
		id: 'discountSignup.purchaseForm.costPerYear',
		defaultMessage: 'Cost per year',
		description: 'The cost of a Calm subscription per year',
	},
	trialEndTerms: {
		id: 'discountSignup.purchaseForm.trialEndTerms2',
		defaultMessage:
			'After your offer ends, you will be automatically billed for Calm Premium at {cost} + applicable tax.',
		description: 'The cost of a Calm subscription per year',
	},
	trialDuration: {
		id: 'paymentForm.trial.duration',
		defaultMessage: '{duration}-{unit} Trial',
		description: 'A purchase title for a Calm free trial that is X units',
	},
	trialDays: {
		id: 'paymentDetails.modal.trialDays',
		defaultMessage: '{number}-day',
		description: 'the number of days for a trial, usually `7-day` trial',
	},
	trialMonths: {
		id: 'paymentDetails.modal.trialMonths',
		defaultMessage: '{number}-month',
		description: 'the number of months for a trial, something like `3-month` trial',
	},
	trial: {
		id: 'paymentForm.trial',
		defaultMessage: 'Trial',
		description: 'The word "trial"',
	},
	yearlyAbbreviation: {
		id: 'paymentForm.year.abbreviation',
		defaultMessage: 'yr.',
		description: 'An abbreviation of the word year',
	},
	yearlyTitle: {
		id: 'paymentForm.year.title',
		defaultMessage: 'Yearly Plan',
		description: 'A title to inform the user they are getting a yearly plan',
	},
	every3mo: {
		id: 'paymentForm.quarter.every3mo',
		defaultMessage: 'every 3 mo.',
		description: 'An abbreviation of the word quarter',
	},
	quarterlyTitle: {
		id: 'paymentForm.quarter.title',
		defaultMessage: 'Quarterly Plan',
		description: 'A title to inform the user they are getting a quarterly plan',
	},
	monthlyAbbreviation: {
		id: 'paymentForm.month.abbreviation',
		defaultMessage: 'mo.',
		description: 'An abbreviation of the word month',
	},
	monthlyTitle: {
		id: 'paymentForm.month.title',
		defaultMessage: 'Monthly Plan',
		description: 'A title to inform the user they are getting a monthly plan',
	},
	monthlyBreakdown: {
		id: 'paymentForm.month.breakdown.title',
		defaultMessage: 'Cost per Month',
		description: 'A title to inform the user they are getting a monthly plan',
	},
	lifetimeTitle: {
		id: 'paymentForm.lifetime.title',
		defaultMessage: 'Lifetime Plan',
		description: 'A title to inform the user they are getting a lifetime plan',
	},
	aarpPartnerBranding: {
		id: 'paymentForm.discount.AARP',
		defaultMessage: '{partner} Member Discount',
		description: 'A title to inform the user they are getting a monthly plan',
	},
});

export default messages;
