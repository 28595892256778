import { useMountToggle } from '@/hooks/utils';

import { Wrapper } from './styles';
import { AnimatedScreenWrapperProps } from './types';

const AnimatedScreenWrapper = ({
	children,
	isExiting,
	className,
	announce = true,
}: AnimatedScreenWrapperProps) => {
	const isEntering = useMountToggle();

	return (
		<Wrapper
			className={className}
			isEntering={isEntering}
			isExiting={isExiting}
			role={announce ? 'alert' : 'region'}
			aria-live={announce ? 'polite' : 'off'}
		>
			{children}
		</Wrapper>
	);
};

export default AnimatedScreenWrapper;
