import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { Program } from '@/store/program/types';

type ProgramResponse = {
	program: Program;
};

export const useProgram = (programId?: string) => {
	const apiRequest = useApi();

	const { data, error } = useSWR<ProgramResponse>(
		programId ? `content/programs/${programId}` : null,
		async (endpoint: string) => {
			const response = await apiRequest<ProgramResponse>({
				endpoint,
				method: 'GET',
				customHeaders: {
					'x-device-capabilities': 'v1;signedCookie;streamOnly',
				},
			});
			return response.data;
		},
	);

	return {
		isFetching: !data && !error,
		program: data?.program,
		error,
	};
};
