import { useEffect, useMemo, useRef } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import getUrl from '@/utils/getUrl';

import StripeElementsProvider from '../purchase/StripeElementsProvider';
import { useSimplifiedSignupContext } from './Context';
import { HandoffScreenConfig } from './Handoff/types';
import { Header, SimplifiedSignupFlowContainer } from './styles';

const SimplifiedSignup: React.FC = () => {
	const { ActiveScreen, activeScreenConfig } = useSimplifiedSignupContext();
	const { userCanExit = false } = activeScreenConfig as HandoffScreenConfig;
	const containerRef = useRef<HTMLDivElement>(null);
	const routerPush = useRouterPush();
	const { logEvent } = useAnalytics();
	const { isEnrolled } = useAmplitudeExperiment('www-signup-flow-exit-account-screen');

	const headerExitProps = useMemo(() => {
		async function headerOnClose() {
			const eventName = () => {
				if (activeScreenConfig.name === 'account') {
					return 'Login Form : Exited';
				} else if (activeScreenConfig.name === 'payment') {
					return 'Subscribe : Purchase : Form : Exited';
				}
				return `${activeScreenConfig.name} : Exited`;
			};
			logEvent({
				eventName: eventName(),
			});
			await routerPush(`${getUrl('www-app')}`);
		}

		const hideExitIconInAccountScreen = isEnrolled && activeScreenConfig.name === 'account';
		if (userCanExit && !hideExitIconInAccountScreen) {
			return {
				onExit: headerOnClose,
			};
		} else {
			return {};
		}
	}, [userCanExit, routerPush, activeScreenConfig.name, logEvent, isEnrolled]);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollTop = 0;
		}
	}, [ActiveScreen]);

	return (
		<StripeElementsProvider isStripeNext>
			<SimplifiedSignupFlowContainer ref={containerRef} isWithinModal={activeScreenConfig.isWithinModal}>
				{activeScreenConfig?.showHeader && (
					<Header
						{...headerExitProps}
						hideForBusinessCta
						isStatic
						hideProfile
						hideHambugerNav
						hideAuthCTA
						hideFreeTrialCta
					/>
				)}
				{ActiveScreen && <ActiveScreen config={activeScreenConfig} />}
			</SimplifiedSignupFlowContainer>
		</StripeElementsProvider>
	);
};

export default SimplifiedSignup;
