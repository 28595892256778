import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAnalytics } from '@/hooks/analytics';
import { setRecaptchaVisible } from '@/store/recaptchaVisible/actions';
import { injectHostDomainToPxResponse, PxScript } from '@/utils/apiRequest/perimeterx';

/**
 * See https://docs.perimeterx.com/pxconsole/docs/human-challenge#code=-Pcc_qY_ewiICrncCgO4&state=N9gttHuetHPyvbYZQJlM9zSKGYKcYVgbh1binMpE4vnFeR4OVYy8GyP9cBpdskUb
 * for options of how to customize the recaptcha form
 */
declare global {
	interface Window {
		_pxAppId?: string;
		_pxJsClientSrc?: string;
		_pxFirstPartyEnabled?: boolean;
		_pxVid?: string;
		_pxUuid?: string;
		_pxHostUrl?: string;
		_pxOnCaptchaSuccess?: () => void;
	}
}

interface UseRecaptcha {
	(pxData: PxScript): Promise<void>;
}

export const useRecaptcha = (): UseRecaptcha => {
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	const recaptchaFn: UseRecaptcha = useCallback(
		(pxData): Promise<void> => {
			logEvent({ eventName: 'Perimeter X : Recaptcha : Entered' });

			return new Promise(resolve => {
				const recaptchaData = injectHostDomainToPxResponse(pxData);
				if (!recaptchaData) {
					resolve();
				}

				if (recaptchaData?.blockScript) {
					const script = document.createElement('script');
					document.getElementsByTagName('head')[0].appendChild(script);
					script.src = recaptchaData?.blockScript;
					script.addEventListener('load', () => {
						dispatch(setRecaptchaVisible(true));
					});
				}

				window._pxAppId = recaptchaData?.appId;
				window._pxJsClientSrc = recaptchaData?.jsClientSrc;
				window._pxFirstPartyEnabled = recaptchaData?.firstPartyEnabled;
				window._pxVid = recaptchaData?.vid;
				window._pxUuid = recaptchaData?.uuid;
				window._pxHostUrl = recaptchaData?.hostUrl;
				window._pxOnCaptchaSuccess = (): void => {
					logEvent({ eventName: 'Perimeter X : Recaptcha : Success' });
					dispatch(setRecaptchaVisible(false));
					resolve();
				};

				const appConfigId = `_${recaptchaData?.appId}`;
				// TODO: find a better way to type this?
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(window as any)[appConfigId] = {
					challenge: {
						view: {
							width: '100%',
							height: 54,
							fontWeight: 'bold',
							texSize: 16,
							backgroundColor: '#53D477',
							fillColor: '#2C8D47',
							textColor: '#FFFFFF',
							borderColor: '#FFFFFF',
							animation: true,
							textTransform: 'capitalized',
							borderWidth: 0,
						},
					},
				};
			});
		},
		[dispatch, logEvent],
	);
	return recaptchaFn;
};
