import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text, FontSizes, FontWeights } from '@calm-web/design-system';

export const FooterText = styled(Text).attrs({
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	color: rgba(0, 0, 0, 0.6);
	margin: 2rem 0;

	& a {
		color: ${palette('blue3')};
	}
`;
