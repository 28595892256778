import { Button } from '@calm-web/design-system';

import { CtaButtonProps } from './types';

const CtaButton = ({ href, onClick, children, maxWidth, ...props }: CtaButtonProps) => {
	return (
		<Button
			fullWidth
			href={href}
			onPress={onClick}
			backgroundColor="white"
			textColor="gray8"
			maxWidth={maxWidth}
			{...props}
		>
			{children}
		</Button>
	);
};

export default CtaButton;
