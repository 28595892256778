/* eslint-disable @typescript-eslint/no-explicit-any */

import styled from 'styled-components';
import { palette } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.div<{
	gradient: string;
	mobileGradient?: string;
}>`
	background-image: ${(props): any =>
		props.mobileGradient ? palette(props.mobileGradient) : palette(props.gradient)};
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	z-index: -1;

	${minWidth('desktop')} {
		background-image: ${(props): any => (props.gradient ? palette(props.gradient) : '')};
	}
`;
