import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FormInput } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState } from '@/hooks/store';

import CountrySelectInput from '../CountrySelectInput';
import { CustomerAddress } from '../PurchaseForm/types';
import messages from './messages';
import { Wrapper } from './styles';
import { getIsPostalCodeValid, getRequiresPostalCode } from './utils';

const CustomerAddressInputs = ({
	setCustomerAddress,
	analyticsPrefix,
}: {
	setCustomerAddress: Dispatch<SetStateAction<CustomerAddress>>;
	analyticsPrefix: string;
}) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const device = useDeviceState();

	const [postalCode, setPostalCode] = useState('');
	const [countryCode, setCountryCode] = useState(device.ip_country ?? 'US');

	const isPostalCodeValid = getIsPostalCodeValid({
		postalCode,
		country: countryCode,
	});

	const onBlur = (inputName: 'country' | 'postalCode', analyticsName: string) => {
		logEvent({
			eventName: `${analyticsPrefix} : ${analyticsName} : Entered`,
			eventProps: {
				is_valid: inputName === 'country' ?? isPostalCodeValid,
			},
		});
	};

	useEffect(() => {
		setCustomerAddress({
			postalCode,
			country: countryCode,
		});
	}, [countryCode, postalCode, setCustomerAddress]);

	const requiresPostalCode = getRequiresPostalCode(countryCode);

	return (
		<Wrapper $numColumns={requiresPostalCode ? 2 : 1}>
			<CountrySelectInput onBlur={onBlur} setCountryCode={setCountryCode} />
			{requiresPostalCode && (
				<FormInput
					label={formatMessage(messages.postalCodeInput)}
					name="postalCode"
					type="text"
					value={postalCode}
					isValid={isPostalCodeValid}
					onChange={e => setPostalCode(e.currentTarget.value)}
					onBlur={() => onBlur('postalCode', 'Postal Code')}
					error={!isPostalCodeValid ? formatMessage(messages.postalCodeError) : undefined}
				/>
			)}
		</Wrapper>
	);
};

export default CustomerAddressInputs;
