import { useSelector } from 'react-redux';

import { PartnerDetails as Partner } from '@/components/b2b/shared/types';
import { AppLoaded } from '@/store/appLoaded/types';
import { AuthFormMode } from '@/store/authFormMode/types';
import { Campaign } from '@/store/campaign/types';
import { ComplianceLevel } from '@/store/complianceLevel/types';
import { Device } from '@/store/device/types';
import { FeatureFlags } from '@/store/feature-flags/types';
import { Guide } from '@/store/guide/types';
import { AppState } from '@/store/index';
import { Notification } from '@/store/notification/types';
import { Prices } from '@/store/prices/types';
import { Program } from '@/store/program/types';
import { PurchaseParams } from '@/store/purchaseParams/types';
import { RecaptchaVisible } from '@/store/recaptchaVisible/types';
import { ServerSide } from '@/store/serverSide/types';
import { Theme } from '@/store/theme/types';
import { User } from '@/store/user/types';
import { Zoom } from '@/store/zoom/types';

export { useBrowserLanguageState, useLocaleState } from '../locale/useLocale';

export function useAppLoadedState(): AppLoaded {
	return useSelector((state: AppState) => state.appLoaded);
}
export function useAuthFormModeState(): AuthFormMode {
	return useSelector((state: AppState) => state.authFormMode);
}
export function useComplianceLevelState(): ComplianceLevel {
	return useSelector((state: AppState) => state.complianceLevel);
}
export function useDeviceState(): Device {
	return useSelector((state: AppState) => state.device);
}
export function useFeatureFlagsState(): FeatureFlags {
	return useSelector((state: AppState) => state.featureFlags);
}
export function useGuideState(): Guide | null {
	return useSelector((state: AppState) => state.guide);
}
export function useNotificationState(): Notification | null {
	return useSelector((state: AppState) => state.notification);
}
export function usePartnerState(): Partner | null {
	return useSelector((state: AppState) => state.partner);
}
export function usePricesState(): Prices {
	return useSelector((state: AppState) => state.prices);
}
export function useProgramState(): Program | null {
	return useSelector((state: AppState) => state.program);
}
export function usePurchaseParamsState(): PurchaseParams {
	return useSelector((state: AppState) => state.purchaseParams);
}
export function useRecaptchaVisibleState(): RecaptchaVisible {
	return useSelector((state: AppState) => state.recaptchaVisible);
}
export function useServerSideState(): ServerSide {
	return useSelector((state: AppState) => state.serverSide);
}
export function useThemeState(): Theme {
	return useSelector((state: AppState) => state.theme);
}
export function useUserState(): User | null {
	return useSelector((state: AppState) => state.user);
}
export function useZoomState(): Zoom | null {
	return useSelector((state: AppState) => state.zoom);
}

export function useCampaignState(): Campaign | null {
	return useSelector((state: AppState) => state.campaign);
}
