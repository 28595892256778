import styled from 'styled-components';

import { Button, ButtonSizes } from '@calm-web/design-system';

export const Wrapper = styled(Button).attrs(
	(p: { $withBorder?: boolean; $isFaved: boolean; $isWebAppRedesign?: boolean }) => ({
		color: 'white',
		backgroundColor: p.$withBorder
			? p.$isFaved
				? 'heartRed'
				: p.$isWebAppRedesign
				? 'whiteAlpha10'
				: 'whiteAlpha20'
			: 'transparent',
		size: ButtonSizes.Sm,
	}),
)<{
	$withBorder?: boolean;
	$isFaved: boolean;
	$isWebAppRedesign?: boolean;
}>``;
