import styled from 'styled-components';

import { Button, ButtonSizes, IconButton, minWidth } from '@calm-web/design-system';

import infoIcon from 'icons/info-outline.svg';

export const ShareIcon = styled(Button).attrs((p: { $isWebAppRedesign?: boolean }) => ({
	backgroundColor: p.$isWebAppRedesign ? 'whiteAlpha10' : 'whiteAlpha20',
	textColor: 'white',
	size: ButtonSizes.Sm,
}))<{
	$isWebAppRedesign?: boolean;
}>``;

export const TooltipContent = styled.div`
	align-items: center;
	display: flex;
	padding: 0 12px;
	text-align: left;
	width: 288px;

	${minWidth('tablet')} {
		width: 560px;
	}
`;

export const InfoIcon = styled(infoIcon)`
	flex: 0 0 20px;
	height: 20px;
	margin-right: 10px;
	width: 20px;
`;

export const CloseButton = styled(IconButton)`
	margin-left: auto;
	height: 20px;
	width: 20px;

	& svg {
		height: 20px;
		width: 20px;
	}
`;
