import { defineMessages } from 'react-intl';

export default defineMessages({
	upsellTitle: {
		id: 'guideUpsell.title',
		defaultMessage: 'Enjoy your free preview!',
		description: 'An upsell title indicating the user can preview the content',
	},
	trialLength: {
		id: 'guideUpsell.trialLength',
		defaultMessage: 'Try 7 Days for Free',
		description: 'The length of a Calm free trial',
	},
	trialLength2: {
		id: 'guideUpsell.trialLength.v3',
		defaultMessage: 'Try {trail_length} Days for Free',
		description: 'The length of a Calm free trial',
	},
	buttonText: {
		id: 'guideUpsell.buttonText',
		defaultMessage: 'UNLOCK CALM',
		description: 'A button that takes the user to a subscription page to unlock the full content',
	},
	redeemButtonText: {
		id: 'guideUpsell.redeemButtonText',
		defaultMessage: 'Redeem Now',
		description:
			'A redeem variant of the button that takes the user to a subscription page to unlock the full content',
	},
	discountHeading: {
		id: 'guideUpsell.discountHeading',
		defaultMessage: 'Exclusive offer, {percentOff}% off Calm Premium',
		description: 'Excluse offer upsell',
	},
	videoTitle: {
		id: 'guideUpsell.videoTitle',
		defaultMessage: 'This video is not available for preview.',
		description: 'Telling the user that the video does not have preview content',
	},
});
