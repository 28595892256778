import { app as microsoftTeamsApp, FrameContexts } from '@microsoft/teams-js';
import { useCallback, useEffect, useState, useMemo } from 'react';

import { calmLogger } from '@/utils/calmLogger';

export default function useIsInMsTeamsMeeting() {
	const checkIfInMeeting = useCallback(async () => {
		try {
			const context = await microsoftTeamsApp.getContext();
			const frameContext = await microsoftTeamsApp.getFrameContext();
			const frameContextsInMeeting = [FrameContexts.sidePanel, FrameContexts.meetingStage];
			return Boolean(context.meeting && frameContext && frameContextsInMeeting.includes(frameContext));
		} catch (err) {
			calmLogger.error('Error in teams-calm useIsInMsTeamsMeeting', {}, err);
			return false;
		}
	}, []);
	return useMemo(() => ({ checkIfInMeeting }), [checkIfInMeeting]);
}

export function useIsInMsTeamsMeetingStage() {
	const [isInMeetingStage, setIsInMeetingStage] = useState<boolean>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		try {
			const frameContext = microsoftTeamsApp.getFrameContext();
			const frameContextsInMeeting = [FrameContexts.meetingStage];
			setIsInMeetingStage(Boolean(frameContext && frameContextsInMeeting.includes(frameContext)));
		} catch (err) {
			calmLogger.error('Error in teams-calm useIsInMsTeamsMeetingStage', {}, err);
		} finally {
			setIsLoading(false);
		}
	}, []);

	return { isInMeetingStage, isLoading };
}

export function useExecuteIsInMsTeamsMeeting() {
	const [isInMeeting, setIsInMeeting] = useState<boolean>();
	const [isLoading, setIsLoading] = useState(true);
	const { checkIfInMeeting } = useIsInMsTeamsMeeting();
	const isMsTeamsAppInitialized = microsoftTeamsApp.isInitialized();

	useEffect(() => {
		if (isMsTeamsAppInitialized && checkIfInMeeting) {
			(async () => {
				try {
					setIsLoading(true);
					const isInMeeting = await checkIfInMeeting();
					setIsInMeeting(isInMeeting);
				} finally {
					setIsLoading(false);
				}
			})().catch(error => calmLogger.error('Error in useExecuteIsInMsTeamsMeeting', {}, error));
		}
	}, [checkIfInMeeting, isMsTeamsAppInitialized]);

	return { isInMeeting, isLoading };
}
