import { AxiosPromise } from 'axios';

import apiRequest from '@/utils/apiRequest';

export function getDevice(): AxiosPromise {
	return apiRequest({
		endpoint: 'device',
		method: 'GET',
	});
}
