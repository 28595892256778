import { PayPalScriptProvider, BraintreePayPalButtons } from '@paypal/react-paypal-js';
import { BraintreeTokenizePayload } from '@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes';
import { JSONObject } from '@segment/analytics-next';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import messages from '@/components/purchase/PaymentElementsForm/messages';
import { useAnalytics } from '@/hooks/analytics';
import { usePaymentFormValues } from '@/hooks/purchase/usePaymentFormValues';
import { usePricesState, usePurchaseParamsState } from '@/hooks/store';

import { LoadingButton, Wrapper } from './styles';

const getButtonHeight = (): number => {
	if (typeof window === 'undefined') return 55;

	const { clientWidth: width } = document.body;

	if (width < 475) return 52;

	return 55;
};

const PaypalButton: React.FC<{
	children?: React.ReactNode;
	isDisabled: boolean;
	isLoading: boolean;
	setSelectedPaymentType: Dispatch<SetStateAction<string | null>>;
	onSubmit: (paypalToken: BraintreeTokenizePayload) => Promise<void>;
	onError: (errorMessageToDisplay: string, eventProps?: JSONObject, errorCode?: string | JSONObject) => void;
	analyticsPrefix: string;
	clientToken?: string;
}> = ({
	children,
	isDisabled,
	isLoading,
	setSelectedPaymentType,
	onSubmit,
	onError,
	analyticsPrefix,
	clientToken,
}) => {
	const { plan } = usePurchaseParamsState();
	const prices = usePricesState();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const { paymentFormCurrency } = usePaymentFormValues();

	const amount = prices.current[plan];

	if (!process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID) {
		return null;
	}

	return (
		<Wrapper isDisabled={isDisabled}>
			{children}
			{isLoading || !clientToken ? (
				<LoadingButton fullWidth isLoading backgroundColor="buttonPaypalYellow" />
			) : (
				<PayPalScriptProvider
					options={{
						clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
						components: 'buttons',
						dataClientToken: clientToken,
						intent: 'tokenize',
						vault: true,
					}}
				>
					<BraintreePayPalButtons
						style={{
							height: getButtonHeight(),
							shape: 'pill',
						}}
						fundingSource="paypal"
						forceReRender={[amount, paymentFormCurrency, analyticsPrefix]}
						createBillingAgreement={function (data, actions) {
							setSelectedPaymentType('paypal');
							return actions.braintree.createPayment({
								flow: 'vault',
								billingAgreementDescription: 'Calm Premium',
								intent: 'authorize',
								currency: paymentFormCurrency,
								amount,
								enableShippingAddress: true,
								shippingAddressEditable: true,
							});
						}}
						onApprove={async function (data, actions) {
							return actions.braintree.tokenizePayment(data).then(async payload => {
								await onSubmit(payload);
							});
						}}
						onCancel={function (data) {
							logEvent({
								eventName: `${analyticsPrefix} : Cancelled`,
								eventProps: {
									error: data as JSONObject,
									payment_type: 'paypal',
								},
							});
						}}
						onError={function (err) {
							onError(formatMessage(messages.fallback), { payment_type: 'paypal' }, err as JSONObject);
						}}
					/>
				</PayPalScriptProvider>
			)}
		</Wrapper>
	);
};

export default PaypalButton;
