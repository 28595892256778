/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

/* ----------------- GENERIC MESSAGING ----------------- */

const generics = defineMessages({
	yes: {
		id: 'generics.yes',
		defaultMessage: 'yes',
		description: 'The word yes',
	},
	no: {
		id: 'generics.no',
		defaultMessage: 'no',
		description: 'The word no',
	},
	back: {
		id: 'generics.back',
		defaultMessage: 'back',
		description: 'The word back',
	},
	here: {
		id: 'generics.here',
		defaultMessage: 'here',
		description: 'Usually refers to a link that the user can click',
	},
	terms: {
		id: 'generics.terms',
		defaultMessage: 'Terms',
		description: 'the word "terms"',
	},
	cancelAnytime: {
		id: 'generics.cancelAnytime',
		defaultMessage: 'Cancel anytime',
		description: 'The phrase "cancel anytime"',
	},
	faqs: {
		id: 'generics.faqs',
		defaultMessage: 'FAQs',
		description: 'the word "faqs"',
	},
	termsAndConditions: {
		id: 'generics.termsAndConditions',
		defaultMessage: 'Terms and Conditions',
		description: 'the phrase Terms and Conditions',
	},
	submit: {
		id: 'generics.submit',
		defaultMessage: 'Submit',
		description: 'the word "submit"',
	},
	continue: {
		id: 'generics.continue',
		defaultMessage: 'Continue',
		description: 'Copy usually refers to continuing to the next screen',
	},
	subscribe: {
		id: 'generics.subscribe',
		defaultMessage: 'Subscribe',
		description: 'the word "subscribe"',
	},
	free: {
		id: 'generics.free',
		defaultMessage: 'Free',
		description: 'the word "free"',
	},
	lifetime: {
		id: 'generics.lifetime',
		defaultMessage: 'lifetime',
		description: 'the phrase lifetime',
	},
	profile: {
		id: 'generics.profile',
		defaultMessage: 'Profile',
		description: 'the word "profile',
	},
	startListening: {
		id: 'generics.startListening',
		defaultMessage: 'Start listening',
		description: 'the phrase "Start listening"',
	},
	exploreInApp: {
		id: 'generics.exploreInApp',
		defaultMessage: 'Explore Now in the Calm App',
		description: 'the phrase "Explore Now in the Calm App"',
	},
	enrollNow: {
		id: 'generics.enrollNow',
		defaultMessage: 'Enroll Now',
		description: 'the phrase "Enroll Now"',
	},
	paymentDetails: {
		id: 'generics.paymentDetails',
		defaultMessage: 'Payment Details',
		description: 'the phrase "Payment Details"',
	},
	applicableTax: {
		id: 'generics.applicableTaxesPlaceholder',
		defaultMessage: 'applicable taxes',
		description: 'the phrase "applicable taxes"',
	},
	tax: {
		id: 'generics.tax',
		defaultMessage: 'tax',
		description: 'the word "tax"',
	},
	forBusiness: {
		id: 'generics.forBusiness',
		defaultMessage: 'For Business',
		description: 'the phrase "For Business"',
	},
	downloadCalm: {
		id: 'generics.downloadCalm',
		defaultMessage: 'Download Calm',
		description: 'the phrase "Download Calm',
	},
	downloadCalmApp: {
		id: 'generics.downloadCalmApp',
		defaultMessage: 'Download Calm App',
		description: 'the phrase "Download Calm App',
	},
	openCalmApp: {
		id: 'generics.openCalmApp',
		defaultMessage: 'Open Calm App',
		description: 'the phrase "Open Calm App"',
	},
	getStarted: {
		id: 'generics.getStarted',
		defaultMessage: 'Get Started',
		description: 'the phrase "Get Started"',
	},
	loading: {
		id: 'generics.loading',
		defaultMessage: 'Loading...',
		description: 'a loading indicator',
	},
	success: {
		id: 'generics.success',
		defaultMessage: 'Success',
		description: 'a success message',
	},
	sorry: {
		id: 'generics.sorry',
		defaultMessage: 'Sorry',
		description: 'the word "sorry"',
	},
	agree: {
		id: 'generics.agree',
		defaultMessage: 'Agree',
		description: 'the word "Agree"',
	},
	cancel: {
		id: 'generics.cancel',
		defaultMessage: 'Cancel',
		description: 'the word "Cancel"',
	},
	getPercentOff: {
		id: 'generics.getPercentOff',
		defaultMessage: 'Get {percent}% off',
		description: 'a CTA for a discounted Calm',
	},
	skip: {
		id: 'generics.skip',
		defaultMessage: 'Skip',
		description: 'the word "skip",',
	},
	next: {
		id: 'generics.next',
		defaultMessage: 'Next',
		description: 'the word "next",',
	},
	year: {
		id: 'generics.pricing.year',
		defaultMessage: 'year',
		description: 'A duration for a (typically paid) subscription to Calm - eg "renews each year"',
	},
	month: {
		id: 'generics.pricing.month',
		defaultMessage: 'month',
		description: 'A duration for a (typically paid) subscription to Calm - eg "renews each month"',
	},
	yearly: {
		id: 'generics.yearly',
		defaultMessage: 'yearly',
		description: 'A duration for a (typically paid) subscription to Calm',
	},
	monthly: {
		id: 'generics.monthly',
		defaultMessage: 'monthly',
		description: 'A duration for a (typically paid) subscription to Calm',
	},
	downloadApp: {
		id: 'generics.downloadApp',
		defaultMessage: 'Download app',
		description: 'Download app cta button copy.',
	},
});

/* ----------------- HEADER MESSAGING ----------------- */

const headerMessages = defineMessages({
	meditate: {
		id: 'nav.meditate',
		defaultMessage: 'Meditate',
		description: 'Nav Meditate',
	},
	sleepStories: {
		id: 'nav.sleepStories',
		defaultMessage: 'Sleep Stories',
		description: 'Nav Sleep Stories',
	},
	music: {
		id: 'nav.music',
		defaultMessage: 'Music',
		description: 'Nav Music',
	},
	masterclass: {
		id: 'nav.masterclass',
		defaultMessage: 'Masterclass',
		description: 'Nav Masterclass',
	},
	body: {
		id: 'nav.body',
		defaultMessage: 'Body',
		description: 'Nav Body',
	},
	wisdom: {
		id: 'nav.wisdom',
		defaultMessage: 'Wisdom',
		description: 'Nav Wisdom',
	},
	movement: {
		id: 'nav.movement',
		defaultMessage: 'Movement',
		description: 'Nav Movement',
	},
	startFreeTrial: {
		id: 'nav.start',
		defaultMessage: 'Start Free Trial',
		description: 'Nav Start Free Trial',
	},
	contactUs: {
		id: 'nav.contactUs',
		defaultMessage: 'Contact Us',
		description: 'Nav Contact Us',
	},
	continueToApp: {
		id: 'nav.continueToApp',
		defaultMessage: 'Continue to app',
		description: 'Button that routes user to web app',
	},
	subscribeToCalm: {
		id: 'nav.subscribeToCalm',
		defaultMessage: 'Subscribe to Calm',
		description: 'Button that routes user to subscription checkout flow',
	},
	sleep: {
		id: 'nav.sleep',
		defaultMessage: 'Sleep',
		description: 'Link that takes user to sleep page',
	},
	stressAnxiety: {
		id: 'nav.stressAnxiety',
		defaultMessage: 'Stress & Anxiety',
		description: 'Link that takes user to stress page',
	},
	mindfulness: {
		id: 'nav.mindfulness',
		defaultMessage: 'Mindfulness',
		description: 'Link that takes user to mindfulness page',
	},
	screening: {
		id: 'nav.screening',
		defaultMessage: 'Screening',
		description: 'Link that takes user to Screening page',
	},
	calmBusiness: {
		id: 'nav.calmBusiness',
		defaultMessage: 'Calm Business',
		description: 'Link that takes user to Calm Business page',
	},
	calmHealth: {
		id: 'nav.calmHealth',
		defaultMessage: 'Calm Health',
		description: 'Link that takes user to Calm Health page',
	},
});

/* ----------------- LOGIN/SIGNUP UI MESSAGING ----------------- */

const loginMessages = defineMessages({
	loginButton: {
		id: 'nav.login',
		defaultMessage: 'Log In',
		description: 'Nav Log In button text',
	},
	loginTerms2: {
		id: 'login.terms.long',
		defaultMessage:
			'By clicking Continue, you agree to our {terms} and acknowledge that you have read our <privacypolicylink>Privacy Policy</privacypolicylink>, which explains how to opt out of offers and promos',
		description: 'Description for terms and privacy policy on login page',
	},
	loginTermsV2: {
		id: 'login.terms.v2',
		defaultMessage:
			'By tapping Continue or logging into an existing Calm account, you agree to our {terms} and acknowledge that you have read our <privacypolicylink>Privacy Policy</privacypolicylink>, which explains how to opt out of offers and promos',
		description: 'Description for terms and privacy policy on login page',
	},
	loginTermsDataSharing: {
		id: 'login.termAndPPDataSharing',
		defaultMessage:
			"By clicking Continue, you agree to Calm's {terms} and acknowledge that you have read Calm's <privacypolicylink>Privacy Policy</privacypolicylink>. In addition, if your health plan is providing you with access to Calm, you consent to Calm sharing information collected through your use of the services with that health plan. You may withdraw your consent at any time, although doing so may render you unable to use the service. Please see your health plan for information about their privacy practices.",
		description: 'Description for terms and privacy policy on login page for KP',
	},
	signupTermsShort: {
		id: 'signup.termAndPP2Short',
		defaultMessage:
			'By clicking Continue, you agree to our {terms} and acknowledge that you have read our <privacypolicylink>Privacy Policy</privacypolicylink>.',
		description: 'Description for terms and privacy policy on signup page, shown only to EU users.',
	},
	signupTermsShortV2: {
		id: 'signup.terms.v2.short',
		defaultMessage:
			'By tapping Continue or logging into an existing Calm account, you agree to our {terms} and acknowledge that you have read our <privacypolicylink>Privacy Policy</privacypolicylink>.',
		description: 'Description for terms and privacy policy on signup page, shown only to EU users.',
	},
	signupTerms: {
		id: 'signup.termAndPP2',
		defaultMessage:
			'By clicking Continue, you agree to our {terms} and acknowledge that you have read our <privacypolicylink>Privacy Policy</privacypolicylink>, which explains how to opt out of offers and promos.',
		description: 'Description for terms and privacy policy on signup page',
	},
	signupTermsV2: {
		id: 'signup.terms.v2',
		defaultMessage:
			'By tapping Continue or logging into an existing Calm account, you agree to our {terms} and acknowledge that you have read our <privacypolicylink>Privacy Policy</privacypolicylink>, which explains how to opt out of offers and promos.',
		description: 'Description for terms and privacy policy on signup page',
	},
	createAccount: {
		id: 'signup.createAccount',
		defaultMessage: 'Create Account',
		description: 'CTA instructing the user to proceed with signing up for Calm',
	},
	tryCalm: {
		id: 'navHeader.cta.tryFree',
		defaultMessage: 'Try Calm for Free',
		description: 'Nav header upsell text',
	},
	login: {
		id: 'login.buttonText.login',
		defaultMessage: 'Log in',
		description: 'Generic text for a user to click to log in to their account',
	},
	loginTitleCase: {
		id: 'login.buttonText.loginTitleCase',
		defaultMessage: 'Log In',
		description: 'Generic text for a user to click to log in to their account',
	},
	logout: {
		id: 'login.buttonText.logout',
		defaultMessage: 'Log out',
		description: 'Generic text for a user to click to log out of their account',
	},
	signup: {
		id: 'login.buttonText.signup',
		defaultMessage: 'Sign up',
		description: 'Generic text for a user to click to sign up for a calm account',
	},
	signupTitleCase: {
		id: 'login.buttonText.signupTitleCase',
		defaultMessage: 'Sign Up',
		description: 'Generic text for a user to click to sign up for a calm account',
	},
	signupTitle: {
		id: 'login.title.signup',
		defaultMessage: 'Create a free Calm account',
		description: 'The title for the signup component',
	},
	signupTitle2: {
		id: 'login.title.signup2',
		defaultMessage: 'Create your Calm account',
		description: 'The title for the signup component',
	},
	upsellSignupTitle: {
		id: 'login.upsell.signupTitle',
		defaultMessage: 'Unlock a happier, healthier you with Calm',
		description: 'Title for a login/signup modal',
	},
	loginTitle: {
		id: 'login.loginTitleLong',
		defaultMessage: 'Log into your account',
		description: 'The title for the login component',
	},
	loginCalmTitle: {
		id: 'login.loginCalmTitle',
		defaultMessage: 'Log into your Calm account',
		description: 'The title for the Calm login component',
	},
	signupTitleMedium: {
		id: 'login.signupTitleMedium',
		defaultMessage: 'Create your account',
		description: 'Heading for sign up screen',
	},
	readFaq: {
		id: 'faq.text',
		defaultMessage: 'View FAQ',
		description: 'FAQ text',
	},
	haveAccount: {
		id: 'generics.haveAccount',
		defaultMessage: 'Do you already have a Calm account?',
		description: 'Have Account text',
	},
	emailPlaceholder: {
		id: 'generics.emailAddress',
		defaultMessage: 'Email address',
		description: 'A generic email placeholder',
	},
	emailRequiredPlaceholder: {
		id: 'generics.emailAddress.required',
		defaultMessage: 'Email address (Required)',
		description: 'A generic email placeholder, labeling the field as required',
	},
	personalEmailPlaceholder: {
		id: 'generics.personalEmailAddress.required',
		defaultMessage: 'Personal email address (Required)',
		description: 'A generic personal email placeholder',
	},
	passwordPlaceholder: {
		id: 'generics.password',
		defaultMessage: 'Password',
		description: 'A generic password placeholder',
	},
	signupPasswordRequiredPlaceholder: {
		id: 'generics.password.required',
		defaultMessage: 'Password (Required, 8+ characters)',
		description: 'A generic password placeholder with validation info',
	},
	loginPasswordRequiredPlaceholder: {
		id: 'generics.loginPassword.required',
		defaultMessage: 'Password (Required, 6+ characters)',
		description: 'A generic password placeholder with validation info for login screen',
	},
	nameRequiredPlaceholder: {
		id: 'login.namePlaceholder.required',
		defaultMessage: 'First name (Required)',
		description: 'Input asking for the user to enter their first name',
	},
});

/* ----------------- FACEBOOK LOGIN MESSAGING ----------------- */

const facebookLoginMessages = defineMessages({
	facebookSignInMessage: {
		id: 'login.facebookLoginButton.signinMessage',
		description: 'facebook signin button text',
		defaultMessage: 'Sign in with Facebook',
	},
	facebookSignupMessage: {
		id: 'login.facebookLoginButton.signupMessage',
		description: 'facebook signup button text',
		defaultMessage: 'Sign up with Facebook',
	},
	continueFacebookSignupMessage: {
		id: 'login.facebookLoginButton.continueMessage',
		description: 'facebook signup button text',
		defaultMessage: 'Continue with Facebook',
	},
});

/* ----------------- OAUTH LINK MESSAGING ----------------- */
const oauthAccountLinkMessages = defineMessages({
	oauthLoggedInAsMessage: {
		id: 'oauthAccountLink.oauthLoggedInAsMessage',
		description: 'oauth signed in as message',
		defaultMessage: "You're signed in as {email}. <a>Not you?</a>",
	},
	oauthAccountLinkAgreeMessage: {
		id: 'oauthAccountLink.oauthAccountLinkAgreeMessage',
		description: 'oauth account linking agreement message',
		defaultMessage: 'You agree that {client} will be able to:',
	},
	oauthAccountLinkViewDataMessage: {
		id: 'oauthAccountLink.oauthAccountLinkViewDataMessage',
		description: 'oauth account link view data message',
		defaultMessage: 'View your Calm account data',
	},
	oauthAccountLinkViewActivityMessage: {
		id: 'oauthAccountLink.oauthAccountLinkActivityViewMessage',
		description: 'oauth account link view activity message',
		defaultMessage: 'View your activity on Calm',
	},
	oauthAccountLinkActionMessage: {
		id: 'oauthAccountLink.oauthAccountLinkActionMessage',
		description: 'oauth account link take action message',
		defaultMessage: 'Take actions in Calm on your behalf',
	},
	oauthViewPrivacyPolicyMessage: {
		id: 'oauthAccountLink.oauthAccountLinkPrivacyPolicyMessage',
		description: 'oauth account link privacy policy message',
		defaultMessage:
			"For more information about how {client} can use your personal data, please see {client}'s <a>privacy policy</a>",
	},
});

/* ----------------- PAYMENT FORM MESSAGING ----------------- */

const paymentMessages = defineMessages({
	purchaseTitlePercentOff: {
		id: 'paymentForm.title.yearly.percentOff',
		defaultMessage: 'Get {percentOff}% off',
		description: 'A payment title indicating the user gets some percentage off the normal price',
	},
	purchaseTitleYearly: {
		id: 'paymentForm.title.yearly',
		defaultMessage: '12 months of Calm Premium',
		description: 'A title for a purchase form for Calm Premium',
	},
	purchaseTitleMonthly: {
		id: 'paymentForm.title.monthly',
		defaultMessage: '1 month of Calm Premium',
		description: 'A title for a purchase form for Calm Premium monthly sub',
	},
	purchaseTitleFreetrial: {
		id: 'paymentForm.title.freetrial',
		defaultMessage: '{duration}-{quantity, plural, one {Day} other {Day}} Free Trial',
		description: 'A purchase title for a Calm free trial that is X days',
	},
	purchaseTitleFreetrialMonth: {
		id: 'paymentForm.title.freetrial.month',
		defaultMessage: '{duration}-{quantity, plural, one {Month} other {Month}} Free Trial',
		description: 'A purchase title for a Calm free trial that is X months',
	},
	purchaseSubtitleTrial: {
		id: 'paymentForm.subtitle.freetrial',
		defaultMessage: 'No commitments. Cancel anytime.',
		description: 'A subtitle indicating the user can cancel a trial at any time',
	},
	purchaseTitleLifetime: {
		id: 'paymentForm.title.lifetime',
		defaultMessage: 'Lifetime of Calm Premium',
		description: 'A purchase title for a Calm Lifetime membership',
	},
	alreadySubscribedTitle: {
		id: 'paymentForm.error.alreadySubscribed.title',
		defaultMessage: 'You Already Have A Subscription',
		description: 'Title for an error screen that the user cannot subscribe again',
	},
	alreadySubscribedSubtitle: {
		id: 'paymentForm.error.alreadySubscribed.lifetimeSubtitle',
		defaultMessage:
			"You already have a valid subscription to Calm, so you can't purchase another subscription right now.",
		description: 'Subtitle stating that a user cannot subscribe a second time',
	},
	learnMore: {
		id: 'paymentForm.error.alreadySubscribed.learnMore',
		defaultMessage: 'You can learn more about your own subscription {here}.',
		description:
			'{here} is literally the word here that is a link to the profile page to view subscription info',
	},
	alreadyTrialingTitle: {
		id: 'paymentForm.error.alreadyTrialing.title',
		defaultMessage: 'Already In Free Trial',
		description: "Title for a user not able to start a trial when they're already in one",
	},
	alreadyTrialingSubtitle: {
		id: 'paymentForm.error.alreadyTrialing.subtitle',
		defaultMessage: 'You are currently already in your free trial.',
		description: "Subtitle indicating a user can't enter a second trial",
	},
	defaultSubscribeError: {
		id: 'paymentForm.error.alreadySubscribed.defaultError',
		defaultMessage: 'Unable to Purchase Subscription',
		description: 'A generic error title when a user is not able to purchase a subscriptoin',
	},
	alreadyTrialedTitle: {
		id: 'paymentForm.error.alreadyTrialed.title',
		defaultMessage: 'Free Trial Already Claimed',
		description: 'Title for when a user has already claimed a free trial and cannot claim another',
	},
	alreadyTrialedSubtitle: {
		id: 'paymentForm.error.alreadyTrialed.subtitle',
		defaultMessage: 'You can subscribe to Calm Premium {here}',
		description: 'here brings the user from a trial page to a subscribe page',
	},
	paymentFooterWithPrices: {
		id: 'paymentForm.footer.withPrices',
		defaultMessage:
			"The yearly membership gives you unlimited access to Calm's entire library of content. You will be charged {firstYearPrice} for a 1 year subscription that will renew at {nextYearPrice}, billed annually.",
		description: 'payment for footer messaging with current and first renewal prices',
	},
	paymentFooterCancel: {
		id: 'paymentForm.footer.cancel',
		defaultMessage:
			"You may cancel your subscription at any time by going to your Profile and following the instructions on the 'Manage Subscription' page.",
		description: 'payment footer cancel messaging',
	},
	notYou: {
		id: 'paymentForm.notYou',
		defaultMessage: "You're logged in as {email}. <you>Not You?</you>",
		description: 'payment form Not You link',
	},
	subscription: {
		id: 'paymentForm.subscription',
		defaultMessage: 'Subscription',
		description: 'The word "subscription"',
	},
	trial: {
		id: 'paymentForm.trial',
		defaultMessage: 'Trial',
		description: 'The word "trial"',
	},
});

/* ----------------- ONBOARDING/FTUE MESSAGING ----------------- */
const onboardingMessages = defineMessages({
	skipForNow: {
		id: 'onboarding.skipForNow',
		defaultMessage: 'Skip for now',
		description: 'A button for a user to skip the current section',
	},
	maybeLater: {
		id: 'onboarding.maybeLater',
		defaultMessage: 'Maybe later',
		description: 'A button for a user to skip the current section',
	},
	meditateTitle: {
		id: 'recommend.meditate.title',
		defaultMessage: 'How to Meditate',
		description: 'Meditate program title',
	},
	meditateDescription: {
		id: 'recommend.meditate.description',
		defaultMessage:
			"Let's start with {title}, a 30-day program that will teach you the basics of meditation.",
		description: 'Meditate program description',
	},
	anxietyTitle: {
		id: 'recommend.anxiety.title',
		defaultMessage: '7 Days of Calming Anxiety',
		description: 'Anxiety program title',
	},
	anxietyDescription: {
		id: 'recommend.anxiety.description',
		defaultMessage:
			"Let's start with {title}, a 7-day program that will teach you how to ride the waves of anxiety.",
		description: 'Anxiety program description',
	},
	stressTitle: {
		id: 'recommend.stress.title',
		defaultMessage: '7 Days of Managing Stress',
		description: 'Stress program title',
	},
	stressDescription: {
		id: 'recommend.stress.description',
		defaultMessage: "Let's start with {title}, a 7-day program that will teach you how to master stress.",
		description: 'Stress program description',
	},
	happinessTitle: {
		id: 'recommend.happiness.title',
		defaultMessage: '7 Days of Happiness',
		description: 'Happiness program title',
	},
	happinessDescription: {
		id: 'recommend.happiness.description',
		defaultMessage:
			"Let's start with {title}, a 7-day program that will teach you how to relish each day with gratitude and acceptance.",
		description: 'Happiness program description',
	},
	performanceTitle: {
		id: 'recommend.performance.title',
		defaultMessage: 'Train Your Mind',
		description: 'Performance program title',
	},
	performanceDescription: {
		id: 'recommend.performance.description',
		defaultMessage:
			"Let's start with {title}, a program by three-time champion LeBron James that will teach you how to improve your performance.",
		description: 'Performance program description',
	},
	gratitudeTitle: {
		id: 'recommend.gratitude.title',
		defaultMessage: '7 Days of Gratitude',
		description: 'Gratitude program title',
	},
	gratitudeDescription: {
		id: 'recommend.gratitude.description',
		defaultMessage:
			"Let's start with {title}, a 7-day program that will teach you how to allow appreciation to flourish.",
		description: 'Gratitude program description',
	},
	esteemTitle: {
		id: 'recommend.esteem.title',
		defaultMessage: '7 Days of Self-Esteem',
		description: 'Esteem program title',
	},
	esteemDescription: {
		id: 'recommend.esteem.description',
		defaultMessage:
			"Let's start with {title}, a 7-day program that will teach you how to find self-acceptance.",
		description: 'Esteem program description',
	},
	accountInfoSimplifiedFTUETitle: {
		id: 'signup.accountInfoSimplifiedFTUETitle',
		defaultMessage: 'Start your mindfulness {pagebreak} journey with Calm',
		description: 'Account info heading for educational onboarding sign up flow',
	},
});

const pageTitles = defineMessages({
	signupPageTitle: {
		id: 'pageTitle.signup',
		defaultMessage: 'Sign Up',
		description: 'Page title for signup-flow',
	},
	newMemberOfferTitle: {
		id: 'pageTitle.newMemberOffer',
		defaultMessage: 'New Member Offer',
		description: 'Page title for the page where new members get a discount or other special offer',
	},
	familyPlanLandingTitle: {
		id: 'pageTitle.familyPlanLanding',
		defaultMessage: 'Family Plan',
		description: 'Page title for family plan marketing page',
	},
	acceptYourInviteLandingTitle: {
		id: 'pageTitle.acceptYourInviteLandingTitle',
		defaultMessage: 'Accept Your Invite',
		description: 'Page title for Calm Business - dependent invite marketing page',
	},
	marketingPageTitle: {
		id: 'pageTitle.genericMarketing',
		defaultMessage: 'The #1 App for Meditation and Sleep',
		description: 'Page title for most marketing pages',
	},
	giftCardPageTitle: {
		id: 'pageTitle.giftCard',
		defaultMessage: 'Give the Gift of a Calmer Mind',
		description: 'Page title for buying our gift cards',
	},
	lifetimeGiftCardPageTitle: {
		id: 'pageTitle.lifetimeGiftCard',
		defaultMessage: 'Give Calm for Life',
		description: 'Page title for buying our lifetime gift cards',
	},
	giftCardRedeemPageTitle: {
		id: 'pageTitle.giftCardRedeem',
		defaultMessage: 'Redeem your Gift',
		description: 'Page title for redeeming a gift card',
	},
	passwordResetTitle: {
		id: 'pageTitle.passwordReset',
		defaultMessage: 'Password Reset',
		description: 'Page title for resetting your password to log in',
	},
	calmBoxPageTitle: {
		id: 'pageTitle.calmBox',
		defaultMessage: 'Give the Gift of Calm',
		description: 'Page title for buying Calm merchandise',
	},
	leBronPageTitle: {
		id: 'pageTitle.leBron',
		defaultMessage: 'Train Your Mind with LeBron James',
		description: 'Page title for our mental fitness course by Lebron James',
	},
	emailConfirmPageTitle: {
		id: 'pageTitle.emailConfirm',
		defaultMessage: 'Email Confirm',
		description: 'Page title for confirming your email address',
	},
	specialWelcomeOfferTitle: {
		id: 'pageTitle.specialWelcomeOffer',
		defaultMessage: 'Special Welcome Offer',
		description: 'Page title for subscription page after clicking web app cta',
	},
	calmSchoolsTitle: {
		id: 'pageTitle.calmSchoolsTitle',
		defaultMessage: 'Calm Schools',
		description: 'Page title for the Calm Schools pages',
	},
	wellnessHubPageTitle: {
		id: 'pageTitle.wellnessHubPageTitle',
		defaultMessage: 'Calm Together',
		description: 'Page title for our wellness hub page',
	},
	wellnessHubPageDescription: {
		id: 'pageTitle.wellnessHubDescription',
		defaultMessage:
			'Free access to our most popular mental wellness resources & tools to help care for your mental wellbeing.',
		description: 'Page description for the Calm wellness hub pages',
	},
	oauthAccountLinkPageTitle: {
		id: 'pageTitle.googleAccountLinkPageTitle',
		defaultMessage: 'Link Calm with {client}',
		description: 'Page title for OAuth Account linking',
	},
	pageNotFoundTitle: {
		id: 'pageTitle.notFoundPageTitle',
		defaultMessage: 'Page not found',
		description: 'Page title for the 404 not found error page.',
	},
	marketingEmailsOptIn: {
		id: 'pageTitle.marketingEmailsOptIn',
		defaultMessage: 'Receive emails from Calm',
		description: 'Page title for a page where users can ask to be added to calm marketing mailing list.',
	},
	guestPassPageTitle: {
		id: 'pageTitle.guestPass',
		defaultMessage: 'Claim your 30-Day Calm Guest Pass',
		description: 'Page title for when a user receives a guest pass',
	},
});

const pageSeoTags = defineMessages({
	guidePageSeoTitle: {
		id: 'pageSeoTag.guideTitle',
		defaultMessage: 'Listen to {title} by {narrator} on Calm',
		description: 'Page seo title for guides',
	},
	guidePageSeoDescription: {
		id: 'pageSeoTag.guideDescription',
		defaultMessage: 'FREE PREVIEW: {description}',
		description: 'Page seo description for guides',
	},
	programPageSeoTitle: {
		id: 'pageSeoTag.programTitle',
		defaultMessage: 'Listen to {title} by {narrator}',
		description: 'Page seo title for programs',
	},
	programPageSeoDescription: {
		id: 'pageSeoTag.programDescription',
		defaultMessage: 'FREE PREVIEW: {description}',
		description: 'Page seo description for programs',
	},
});

export default {
	...generics,
	...headerMessages,
	...facebookLoginMessages,
	...oauthAccountLinkMessages,
	...loginMessages,
	...paymentMessages,
	...onboardingMessages,
	...pageTitles,
	...pageSeoTags,
};
