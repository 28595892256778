import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { ShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { usePartnerState, usePurchaseParamsState } from '@/hooks/store';
import { User } from '@/store/user/types';
import { getCouponForLogging, getSlug } from '@/utils/analytics/setupEventProps';
import { formatError } from '@/utils/formatErrors';
import { parseLoginError } from '@/utils/forms/parseLoginError';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import { useSetupUserAfterAuth } from './useSetupUserAfterAuth';

export type LoginSubmitValues = {
	email: string;
	password: string;
	guest_pass_code?: string | undefined;
};

export type SignupSubmitValues = {
	email: string;
	password: string;
	name: string;
	marketing_opt_in: boolean;
	share_token?: string | null;
	skip_free_access?: boolean;
	guest_pass_code?: string | undefined;
};

type OnAuthSubmitArgs =
	| {
			authFormMode: 'login';
			values: LoginSubmitValues;
			source?: string;
	  }
	| {
			authFormMode: 'signup';
			values: SignupSubmitValues;
			source?: string;
	  };

interface OnAuthSubmit {
	(args: OnAuthSubmitArgs): Promise<{ success: User } | { error: string }>;
}

export function useAuthSubmit({ source }: { source?: string }): OnAuthSubmit {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const apiRequest = useApi();
	const partner = usePartnerState();
	const purchaseParamsState = usePurchaseParamsState();
	const setupUserAfterAuth = useSetupUserAfterAuth();

	const { isShareContentRecipient, shareToken } = useContext(ShareContext);
	const sourceProp: Record<string, string | undefined> = source ? { source } : {};

	const onAuthSubmit: OnAuthSubmit = async ({ authFormMode, values, source }) => {
		if (!authFormMode) {
			return { error: formatMessage(parseLoginError({})) };
		}

		logEvent({
			eventName: 'Login Form : Submit Clicked',
			eventProps: {
				mode: authFormMode,
				...(source ? { source } : {}),
				...(isShareContentRecipient
					? {
							source: 'Shared Link',
							share_token: shareToken,
					  }
					: {}),
				...sourceProp,
			},
		});

		try {
			const partnerSpecificAnalytics = {
				partner_id: partner?.id,
				partner_type: [partner?.category?.toLowerCase() || null],
			};

			const authValues = {
				...values,
				...(source ? { source } : {}),
				...(partner ? partnerSpecificAnalytics : {}),
				...sourceProp,
				slug: getSlug(),
				coupon: getCouponForLogging(purchaseParamsState?.coupon),
			};

			const { data } = await apiRequest<User>({
				body: authValues,
				endpoint: authFormMode,
				method: 'POST',
				customHeaders: {
					Accept: 'application/json',
				},
			});

			await setupUserAfterAuth(data, CalmAuthMethods.Email);

			logEvent({
				eventName: 'Login Form : Success',
				eventProps: {
					mode: data.was_created ? 'signup' : 'login',
					mode_shown: authFormMode,
					calm_user_id: data.id as string,
					...(partner ? partnerSpecificAnalytics : {}),
					...sourceProp,
					...(source ? { source } : {}),
				},
			});

			return { success: data };
		} catch (err) {
			const error = err?.data?.error;
			logEvent({
				eventName: 'Login Form : Error',
				eventProps: {
					mode: authFormMode,
					...formatError(error),
					...(source ? { source } : {}),
					...sourceProp,
				},
			});
			return { error: formatMessage(parseLoginError(error)) };
		}
	};

	return onAuthSubmit;
}
