import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

export interface SimplifiedSignupModalContextState {
	isModalOpen: boolean;
	setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const SimplifiedSignupModalContext = createContext<SimplifiedSignupModalContextState>({
	isModalOpen: false,
	setIsModalOpen: () => {},
});

export const useSimplifiedSignupModalContext = (): SimplifiedSignupModalContextState =>
	useContext(SimplifiedSignupModalContext);

const SimplifiedSignupModalContextProvider = ({ children }: { children: ReactNode }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const value = useMemo(
		() => ({
			isModalOpen,
			setIsModalOpen,
		}),
		[isModalOpen],
	);

	return (
		<SimplifiedSignupModalContext.Provider value={value}>{children}</SimplifiedSignupModalContext.Provider>
	);
};

export default SimplifiedSignupModalContextProvider;
