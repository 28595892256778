import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text } from '@calm-web/design-system';

export const Wrapper = styled.div`
	margin: 24px 0;
	text-align: left;
`;

export const Email = styled(Text)`
	padding-left: 4px;
	padding-right: 16px;
`;

export const LogoutCta = styled.button`
	background: transparent;
	border: 1px solid transparent;
	color: ${palette('blue2')};
	display: inline;
	cursor: pointer;
	font-size: 13.5px;
	font-weight: bold;
	margin-bottom: 2px;
	padding: 0;
	position: relative;
	text-decoration: underline;
`;
