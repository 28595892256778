import Link from 'next/link';
import { useIntl } from 'react-intl';

import ScenesIcon from 'icons/scenes-outline.svg';

import messages from './messages';
import { Wrapper } from './styles';

const ScenesLink = ({ className }: { className?: string }) => {
	const { formatMessage } = useIntl();

	return (
		<Link passHref href="/app/scenes" legacyBehavior className={className}>
			<Wrapper aria-label={formatMessage(messages.linkText)}>
				<ScenesIcon />
			</Wrapper>
		</Link>
	);
};

export default ScenesLink;
