import { MessageDescriptor, useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics';
import { useAuthFormModeState } from '@/hooks/store';
import { useIsGDPRDevice, useIsUKGDPRDevice } from '@/hooks/user';
import genericMessages from '@/messages/messages';
import { AuthFormMode } from '@/store/authFormMode/types';

import { Wrapper } from './styles';
import { AuthTermsProps } from './types';

const AuthTerms = ({ signupTermsText, loginTermsText }: AuthTermsProps) => {
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();
	const isUKGDPRDevice = useIsUKGDPRDevice();
	const isGDPRDevice = useIsGDPRDevice();
	const useShortTerms = isGDPRDevice || isUKGDPRDevice;
	const { logEvent } = useAnalytics();

	const defaultTermsMessages: {
		[mode in AuthFormMode]: {
			[isShort in 'short' | 'normal']: MessageDescriptor;
		};
	} = {
		signup: {
			normal: genericMessages.signupTerms,
			short: genericMessages.signupTermsShort,
		},
		login: {
			normal: genericMessages.loginTerms2,
			short: genericMessages.loginTerms2,
		},
	};

	const defaultTermsMessage = formatMessage(
		defaultTermsMessages[authFormMode][useShortTerms ? 'short' : 'normal'],
		{
			terms: (
				<a
					href="/terms"
					target="_blank"
					onClick={() =>
						logEvent({
							eventName: `Login Form : Terms : Clicked`,
							eventProps: {
								mode: authFormMode,
							},
						})
					}
				>
					{formatMessage(genericMessages.terms)}
				</a>
			),
			privacypolicylink: (...chunks): JSX.Element => (
				<a
					href="/privacy"
					target="_blank"
					onClick={() =>
						logEvent({
							eventName: `Login Form : Privacy Policy : Clicked`,
							eventProps: {
								mode: authFormMode,
							},
						})
					}
				>
					{chunks}
				</a>
			),
		},
	);
	const customTermsMessages = authFormMode === 'login' ? loginTermsText : signupTermsText;

	const termsMessage = customTermsMessages ?? defaultTermsMessage;

	return <Wrapper className="loginTerms">{termsMessage}</Wrapper>;
};

export default AuthTerms;
