import { defineMessages } from 'react-intl';

export default defineMessages({
	openButtonLabel: {
		id: 'header.hamburgerMenu.openButtonLabel',
		defaultMessage: 'Open Mobile menu',
		description: 'Button description for opening the mobile menu',
	},
	closeButtonLabel: {
		id: 'header.hamburgerMenu.closeButtonLabel',
		defaultMessage: 'Close Mobile menu',
		description: 'Button description for closing the mobile menu',
	},
});
