import { defineMessages } from 'react-intl';

export const messages = defineMessages({
	teamsAuthenticationErrorTitle: {
		id: 'teamsAuthenticationError.title',
		defaultMessage: 'Oops, something went wrong',
		description: 'Title for teams authentication error page. Displays when we cannot get their jwt token.',
	},
	teamsAuthenticationErrorBody1: {
		id: 'teamsAuthenticationError.body1',
		defaultMessage: 'You might not have granted Teams permission to use the Calm app.',
		description: 'Body1 for teams authentication error page. Displays when we cannot get their jwt token.',
	},
	teamsAuthenticationErrorBody2: {
		id: 'teamsAuthenticationError.body2',
		defaultMessage: 'Please go to the Settings tab in the Calm app within Teams to grant permissions.',
		description: 'Body2 for teams authentication error page. Displays when we cannot get their jwt token.',
	},
	teamsAuthenticationErrorBody3: {
		id: 'teamsAuthenticationError.body3',
		defaultMessage: 'If the problem persists, please contact Calm support at {supportLink}.',
		description: 'Body3 for teams authentication error page. Displays when we cannot get their jwt token.',
	},
});
