import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes } from '@calm-web/design-system';

export const Wrapper = styled.div`
	${fontSize(FontSizes.sm)};
	width: 100%;
	margin: 28px 0;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	height: 26px;
`;

// Assume a width of 26px, which is slightly more than what desktop has
const OR_WIDTH = 26;
export const OrText = styled.div`
	z-index: 1;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 8px 4px; // account for the wonky font height
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const breakLine = css`
	width: calc(50% - ${OR_WIDTH / 2}px);
	position: absolute;
	border-bottom: 1px solid ${palette('gray2')};
	vertical-align: middle;
`;

export const OrLineLeft = styled.span`
	${breakLine}
	left: 0;
`;

export const OrLineRight = styled.span`
	${breakLine}
	right: 0;
`;
