import { defineMessages } from 'react-intl';

const messages = defineMessages({
	offerHeading: {
		id: 'spotify.payment.heading2',
		defaultMessage: 'Start your 30-day free trial of Calm Premium.',
		description: 'Heading for 30 day trial',
	},
	offerWithDiscountHeading: {
		id: 'spotify.payment.offerWithDiscountHeading',
		defaultMessage: 'Get an extended free trial and 50% off your first year.',
		description: 'Heading for 30 day trial and discount',
	},
	directSubOfferHeading: {
		id: 'spotify.payment.directSubOfferHeading',
		defaultMessage: 'Get Calm Premium.',
		description: 'Heading for users who are not eligible for trial',
	},
	valueProp1Head: {
		id: 'spotify.payment.valueprop1.header',
		defaultMessage: 'Get in-the-moment relief from stress and anxiety',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp1Body: {
		id: 'spotify.payment.valueprop1.body',
		defaultMessage: 'Expert-led sessions and grounding exercises to quickly calm your mind',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp2Head: {
		id: 'spotify.payment.valueprop2.header',
		defaultMessage: 'Fall (and stay) asleep naturally',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp2Body: {
		id: 'spotify.payment.valueprop2.body',
		defaultMessage: 'Sleep Stories and meditations designed to keep you relaxed through the night',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp3Head: {
		id: 'spotify.payment.valueprop3.header',
		defaultMessage: 'Learn with guidance from the best',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp3Body: {
		id: 'spotify.payment.valueprop3.body',
		defaultMessage: 'Our instructors make mindfulness easy and welcoming, even for beginners',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	limitedTimeCallout: {
		id: 'freeTrialPlans.planPicker.freeTrial.banner',
		defaultMessage: 'Limited Time Offer',
		description: 'Free trial banner',
	},
	spotifyShortTerms: {
		id: 'spotify.payment.terms.short',
		defaultMessage:
			'{amount} days free, then {monthly}/month, billed at {yearly} for your first year. <cancelAnytimeLink>Cancel anytime.</cancelAnytimeLink>',
		description: 'A line to inform the user of the terms of their trial',
	},
});

export default messages;
