import getUrl from '@/utils/getUrl';

export const CALM_ONE_LINKS = {
	login: `https://calm.onelink.me/314175158?af_dp=calm://login&af_web_dp=${getUrl('www')}/login&c=&pid=`,
	app: `https://calm.onelink.me/314175158?af_dp=calm://&af_web_dp=${getUrl('www')}/app`,
	homepage: `https://calm.onelink.me/314175158?af_dp=calm://&af_web_dp=${getUrl('www')}/&c=&pid=`,
	checkin: `https://calm.onelink.me/314175158?af_dp=calm://home&af_web_dp=${getUrl('www')}/app`,
	lebron: `https://calm.onelink.me/314175158?af_dp=calm://program/Kl8gZDaKoW&af_web_dp=${getUrl(
		'www',
	)}/app/program/Kl8gZDaKoW`,
	sevenDaysOfCalm: `https://calm.onelink.me/314175158?af_dp=calm://program/qK3IeqhiJP&af_web_dp=${getUrl(
		'www',
	)}/app/program/qK3IeqhiJP`,
	wonder: `https://calm.onelink.me/314175158?af_dp=calm://program/v783WD8Oq&af_web_dp=${getUrl(
		'www',
	)}/app/program/v783WD8Oq`,
	dailyCalm: `https://calm.onelink.me/314175158?af_dp=calm://daily-calm&af_web_dp=${getUrl(
		'www',
	)}/app/meditate`,
};
