import styled from 'styled-components';
import { palette } from 'styled-tools';

import { IconButton } from '@calm-web/design-system';

import helpFilled from 'icons/help-filled.svg';

export const RowWrapper = styled.div`
	display: grid;
	grid-column-gap: 8px;
	grid-template-columns: 1fr 1fr;
`;

export const CvcWrapper = styled.div`
	position: relative;
`;

export const HelpButton = styled(IconButton).attrs({
	tabIndex: 0,
})`
	all: unset;
	cursor: pointer;
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(calc(-50% - 8px));
	z-index: 1;
`;

export const HelpIcon = styled(helpFilled)`
	height: 24px;
	width: 24px;
	&&& path {
		fill: ${palette('inputIcon')};
	}
`;
