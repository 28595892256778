import styled from 'styled-components';

export const Wrapper = styled.div<{
	isEntering: boolean;
	isExiting?: boolean;
	className?: string;
}>`
	display: flex;
	flex-direction: column;
	flex: 1;
	opacity: ${(props): number => (!props.isEntering && !props.isExiting ? 1 : 0)};
	transition: 250ms ease-out;
`;
