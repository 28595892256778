import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { IconButton, minWidth } from '@calm-web/design-system';

import { TransportProps } from '@/components/app/contentPlayer/Transport/styles';

export const Wrapper = styled.div<{
	$alignment?: string;
	$width: string;
	$sizeTheme?: 'small' | 'large';
	$isPrimary?: boolean;
}>`
	flex: 0 0 ${p => (p.$sizeTheme === 'large' ? p.$width : '100%')};
	text-align: ${p => p.$alignment ?? 'center'};
	width: ${p => (p.$sizeTheme === 'large' ? p.$width : '100%')};

	${minWidth('tablet')} {
		flex: 0 0 ${p => p.$width};
		width: ${p => p.$width};
	}

	height: 48px;
	${minWidth('tablet')} {
		height: ${p => (p.$isPrimary && p.$sizeTheme === 'large' ? '60px' : '48px')};
	}
	margin-bottom: 5.5px;
`;

export const StyledIconButton = styled(IconButton)<TransportProps>`
	&& {
		padding: 0;

		& > div,
		svg {
			height: ${p => {
				if (p.$isPrimary) {
					return p.$sizeTheme === 'large' ? '48px' : '24px';
				}
				return p.$sizeTheme === 'large' ? '24px' : '16px';
			}};
			width: auto;
		}

		${minWidth('tablet')} {
			& > div,
			svg {
				height: ${p => {
					if (p.$isPrimary) {
						return p.$sizeTheme === 'large' ? '60px' : '36px';
					}
					return p.$sizeTheme === 'large' ? '36px' : '24px';
				}};
			}
		}
	}
`;

export const TransportIconSpacer = styled.div<{ $width?: string; $isWebAppRedesign?: boolean }>`
	flex: 0 0 ${p => p.$width ?? '20%'};
	width: ${p => p.$width ?? '20%'};

	${ifProp(
		'$isWebAppRedesign',
		css`
			${minWidth('tablet')} {
				display: none;
			}
		`,
	)}
`;
