import styled, { css } from 'styled-components';
import { palette, ifProp } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const Wrapper = styled.div<{ isTeams: boolean }>`
	${ifProp(
		'isTeams',
		css`
			padding: ${units(2)};
		`,
	)}
`;

export const SadFaceContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: ${units(3.5)};
	margin-top: ${units(1.125)};
	svg {
		fill: ${palette('adaptiveText', 'white')};
		width: 80px;
		height: 80px;
	}
`;
