import styled from 'styled-components';
import { palette } from 'styled-tools';

export const Wrapper = styled.div`
	color: ${palette('gray7')};
	text-align: center;
`;

export const HeadingWrapper = styled.div`
	display: grid;
	grid-row-gap: 0.5rem;
	margin-bottom: 2rem;
`;

export const SocialSignupWrapper = styled.ul`
	list-style-type: none;
	padding: 0;
`;
