import { FormEvent, useRef } from 'react';
import { useIntl } from 'react-intl';

import { FormInput } from '@calm-web/design-system';

import ForgotPassword from '@/components/authForm/ForgotPassword';
import { useFormStateContext } from '@/context/FormStateContextProvider';
import { FORM_ACTIONS, FORM_STATES, useAuthSubmit } from '@/hooks/forms';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import { useShareToken } from '@/hooks/share/useShareToken';
import { useAuthFormModeState, useRecaptchaVisibleState } from '@/hooks/store';
import messages from '@/messages/messages';
import { getIsFormSubmittable } from '@/utils/forms/getIsFormSubmittable';

import { FormInput as FormInputType, loginFormInputs, signupFormInputs } from './data';
import ariaMessages from './messages';
import { SubmitButton } from './styles';
import { LoginSignupFormProps } from './types';

const LoginSignupForm = ({
	inputActions,
	inputFields,
	onAuthSuccess,
	buttonText,
	customPlaceholders,
	isCollege,
	marketingOptIn,
	isB2B,
	showInputIcons,
	lightText = false,
	buttonBackgroundColor = 'buttonBlue',
	source,
}: LoginSignupFormProps) => {
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();
	const recaptchaVisible = useRecaptchaVisibleState();
	const { formDispatch, setFormError, setFormSuccess, formState } = useFormStateContext();
	const onAuthSubmit = useAuthSubmit({ source });
	const shareToken = useShareToken();
	const formRef = useRef<HTMLFormElement>(null);
	const guestPassCode = useGuestPassRequestCode();

	const activeInputs = authFormMode === 'login' ? loginFormInputs : signupFormInputs;
	if (isB2B) {
		const emailField = activeInputs.filter(({ name }) => name === 'email')[0];
		if (emailField) {
			emailField.placeholder = messages.personalEmailPlaceholder;
		}
	}

	const isFormSubmittable = getIsFormSubmittable(activeInputs.map(({ name }) => inputFields[name]));

	async function onSubmit(): Promise<void> {
		if (isFormSubmittable && authFormMode) {
			formDispatch({ type: FORM_ACTIONS.requestSent });

			const values = {
				is_college: isCollege,
				email: inputFields.email.value.trim(),
				password: inputFields.password.value,
				guest_pass_code: guestPassCode,
			};
			const data = await onAuthSubmit(
				// This is a little gnarly just to enforce correct types, sorry
				authFormMode === 'signup'
					? {
							authFormMode: 'signup',
							values: {
								...values,
								name: inputFields.name.value.trim(),
								marketing_opt_in: marketingOptIn,
								share_token: shareToken,
								skip_free_access: true,
							},
					  }
					: { authFormMode: 'login', values },
			);

			if (data) {
				if ('success' in data) {
					formDispatch({ type: FORM_ACTIONS.requestSuccess });
					if (setFormSuccess) {
						const successMessage =
							authFormMode === 'login' ? ariaMessages.ariaLoginLabel : ariaMessages.ariaSignupLabel;
						setFormSuccess(formatMessage(successMessage));
					}
					if (onAuthSuccess) {
						onAuthSuccess(data.success);
					}
				} else if ('error' in data) {
					setFormError(data.error);
					formDispatch({ type: FORM_ACTIONS.requestFailure });

					// Focus first input on failure
					const firstFormInput = formRef?.current?.querySelector('input');
					if (firstFormInput) {
						firstFormInput.focus();
					}
				}
			} else {
				setFormSuccess(null);
				setFormError(null);
				formDispatch({ type: FORM_ACTIONS.requestCancelled });
			}
		}
	}

	const getAutoCompleteAttribute = (name: FormInputType['name']): { autoComplete: string } | undefined => {
		switch (name) {
			case 'name':
			case 'email':
				return { autoComplete: name };
			default:
				return;
		}
	};

	return (
		<form
			ref={formRef}
			data-testid="login-form"
			onSubmit={async (e: FormEvent) => {
				e.preventDefault();
				await onSubmit();
			}}
		>
			{activeInputs.map(input => (
				<FormInput
					Icon={showInputIcons ? inputFields[input.name]?.Icon : undefined}
					key={input.name}
					name={input.name}
					value={inputFields[input.name].value}
					isValid={inputFields[input.name].isValid}
					label={
						customPlaceholders && customPlaceholders[input.name]
							? (customPlaceholders[input.name] as string)
							: formatMessage(input.placeholder)
					}
					type={input.type}
					error={inputFields[input.name].errorMessage}
					onBlur={inputActions.onBlur}
					onChange={inputActions.onChange}
					onFocus={inputActions.onFocus}
					required={input.required}
					backgroundColor={lightText ? 'transparent' : undefined}
					textColor={lightText ? 'white' : undefined}
					{...getAutoCompleteAttribute(input.name)}
				/>
			))}
			{authFormMode === 'login' && (
				<ForgotPassword isLightLeftAlignStyles={isB2B} inputFields={inputFields} lightText={lightText} />
			)}

			<SubmitButton
				fullWidth
				backgroundColor={buttonBackgroundColor}
				isDisabled={!isFormSubmittable || recaptchaVisible}
				isLoading={formState === FORM_STATES.loading}
				type="submit"
				data-testid="auth-form-button"
			>
				{buttonText || formatMessage(messages.continue)}
			</SubmitButton>
		</form>
	);
};

export default LoginSignupForm;
