import { Prices } from '@/store/prices/types';

const isValidLifetimeCoupon = (prices?: Prices | null): boolean => {
	if (!prices) {
		return false;
	}

	return (
		typeof prices?.coupons?.lifetime?.percent_off === 'number' && prices?.coupons?.lifetime?.percent_off > 0
	);
};

export default isValidLifetimeCoupon;
