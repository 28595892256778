import { AnyAction } from 'redux';

import { FeatureFlags, SET_FEATURE_FLAGS } from './types';

const initialState: FeatureFlags = {};

export function featureFlagsReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): FeatureFlags {
	switch (action.type) {
		case SET_FEATURE_FLAGS:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
