import { lazy, Suspense } from 'react';

import { Loader } from '@calm-web/design-system';

import { Wrapper } from '../VideoOverlay/styles';
import { VideoOverlayProps } from '../VideoOverlay/types';

const VideoOverlay = lazy(() => import('@/components/ui/VideoOverlay'));

const LazyVideoOverlay = (props: VideoOverlayProps) => {
	return (
		<Suspense
			fallback={
				<Wrapper>
					<Loader color="white" />
				</Wrapper>
			}
		>
			<VideoOverlay {...props} />
		</Suspense>
	);
};

export default LazyVideoOverlay;
