import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'loader.title',
		defaultMessage: 'Loading spinner',
		description: 'Title for screen reader that a loader is displaying',
	},
	description: {
		id: 'loader.description',
		defaultMessage: 'Two circles spinning around',
		description: 'Description for screen reader of what the loader looks like',
	},
	announcement: {
		id: 'loader.announcement',
		defaultMessage: 'Loading',
		description: 'Description for screen reader of the state that this component represents',
	},
});
