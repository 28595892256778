import { datadogRum } from '@datadog/browser-rum';

export const initDatadogRum = (): void => {
	datadogRum.init({
		applicationId: '2eda852e-f548-4799-b949-c4dc88df9390',
		clientToken: 'pub765f9f1e73096b3302d050f96722d77a',
		site: 'datadoghq.com',
		service: 'web-www',
		version: process.env.BUILD_ID,
		env: process.env.NEXT_PUBLIC_LOG_ENV,
		sessionSampleRate: 100,
		trackUserInteractions: true,
		beforeSend: event => {
			// this is so password-reset tokens in the URL are not sent to Datadog RUM
			// we should be able to remove this once we have migrated to Auth0
			event.view.url = event.view.url.replace(/\/password-reset\/.+/, '/password-reset/REDACTED');

			// true means the event should not be discarded. false would mean discard the event
			return true;
		},
	});
};
