import { EffectCallback, useEffect, useRef } from 'react';

// This should only be used in rare cases where we absolutely want the function firing only once.
// Almost always, this is only appropriate for logEvent cases, where we want to capture the state on initial render.
// Otherwise, you want the real useEffect with proper dependencies.
export const useComponentDidMount = (effect: EffectCallback): void => {
	const ref = useRef(effect);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(ref.current, []);
};
