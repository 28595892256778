import styled from 'styled-components';
import { palette } from 'styled-tools';

import { PaletteColor, fontWeight, FontWeights } from '@calm-web/design-system';

import CircleCheck from 'icons/circle-check.svg';

export const Wrapper = styled.p<{
	$color?: PaletteColor;
	$textColor?: PaletteColor;
}>`
	align-items: flex-start;
	color: ${p => palette(p.$textColor ?? 'gray8')};
	display: flex;
	${fontWeight(FontWeights.Regular)};
	margin-top: 8px;
	text-align: left;
	width: 100%;

	& svg path {
		fill: ${p => palette(p.$color || 'accessibleBrandGradient')};
	}
`;

export const Icon = styled(CircleCheck)`
	flex: 0 0 24px;
	height: 24px;
	margin-right: 24px;
	position: relative;
	top: 3px;
	width: 24px;
`;
