import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import Loader from '@/components/Loader';
import { FormStateContext } from '@/context/FormStateContextProvider';
import { useAuth } from '@/hooks/auth/useAuth';
import { FORM_ACTIONS } from '@/hooks/forms';
import { useAppleSubmit } from '@/hooks/forms/useAppleSubmit';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import { useAuthFormModeState } from '@/hooks/store/useAppState';
import { useIsTeamsNativeApp } from '@/utils/app/msftTeams';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import AppleAuthButtonTeams from './AppleAuthButtonTeams';
import messages from './messages';
import { Icon, Wrapper } from './styles';
import { AppleAuthButtonProps, AppleButtonProps } from './types';

const AppleButton = ({ contentAlignment, hideMessage = false, onClick }: AppleButtonProps) => {
	const { isAuth0Enabled } = useAuth();
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();

	const getButtonCta = () => {
		if (isAuth0Enabled) {
			return authFormMode === 'login'
				? formatMessage(messages.appleSignInCta)
				: formatMessage(messages.appleContinueCta);
		}

		return formatMessage(messages.appleContinueCta);
	};

	const getContentAlignment = () => {
		if (isAuth0Enabled) {
			return 'center';
		}

		return contentAlignment ?? hideMessage ? 'center' : 'left';
	};

	const buttonCta = getButtonCta();
	const _contentAlignment = getContentAlignment();
	const bgColor = isAuth0Enabled ? 'white' : 'black';
	const textColor = isAuth0Enabled ? 'black' : 'white';
	const borderColor = isAuth0Enabled ? 'gray3' : 'white';

	return (
		<Wrapper
			backgroundColor={bgColor}
			contentAlignment={_contentAlignment}
			onPress={onClick}
			data-testid="apple-button"
			role="link"
			$noPadding={hideMessage}
			textColor={textColor}
			borderColor={borderColor}
		>
			<Icon $noMargin={hideMessage} $iconColor={textColor} />
			{hideMessage ? <VisuallyHidden>{buttonCta}</VisuallyHidden> : buttonCta}
		</Wrapper>
	);
};

/**
 * @deprecated This will be removed when auth0 migration is completed in favor of AppleAuthButtonBrowser.
 */
const AppleAuthButtonBrowserDeprecated = ({
	onAuthSuccess,
	marketingOptIn,
	contentAlignment,
	hideMessage = false,
}: AppleAuthButtonProps) => {
	const { formDispatch, setFormError, setFormSuccess } = useContext(FormStateContext);
	const onAppleSubmit = useAppleSubmit();
	const guestPassCode = useGuestPassRequestCode();

	async function onClick(): Promise<void> {
		formDispatch({ type: FORM_ACTIONS.requestSent });

		const data = await onAppleSubmit(marketingOptIn, guestPassCode);

		if (data) {
			if ('success' in data) {
				formDispatch({ type: FORM_ACTIONS.requestSuccess });
				if (onAuthSuccess) {
					onAuthSuccess(data.success);
				}
			} else if ('error' in data) {
				setFormError(data.error);
				formDispatch({ type: FORM_ACTIONS.requestFailure });
			}
		} else {
			setFormSuccess(null);
			setFormError(null);
			formDispatch({ type: FORM_ACTIONS.requestCancelled });
		}
	}

	return <AppleButton contentAlignment={contentAlignment} hideMessage={hideMessage} onClick={onClick} />;
};

const AppleAuthButtonBrowser = (props: AppleAuthButtonProps) => {
	const { contentAlignment, hideMessage, onAuthSuccess, marketingOptIn } = props;

	const { authenticate, isAuth0Enabled } = useAuth();

	if (!isAuth0Enabled) {
		return <AppleAuthButtonBrowserDeprecated {...props} />;
	}

	const onClick = async (): Promise<void> => {
		authenticate({ authMethod: CalmAuthMethods.Apple, marketingOptIn, onAuthSuccess });
	};

	return <AppleButton contentAlignment={contentAlignment} hideMessage={hideMessage} onClick={onClick} />;
};

function AppleAuthButton(props: AppleAuthButtonProps) {
	const { isTeamsNativeApp, isLoading: isTeamsLoading } = useIsTeamsNativeApp();
	if (isTeamsLoading) {
		return <Loader color="gray1" />;
	}
	if (isTeamsNativeApp) {
		return <AppleAuthButtonTeams {...props} />;
	}
	return <AppleAuthButtonBrowser {...props} />;
}

export default AppleAuthButton;
