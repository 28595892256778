import { useIntl } from 'react-intl';

import { Mail } from '@calm-web/icons';

import { useAuth } from '@/hooks/auth/useAuth';
import { useAuthFormModeState } from '@/hooks/store/useAppState';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import messages from './messages';
import { Wrapper } from './styles';
import { EmailAuthButtonProps } from './types';

const EmailAuthButton = ({ marketingOptIn, onAuthSuccess, buttonCTA }: EmailAuthButtonProps) => {
	const { authenticate } = useAuth();
	const { isAuth0Enabled } = useAuth();
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();

	const getButtonCta = () => {
		if (buttonCTA) {
			return formatMessage(buttonCTA);
		}
		if (isAuth0Enabled) {
			return authFormMode === 'login'
				? formatMessage(messages.emailSignInCta)
				: formatMessage(messages.emailContinueCta);
		}

		return formatMessage(messages.emailContinueCta);
	};

	const handleClick = () => {
		authenticate({ authMethod: CalmAuthMethods.Email, marketingOptIn, onAuthSuccess });
	};

	const buttonCta = getButtonCta();

	return (
		<Wrapper
			$noPadding={false}
			backgroundColor="blue7"
			borderColor="blue7"
			textColor="white"
			contentAlignment="center"
			data-testid="email-button"
			Icon={Mail}
			onPress={handleClick}
			role="link"
		>
			{buttonCta}
		</Wrapper>
	);
};

export default EmailAuthButton;
