import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights } from '@calm-web/design-system';

export const Icon = styled.div`
	align-items: center;
	border-radius: 50%;
	display: flex;
	flex: 0 0 48px;
	height: 48px;
	justify-content: center;
	position: relative;
	width: 48px;

	& svg {
		height: 24px;
		width: 24px;

		& path {
			fill: ${palette('white')};
		}
	}
`;

export const Label = styled.div`
	color: ${palette('white')};
	font-size: 18px;
	margin-left: 12px;
`;

export const Wrapper = styled.a<{
	isActive: boolean;
}>`
	align-items: center;
	display: flex;
	margin-bottom: 20px;
	width: 100%;
	text-decoration: none;

	${Label} {
		${p => fontWeight(p.isActive ? FontWeights.Demi : FontWeights.Regular)}
	}
`;
