import { defineMessages } from 'react-intl';

export default defineMessages({
	label: {
		id: 'copyLinkField.label',
		defaultMessage: 'Click link to copy',
		description: 'Label instructing user to click the input field to copy the link',
	},
	successNotification: {
		id: 'copyLinkField.successNotification',
		defaultMessage: 'Link copied',
		description: 'The notification that appears when a user copies the link',
	},
});
