import { ReactNode } from 'react';

import QRCodeHandoffContextProvider from '@/components/QRCodeHandoff/QRCodeHandoffContext';
import MediaPlayerProvider, { MediaPlayerContext } from '@/components/app/contentPlayer/MediaPlayerProvider';
import SceneProvider from '@/components/app/scenes/SceneProvider';
import ShareProvider from '@/components/app/shareFreeContent/ShareProvider';
import AuthModalContextProvider from '@/context/AuthModalContextProvider';
import SimplifiedSignupModalContextProvider from '@/context/SimplifiedSignupModalContextProvider';
import { useThemeState } from '@/hooks/store';

import PacksProvider from '../PacksProvider';

const AppProviders = ({ children }: { children?: ReactNode }) => {
	const theme = useThemeState();

	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!theme?.webAppLayout) return <>{children}</>;

	return (
		<PacksProvider>
			<ShareProvider>
				<MediaPlayerProvider>
					<MediaPlayerContext.Consumer>
						{({ playerMode, contentType }) => (
							<SceneProvider playerMode={playerMode} contentType={contentType}>
								<AuthModalContextProvider>
									<SimplifiedSignupModalContextProvider>
										<QRCodeHandoffContextProvider>{children}</QRCodeHandoffContextProvider>
									</SimplifiedSignupModalContextProvider>
								</AuthModalContextProvider>
							</SceneProvider>
						)}
					</MediaPlayerContext.Consumer>
				</MediaPlayerProvider>
			</ShareProvider>
		</PacksProvider>
	);
};

export default AppProviders;
