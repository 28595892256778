import { defineMessages } from 'react-intl';

export default defineMessages({
	loginToggle: {
		id: 'login.loginToggleText',
		defaultMessage: "Don't have an account?",
		description:
			"Text shown on the login form with the text 'Sign up' right after it to switch to the signup form",
	},
	b2bLoginToggle: {
		id: 'login.b2bLoginToggleText',
		defaultMessage: 'New to Calm?',
		description:
			"Text shown on the login form with the text 'Sign up' right after it to switch to the signup form",
	},
	signupToggle: {
		id: 'login.signupToggleText',
		defaultMessage: 'Already have an account?',
		description:
			"Text shown on the signup form with the text 'Log in' right after to switch to the login form",
	},
	b2bSignupToggle: {
		id: 'login.b2bSignupToggleText',
		defaultMessage: 'Have an account?',
		description:
			"Text shown on the signup form with the text 'Log in' right after to switch to the login form",
	},
});
