import styled from 'styled-components';
import { palette } from 'styled-tools';

export const Wrapper = styled.div`
	color: ${palette('white')};
	justify-content: space-between;
	margin: 0 auto;
	max-width: 480px;
	text-align: center;
`;

export const Title = styled.h4`
	font-weight: 500;
	margin-bottom: 24px;
`;

export const TrialText = styled.div`
	font-size: 13.5px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: 48px;
`;
