import { defineMessages } from 'react-intl';

export default defineMessages({
	confirmPlan: {
		id: 'purchase.sku.toggle.header',
		defaultMessage: 'Confirm Your Plan',
		description: 'Instructions for the user to select which plan they want',
	},
	confirmSubtitle: {
		id: 'purchase.sku.toggle.subheader',
		defaultMessage: "Don't worry, you can cancel at any time.",
		description: 'Instructions informing the user they can cancel their sub',
	},
	totalDue: {
		id: 'creditCardForm.totalDue',
		defaultMessage: 'Total due today',
		description: "A line which adds up a user's purchase total",
	},
	terms: {
		id: 'purchase.sku.toggle.terms',
		defaultMessage:
			'{amount} days free, then {monthly}/month, billed annually at {yearly}/year. <cancelAnytimeLink>Cancel anytime.</cancelAnytimeLink>',
		description: 'A line to inform the user of the terms of their trial',
	},
});
