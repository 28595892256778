import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

import { Button, buttonNormalize, CalmTheme } from '@calm-web/design-system';

import { HAMBURGER_INDEX } from '@/utils/ui/zIndices';

export const Line = styled.div`
	border-radius: 2.5px;
	content: '';
	height: 2.5px;
	position: absolute;
	width: 32px;
`;

const lineOpenTransformation = css`
	&:nth-child(1) {
		transform: translate3d(0, 0, 0) rotate(45deg);
	}

	&:nth-child(2) {
		opacity: 0;
		transform: translate3d(-100%, 0, 0) scale(0);
	}

	&:nth-child(3) {
		transform: translate3d(0, 0, 0) rotate(-45deg);
	}
`;

export const Wrapper = styled(Button).attrs({
	backgroundColor: 'transparent',
})<{
	isDark: boolean;
	isOpen: boolean;
}>`
	&&& {
		${buttonNormalize}
		${p => `z-index: ${p.isOpen ? (p.theme as typeof CalmTheme).modal.zIndex + 1 : HAMBURGER_INDEX}`};

		& ${Line} {
			background: ${p => (p.isDark || p.isOpen ? '#fff' : '#000')};
			transition: ${theme('animation.transition')};

			&:nth-child(1) {
				transform: translate3d(0, -8px, 0);
			}

			&:nth-child(2) {
				transform: translate3d(0, 0, 0);
			}

			&:nth-child(3) {
				transform: translate3d(0, 8px, 0);
			}

			${p => p.isOpen && lineOpenTransformation}
		}
	}
`;
