import { useDispatch } from 'react-redux';

import { useComplianceLevelState, useThemeState } from '@/hooks/store';
import { IS_GDPR_LIMITED, IS_LIMITED } from '@/store/complianceLevel/types';
import { setUser } from '@/store/user/actions';
import { User } from '@/store/user/types';
import { initSegmentUser } from '@/utils/analytics/segment';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';
import { calmLogger } from '@/utils/calmLogger';
import { postMe } from '@/utils/endpoints';
import { CalmAuthMethods, setUserCookies, setTeamsUserCookies } from '@/utils/privacyCookies';

export const useSetupUserAfterAuth = () => {
	const dispatch = useDispatch();
	const complianceLevel = useComplianceLevelState();
	const theme = useThemeState();
	const { isTeamsApp } = useGetIsTeamsApp();
	const _setUserCookies = isTeamsApp || theme.isTeamsApp ? setTeamsUserCookies : setUserCookies;

	const setupUserAfterAuth = async (user: User, authMethod?: CalmAuthMethods) => {
		_setUserCookies(user, authMethod);
		dispatch(setUser(user));

		await postMe({
			compliance_level: complianceLevel,
			has_opted_in_limited_data_use: complianceLevel === IS_GDPR_LIMITED || complianceLevel === IS_LIMITED,
		}).catch(error => calmLogger.error('Error in useSetupUserAfterAuth postMe', {}, error));

		initSegmentUser(user.id).catch(error =>
			calmLogger.error('Error in useSetupUserAfterAuth initSegmentUser', {}, error),
		);
	};

	return setupUserAfterAuth;
};
