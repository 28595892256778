import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, PaletteColor, Text } from '@calm-web/design-system';

import circleCheck from 'icons/circle-check.svg';
import creditCardIcon from 'icons/credit-card.svg';

export const Heading = styled(Text)`
	color: ${p => (p.theme.isDark ? palette('white') : palette('gray8'))};
	margin: 24px 0;
`;

export const ThirdPartyButtonsWrapper = styled.div<{
	$numColumns: number;
}>`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;

	& > div {
		flex: 1;
		margin-left: 5px;
		margin-right: 5px;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`;

export const CCButton = styled(Button)`
	&& {
		border: 2px ${palette('gray6')} solid;
		margin-top: 10px;
		position: relative;
	}
`;

export const CircleCheck = styled(circleCheck)<{ $fill: PaletteColor }>`
	position: absolute;
	left: 18px;
	height: 18px;
	top: 50%;
	transform: translateY(-50%);
	width: 18px;
	z-index: 9999;

	path {
		fill: ${p => palette(p.$fill)};
	}
`;

export const CCIcon = styled(creditCardIcon)`
	height: 24px;
	margin-right: 10px;
	width: 24px;
`;
