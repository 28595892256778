import { ReactNode } from 'react';

export type Notification = {
	// defaults to 'bottom'
	position?: 'bottom' | 'top';
	// defaults to 'toastBg'
	backgroundColor?: string;
	// defaults to 'iconColor'
	iconColor?: string;
} & (
	| {
			children: ReactNode;
			message?: never;
	  }
	| {
			children?: never;
			message: string;
	  }
);

export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export interface SetNotificationAction {
	type: typeof SET_NOTIFICATION;
	payload: Notification | null;
}

export type NotificationActionTypes = SetNotificationAction;
