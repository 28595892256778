import { DateTime } from 'luxon';

import { useBrowserLanguageState, useUserState } from '@/hooks/store';
import { SubscriptionKeys } from '@/store/user/types';

export function useSubscriptionDate(): {
	expireDate: string | null;
	extendDate: string | null;
	renewDate: string | null;
} {
	const user = useUserState();
	const browserLanguage = useBrowserLanguageState();

	const expireDate = user?.subscription?.expires
		? DateTime.fromISO(user.subscription.expires, {
				locale: browserLanguage ?? undefined,
		  }).toLocaleString(DateTime.DATE_FULL)
		: null;

	const extendDate = DateTime.fromISO(DateTime.local().toString(), {
		locale: browserLanguage ?? undefined,
	})
		.plus({ year: 1 })
		.toLocaleString(DateTime.DATE_FULL);

	const detailsKey: SubscriptionKeys | undefined =
		user?.subscription?.type && `${user?.subscription?.type}_details`;
	const subscriptionDetails = detailsKey && user?.subscription?.[detailsKey];

	const renewDate =
		subscriptionDetails && 'renews_at' in subscriptionDetails
			? DateTime.fromISO(String(subscriptionDetails.renews_at), {
					locale: browserLanguage ?? undefined,
			  }).toLocaleString(DateTime.DATE_FULL)
			: null;

	return { expireDate, extendDate, renewDate };
}
