import { MessageDescriptor } from 'react-intl';

import messages from '@/components/authForm/AuthNotice/messages';

export function parseLoginError(err: {
	didTimeout?: boolean;
	calmCode?: string;
	code?: string;
}): MessageDescriptor {
	if (err?.didTimeout) return messages.timeout;

	switch (err?.code) {
		case 'email_taken':
			return messages.emailTaken;
		case 'email_invalid':
			return messages.emailInvalid;
		case 'password_invalid':
			return messages.passwordInvalid;
		case 'bad_credentials':
			return messages.badCredentials;
		case 'password_too_common':
			return messages.passwordTooCommon;
		case 'password_too_short':
			return messages.passwordShortError;
		case 'no_user':
			return messages.noUser;
		case 'no_email':
			return messages.noEmail;
		default:
			return messages.generic;
	}
}
