import Agent from 'agentkeepalive';

// See: https://github.com/node-modules/agentkeepalive/blob/de66b0206d064a97129c2c31bcdabd4d64557b91/README.md#new-agentoptions
const agentSettings = {
	keepAlive: true,
	freeSocketTimeout: 60 * 1000,
	timeout: 4 * 60 * 1000, // must be greater than LD timeout
};
export const httpAgent = new Agent({ ...agentSettings });
export const httpsAgent = new Agent.HttpsAgent({
	...agentSettings,
	rejectUnauthorized: process.env.NEXT_PUBLIC_CALM_ENV !== 'local',
});

// For debugging purposes
// const reportSingleStat = (desc, stat, agent) => {
//   // if stat exists, but special case 0 falsey value
//   if (agent[stat] || agent[stat] === 0) {
//     console.log(`${desc}.${stat} = ${agent[stat]}`);
//   }
// };

// const reportAllStatsForAgent = (desc, agent) => {
//   const descWithAgent = `${desc}Agent`;
//   reportSingleStat(descWithAgent, 'createSocketCount', agent);
//   reportSingleStat(descWithAgent, 'createSocketErrorCount', agent);
//   reportSingleStat(descWithAgent, 'closeSocketCount', agent);
//   reportSingleStat(descWithAgent, 'errorSocketCount', agent);
//   reportSingleStat(descWithAgent, 'requestCount', agent);
//   reportSingleStat(descWithAgent, 'timeoutSocketCount', agent);
// };

// const reportMetrics = () => {
//   setInterval(() => {
//     reportAllStatsForAgent('http', httpAgent);
//     reportAllStatsForAgent('https', httpsAgent);
//   }, 20 * 1000);
// };

// reportMetrics();
