import { OnGenericPaymentSubmit } from '@/components/purchase/PurchaseForm/types';
import { usePricesState, usePurchaseParamsState } from '@/hooks/store';
import { isValidPlan } from '@/store/purchaseParams/types';
import { isGiftCoupon } from '@/utils/getInitialState/intiGiftPricing';

import { useFreeTrial } from './useFreeTrial';
import { usePurchaseSubmit } from './usePurchaseSubmit';
import { usePurchaseToken } from './usePurchaseToken';

interface UseSubPurchase {
	(): OnGenericPaymentSubmit;
}

export const getCouponToSend = (coupon?: string | null) => {
	if (!coupon) {
		return null;
	}

	if (coupon === 'freetrial') {
		return null;
	}

	if (isGiftCoupon(coupon)) {
		return null;
	}

	return coupon;
};

export const useSubPurchase: UseSubPurchase = () => {
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const purchaseType = purchaseParams.purchaseType;
	const onPurchaseSubmit = usePurchaseSubmit();
	const createTokenObject = usePurchaseToken();
	const userCanTrial = useFreeTrial();

	const submitSubPurchase: OnGenericPaymentSubmit = async ({
		cardInfo,
		userInfo,
		requestInfo,
		token,
		customerAddress,
		plan: _plan = null,
		paymentType = 'credit_card',
		endpoint = 'subscription',
		service = 'api',
		dtc_partnership_details,
	}) => {
		const tokenObject = await createTokenObject({
			paymentType,
			token,
			cardInfo,
			cardData: {
				...userInfo,
				address_state: customerAddress?.region,
				address_zip: customerAddress?.postalCode,
				address_country: customerAddress?.country,
			},
		});

		const plan = _plan ?? purchaseParams.plan;
		const isStandardPlan = isValidPlan(plan);

		const purchaseInfo = {
			...purchaseParams,
			...requestInfo,
			plan,
			coupon: getCouponToSend(purchaseParams.coupon),
			freeTrial:
				(purchaseParams.coupon === 'freetrial' || purchaseParams.purchaseType?.isFreeTrial) && userCanTrial,
			paymentMethod: paymentType === 'paypal' ? 'paypal' : 'stripe',
			payment_method: paymentType === 'paypal' ? 'paypal' : 'stripe',
			...(dtc_partnership_details && dtc_partnership_details),
			customerAddress: {
				postal_code: customerAddress?.postalCode,
				state: customerAddress?.region,
				country: customerAddress?.country,
			},
			...(isStandardPlan && {
				purchaseType: {
					...purchaseParams.purchaseType,
					type: purchaseParams.purchaseType?.isFreeTrial && userCanTrial ? 'freetrial' : plan,
					price: prices?.current[plan] || 0,
					duration: purchaseType?.duration ?? 14,
				},
			}),
			...tokenObject,
		};

		return onPurchaseSubmit({
			purchaseInfo,
			endpoint,
			paymentType,
			service,
		});
	};

	return submitSubPurchase;
};
