import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text, minWidth, fontSize, FontSizes, FontWeights, maxWidth } from '@calm-web/design-system';

import { Wrapper as _ScenesLinkWrapper } from '@/components/app/ui/ScenesLink/styles';
import { Wrapper as _SearchLinkWrapper } from '@/components/app/ui/SearchLink/styles';

export const Wrapper = styled.nav`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 64px;
	background: rgba(0, 0, 0, 0.1);

	${minWidth('desktop')} {
		height: 100px;
	}
`;

export const Container = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding: 32px 0;

	${maxWidth('desktop')} {
		display: none;
	}
`;

export const IconsWrapper = styled.div`
	display: flex;
	padding-top: 7px;
	padding-bottom: 7px;
	padding-right: 24px;
	border-right: 2px solid rgba(255, 255, 255, 0.2);
	margin-right: 24px;

	${_ScenesLinkWrapper} {
		background: ${palette('transparentWhiteBackground')};
	}

	${_SearchLinkWrapper} {
		background: ${palette('transparentWhiteBackground')};
	}
`;

export const PageTitle = styled(Text).attrs({
	color: 'white',
	size: FontSizes.xl,
	weight: FontWeights.Regular,
	noMargin: true,
	el: 'h1',
})`
	${minWidth('mobileLarge')} {
		${fontSize(FontSizes['2xl'])}
	}
`;

export const ButtonWrapper = styled.div`
	margin-right: 40px;

	> button:first-child {
		margin-right: 16px;
	}
`;
