import { defineMessages } from 'react-intl';

const messages = defineMessages({
	validationInputLabel: {
		id: 'partnership.validation.inputLabel',
		defaultMessage: 'Enter your offer code',
		description: 'Partnership Validation Input Label',
	},
	validationInputLabel_AARP: {
		id: 'partnership.validation.inputLabel.aarp',
		defaultMessage: 'Enter your AARP Membership Number',
		description: 'Partnership Validation Input Label for AARP',
	},
	validationInputPlaceholder_AARP: {
		id: 'partnership.validation.inputPlaceholder.aarp',
		defaultMessage: 'Membership Number',
		description: 'Partnership Validation Input Placeholder for AARP',
	},
	validationInputPlaceholder: {
		id: 'partnership.validation.inputPlaceholder',
		defaultMessage: 'Offer code',
		description: 'Partnership Validation Input Placeholder',
	},
	validationError_AARP: {
		id: 'partnership.validation.inputError.aarp2',
		defaultMessage:
			'Unfortunately your AARP member number seems to be invalid. Please try again or call the AARP Member contact center (Monday through Friday, 7 a.m. to 11 p.m. ET) at 1-888-687-2277',
		description: 'Partnership Validation Input Error for AARP',
	},
	validationError: {
		id: 'dtcPartnership.eligibilityEnrollment.errorInvalidOfferCode',
		defaultMessage:
			'This offer code is not valid. Please check for any accidental typos made before trying again. If you are still experiencing trouble, please reach out to {partnerName} for assistance.',
		description: "Indicates the user's offer code is invalid",
	},
	linkToAARP: {
		id: 'partner.aarp.membership.validation',
		defaultMessage: 'Not an AARP Member? <join>Join now</join> or <renew>renew</renew>.',
		description: 'Text indicating the year duration for the offer',
	},
	invalidOfferCodeId: {
		id: 'partner.error.invalidOfferCodeId',
		defaultMessage: 'This offer code is not valid or has already been used.',
		description: 'Error message when the user tries to use an invalid offer code embedded link.',
	},
});

export default messages;
