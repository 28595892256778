import styled from 'styled-components';

import { Button, minWidth } from '@calm-web/design-system';

export const Wrapper = styled(Button)`
	margin-right: 24px;

	${minWidth('desktop')} {
		flex: 0 0 60px;
		margin-right: 0;
		margin-top: 24px;
	}
`;
