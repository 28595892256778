import { defineMessages } from 'react-intl';

const messages = defineMessages({
	valuePropHeader: {
		id: 'spotify.account.valueproplist.header',
		defaultMessage: 'Find your Calm, now on Spotify.',
		description: 'Value props of calm on spotify',
	},
	valueProp1Head: {
		id: 'spotify.account.valueprop1.header',
		defaultMessage: 'Get in-the-moment relief from stress and anxiety',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp1Body: {
		id: 'spotify.account.valueprop1.body',
		defaultMessage: 'Expert-led sessions and grounding exercises to quickly calm your mind',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp2Head: {
		id: 'spotify.account.valueprop2.header',
		defaultMessage: 'Fall (and stay) asleep naturally',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp2Body: {
		id: 'spotify.account.valueprop2.body',
		defaultMessage: 'Sleep Stories and meditations designed to keep you relaxed through the night',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp3Head: {
		id: 'spotify.account.valueprop3.header',
		defaultMessage: 'Learn with guidance from the best',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp3Body: {
		id: 'spotify.account.valueprop3.body',
		defaultMessage: 'Our instructors make mindfulness easy and welcoming, even for beginners',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	signupFormHeading: {
		id: 'spotify.account.signup.heading2',
		defaultMessage: 'Sign up and get a 30-day free trial of Calm Premium.',
		description: 'Header for the authorization form to sign up',
	},
	signupFormSimpleHeading: {
		id: 'account.signup.title.simple',
		defaultMessage: 'Create a free account to start your mindfulness journey',
		description: 'The title for the Calm signup component',
	},
	signupFormOfferHeading: {
		id: 'spotify.account.signup.signupFormOfferHeading',
		defaultMessage: 'Sign up and get an extended free trial and 50% off your first year.',
		description: 'Header for the authorization form to sign up',
	},
	loginFormHeading: {
		id: 'spotify.account.login.heading',
		defaultMessage: 'Log in to link your accounts.',
		description: 'Header for the authorization form to sign up',
	},
	faqHeading: {
		id: 'spotify.account.faq.heading',
		defaultMessage: 'Frequently Asked Questions',
		description: 'Header for FAQ',
	},
	faq1Question: {
		id: 'spotify.account.faq.q1',
		defaultMessage: "What's the difference between Calm content on Spotify and the Calm app?",
		description: 'A question for the FAQ',
	},
	faq1Answer: {
		id: 'spotify.account.faq.a1a',
		defaultMessage:
			"You can listen to a wide selection of Calm's Sleep Stories, meditations and mindfulness tools on Spotify, available through a variety of unique shows.{br}However, you will need to download the Calm app to access Calm's full range of features and benefits, including 50,000+ minutes of guided content, Sleep Stories with exclusive celebrity voices and fan-favorite characters, and soothing music and soundscapes.{br}The Calm app also offers personalized recommendations plus motivating features like reminders and streaks to help you maintain your progress.",
		description: 'An answer for an FAQ question',
	},
	faq2Question: {
		id: 'spotify.account.faq.q2',
		defaultMessage: 'How often is Calm content updated on Spotify?',
		description: 'A question for the FAQ',
	},
	faq2Answer: {
		id: 'spotify.account.faq.a2',
		defaultMessage:
			"If you're a Calm Premium subscriber, you can access featured Daily Calm, Daily Trip and Daily Jay episodes every day. New episode frequency will vary across Calm’s other shows.{br}If you haven’t yet subscribed to Calm Premium, you can access a featured Daily Calm, Daily Trip or Daily Jay episode weekly. Free episode updates and libraries may vary across Calm’s other shows.",
		description: 'An answer for an FAQ question',
	},
	faq3Question: {
		id: 'spotify.account.faq.q3',
		defaultMessage: "I'm a Spotify Premium subscriber. Can I access Calm Premium content on Spotify?",
		description: 'A question for the FAQ',
	},
	faq3Answer: {
		id: 'spotify.account.faq.a3',
		defaultMessage: 'No, only Calm Premium subscribers can access Calm Premium content on Spotify.',
		description: 'An answer for an FAQ question',
	},
	faq4Question: {
		id: 'spotify.account.faq.q4',
		defaultMessage: 'How do I subscribe to Calm Premium?',
		description: 'A question for the FAQ',
	},
	faq4Answer: {
		id: 'spotify.account.faq.a4',
		defaultMessage:
			'You can subscribe to Calm Premium through the form above, or by visiting <site>calm.com/subscribe.</site>',
		description: 'An answer for an FAQ question',
	},
	faq5Question: {
		id: 'spotify.account.faq.q5',
		defaultMessage: 'I’m already a Calm Premium subscriber. Can I access Calm Premium content on Spotify?',
		description: 'A question for the FAQ',
	},
	faq5Answer: {
		id: 'spotify.account.faq.a5b',
		defaultMessage:
			'Yes, as a Calm Premium subscriber, you can link your Calm account to access all of the content Calm has on Spotify. However, to access the full 50,000+ minute library of Calm content or to record your stats/streaks/history, you must use the Calm app.',
		description: 'An answer for an FAQ question',
	},
	faq6Question: {
		id: 'spotify.account.faq.q6',
		defaultMessage: 'How do I link my existing Calm account to Spotify?',
		description: 'A question for the FAQ',
	},
	faq6Answer: {
		id: 'spotify.account.faq.a6',
		defaultMessage: 'Just tap “Log In” above and continue with your Calm credentials to link your account.',
		description: 'An answer for an FAQ question',
	},
	faq7Question: {
		id: 'spotify.account.faq.q7',
		defaultMessage:
			'Will listening to Calm content on Spotify count toward my stats, streaks and history on the Calm app?',
		description: 'A question for the FAQ',
	},
	faq7Answer: {
		id: 'spotify.account.faq.a7',
		defaultMessage:
			'No, your activity with Calm content on Spotify will not count toward your personal profile data on the Calm app.',
		description: 'An answer for an FAQ question',
	},
});

export default messages;
