import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ContentType, PlayerMode } from '@/components/app/contentPlayer/MediaPlayerProvider/types';
import { PacksContext } from '@/components/app/layout/PacksProvider';
import { calmLogger } from '@/utils/calmLogger';

import { Scene } from './useSelectedScene';

const SCENE_VOLUME_KEY = 'sceneVolumeKey';

const typesToPauseOn = new Set([
	'sleep',
	'music',
	'wisdom',
	'body',
	'movement',
	'soundscape',
	'meditation_with_music',
]);

export const useSelectedSceneAudio = ({
	selectedScene,
	playerMode,
	contentType,
}: {
	selectedScene?: Scene;
	playerMode: PlayerMode;
	contentType: ContentType;
}) => {
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [sceneVolume, setSceneVolume] = useState(0);
	const { currentProgram } = useContext(PacksContext);

	const meditationType = currentProgram?.meditation_type;
	const shouldPauseSceneAudio =
		meditationType &&
		(typesToPauseOn.has(meditationType) || contentType === 'video') &&
		playerMode === 'playing';

	const onPlay = useCallback(async () => {
		if (!audioRef.current) return;

		try {
			if (audioRef.current.paused) {
				await audioRef.current.play();
			}
		} catch (err) {
			// this is likely going to fail on first load
			// browsers don't allow audio to autoplay
		}
	}, []);

	const onPause = useCallback(() => {
		if (!audioRef.current) return;

		audioRef.current.pause();
	}, []);

	// Play or pause audio on playerMode change
	useEffect(() => {
		if (shouldPauseSceneAudio) {
			onPause();
		} else {
			onPlay().catch(error => calmLogger.error('Error in useSelectedSceneAudio onPlay', {}, error));
		}
	}, [onPause, onPlay, shouldPauseSceneAudio]);

	// Once client loads, set scene volume
	useEffect(() => {
		const initialSceneVolume = localStorage.getItem(SCENE_VOLUME_KEY)
			? Number(localStorage.getItem(SCENE_VOLUME_KEY))
			: 1;
		setSceneVolume(initialSceneVolume);
	}, []);

	// Update audioRef volume on volume change
	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.volume = sceneVolume;
			localStorage.setItem(SCENE_VOLUME_KEY, sceneVolume.toString());
		}
	}, [sceneVolume]);

	// Init scene audio
	useEffect(() => {
		const initSceneAudio = async () => {
			onPause();
			audioRef.current = null;
			const sceneAudioUrl = selectedScene?.audio.url;

			audioRef.current = new Audio(sceneAudioUrl);
			audioRef.current.loop = true;
			audioRef.current.volume = Number(localStorage.getItem(SCENE_VOLUME_KEY));

			if (!shouldPauseSceneAudio) {
				await onPlay();
			}
		};

		initSceneAudio().catch(error =>
			calmLogger.error('Error in useSelectedSceneAudio initSceneAudio', {}, error),
		);
	}, [onPause, onPlay, selectedScene?.audio.url, shouldPauseSceneAudio]);

	// Clear scene audio on unmount
	useEffect(() => {
		return () => onPause();
	}, [onPause]);

	return {
		sceneVolume,
		setSceneVolume,
		shouldPauseSceneAudio,
	};
};
