import { defineMessages } from 'react-intl';

export default defineMessages({
	loggedIn: {
		id: 'redesign.stepsFlow.userWrapper.loggedIn2',
		defaultMessage: 'Offer will be applied to the Calm account connected to:',
		description: 'Label showing who the user is logged in as',
	},
	logout: {
		id: 'redesign.stepsFlow.userWrapper.logout',
		defaultMessage: 'Log Out',
		description: 'Button text to log out',
	},
});
