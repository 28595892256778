import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Text, FontSizes } from '@calm-web/design-system';

import { MediaPlayerContext } from '@/components/app/contentPlayer/MediaPlayerProvider';
import { useThemeState, useUserState } from '@/hooks/store';

import { useQRCodeHandoffContext } from './QRCodeHandoffContext';
import messages from './messages';
import { QRCode, CloseButton, HandoffExpandedWrapper, HandoffWrapperWrapper } from './styles';

export const QRCodeHandoffExpanded = () => {
	const { formatMessage } = useIntl();
	const { contentType, playerMode } = useContext(MediaPlayerContext);
	const { setIsHandoffOpen, isHandoffOpen } = useQRCodeHandoffContext();
	const user = useUserState();
	const theme = useThemeState();
	function onDismiss(): void {
		setIsHandoffOpen(false);
	}

	const miniPlayerOpen =
		theme.webAppLayout !== 'player' &&
		contentType === 'audio' &&
		(playerMode === 'paused' || playerMode === 'playing');

	return (
		<HandoffWrapperWrapper isOpen={isHandoffOpen} audioPlayerAllowance={miniPlayerOpen ? '98px' : '0'}>
			<HandoffExpandedWrapper isOpen={isHandoffOpen}>
				<CloseButton onPress={onDismiss} aria-label={formatMessage(messages.close)} color="black" />
				<Text size={FontSizes.sm} align="center">
					{' '}
					{formatMessage(messages.handoffText)}
				</Text>
				<QRCode
					src={user?.subscription ? '/_n/images/handoff_no_ftue.png' : '/_n/images/handoff_ftue.png'}
					alt={formatMessage(messages.handoffText)}
				/>
			</HandoffExpandedWrapper>
		</HandoffWrapperWrapper>
	);
};
