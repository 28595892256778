import { useDeviceState } from '@/hooks/store/useAppState';

export const GDPRCountries = [
	'at',
	'be',
	'bg',
	'cy',
	'cz',
	'de',
	'dk',
	'ee',
	'es',
	'fi',
	'fr',
	'gr',
	'hr',
	'hu',
	'ie',
	'it',
	'lt',
	'lu',
	'lv',
	'mt',
	'nl',
	'pl',
	'pt',
	'ro',
	'se',
	'si',
	'sk',
];

export function useIsGDPRDevice(): boolean {
	const { ip_country: ipCountry } = useDeviceState();

	if (!ipCountry) {
		return false;
	}

	if (!GDPRCountries.includes(ipCountry)) {
		return false;
	}
	return true;
}
