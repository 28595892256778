import styled from 'styled-components';

export const BrandingWrapper = styled.div<{
	alignment?: 'left' | 'center';
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${p => (p.alignment === 'left' ? '100%' : '80%')};
	margin: auto;
	margin-bottom: 36px;
	text-align: ${p => (p.alignment === 'left' ? p.alignment : 'center')};

	img {
		max-height: ${p => (p.alignment === 'left' ? '40px' : '50px')};
	}
`;

export const ImageContainer = styled.div`
	height: 50px;
	width: 100%;
	position: relative;
`;
