import { authentication } from '@microsoft/teams-js';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useIntl } from 'react-intl';

import { useFormStateContext } from '@/context/FormStateContextProvider';
import { FORM_ACTIONS } from '@/hooks/forms';
import { useAuthAppleUser } from '@/hooks/forms/useAppleSubmit';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import getUrl from '@/utils/getUrl';

import messages from './messages';
import { Icon, Wrapper } from './styles';
import { AppleAuthButtonProps } from './types';

const redirectUri = `${getUrl('www')}/teams-calm/apple-auth-end`;

export default function AppleAuthButtonTeams({
	onAuthSuccess,
	marketingOptIn,
	contentAlignment,
	hideMessage = false,
}: AppleAuthButtonProps) {
	const { formatMessage } = useIntl();
	const { formDispatch, setFormError, setFormSuccess } = useFormStateContext();
	const authUser = useAuthAppleUser(redirectUri);
	const guestPassCode = useGuestPassRequestCode();

	async function onClick(): Promise<void> {
		formDispatch({ type: FORM_ACTIONS.requestSent });
		const url = `${getUrl('www')}/teams-calm/apple-auth-end`;

		return authentication
			.authenticate({
				url: `${getUrl(
					'www',
				)}/teams-calm/apple-auth-start?oauthRedirectMethod={oauthRedirectMethod}&authId={authId}&hostRedirectUrl=${url}`,
				isExternal: true,
			})
			.then(async response => {
				const decoded = atob(response);
				const { code, user } = JSON.parse(decoded);
				const data = await authUser({ user: user, marketingOptIn, code: code, guestPassCode });

				if (!data) {
					setFormSuccess(null);
					setFormError(null);
					formDispatch({ type: FORM_ACTIONS.requestCancelled });
				}

				if ('success' in data) {
					formDispatch({ type: FORM_ACTIONS.requestSuccess });
					if (onAuthSuccess) {
						onAuthSuccess(data.success);
					}
				} else if ('error' in data) {
					setFormError(data.error);
					formDispatch({ type: FORM_ACTIONS.requestFailure });
				}
			});
	}

	const buttonCta = formatMessage(messages.appleContinueCta);

	return (
		<Wrapper
			backgroundColor="black"
			contentAlignment={contentAlignment ?? hideMessage ? 'center' : 'left'}
			onPress={onClick}
			data-testid="apple-button"
			role="link"
			$noPadding={hideMessage}
		>
			<Icon $noMargin={hideMessage} />
			{hideMessage ? <VisuallyHidden>{buttonCta}</VisuallyHidden> : buttonCta}
		</Wrapper>
	);
}
