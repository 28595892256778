import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FormSelect } from '@calm-web/design-system';

import { useLocale } from '@/hooks/locale/useLocale';
import { useDeviceState } from '@/hooks/store';

import { countryCodes } from './data';
import messages from './messages';

const CountrySelectInput = ({
	setCountryCode,
	onBlur,
}: {
	onBlur: (inputName: 'country' | 'postalCode', analyticsName: string) => void;
	setCountryCode: (value: string) => void;
}) => {
	const { formatMessage } = useIntl();
	const device = useDeviceState();
	const { localeState, routerLocale, browserLanguageState } = useLocale();

	const regionNames = new Intl.DisplayNames([localeState ?? routerLocale ?? browserLanguageState ?? 'en'], {
		type: 'region',
	});
	const countries: Record<string, string> = countryCodes.reduce((accu, countryCode) => {
		return {
			...accu,
			[countryCode]: regionNames.of(countryCode),
		};
	}, {});

	const [countryValue, setCountryValue] = useState(
		device.ip_country ? countries[device.ip_country.toUpperCase()] : 'United States',
	);

	const setCountryValueAndCode = (value: string) => {
		setCountryValue(value);
		const [[newCountryCode]] = Object.entries(countries).filter(([, cValue]) => cValue === value);
		setCountryCode(newCountryCode);
	};

	return (
		<FormSelect
			aria-label={formatMessage(messages.countryInput)}
			name="country"
			value={countryValue}
			onChange={e => setCountryValueAndCode(e.currentTarget.value)}
			options={Object.values(countries).sort()}
			onBlur={() => onBlur('country', 'Country')}
		/>
	);
};

export default CountrySelectInput;
