export * from './getDevice';
export * from './getDependents';
export * from './getPartnerInfo';
export * from './getPrices';
export * from './postAppleAuth';
export * from './postDependents';
export * from './postDevice';
export * from './postFacebookAuth';
export * from './postForgotPassword';
export * from './postMe';
export * from './postPartnerLogin';
export * from './postQuestionnairePoll';
export * from './postTestsRechoose';
export * from './putPartnerSubscribe';
