import Link from 'next/link';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FocusRingVariants } from '@calm-web/design-system';

import { AuthModalContext } from '@/context/AuthModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useLocale } from '@/hooks/locale/useLocale';
import { useUserState } from '@/hooks/store';
import messages from '@/messages/messages';
import { setAuthFormMode } from '@/store/authFormMode/actions';
import { userCanTrial } from '@/utils/subscriptions';

import { SUPPORT_LINK } from '../Header/constants';
import {
	FreeTrialNavPrimaryButton,
	LoggedInNav,
	LoggedOutNav,
	NavButton,
	NavItem,
	ProfileIcon,
	ProfileWrapper,
	Wrapper,
} from './styles';
import { NavProps } from './types';

const DesktopNav = ({
	onNavLinkClick,
	isDark,
	forBusinessHref,
	onForBusinessClick,
	loggedInNavItems: unfilteredNavItems,
	onFreeCtaClick,
	query,
	hideMenu,
	hideProfile,
	useContactButton,
	contactUsHref,
	onContactUsClick,
	hideFreeTrialCta = false,
	hideForBusinessCta = false,
	hideAuthCTA = false,
}: NavProps) => {
	const { calmLanguage } = useLocale();
	const loggedInNavItems = unfilteredNavItems.filter(f =>
		f.supportedLocales ? f.supportedLocales?.includes(calmLanguage) : true,
	);
	const user = useUserState();
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const { setIsModalOpen } = useContext(AuthModalContext);

	const onLoginClick = (): void => {
		logEvent({ eventName: 'Homepage : NavHeader : Login : Clicked' });
		dispatch(setAuthFormMode('login'));
		if (setIsModalOpen) {
			setIsModalOpen(true);
		}
	};

	// TODO: This should do a more complicated check for subscription status eventually
	const renderLoggedInOptions = (): JSX.Element => {
		return (
			<LoggedInNav $hideProfile={hideProfile}>
				{!hideMenu &&
					loggedInNavItems.map(navItem => {
						const href = `${navItem.href}${query}`;
						return (
							<li key={formatMessage(navItem.title)}>
								<NavItem href={href} onClick={(): void => onNavLinkClick(navItem.href)}>
									{formatMessage(navItem.title)}
								</NavItem>
							</li>
						);
					})}
				{!hideFreeTrialCta && !hideMenu && !useContactButton && user && userCanTrial(user) && (
					<li>
						<NavItem
							href={`/signup-flow${query}`}
							onClick={(): void => onNavLinkClick(`/signup-flow${query}`)}
						>
							{formatMessage(messages.startFreeTrial)}
						</NavItem>
					</li>
				)}
				{useContactButton && (
					<li>
						<NavItem href={SUPPORT_LINK} onClick={(): void => onNavLinkClick(SUPPORT_LINK)}>
							{formatMessage(messages.contactUs)}
						</NavItem>
					</li>
				)}
				{!hideProfile && (
					<li>
						<Link href="/app/profile/stats" passHref legacyBehavior>
							<ProfileWrapper
								aria-label={formatMessage(messages.profile)}
								onClick={(): void => onNavLinkClick(`/app/profile/stats${query}`)}
							>
								<ProfileIcon />
							</ProfileWrapper>
						</Link>
					</li>
				)}
			</LoggedInNav>
		);
	};

	const renderLoggedOutOptions = (): JSX.Element | null => {
		if (hideMenu) return null;

		return (
			<LoggedOutNav>
				{!hideForBusinessCta && (
					<li>
						<NavButton
							backgroundColor="transparent"
							textColor={isDark ? 'white' : 'black'}
							focusRingVariant={isDark ? FocusRingVariants.Light : FocusRingVariants.Dark}
							href={forBusinessHref}
							onPress={onForBusinessClick}
						>
							{formatMessage(messages.forBusiness)}
						</NavButton>
					</li>
				)}
				{!hideAuthCTA && (
					<li>
						<NavButton
							data-testid="desktopnav-login-button"
							backgroundColor="transparent"
							textColor={isDark ? 'white' : 'black'}
							focusRingVariant={isDark ? FocusRingVariants.Light : FocusRingVariants.Dark}
							onPress={onLoginClick}
						>
							{formatMessage(messages.login)}
						</NavButton>
					</li>
				)}
				{!hideFreeTrialCta && (
					<li>
						<FreeTrialNavPrimaryButton onPress={onFreeCtaClick} backgroundColor="accessibleBrandGradient">
							{useContactButton ? formatMessage(messages.contactUs) : formatMessage(messages.tryCalm)}
						</FreeTrialNavPrimaryButton>
					</li>
				)}
			</LoggedOutNav>
		);
	};

	function evalHeaderOptions(): JSX.Element | null {
		if (user) {
			if (hideAuthCTA) {
				return renderLoggedOutOptions();
			}
			return renderLoggedInOptions();
		} else {
			return renderLoggedOutOptions();
		}
	}

	return <Wrapper aria-label="primary">{evalHeaderOptions()}</Wrapper>;
};

export default DesktopNav;
