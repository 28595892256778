import { Device } from '@/store/device/types';
import { Prices } from '@/store/prices/types';

const isUSPricing = (prices: Prices, device: Device): boolean => {
	const currency = prices?.original?.currency;
	const yearly = prices?.original?.yearly;

	const isSupportedCountry = device?.ip_country === 'us';

	if (currency?.toLowerCase() === 'usd' && yearly === 6999 && isSupportedCountry) {
		return true;
	}
	return false;
};

export default isUSPricing;
