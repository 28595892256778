import { AnyAction } from 'redux';

import { BrowserLanguage, SET_BROWSER_LANGUAGE } from './types';

const initialState: BrowserLanguage | null = null;

export function browserLanguageReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): BrowserLanguage | null {
	switch (action.type) {
		case SET_BROWSER_LANGUAGE:
			return action.payload;
		default:
			return state;
	}
}
