import { useWindowWidth } from '@react-hook/window-size';
import { useEffect, useState } from 'react';

import { Breakpoints } from '@calm-web/design-system/lib/utils/mediaQueries';

export function useMaxWidth(screenSize: keyof typeof Breakpoints) {
	const winWidth = useWindowWidth();
	const [hasMaxWidth, setHasMaxWidth] = useState(false);

	useEffect(() => {
		setHasMaxWidth(winWidth < Breakpoints[screenSize]);
	}, [screenSize, winWidth]);

	return [hasMaxWidth];
}
