export type RecaptchaVisible = boolean;

export const SET_RECAPTCHA_VISIBLE = 'SET_RECAPTCHA_VISIBLE';

export interface SetRecaptchaVisibleAction {
	type: typeof SET_RECAPTCHA_VISIBLE;
	payload: RecaptchaVisible;
}

export type SetRecaptchaVisibleTypes = SetRecaptchaVisibleAction;
