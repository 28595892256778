import { defineMessages } from 'react-intl';

export default defineMessages({
	generic: {
		id: 'errorMessages.generic',
		defaultMessage: 'An error has occured',
		description: 'Error Message on a credit card form',
	},
	unspecified: {
		id: 'errorMessages.unspecified',
		defaultMessage: 'An unspecified error has occured',
		description: 'Error Message on a credit card form',
	},
	bad_credentials: {
		id: 'errorMessages.badCredentials',
		defaultMessage: 'Bad credentials',
		description: 'Error Message on a credit card form',
	},
	not_found: {
		id: 'errorMessages.notFound',
		defaultMessage: 'An error has occured', // TODO: Should this stay?
		description: 'Error Message on a credit card form',
	},
	exceeded_retry_count: {
		id: 'errorMessages.exceededRetryCount',
		defaultMessage: 'Exceeded retry count',
		description: 'Error Message on a credit card form',
	},
	insufficient_permissions: {
		id: 'errorMessages.insufficientPermissions',
		defaultMessage: 'Permission denied',
		description: 'Error Message on a credit card form',
	},
	email_invalid: {
		id: 'errorMessages.emailInvalid',
		defaultMessage: 'Invalid email',
		description: 'Error Message on a credit card form',
	},
	password_invalid: {
		id: 'errorMessages.passwordInvalid',
		defaultMessage: 'Invalid password',
		description: 'Error Message on a credit card form',
	},
	invalid_coupon: {
		id: 'errorMessages.invalidCoupon',
		defaultMessage: 'Invalid coupon',
		description: 'Error Message on a credit card form',
	},
	invalid_gift_code: {
		id: 'errorMessages.invalidGiftCode',
		defaultMessage: 'Invalid gift code',
		description: 'Error Message on a credit card form',
	},
	invalid_email_token: {
		id: 'errorMessages.invalidEmailToken',
		defaultMessage: 'Invalid email token',
		description: 'Error Message on a credit card form',
	},
	no_session: {
		id: 'errorMessages.noSession',
		defaultMessage: 'Failed to load session',
		description: 'Error Message on a credit card form',
	},
	no_daily_calm: {
		id: 'errorMessages.noDailyCalm',
		defaultMessage: 'Failed to load Daily Calm',
		description: 'Error Message on a credit card form',
	},
	no_stats: {
		id: 'errorMessages.noStats',
		defaultMessage: 'No stats available',
		description: 'Error Message on a credit card form',
	},
	facebook_token_expired: {
		id: 'errorMessages.facebookTokenExpired',
		defaultMessage: 'Facebook login has expired',
		description: 'Error Message on a credit card form',
	},
	auth_reset_token_not_found: {
		id: 'errorMessages.authResetTokenNotFound',
		defaultMessage: 'Invalid reset token',
		description: 'Error Message on a credit card form',
	},
	auth_reset_token_expired: {
		id: 'errorMessages.authResetTokenExpired2',
		defaultMessage: 'Reset password link has expired. Please try requesting a new one.',
		description: 'Error Message on a credit card form',
	},
	email_taken: {
		id: 'errorMessages.emailTaken',
		defaultMessage: 'Email already taken',
		description: 'Error Message on a credit card form',
	},
	no_user: {
		id: 'errorMessages.noUser',
		defaultMessage: 'User not found',
		description: 'Error Message on a credit card form',
	},
	no_user_for_jwt: {
		id: 'errorMessages.noUserForJwt',
		defaultMessage: 'No user is associated with this token',
		description: 'Error Message on a credit card form',
	},
	gift_already_granted: {
		id: 'errorMessages.giftAlreadyGranted',
		defaultMessage: 'It seems this gift has already been used',
		description: 'Error Message on a credit card form',
	},
	gift_grant_failed: {
		id: 'errorMessages.giftGrantFailed',
		defaultMessage: 'Did not successfully create subscription for user', // TODO: also seems weird
		description: 'Error message on a credit card form',
	},
	gift_already_redeemed: {
		id: 'errorMessages.giftAlreadyRedeemed',
		defaultMessage: 'Gift has already been redeemed',
		description: 'Error message on a credit card form',
	},
	redemption_code_taken: {
		id: 'errorMessages.redemptionCodeTaken',
		defaultMessage: 'Code has already been redeemed',
		description: 'Error message on a credit card form',
	},
	incomm_invalid_code: {
		id: 'errorMessages.incommInvalidCode',
		defaultMessage: 'An error has occured', // TODO: See if the incomm stuff needs to be here?
		description: 'Error message on a credit card form',
	},
	incomm_cannot_be_redeemed: {
		id: 'errorMessages.incommCannotBeRedeemed',
		defaultMessage: 'Cannot be redeemed',
		description: 'Error message on a credit card form',
	},
	incomm_already_redeemed: {
		id: 'errorMessages.incommAlreadyRedeemed',
		defaultMessage: 'Already redeemed',
		description: 'Error message on a credit card form',
	},
	incomm_redeem_failed: {
		id: 'errorMessages.incommRedeemFailed',
		defaultMessage: 'Failed to redeem',
		description: 'Error message on a credit card form',
	},
	already_subscribed: {
		id: 'errorMessages.alreadySubscribed.2',
		defaultMessage: 'You are already subscribed',
		description: 'Error message on a credit card form',
	},
	already_canceled: {
		id: 'errorMessages.alreadyCanceled',
		defaultMessage: 'Subscription is already canceled',
		description: 'Error message on a credit card form',
	},
	already_has_gift: {
		id: 'errorMessages.alreadyHasGift',
		defaultMessage: 'Gift has already been claimed',
		description: 'Error message on a credit card form',
	},
	subscription_expired: {
		id: 'errorMessages.subscriptionExpired',
		defaultMessage: 'Subscription is expired',
		description: 'Error message on a credit card form',
	},
	no_subscription: {
		id: 'errorMessages.noSubscription',
		defaultMessage: 'User is not subscribed',
		description: 'Error message on a credit card form',
	},
	no_team: {
		id: 'errorMessages.noTeam',
		defaultMessage: 'Failed to find team',
		description: 'Error message on a credit card form',
	},
	slug_unavailable: {
		id: 'errorMessages.slugUnavailable',
		defaultMessage: 'This slug is unavailable',
		description: 'Error message on a credit card form',
	},
	college_email_unsupported: {
		id: 'errorMessages.collegeEmailUnsupported',
		defaultMessage: 'College email unsupported',
		description: 'Error message on a credit card form',
	},
	balance_insufficient: {
		id: 'errorMessages.balanceInsufficient',
		defaultMessage: 'Insufficient balance on card',
		description: 'Error message on a credit card form',
	},
	card_declined: {
		id: 'errorMessages.cardDeclined',
		defaultMessage: 'Card has been declined',
		description: 'Error message on a credit card form',
	},
	country_unsupported: {
		id: 'errorMessages.countryUnsupported',
		defaultMessage: 'Country unsupported',
		description: 'Error message on a credit card form',
	},
	coupon_expired: {
		id: 'errorMessages.couponExpired',
		defaultMessage: 'Coupon is expired',
		description: 'Error message on a credit card form',
	},
	expired_card: {
		id: 'errorMessages.expiredCard',
		defaultMessage: 'Card is expired',
		description: 'Error message on a credit card form',
	},
	incorrect_address: {
		id: 'errorMessages.incorrectAddress',
		defaultMessage: 'An error has occured',
		description: 'Error message on a credit card form',
	},
	incorrect_cvc: {
		id: 'errorMessages.incorrectCvc',
		defaultMessage: 'Incorrect CVC',
		description: 'Error message on a credit card form',
	},
	incorrect_number: {
		id: 'errorMessages.incorrectNumber',
		defaultMessage: 'Incorrect card number',
		description: 'Error message on a credit card form',
	},
	incorrect_zip: {
		id: 'errorMessages.incorrectZip',
		defaultMessage: 'Incorrect Zip Code',
		description: 'Error message on a credit card form',
	},
	invalid_card_type: {
		id: 'errorMessages.invalidCardType',
		defaultMessage: 'Invalid card type',
		description: 'Error message on a credit card form',
	},
	invalid_charge_amount: {
		id: 'errorMessages.invalidChargeAmount',
		defaultMessage: 'Invalid charge amaount',
		description: 'Error message on a credit card form',
	},
	invalid_cvc: {
		id: 'errorMessages.invalidCvc',
		defaultMessage: 'Invalid cvc',
		description: 'Error message on a credit card form',
	},
	invalid_expiry_month: {
		id: 'errorMessages.invalidExpiryMonth',
		defaultMessage: 'Invalid expiry month',
		description: 'Error message on a credit card form',
	},
	invalid_expiry_year: {
		id: 'errorMessages.invalidExpiryYear',
		defaultMessage: 'Invalid expiry year',
		description: 'Error message on a credit card form',
	},
	invalid_number: {
		id: 'errorMessages.invalidNumber',
		defaultMessage: 'Invalid card number',
		description: 'Error message on a credit card form',
	},
	postal_code_invalid: {
		id: 'errorMessages.postalCodeInvalid',
		defaultMessage: 'Invalid postal code',
		description: 'Error message on a credit card form',
	},
	processing_error: {
		id: 'errorMessages.processingError',
		defaultMessage: 'Failed to process payment',
		description: 'Error message on a credit card form',
	},
	already_trialed: {
		id: 'errorMessages.alreadyTrialed',
		defaultMessage:
			'We are sorry, but you are not eligible for this offer. This offer is only available for customers who have not previously tried Calm Premium.',
		description: 'User cannot redeem the offer a second time',
	},
	cardError: {
		id: 'models.creditCard.errors.cardError',
		defaultMessage: 'There was an error charging your card',
		description: 'Error message on a credit card form',
	},
	timeoutError: {
		id: 'models.creditCard.errors.timeout',
		defaultMessage: 'Please try again when you have faster internet',
		description: 'Error message on a credit card form',
	},
	contactIssuer: {
		id: 'models.creditCard.errors.contactIssuer',
		defaultMessage:
			'There was an error charging your card. Please contact your card issuer for more information',
		description: 'Error message on a credit card form',
	},
	insufficientFunds: {
		id: 'models.creditCard.errors.insufficientFunds',
		defaultMessage:
			'There was an error charging your card. Please try another card or contact your card issuer for more information',
		description: 'Error message on a credit card form',
	},
	declinedError: {
		id: 'models.creditCard.errors.genericDeclined',
		defaultMessage: 'There was an error charging your card. Please try again',
		description: 'Error message on a credit card form',
	},
	ineligibleCard: {
		id: 'models.creditCard.errors.ineglibielCard',
		defaultMessage: 'We’re sorry this card isn’t valid for redeeming this offer.',
		description: 'Error for ineligible card',
	},
	samsungPromotionError: {
		id: 'models.creditCard.errors.samsungPromotionError',
		defaultMessage:
			"It looks like you've already redeemed this promotion. Click the link below to redeem a non-promotional subscription.",
		description: "We don't allow a user tries to redeem a subscription twice with the same promotion",
	},
});
