import { AnyAction } from 'redux';

import { PurchaseParams, SET_PURCHASE_PARAMS } from './types';

const initialState: PurchaseParams = {
	plan: 'yearly',
	coupon: null,
	promotion: null,
	samsungGUID: null,
	guestPass: null,
	calmjwt: null,
	is30DayOverride: false,
	giftPrice: null,
	isUpdateBillingScreen: false,
};

export function purchaseParamsReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): PurchaseParams {
	switch (action.type) {
		case SET_PURCHASE_PARAMS:
			return action.payload;
		default:
			return state;
	}
}
