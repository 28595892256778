import { defineMessages } from 'react-intl';

const messages = defineMessages({
	signupFormHeading: {
		id: 'signup.accountSetup.title',
		defaultMessage: 'Create a free account to save your preferences',
		description: 'The title for the Calm signup component',
	},
	paymentFormHeading: {
		id: 'simpleSignup.paymentFormHeading2',
		defaultMessage: 'Try Calm Premium with a {trialLength} day free trial',
		description: 'The title for the Calm payment component',
	},
	loginFormHeading: {
		id: 'login.loginCalmTitle',
		defaultMessage: 'Log into your Calm account',
		description: 'The title for the Calm login component',
	},
	valuePropHeader: {
		id: 'control.account.valueproplist.header',
		defaultMessage: 'Calm your mind. Change your life.',
		description: 'Value props of calm on spotify',
	},
	valueProp1Head: {
		id: 'spotify.account.valueprop1.header',
		defaultMessage: 'Get in-the-moment relief from stress and anxiety',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp1Body: {
		id: 'spotify.account.valueprop1.body',
		defaultMessage: 'Expert-led sessions and grounding exercises to quickly calm your mind',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp2Head: {
		id: 'spotify.account.valueprop2.header',
		defaultMessage: 'Fall (and stay) asleep naturally',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp2Body: {
		id: 'spotify.account.valueprop2.body',
		defaultMessage: 'Sleep Stories and meditations designed to keep you relaxed through the night',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp3Head: {
		id: 'spotify.account.valueprop3.header',
		defaultMessage: 'Learn with guidance from the best',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	valueProp3Body: {
		id: 'spotify.account.valueprop3.body',
		defaultMessage: 'Our instructors make mindfulness easy and welcoming, even for beginners',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	includedBasic: {
		id: 'signup.basic.compare.included.header',
		defaultMessage: 'Included in basic membership',
		description: 'Heading for basic package',
	},
	includedPremium: {
		id: 'signup.premium.compare.included.header',
		defaultMessage: 'Your premium membership includes',
		description: 'Heading for premium package',
	},
	comparePremiumProp1Header: {
		id: 'signup.premium.compare.premiumProp1.header',
		defaultMessage: '50,000+ minutes of premium content',
		description: 'header for Premium feature 1',
	},
	comparePremiumProp1Body: {
		id: 'signup.premium.compare.premiumProp1.body',
		defaultMessage: 'Sleep Stories, Meditations, Nature sounds, Sleep soundscapes, Relaxing music, and more',
		description: 'body for Premium feature 1',
	},
	comparePremiumProp2Header: {
		id: 'signup.premium.compare.premiumProp2.header',
		defaultMessage: 'New content daily',
		description: 'header for Premium feature 2',
	},
	comparePremiumProp2Body: {
		id: 'signup.premium.compare.premiumProp2.body',
		defaultMessage:
			"Daily guided content to help with any of life's stressors, from work, parenting, finances, and more. ",
		description: 'body for Premium feature 2',
	},
});

export const faqMessages = defineMessages({
	faq1Question: {
		id: 'control.account.faq.q1',
		defaultMessage: 'What does the student plan give me access to?',
		description: 'A question for the FAQ',
	},
	faq1Answer: {
		id: 'control.account.faq.a1',
		defaultMessage:
			'You will receive a yearly subscription with full access to the Calm app. In addition to content to support anxiety and overwhelm, content to help you fall (and stay) asleep and music to support your focus, we also have a College Collection with Tamara Levitt that includes: <ul><li>Meditations to improve your focus</li><li>Music designed to keep you motivated as you study</li><li>Many more tools to help you relax and avoid getting overwhelmed during the school year</li></ul>',
		description: 'An answer for an FAQ question',
	},
	faq2Question: {
		id: 'control.account.faq.q2',
		defaultMessage: 'Do I need to use my school email address?',
		description: 'A question for the FAQ',
	},
	faq2Answer: {
		id: 'control.account.faq.a2',
		defaultMessage:
			'You can use any email address to register for the Calm Student plan. You do not need to use your school email, as our partner SheerID handles the verification process.',
		description: 'An answer for an FAQ question',
	},
	faq3Question: {
		id: 'control.account.faq.q3',
		defaultMessage: 'How do I get the student rate?',
		description: 'A question for the FAQ',
	},
	faq3Answer: {
		id: 'control.account.faq.a3',
		defaultMessage:
			'To be eligible for the student rate, you must be over the age of 18 and currently enrolled in a post-secondary program or university and earning credit towards a diploma, degree, or professional license in the United States.',
		description: 'An answer for an FAQ question',
	},
	faq4Question: {
		id: 'control.account.faq.q4',
		defaultMessage: 'How do I verify my student status?',
		description: 'A question for the FAQ',
	},
	faq4Answer: {
		id: 'control.account.faq.a4a',
		defaultMessage:
			"With SheerID, our verification partner, you can quickly verify your student status. If you were unable to verify your status instantly, the next step in the form will request you upload a document that shows you're currently enrolled in a post-secondary program or university in the United States.{br}Your first and last names, the name of your college or university, and an issue date from the current academic term or within the last three months must be visible.{br} The following documents can be used to verify your status:<ul><li>Official Enrollment Letter, not just an acceptance letter</li><li>Current class schedule</li><li>Registration or tuition receipt</li><li>Transcript with your classes</li><li>Other school-issued documents with an issue date within the last three months that display your first and last names</li></ul>{br}For your privacy and safety, we recommend hiding all sensitive information, such as your SSN, on the documentation you upload into the SheerID system.",
		description: 'An answer for an FAQ question',
	},
	faq5Question: {
		id: 'control.account.faq.q5',
		defaultMessage: "What do I do if my submission wasn't approved?",
		description: 'A question for the FAQ',
	},
	faq5Answer: {
		id: 'control.account.faq.a5a',
		defaultMessage:
			'If you receive an email that your submission was denied, click the "TRY AGAIN" button to resubmit. Please double-check that the document you submitted meets the requirements we outlined above. {br}If you continue to be denied, please get in touch with the SheerID support team via customerservice@sheerid.com or https://offers.sheerid.com/sheerid/help-center/. When you email SheerID, mention which document you submitted, why your submission was rejected (this should be listed in the denial email), and the email address you used to verify.',
		description: 'An answer for an FAQ question',
	},
	faq6Question: {
		id: 'control.account.faq.q6',
		defaultMessage: 'I already have a Calm subscription. Can I get the student rate?',
		description: 'A question for the FAQ',
	},
	faq6Answer: {
		id: 'control.account.faq.a6',
		defaultMessage:
			'Not right away. Once your current subscription expires, you can sign up again and receive the student rate once you verify your student status.',
		description: 'An answer for an FAQ question',
	},
	faq7Question: {
		id: 'control.account.faq.q7',
		defaultMessage: 'Does the Student plan automatically renew?',
		description: 'A question for the FAQ',
	},
	faq7Answer: {
		id: 'control.account.faq.a7a',
		defaultMessage:
			"No, it does not. You'll get an annual notification when we need to re-verify your student status through SheerID. {br}If your student status does not meet the verification requirements (e.g., you graduate), we'll let you know via email and automatically enroll you in a 1-year subscription at the then-current rate at the end of your billing cycle.",
		description: 'An answer for an FAQ question',
	},
});

export default messages;
