import * as amplitude from '@amplitude/analytics-browser';
import MobileDetect from 'mobile-detect';

import { LogEventArgs, LogPurchaseArgs } from '@/hooks/analytics/types';

import { calmLogger } from '../calmLogger';

export type LogEventResult = amplitude.Types.Result;

export function setAmplitudeUserId(userId: string): void {
	if (userId) {
		amplitude.setUserId(userId);
	}
}

export function setAmplitudeDeviceProperties(deviceId: string): void {
	if (deviceId) {
		amplitude.setDeviceId(deviceId);
		const md = new MobileDetect(window.navigator.userAgent);
		const deviceType = md.mobile() ? 'mobile' : 'desktop';

		const identifyObj = new amplitude.Identify();
		identifyObj.set('web_device_type', deviceType);
		identifyObj.set('Other', deviceId);
		amplitude.identify(identifyObj);
	}
}

export function setAmplitudeUserProperties(
	userProperties: Record<string, amplitude.Types.ValidPropertyType>,
): void {
	const identifyObj = new amplitude.Identify();
	Object.entries(userProperties).forEach(([key, value]) => {
		identifyObj.set(key, value);
	});
	amplitude.identify(identifyObj);
}

// we can remove this once we are no longer using the /password-reset/token route
// we can probably remove this once we are migrated to Auth0
const pageViewSanitizationEnrichment = (): amplitude.Types.EnrichmentPlugin => {
	return {
		name: 'page-view-tracking-enrichment',
		type: 'enrichment',
		setup: async () => undefined,
		execute: async event => {
			if (event.event_type !== '[Amplitude] Page Viewed' || !event.event_properties) {
				return event;
			}

			event.event_properties = {
				...event.event_properties,
				...(event.event_properties['[Amplitude] Page URL'] && {
					['[Amplitude] Page URL']: (event.event_properties['[Amplitude] Page URL'] as string).replace(
						/\/password-reset\/.+/,
						'/password-reset/REDACTED',
					),
				}),
				...(event.event_properties['[Amplitude] Page Path'] && {
					['[Amplitude] Page Path']: (event.event_properties['[Amplitude] Page Path'] as string).replace(
						/\/password-reset\/.+/,
						'/password-reset/REDACTED',
					),
				}),
				...(event.event_properties['[Amplitude] Page Location'] && {
					['[Amplitude] Page Location']: (
						event.event_properties['[Amplitude] Page Location'] as string
					).replace(/\/password-reset\/.+/, '/password-reset/REDACTED'),
				}),
			};
			return event;
		},
	};
};

export async function initAmplitude(): Promise<void> {
	if (!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
		calmLogger.warn('NEXT_PUBLIC_AMPLITUDE_API_KEY not defined.. expect errors below');
		return;
	}

	amplitude.add(pageViewSanitizationEnrichment());

	await amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
		defaultTracking: true,
		cookieOptions: {
			domain: 'calm.com',
		},
	}).promise;
}

export async function logAmplitudeEvent({
	eventName,
	eventProps,
}: LogEventArgs): Promise<amplitude.Types.Result> {
	const res = await amplitude.track(eventName, eventProps).promise;
	return res;
}

export async function logAmplitudePurchase({
	productInfo,
	transactionInfo,
}: LogPurchaseArgs): Promise<amplitude.Types.Result> {
	const revenueEvent = new amplitude.Revenue()
		.setProductId(productInfo.name)
		.setPrice(productInfo.unit_price)
		.setQuantity(productInfo.quantity)
		.setRevenueType(productInfo.sku || productInfo.name);

	if (transactionInfo.id) {
		revenueEvent.setEventProperties({ id: transactionInfo.id });
	}

	const res = await amplitude.revenue(revenueEvent).promise;
	return res;
}
