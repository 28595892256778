import { defineMessages } from 'react-intl';

export default defineMessages({
	trialInfo: {
		id: 'paymentDetails.modal.trialInfo',
		defaultMessage:
			'We ask for your payment information now so you can enjoy Calm uninterrupted after your {trialLength} trial ends.',
		description: 'Trial info in payment details modal',
	},
	cancel: {
		id: 'paymentDetails.modal.cancelInfo',
		defaultMessage: "If you cancel anytime before the end of the {trialLength} trial, you won't be charged.",
		description: 'Cancellation info in payment details modal',
	},
	confirmButtonCta: {
		id: 'paymentDetails.modal.buttonCta',
		defaultMessage: 'Got it',
		description: 'Button cta to close the modal',
	},
	trialDays: {
		id: 'paymentDetails.modal.trialDays',
		defaultMessage: '{number}-day',
		description: 'the number of days for a trial, usually `7-day` trial',
	},
	trialMonths: {
		id: 'paymentDetails.modal.trialMonths',
		defaultMessage: '{number}-month',
		description: 'the number of months for a trial, something like `3-month` trial',
	},
});
