import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MediaEl, OnAudioStart } from '@/components/app/contentPlayer/MediaPlayerProvider/types';
import { PacksContext } from '@/components/app/layout/PacksProvider';
import { SHARE_FREE_ACCESS_MODAL_COOKIE } from '@/components/app/layout/ShareContentModal';
import { useThemeState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setGuide } from '@/store/guide/actions';
import { Guide } from '@/store/guide/types';
import { Program } from '@/store/program/types';
import { setCookie } from '@/utils/cookies';
import { isAudioMediaEl } from '@/utils/media';

import { useProgram } from './useProgram';

const SUPPORTED_AUTOPLAY_TYPES = new Set(['Album', 'Playlist']);

export const hasAutoplaySupport = (program: Program) => {
	return SUPPORTED_AUTOPLAY_TYPES.has(program?.content_type as string);
};

export const useAutoplay = (
	mediaEl: MediaEl,
	onAudioStart: OnAudioStart,
	isShareContentEligible: boolean,
): void => {
	const dispatch = useDispatch();
	const theme = useThemeState();
	const routerPush = useRouterPush();
	const { currentProgram, currentGuide } = useContext(PacksContext);
	const { program: fullProgram } = useProgram(currentProgram?.id);

	const [autoplayingGuides, setAutoplayingGuides] = useState<Guide[]>([]);

	const guidePosition = Number(currentGuide?.position);

	const onGuideComplete = useCallback(async () => {
		if (isShareContentEligible) {
			setCookie(SHARE_FREE_ACCESS_MODAL_COOKIE, 'true', { expires: 1 });
			await routerPush('/app');
			return;
		}

		if (autoplayingGuides.length <= 1) return;

		const nextGuide =
			autoplayingGuides.find(({ position }) => position === guidePosition + 1) ??
			autoplayingGuides.find(({ position }) => position === 1);

		if (nextGuide) {
			if (theme.webAppLayout === 'player') {
				await routerPush(`/app/player/${nextGuide?.id}`);
			}

			dispatch(setGuide(nextGuide));
			onAudioStart(null, nextGuide);
		}
	}, [
		isShareContentEligible,
		autoplayingGuides,
		routerPush,
		guidePosition,
		theme.webAppLayout,
		dispatch,
		onAudioStart,
	]);

	const shouldAutoplay = currentProgram && hasAutoplaySupport(currentProgram);

	useEffect(() => {
		if (shouldAutoplay && fullProgram) {
			setAutoplayingGuides(fullProgram?.guides);
		}
	}, [shouldAutoplay, fullProgram]);

	useEffect(() => {
		if (shouldAutoplay && mediaEl && isAudioMediaEl(mediaEl)) {
			mediaEl.addEventListener('ended', onGuideComplete);
		}

		return () => {
			if (shouldAutoplay && mediaEl && isAudioMediaEl(mediaEl)) {
				mediaEl.removeEventListener('ended', onGuideComplete);
			}
		};
	}, [shouldAutoplay, mediaEl, onGuideComplete, autoplayingGuides, theme.webAppLayout]);
};
