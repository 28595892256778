import { Dispatch, Reducer, ReducerAction, useReducer } from 'react';

export const FORM_STATES = {
	idle: 'IDLE',
	loading: 'LOADING',
	success: 'SUCCESS',
	failure: 'FAILURE',
} as const;

export const FORM_ACTIONS = {
	requestSent: 'REQUEST_SENT',
	requestSuccess: 'REQUEST_SUCCESS',
	requestFailure: 'REQUEST_FAILURE',
	requestCancelled: 'REQUEST_CANCELLED',
} as const;

function reducer(state: string, action: { type: string }): string {
	switch (action.type) {
		case FORM_ACTIONS.requestSent:
			return FORM_STATES.loading;
		case FORM_ACTIONS.requestSuccess:
			return FORM_STATES.success;
		case FORM_ACTIONS.requestFailure:
			return FORM_STATES.failure;
		case FORM_ACTIONS.requestCancelled:
			return FORM_STATES.idle;
		default:
			return FORM_STATES.idle;
	}
}

export function useFormState(): [string, Dispatch<ReducerAction<Reducer<string, { type: string }>>>] {
	const initialState = FORM_STATES.idle;
	const [formState, formDispatch] = useReducer(reducer, initialState);

	return [formState, formDispatch];
}
