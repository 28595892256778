import { AnyAction } from 'redux';

import { SET_USER, User } from './types';

const initialState: User | null = null;

export function userReducer(state = initialState, action: AnyAction = { type: undefined }): User | null {
	switch (action.type) {
		case SET_USER: {
			return action.payload;
		}
		default:
			return state;
	}
}
