const getTimezoneStandard = () => {
	const timezone_offset_min = new Date().getTimezoneOffset();
	let offset_hrs: string | number = parseInt(`${Math.abs(timezone_offset_min / 60)}`, 10);
	let offset_min: string | number = Math.abs(timezone_offset_min % 60);
	let timezone_standard;

	if (offset_hrs < 10) offset_hrs = `0${offset_hrs}`;

	if (offset_min < 10) offset_min = `0${offset_min}`;
	// Add an opposite sign to the offset
	// If offset is 0, it means timezone is UTC
	if (timezone_offset_min < 0) timezone_standard = `+${offset_hrs}:${offset_min}`;
	else if (timezone_offset_min > 0) timezone_standard = `-${offset_hrs}:${offset_min}`;
	else if (timezone_offset_min === 0) timezone_standard = 'Z';

	return timezone_standard;
};

const getFormattedTimestamp = (time: number) => {
	if (time < 10) {
		return `0${time}`;
	}
	return `${time}`;
};

export const getISOTimestamp = (timestamp: Date): string => {
	const timeZoneStandard = getTimezoneStandard();
	const day = getFormattedTimestamp(timestamp.getDate());
	const month = getFormattedTimestamp(timestamp.getMonth() + 1);
	const hours = getFormattedTimestamp(timestamp.getHours());
	const minutes = getFormattedTimestamp(timestamp.getMinutes());
	const seconds = getFormattedTimestamp(timestamp.getSeconds());
	const newTimestamp = `${timestamp.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
	return newTimestamp.replace('Z', timeZoneStandard as string);
};
