import { AxiosPromise } from 'axios';

import apiRequest from '@/utils/apiRequest';

export function postTestsRechoose(testName: string): AxiosPromise {
	return apiRequest({
		endpoint: 'tests/rechoose',
		method: 'POST',
		body: {
			name: testName,
		},
		customHeaders: {
			'Content-Type': 'application/json',
		},
	});
}
