import styled from 'styled-components';

export const Wrapper = styled.div`
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
