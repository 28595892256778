import NextImage from 'next/legacy/image';

import customLoader from '@/utils/imageLoader';

const Image: typeof NextImage = props => {
	if (process.env.IS_STORYBOOK) {
		return <NextImage {...props} unoptimized />;
	}
	return <NextImage {...props} loader={customLoader} />;
};

export default Image;
