import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	units,
	TextButton,
	minWidth,
	fontFamily,
	fontWeight,
	FontWeights,
	fontSize,
	FontSizes,
} from '@calm-web/design-system';

export const Wrapper = styled.div<{ lightText: boolean; isLightLeftAlignStyles?: boolean }>`
	color: ${palette('gray7')};
	* {
		${ifProp(
			'lightText',
			css`
				color: ${palette('white')};
			`,
		)}
	}

	${ifProp(
		'isLightLeftAlignStyles',
		css`
			text-align: left;
		`,
	)}
`;

export const DesktopCta = styled.div`
	display: none;
	font-size: 18px;
	${fontWeight(FontWeights.Regular)};

	& a {
		${fontWeight(FontWeights.Demi)};
		text-decoration: underline;
	}

	${minWidth('desktop')} {
		display: block;
	}
`;

export const MobileCta = styled(TextButton)<{ isLightLeftAlignStyles?: boolean }>`
	display: block;
	font-size: 16px;
	${fontWeight(FontWeights.Demi)};
	text-decoration: underline;
	background-color: transparent;
	border: none;
	margin: 10px auto 0 auto;

	${minWidth('desktop')} {
		display: none;
	}

	${ifProp(
		'isLightLeftAlignStyles',
		css`
			${fontWeight(FontWeights.Regular)}
			${fontSize(FontSizes.base)}			
			line-height: 150%;
			color: ${palette('gray8')};
			margin: unset;
			padding: unset;
			margin-top: ${units(1.5)};
			${fontFamily}
		`,
	)}
`;

export const ForgotPasswordTextButton = styled(TextButton)<{ isLightLeftAlignStyles?: boolean }>`
	${ifProp(
		'isLightLeftAlignStyles',
		css`
			${fontWeight(FontWeights.Regular)}
			font-size: ${units(1.78)};
			line-height: 150%;
			color: ${palette('gray8')};
		`,
	)}
`;
