import { useEffect, useState } from 'react';

import { useExperimentClient } from '@/hooks/analytics';
import { useDeviceState, useUserState } from '@/hooks/store';
import { AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE } from '@/utils/experiments/amplitudeExperiment';

export const useAmplitudeExperiment = (experimentName: string, shortCircuit: boolean = false) => {
	const user = useUserState();
	const device = useDeviceState();

	const experimentClient = useExperimentClient();

	const [isEnrolled, setIsEnrolled] = useState<boolean>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (shortCircuit || experimentClient === AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE) {
			setIsEnrolled(false);
			setIsLoading(false);
			return;
		}

		if (!experimentClient) return;

		try {
			const variant = experimentClient.variant(experimentName);
			setIsEnrolled(variant?.value === 'treatment');
		} catch (err) {
			setIsEnrolled(false);
		} finally {
			setIsLoading(false);
		}
	}, [device, user, experimentClient, experimentName, shortCircuit]);

	return {
		isLoading,
		isEnrolled,
	};
};
