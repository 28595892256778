import { ApiAsset } from '@/components/app/cards/PacksList/types';

type ContentPolicy = {
	path: string;
	name: string;
	description?: string;
	capabilities?: {
		streamOnly?: true;
		signedCookie?: true;
	};
	georestriction?: {
		kind: 'allow' | 'deny';
		countries: string[];
	};
};
export interface Guide {
	alt_assets: ApiAsset[];
	asset: ApiAsset;
	assets: ApiAsset[];
	cell_image?: ApiAsset;
	quote?: string;
	quote_author?: string;
	quote_image?: ApiAsset;
	content_policies: ContentPolicy[];
	created_at: string;
	id: string;
	is_free: boolean;
	is_trailer: boolean;
	narrator_name: string;
	position: number;
	program_variant_id: string;
	title: string;
	short_title?: string;
	subtitle?: string;
	timer_duration?: number;
	updated_at: string;
	variant_id: string;
	capabilities?: GuideCapabilities;
	has_transcript?: boolean;
	narrator_id?: string;
}

interface GuideCapabilities {
	non_shareable?: boolean;
	stream_only?: boolean;
}

export const SET_GUIDE = 'SET_GUIDE';

interface SetGuideAction {
	type: typeof SET_GUIDE;
	payload: Guide | null;
}

export type GuideActionType = SetGuideAction;
