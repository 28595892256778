import { Subscription } from '@/store/user/types';

export type PurchaseRequestResponse = Subscription | Record<string, unknown>;

export const isSubscriptionResponse = (data: PurchaseRequestResponse): data is Subscription => {
	return 'valid' in data;
};

export type RequiresActionResponse = {
	requiresAction: boolean;
	clientSecret: string;
	customerId: string;
	paymentIntentId: string;
	subscriptionId?: string;
};
