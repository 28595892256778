import { defineMessages } from 'react-intl';

export default defineMessages({
	defaultTitle: {
		id: 'b2b.landing.title.default',
		defaultMessage: 'Welcome to Calm',
		description: 'Default page title for B2B',
	},
	offerMessage: {
		id: 'dtcPartnership.landing.offerMessage',
		defaultMessage: '{partnerName} is offering you a Calm Premium subscription',
		description: 'The type of membership the user will receive',
	},
	partnershipOfferMessageDiscount: {
		id: 'b2b.landing.partnershipOfferMessageDiscount',
		defaultMessage: '{partnerName} is offering you {discountPercent}% off your {interval} of Calm Premium.',
		description: 'The type of membership the user will receive',
	},
	partnershipOfferMessageTrial: {
		id: 'b2b.landing.partnershipOfferMessageTrial',
		defaultMessage: '{partnerName} is offering you a {duration} free trial of Calm Premium.',
		description: 'The type of membership the user will receive',
	},
	partnershipOfferMessageDiscountAndTrialPluralized: {
		id: 'giftCertificate.purchaseConfirmation.partnershipOfferMessageDiscountAndTrialPluralized',
		defaultMessage:
			'{partnerName} is offering you a {duration} free trial and {discountPercent}% off your {interval} of Calm Premium.',
		description: 'Description on the success screen when the user successfully purchases a gift card',
	},
	partnershipOfferMessageDiscountAndTrial: {
		id: 'b2b.landing.partnershipOfferMessageDiscountAndTrial',
		defaultMessage:
			'{partnerName} is offering you a {duration} free trial and {discountPercent}% off your {interval} of Calm Premium.',
		description: 'The type of membership the user will receive',
	},
	partnershipOfferMessageNoDiscountAndNoTrial: {
		id: 'b2b.landing.partnershipOfferMessageNoDiscountAndNoTrial',
		defaultMessage: '{partnerName} is offering you Calm Premium.',
		description: 'The type of membership the user will receive',
	},
	P7D: {
		id: 'b2b.landing.sevenDay',
		defaultMessage: '7 day',
		description: 'translation of 7 day trial label',
	},
	P14D: {
		id: 'b2b.landing.fourteenDay',
		defaultMessage: '14 day',
		description: 'translation of 14 day trial label',
	},
	P1M: {
		id: 'b2b.landing.oneMonth',
		defaultMessage: '1 month',
		description: 'translation of 1 month trial label',
	},
	P2M: {
		id: 'b2b.landing.twoMonth',
		defaultMessage: '2 month',
		description: 'translation of 2 month trial label',
	},
	P3M: {
		id: 'b2b.landing.threeMonth',
		defaultMessage: '3 month',
		description: 'translation of 3 month trial label',
	},
	P4M: {
		id: 'b2b.landing.freeMonth',
		defaultMessage: '4 month',
		description: 'translation of 4 month trial label',
	},
	P6M: {
		id: 'b2b.landing.sixMonth',
		defaultMessage: '6 month',
		description: 'translation of 6 month trial label',
	},
	P12M: {
		id: 'b2b.landing.twelveMonth',
		defaultMessage: '12 month',
		description: 'translation of 12 month trial label',
	},
	adidasActivate6m: {
		id: 'b2b.landing.adidasSixMonth2',
		defaultMessage: 'adidas is offering you a 6 month free trial.',
		description: 'Adidas six month offer header',
	},
	adidasActivate12m: {
		id: 'b2b.landing.adidasTwelveMonth2',
		defaultMessage: 'adidas is offering you a 12 month free trial.',
		description: 'Adidas twelve month offer header',
	},
	maybelline_headline: {
		id: 'dtc.landing.maybeline',
		defaultMessage: 'Maybelline New York is offering you a free year of Calm Premium.',
		description: "Maybelline's offer header",
	},
	tmobile_headline: {
		id: 'dtc.landing.tmobile',
		defaultMessage: 'T-Mobile customers get your first year of Calm for $10.',
		description: 'T-Mobile Tuesdays offer header',
	},
	aarp_headline: {
		id: 'dtc.landing.aarp3',
		defaultMessage: '{partnerName} members get Calm for $48.99/year - a $21 savings.',
		description: 'AARP offer header',
	},
	ally_12m_headline: {
		id: 'dtc.landing.ally12m',
		defaultMessage: 'Ally Financial is offering you a free year of Calm Premium.',
		description: 'Ally Financial twelve month offer header',
	},
});
