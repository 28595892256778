import type { MessageDescriptor } from 'react-intl';

import messages from './messages';

// Flags
export const FLAG_SAMSUNG_PROMOTION_JUL_2024 = 'samsung-partnership-2024-07-01';

// Samsung coupon from latest partnership starting from 2024-07-01
export const SAMSUNG_1MO_PROMOTION = 'samsung_health_1_months_coupon';
export const SAMSUNG_2MO_PROMOTION = 'samsung_health_2_months_coupon';
export const SAMSUNG_3MO_PROMOTION = 'samsung_health_3_months_coupon';
export const SAMSUNG_6MO_PROMOTION = 'samsung_health_6_months_coupon';
export const SAMSUNG_NO_PROMOTION = 'samsung_health_app';
type SamsungPromotionsData = {
	[key: string]: {
		messages: {
			subtitle: MessageDescriptor;
			purchaseTerms: MessageDescriptor;
		};
		subscriptionDetails: {
			duration?: number;
			units?: 'months' | 'days';
			type: 'subscribe' | 'freetrial';
		};
	};
};

export const samsungPromotionsData: SamsungPromotionsData = {
	[SAMSUNG_1MO_PROMOTION]: {
		messages: {
			subtitle: messages.samsung1MoTrialSubtitle,
			purchaseTerms: messages.samsung1MoTrialPurchaseTerms,
		},
		subscriptionDetails: {
			type: 'freetrial',
			duration: 1,
			units: 'months',
		},
	},
	[SAMSUNG_2MO_PROMOTION]: {
		messages: {
			subtitle: messages.samsung2MoTrialSubtitle,
			purchaseTerms: messages.samsung2MoTrialPurchaseTerms,
		},
		subscriptionDetails: {
			type: 'freetrial',
			duration: 2,
			units: 'months',
		},
	},
	[SAMSUNG_3MO_PROMOTION]: {
		messages: {
			subtitle: messages.samsung3MoTrialSubtitle,
			purchaseTerms: messages.samsung3MoTrialPurchaseTerms,
		},
		subscriptionDetails: {
			type: 'freetrial',
			duration: 3,
			units: 'months',
		},
	},
	[SAMSUNG_6MO_PROMOTION]: {
		messages: {
			subtitle: messages.samsung6MoTrialSubtitle,
			purchaseTerms: messages.samsung6MoTrialPurchaseTerms,
		},
		subscriptionDetails: {
			type: 'freetrial',
			duration: 6,
			units: 'months',
		},
	},
	[SAMSUNG_NO_PROMOTION]: {
		messages: {
			subtitle: messages.samsungSuccessSubtitle,
			purchaseTerms: {}, // not used since it's not an actual promotion
		},
		subscriptionDetails: {
			type: 'subscribe',
		},
	},
};

export const samsungPromotions = Object.keys(samsungPromotionsData);
export const isSamsungPromotion = (promotion: string | null | undefined) =>
	promotion && samsungPromotions.includes(promotion);
export const isSamsungFreetrial = (promotion: string | null | undefined) =>
	promotion &&
	isSamsungPromotion(promotion) &&
	samsungPromotionsData[promotion].subscriptionDetails.type === 'freetrial';
