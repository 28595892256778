import { defineMessages } from 'react-intl';

export const messages = defineMessages({
	tooltipMessage: {
		id: 'shareButton.tooltip.message',
		defaultMessage: 'Share this with a friend and they can listen for free!',
		description: 'Explaining to the user how the sharing functionality works',
	},
	shareCTA: {
		id: 'shareButton.title',
		defaultMessage: 'Share',
		description: 'Title of share CTA button.',
	},
});
