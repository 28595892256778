import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { useId, useState } from 'react';

import { useAnalytics } from '@/hooks/analytics';

import { ErrorContainer, INPUT_OPTIONS, InputWrapper, Label, Wrapper } from './styles';
import { CreditCardInputProps } from './types';

const CreditCardInput = ({
	halfWidth,
	placeholder,
	StripeElement,
	analyticsPrefix,
	setIsValid,
}: CreditCardInputProps) => {
	const { logEvent } = useAnalytics();
	const [isFocused, setIsFocused] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [isEmpty, setIsEmpty] = useState(true);
	const [error, setError] = useState('');
	const inputId = useId();

	const onFocus = () => {
		setIsFocused(true);
	};

	const onBlur = () => {
		setIsFocused(false);
	};

	const onChange = (event: StripeElementChangeEvent) => {
		setIsEmpty(event.empty);
		setError(event.error?.message as string);
		if (event.complete) {
			logEvent({
				eventName: `${analyticsPrefix} : Entered`,
			});
			setIsValid(true);
		} else {
			setIsValid(false);
		}
		setIsComplete(event.complete);
	};

	return (
		<Wrapper halfWidth={halfWidth}>
			<InputWrapper isValid={isComplete} isError={!!error} isFocused={isFocused}>
				<StripeElement
					id={inputId}
					options={INPUT_OPTIONS}
					onBlur={onBlur}
					onChange={onChange}
					onFocus={onFocus}
				/>
				<Label isFocused={isFocused || !isEmpty}>{placeholder}</Label>
			</InputWrapper>
			{error && (
				<ErrorContainer role="alert" aria-describedby={inputId}>
					{error}
				</ErrorContainer>
			)}
		</Wrapper>
	);
};

export default CreditCardInput;
