import styled from 'styled-components';

import { Button, minWidth } from '@calm-web/design-system';

import applePayIcon from 'icons/apple-pay.svg';

export const Wrapper = styled(Button)`
	height: 55px;

	${minWidth('tablet')} {
		height: 52px;
	}

	${minWidth('desktop')} {
		height: 55px;
	}
`;

export const ApplePayIcon = styled(applePayIcon)`
	width: 42px;

	${minWidth('tablet')} {
		width: 54px;
	}

	${minWidth('desktop')} {
		width: 57px;
	}
`;
