import { Appearance } from '@stripe/stripe-js';

import { CalmTheme } from '@calm-web/design-system';

export const stripeAppearanceOptions: Appearance = {
	labels: 'floating',
	variables: {
		fontFamily: 'Avenir Next, Arial',
		colorPrimary: CalmTheme.palette.buttonBlue,
		colorText: CalmTheme.palette.gray7,
		colorDanger: CalmTheme.palette.errorRed,
		borderRadius: '36px',
	},
	rules: {
		'.Tab': {
			borderRadius: '6px',
		},
		'.Label': {
			color: CalmTheme.palette.gray5,
			fontWeight: '500',
		},
		'.Input': {
			border: `2px ${CalmTheme.palette.gray2} solid`,
			borderRadius: '6px',
			color: CalmTheme.palette.gray5,
			fontWeight: '500',
		},
		'.Error': {
			fontSize: '0.75rem',
			fontWeight: '500',
			padding: '4px 1px 0 0',
		},
	},
};

export const desktopStripeAppearanceOptions: Appearance = {
	...stripeAppearanceOptions,
	rules: {
		...stripeAppearanceOptions.rules,
		'.Error': {
			fontSize: `${0.75 * 1.125}rem`,
			fontWeight: '500',
			padding: '4px 1px 0 0',
		},
	},
};
