import MobileDetect from 'mobile-detect';

import { Device, OS } from '@/store/device/types';

export const getDeviceDataFromUserAgentString = (ua: string | undefined): Pick<Device, 'isMobile' | 'os'> => {
	if (!ua) {
		return { isMobile: true, os: null };
	}

	const md = new MobileDetect(ua);
	return { isMobile: Boolean(md.mobile()), os: md.os() as OS };
};

export const deviceCouldDownloadApp = (device: Device): boolean =>
	typeof device.os === 'string' && ['AndroidOS', 'iOS', 'iPadOS'].includes(device.os);

export const deviceSupportsAppStoreRedirect = (userAgent: string | undefined): boolean => {
	if (!userAgent) {
		return false;
	}

	const md = new MobileDetect(userAgent);
	return ['iOS', 'iPadOS'].includes(md.os());
};
