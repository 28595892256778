import { CalmLogger } from '@calm-web/logger';

const SERVICE = 'web-www';

export const calmLogger = new CalmLogger(
	process.env.NODE_ENV === 'production'
		? {
				service: SERVICE,
		  }
		: {},
);

export const datadogLogger = new CalmLogger(
	process.env.NODE_ENV === 'production'
		? {
				service: SERVICE,
		  }
		: {},
	true,
);
