import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { palette, ifProp } from 'styled-tools';

import {
	fontWeight,
	FontWeights,
	units,
	Button,
	FormInput,
	TextAlign,
	FontSizes,
	Text,
} from '@calm-web/design-system';

export const BodyHeaderText = styled.h2<{ isLeftAligned?: boolean }>`
	${fontWeight(FontWeights.Medium)};
	font-size: ${units(2.5)};
	line-height: 133.3%;
	text-align: ${p => (p.isLeftAligned ? 'left' : 'center')};
	color: ${palette('adaptiveText', 'white')};
	margin: auto;
	margin-bottom: ${units(1)};
	width: 100%;
`;

export const DescriptionText = styled.p<{ isLeftAligned?: boolean }>`
	line-height: 150%;
	width: 100%;
	margin: auto;
	margin: ${units(3, 0)};
	text-align: ${p => (p.isLeftAligned ? 'left' : 'center')};
	color: ${palette('adaptiveText', 'white')};
	${fontWeight(FontWeights.Regular)}
	font-size: ${units(2)};
`;

export const RedeemFlowButton = styled(Button).attrs({
	backgroundColor: 'transparent',
})`
	background-color: ${palette('redeemSubscriptionButtonBackground', 'white')};
	font-size: ${units(2)};
	${fontWeight(FontWeights.Demi)}
	line-height: 150%;
	padding: ${units(1.75)} ${units(3.5)};
	color: ${props =>
		palette('redeemSubscriptionButtonTextColor')(props) || rgba(palette('black')(props), 0.8)};
	cursor: pointer;
`;

export const RedeemBackButton = styled(Button).attrs({
	backgroundColor: 'white',
	border: 'solid',
	borderColor: 'blue5',
})`
	font-size: ${units(2)};
	${fontWeight(FontWeights.Demi)}
	line-height: 100%;
	padding: ${units(1.75)} ${units(3.5)};
	margin-top: ${units(1.75)};
	color: ${palette('buttonBlue')};
`;

export const Input = styled(FormInput)`
	margin-bottom: ${units(2.5)} !important;

	& > div {
		background-color: ${props => palette('inputBg')(props) || rgba(palette('black')(props), 0.4)};

		${ifProp(
			'disabled',
			css`
				background-color: ${palette('gray6')};
				input {
					color: ${palette('gray3')};
				}
			`,
		)}
	}

	input {
		color: ${palette('adaptiveText', 'white')};
		border: 1px solid ${palette('gray3')};
	}

	label {
		font-size: ${units(2)};
		${fontWeight(FontWeights.Medium)}
		line-height: 150%;
		color: ${palette('gray3')};
	}
`;

export const Header = styled(Text).attrs({
	el: 'h2',
	align: TextAlign.Center,
	color: 'white',
	lineHeight: FontSizes.xl,
	size: FontSizes.xl,
	weight: FontWeights.Regular,
})`
	margin-bottom: ${units(3)};
`;
