import styled from 'styled-components';
import { theme } from 'styled-tools';

import { units, fontWeight, FontWeights } from '@calm-web/design-system';

import { SKIP_TO_MAIN_INDEX } from '@/utils/ui/zIndices';

/**
 * ref: https://webaim.org/techniques/skipnav/
 */
export const SkipToMainLink = styled.a`
	background-color: white;
	border-radius: 0;
	${fontWeight(FontWeights.Demi)}
	left: 0;
	padding: ${units(1)};
	position: fixed;
	top: -60px;
	transition: ${theme('animation.transition')};
	z-index: ${SKIP_TO_MAIN_INDEX};

	&:focus {
		top: 0;
		transition: ${theme('animation.transition')};
	}
`;
