import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { D2CTheme } from '@calm-web/design-system';

import { FORTY_PERCENT_OFF_COUPON } from '@/components/app/layout/DiscountModal';
import { useAnalytics } from '@/hooks/analytics';
import { useUserState } from '@/hooks/store';
import { getCookie } from '@/utils/cookies';

import { ShareContext } from '../../shareFreeContent/ShareProvider';
import messages from './messages';
import { Wrapper } from './styles';

const UpsellButton = ({
	copy: _copy,
	onPress: _onPress,
	isLoading = false,
	forceShow: _forceShow = false,
}: {
	copy?: string;
	onPress?: () => void;
	isLoading?: boolean;
	forceShow?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const user = useUserState();
	const { logEvent } = useAnalytics();
	const router = useRouter();
	const { onShowSharePayment, sidebarCta, isShareContentRecipient } = useContext(ShareContext);

	const defaultCopy = isShareContentRecipient ? sidebarCta : formatMessage(messages.discountUpsell);
	const forceShow = isShareContentRecipient || _forceShow;
	const isUpsellFlow = getCookie('ftue-show-discount-cta');

	const isVisible = useMemo((): boolean => {
		if (forceShow) return true;
		return false;
	}, [forceShow]);

	const defaultOnPress = isShareContentRecipient
		? () => onShowSharePayment('sidebar')
		: async () => {
				const eventProps: Record<string, string | boolean> = isUpsellFlow
					? {
							is_free_trial: Boolean(user?.subscription?.has_ever_done_free_trial),
							coupon: FORTY_PERCENT_OFF_COUPON,
					  }
					: {
							copy: 'Get 40% off',
					  };
				logEvent({
					eventName: 'Web App : CTA : Clicked',
					eventProps: {
						type: 'button',
						...eventProps,
					},
				});
				await router.push(`/new-member-offer?coupon=${FORTY_PERCENT_OFF_COUPON}`);
		  };

	if (!isVisible) {
		return null;
	}

	return (
		<ThemeProvider theme={D2CTheme}>
			<Wrapper
				fullWidth
				textColor="blue2"
				backgroundColor="white"
				onPress={_onPress ?? defaultOnPress}
				isLoading={isLoading}
				loaderColor="blue2"
			>
				{_copy ?? defaultCopy}
			</Wrapper>
		</ThemeProvider>
	);
};

export default UpsellButton;
