import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

const OFFSET_HEIGHT = 16;

export const usePinnedHeader = (): boolean => {
	const [isPinned, setIsPinned] = useState(false);

	const handleScroll = debounce(() => {
		setIsPinned(window.scrollY > OFFSET_HEIGHT);
	}, 10);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return (): void => window.removeEventListener('scroll', handleScroll);
	}, [handleScroll]);

	useEffect(() => {
		if (window?.scrollY > OFFSET_HEIGHT) {
			setIsPinned(true);
		}
	}, []);

	return isPinned;
};
