import { SignupScreens } from '../../PreSignupProvider/types';

export const goalSelectionScreen: SignupScreens = {
	start: {
		componentName: 'Goals',
		analyticsName: 'FTUE : Goal Questionnaire',
		backButtonHidden: true,
		nextScreen: 'start',
	},
};
