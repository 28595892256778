/* eslint-disable max-len */

import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { D2CTheme } from '@calm-web/design-system';

import { samsungPromotionsData } from '@/components/subscribe/Samsung/types';
import { useFormattedPriceWithTax } from '@/hooks/purchase/useFormattedPriceWithTax';
import { usePricesState, usePurchaseParamsState } from '@/hooks/store';

import messages from './messages';
import { CancelLink, Disclaimer, DisclaimerWrapper } from './styles';
import { PurchaseTermsProps } from './types';

const PurchaseTerms = ({ hidePriceAfterDisclaimer, purchaseTerms }: PurchaseTermsProps) => {
	const prices = usePricesState();
	const { purchaseType, plan } = usePurchaseParamsState();
	const { formatMessage } = useIntl();
	const { query } = useRouter();
	const yearlyPriceWithTax = useFormattedPriceWithTax(prices.current.yearly);
	const originalYearlyPriceWithTax = useFormattedPriceWithTax(prices.original.yearly);
	const monthlyPriceWithTax = useFormattedPriceWithTax(prices.current.monthly);
	const originalMonthlyPriceWithTax = useFormattedPriceWithTax(prices.original.monthly);
	const lifetimePriceWithTax = useFormattedPriceWithTax(prices.current.lifetime);

	if (purchaseTerms) {
		return (
			<DisclaimerWrapper>
				<Disclaimer>*{purchaseTerms}</Disclaimer>
			</DisclaimerWrapper>
		);
	}

	if (
		purchaseType?.type === 'freetrial' ||
		(query.source === 'second_free_trial_offer' && purchaseType?.type === 'previously-trialed')
	) {
		if (purchaseType?.modifier === 'samsung_health' && purchaseType.promotion) {
			return (
				<DisclaimerWrapper>
					<Disclaimer>
						{formatMessage(samsungPromotionsData[purchaseType.promotion].messages.purchaseTerms)}
					</Disclaimer>
					<Disclaimer>
						<FormattedMessage
							id="redesign.purchase.creditCard.disclaimers.freeTrial2"
							defaultMessage="You may cancel at any time during your free trial and will not be charged. To cancel, go to your Profile and follow the instructions on the 'Manage Subscription' page."
							description="Second part of the disclaimers for the Samsung Health coupon"
						/>
					</Disclaimer>
				</DisclaimerWrapper>
			);
		}
		if (purchaseType?.modifier === 'guest_pass') {
			return (
				<DisclaimerWrapper>
					<Disclaimer>
						<FormattedMessage
							id="redesign.purchase.purchaseForm.freeTrial.cancelAnytime1"
							defaultMessage="You won't be charged until {afterWeekBold}."
							description="Explains when they will first pay money for their subscription"
							values={{
								afterWeekBold: (
									<b>
										<FormattedMessage
											id="redesign.purchase.purchaseForm.freeTrial.cancelGuestPass"
											defaultMessage="after your 30-day guest pass"
											description="Explains more details about when they will first pay money for their subscription"
										/>
									</b>
								),
							}}
						/>
					</Disclaimer>
					<Disclaimer>
						<FormattedMessage
							id="redesign.purchase.purchaseForm.guestPass.priceAfterDisclaimers"
							defaultMessage="{yearlyPrice} billed annually after guest pass."
							description="Explains when they will pay money for their subscription on an ongoing basis"
							values={{
								yearlyPrice: yearlyPriceWithTax,
							}}
						/>
					</Disclaimer>
				</DisclaimerWrapper>
			);
		}
		return (
			<DisclaimerWrapper>
				<Disclaimer>
					{formatMessage(messages.trialFlowDefault, {
						price: yearlyPriceWithTax,
						strong: chunks => <strong>{chunks}</strong>,
						cancellink: (chunks: ReactNode) => (
							<ThemeProvider theme={D2CTheme}>
								<CancelLink
									href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
									target="_blank"
									rel="noopener noreferrer"
								>
									{chunks}
								</CancelLink>
							</ThemeProvider>
						),
					})}
				</Disclaimer>
				{!hidePriceAfterDisclaimer && (
					<Disclaimer>
						<FormattedMessage
							id="redesign.purchase.purchaseForm.freeTrial.priceAfterDisclaimers"
							defaultMessage="{yearlyPrice} billed annually after free trial."
							description="Explains when they will pay money for their subscription on an ongoing basis"
							values={{
								yearlyPrice: yearlyPriceWithTax,
							}}
						/>
					</Disclaimer>
				)}
			</DisclaimerWrapper>
		);
	}
	if (purchaseType?.type === 'monthly') {
		return (
			<DisclaimerWrapper>
				<Disclaimer>
					<FormattedMessage
						id="purchase.creditCard.disclaimers.monthly"
						defaultMessage="The monthly membership gives you unlimited access to Calm's entire library of content. You will be charged {monthlyPrice} for a 1 month subscription that will renew at {nextMonthPrice}, billed monthly."
						description="Disclaimers for a monthly subscription"
						values={{
							monthlyPrice: monthlyPriceWithTax,
							nextMonthPrice: originalMonthlyPriceWithTax,
						}}
					/>
				</Disclaimer>
				<Disclaimer>
					<FormattedMessage
						id="redesign.purchase.creditCard.disclaimers.subscribe2"
						defaultMessage="You may cancel your subscription at any time by going to your Profile and following the instructions on the 'Manage Subscription' page."
						description="Disclaimers around cancelation for the yearly membership"
					/>
				</Disclaimer>
			</DisclaimerWrapper>
		);
	}
	if (purchaseType?.type === 'lifetime') {
		return (
			<DisclaimerWrapper>
				<Disclaimer>
					<FormattedMessage
						id="redesign.purchase.creditCard.disclaimers.lifetime.price"
						defaultMessage="The lifetime membership permanently gives you unlimited access to Calm's entire library of meditations, Sleep Stories, Music, Calm Body, and Calm Masterclass. You will be charged {lifetimePrice} for a lifetime membership."
						values={{
							lifetimePrice: lifetimePriceWithTax,
						}}
						description="Disclaimers for the lifetime membership"
					/>
				</Disclaimer>
			</DisclaimerWrapper>
		);
	}

	// Default to subscribe yearly
	return (
		<DisclaimerWrapper>
			<Disclaimer>
				{prices.coupons?.[plan]?.id === 'ge2019' ? (
					<FormattedMessage
						id="redesign.purchase.creditCard.disclaimers.subscribege"
						defaultMessage="The yearly membership gives you unlimited access to Calm's entire library of content. You will be charged {firstYearPrice} for a 1 year subscription."
						description="Disclaimers for the yearly membership"
						values={{
							firstYearPrice: yearlyPriceWithTax,
						}}
					/>
				) : (
					<FormattedMessage
						id="redesign.purchase.creditCard.disclaimers.subscribe1"
						defaultMessage="The yearly membership gives you unlimited access to Calm's entire library of content. You will be charged {firstYearPrice} for a 1 year subscription that will renew at {nextYearPrice}, billed annually."
						description="Disclaimers for the yearly membership"
						values={{
							firstYearPrice: yearlyPriceWithTax,
							nextYearPrice:
								prices.coupons?.[plan]?.duration === 'forever'
									? yearlyPriceWithTax
									: originalYearlyPriceWithTax,
						}}
					/>
				)}
			</Disclaimer>
			<Disclaimer>
				<FormattedMessage
					id="redesign.purchase.creditCard.disclaimers.subscribe2"
					defaultMessage="You may cancel your subscription at any time by going to your Profile and following the instructions on the 'Manage Subscription' page."
					description="Disclaimers around cancelation for the yearly membership"
				/>
			</Disclaimer>
		</DisclaimerWrapper>
	);
};

export default PurchaseTerms;
