import { defineMessages } from 'react-intl';

export default defineMessages({
	cvvHelpText: {
		id: 'redesign.purchase.creditCardForm.cvvHelpText',
		defaultMessage:
			"Your card's security code (CVC) is the 3 or 4 digit number located on the back of most cards",
		description: 'Help text for credit card security code',
	},
});
