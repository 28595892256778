import { announce } from '@react-aria/live-announcer';
import { useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import useIsFaved from '@/hooks/app/useIsFaved';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { calmLogger } from '@/utils/calmLogger';
import { AmplitudeExperimentNames } from '@/utils/experiments/amplitudeExperiment';

import heartIconFilled from 'icons/heart-filled.svg';
import heartIconOutline from 'icons/heart-outline.svg';

import messages from './messages';
import { Wrapper } from './styles';
import { FaveHeartProps } from './types';

const FaveHeart = ({ withBorder, guideId, guideTitle, shouldShowText }: FaveHeartProps) => {
	const apiRequest = useApi();
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { isEnrolled, isLoading } = useAmplitudeExperiment(AmplitudeExperimentNames.WEB_APP_REDESIGN_2);

	const [isFaved, setIsFaved] = useIsFaved(guideId);

	const onPress = async (): Promise<void> => {
		const addToFaves = !isFaved;
		setIsFaved(addToFaves);

		const messageToAnnounce = addToFaves ? messages.isFavedAnnouncement : messages.isUnfavedAnnouncement;

		announce(
			formatMessage(messageToAnnounce, {
				title: guideTitle,
			}),
		);

		try {
			await apiRequest({
				endpoint: `programs/guides/${guideId}/faves`,
				method: addToFaves ? 'POST' : 'DELETE',
			});

			logEvent({
				eventName: 'Fave Button : Fave : Posted',
				eventProps: {
					program_title: guideId,
				},
			});
		} catch (err) {
			calmLogger.error('Error favoriting content', {}, err);
		}
	};

	return (
		<Wrapper
			data-testid="fave-heart"
			aria-label={isFaved ? 'unmark as favorite' : 'mark as favorite'}
			aria-pressed={isFaved}
			onPress={onPress}
			$isFaved={isFaved}
			$withBorder={withBorder}
			Icon={isFaved ? heartIconFilled : heartIconOutline}
			$isWebAppRedesign={!isLoading && isEnrolled}
		>
			{shouldShowText && formatMessage(messages.favoriteCTA)}
		</Wrapper>
	);
};

export default FaveHeart;
