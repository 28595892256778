import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { fontWeight, FontWeights } from '@calm-web/design-system';

export const Wrapper = styled.p<{ lightText?: boolean }>`
	color: ${palette('gray7')};
	font-size: 18px;
	margin: 24px 0;

	& a {
		${fontWeight(FontWeights.Demi)};
		color: inherit;
	}

	${ifProp(
		'lightText',
		css`
			color: ${palette('white')};
		`,
	)}
`;
