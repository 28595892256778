import { authentication } from '@microsoft/teams-js';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useIntl } from 'react-intl';

import { useFormStateContext } from '@/context/FormStateContextProvider';
import { FORM_ACTIONS } from '@/hooks/forms';
import { useAuthFbUser } from '@/hooks/forms/useFacebookSubmit';
import messages from '@/messages/messages';
import getUrl from '@/utils/getUrl';

import { FBIcon, Wrapper } from './styles';
import { FacebookAuthButtonProps } from './types';

export default function FacebookAuthButtonTeams({
	className = '',
	onAuthSuccess,
	buttonText,
	marketingOptIn,
	contentAlignment,
	hideMessage = false,
}: FacebookAuthButtonProps) {
	const { formatMessage } = useIntl();
	const authUser = useAuthFbUser();
	const { formDispatch, setFormError, setFormSuccess } = useFormStateContext();

	async function onClick(): Promise<void> {
		formDispatch({ type: FORM_ACTIONS.requestSent });
		const url = `${getUrl('www')}/teams-calm/facebook-auth-end`;
		return authentication
			.authenticate({
				url: `${getUrl(
					'www',
				)}/teams-calm/facebook-auth-start?oauthRedirectMethod={oauthRedirectMethod}&authId={authId}&hostRedirectUrl=${url}`,
				isExternal: true,
			})
			.then(async response => {
				const user = JSON.parse(response);
				const data = await authUser(user, marketingOptIn);
				if (!data) {
					setFormSuccess(null);
					setFormError(null);
					formDispatch({ type: FORM_ACTIONS.requestCancelled });
				}

				if ('success' in data) {
					formDispatch({ type: FORM_ACTIONS.requestSuccess });
					if (onAuthSuccess) {
						onAuthSuccess(data.success);
					}
				} else if ('error' in data) {
					setFormError(data?.error);
					formDispatch({ type: FORM_ACTIONS.requestFailure });
				}
			});
	}

	if (!process.env.NEXT_PUBLIC_FACEBOOK_APP_ID) {
		return null;
	}

	const buttonCta = buttonText || formatMessage(messages.continueFacebookSignupMessage);

	return (
		<Wrapper
			className={className}
			onPress={onClick}
			backgroundColor="facebookBlue"
			contentAlignment={contentAlignment ?? hideMessage ? 'center' : 'left'}
			data-testid="facebook-button"
			role="link"
			$noPadding={hideMessage}
		>
			<FBIcon $noMargin={hideMessage} />
			{hideMessage ? <VisuallyHidden>{buttonCta}</VisuallyHidden> : buttonCta}
		</Wrapper>
	);
}
