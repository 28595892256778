import { useCallback, useEffect, useState } from 'react';

import { Loader } from '@calm-web/design-system';

import AppleAuthButton from '@/components/authForm/AppleAuthButton';
import AuthNotice from '@/components/authForm/AuthNotice';
import AuthTerms from '@/components/authForm/AuthTerms';
import EmailAuthButton from '@/components/authForm/EmailAuthButton';
import FacebookAuthButton from '@/components/authForm/FacebookAuthButton';
import GoogleAuthButton from '@/components/authForm/GoogleAuthButton';
import LoginSignupForm from '@/components/authForm/LoginSignupForm';
import ModeToggle from '@/components/authForm/ModeToggle';
import OrDivider from '@/components/authForm/OrDivider';
import { FormStateContextProvider } from '@/context/FormStateContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useAuth } from '@/hooks/auth/useAuth';
import { useAuthFormInputs } from '@/hooks/forms/useAuthFormInputs';
import { useAuthFormModeState, useRecaptchaVisibleState } from '@/hooks/store';
import { User } from '@/store/user/types';
import { postDevice } from '@/utils/endpoints';
import { getIsTouched } from '@/utils/forms/getIsTouched';

import MarketingOptIn from '../MarketingOptIn';
import { BackIcon, SocialSignupWrapper, Wrapper } from './styles';
import { AuthFormProps } from './types';

const AuthForm = ({
	onAuthSuccess,
	hasBackButton,
	className,
	initialValues,
	buttonText,
	facebookButtonText,
	googleButtonText,
	loginTermsText,
	signupTermsText,
	customPlaceholders,
	isB2B,
	showInputIcons,
	inputIcons,
	source,
	isCollege = false,
	disableFormToggle = false,
	disableSocialSignup = false,
	lightText = false,
	customEventProps,
}: AuthFormProps) => {
	const { isAuth0Enabled, isLoading } = useAuth();
	const recaptchaVisible = useRecaptchaVisibleState();

	const [inputFields, inputActions] = useAuthFormInputs(
		['name', 'email', 'password'] as const,
		initialValues,
		undefined,
		undefined,
		inputIcons,
	);
	const [marketingOptIn, setMarketingOptIn] = useState(true);
	const authFormMode = useAuthFormModeState();
	const { logEvent } = useAnalytics();

	const onChangeMarketingOptIn = useCallback((value: boolean) => setMarketingOptIn(value), []);

	async function _onAuthSuccess(user: User) {
		if (onAuthSuccess) {
			await onAuthSuccess(user);
		}

		// adding a POST /device call here so that we save user level info like timezone that we get from the browswer
		// instead of info coming from server where we make additional POST /device calls
		await postDevice();
	}

	useEffect(() => {
		const sourceProp: Record<string, string> = source ? { source } : {};
		const customProps = customEventProps ? customEventProps : {};

		logEvent({
			eventName: 'Login Form : Landed',
			eventProps: {
				...sourceProp,
				...customProps,
				mode: authFormMode,
			},
		});
	}, [logEvent, authFormMode, source, customEventProps]);

	if (isLoading) {
		return <Loader color="gray8" />;
	}

	return (
		<FormStateContextProvider>
			<Wrapper className={className} lightText={lightText} id="auth-form">
				{hasBackButton && getIsTouched(inputFields) && (
					<BackIcon onPress={inputActions.onClear} aria-label="clear input fields" />
				)}
				<>
					{!isAuth0Enabled ? (
						<>
							<LoginSignupForm
								inputActions={inputActions}
								inputFields={inputFields}
								onAuthSuccess={_onAuthSuccess}
								buttonText={buttonText}
								customPlaceholders={customPlaceholders}
								isCollege={isCollege}
								lightText={lightText}
								marketingOptIn={marketingOptIn}
								isB2B={isB2B}
								showInputIcons={showInputIcons}
								source={source}
							/>
							<AuthNotice />
							{!disableSocialSignup && (
								<>
									<OrDivider />
									<SocialSignupWrapper>
										<li>
											<FacebookAuthButton
												onAuthSuccess={_onAuthSuccess}
												buttonText={facebookButtonText}
												marketingOptIn={marketingOptIn}
											/>
										</li>
										<li>
											<AppleAuthButton onAuthSuccess={_onAuthSuccess} marketingOptIn={marketingOptIn} />
										</li>
										<li>
											<GoogleAuthButton
												onAuthSuccess={_onAuthSuccess}
												buttonText={googleButtonText}
												marketingOptIn={marketingOptIn}
											/>
										</li>
									</SocialSignupWrapper>
								</>
							)}
						</>
					) : (
						<SocialSignupWrapper>
							<li>
								<EmailAuthButton marketingOptIn={marketingOptIn} onAuthSuccess={_onAuthSuccess} />
							</li>
							<li>
								<FacebookAuthButton
									onAuthSuccess={_onAuthSuccess}
									buttonText={facebookButtonText}
									marketingOptIn={marketingOptIn}
								/>
							</li>
							<li>
								<AppleAuthButton onAuthSuccess={_onAuthSuccess} marketingOptIn={marketingOptIn} />
							</li>
							<li>
								<GoogleAuthButton
									onAuthSuccess={_onAuthSuccess}
									buttonText={googleButtonText}
									marketingOptIn={marketingOptIn}
								/>
							</li>
						</SocialSignupWrapper>
					)}
					{!recaptchaVisible && (
						<AuthTerms
							loginTermsText={loginTermsText}
							signupTermsText={signupTermsText}
							lightText={lightText}
						/>
					)}
					{authFormMode === 'signup' && <MarketingOptIn onChange={onChangeMarketingOptIn} />}
					{!disableFormToggle && <ModeToggle />}
				</>
			</Wrapper>
		</FormStateContextProvider>
	);
};

export default AuthForm;
