export const sceneThemeTypes = ['blue', 'night', 'green', 'purple', 'orange'] as const;

type SceneTheme = (typeof sceneThemeTypes)[number];

export const sceneThemeGradients: Record<
	SceneTheme,
	{
		gradientStartColor: string;
		gradientEndColor: string;
	}
> = {
	blue: {
		gradientStartColor: '#156494',
		gradientEndColor: '#2F2F7E',
	},
	night: {
		gradientStartColor: '#371E63',
		gradientEndColor: '#103071',
	},
	green: {
		gradientStartColor: '#3B6D3A',
		gradientEndColor: '#05493D',
	},
	purple: {
		gradientStartColor: '#843F6A',
		gradientEndColor: '#4F366F',
	},
	orange: {
		gradientStartColor: '#7E5606',
		gradientEndColor: '#81381E',
	},
};

export const sceneThemeMapping: Record<string, SceneTheme> = {
	// Jasper Lake
	ZFlV8dbxPd: 'blue',
	// Mountain Lake
	S38pOgKsfM: 'blue',
	// Rain On Leaves
	KRenfJ2WcN: 'green',
	// The Waterfall
	oJJuiLglvc: 'green',
	// Blue Gold
	'PaiBU-QKR3': 'purple',
	// Garden Rain
	YxyG1Gmmrn: 'green',
	// Calm River
	ZTzt5VUKLR: 'green',
	// Fireplace
	p9Kb0nj05: 'orange',
	// Tropical Beach
	'1auAzldp5k': 'blue',
	// Thunderstorm
	AL7jVb5K9: 'blue',
	// Silent Earth
	ZzwjdY1rq: 'blue',
	// Sunset Beach
	'8PliUgmUUp': 'orange',
	// Mystic Fountain
	BLjapx8l1: 'green',
	// Evening Crickets
	'3wb9A8A3B': 'orange',
	// Pouring Rain
	BXSpAYWDTQ: 'green',
	// Coastline At Sunset
	CsIvVSPOrN: 'orange',
	// Foggy Stream
	I5iFDW1Znn: 'blue',
	// Summer Meadow
	t5vo6FcfAq: 'orange',
	// Celestial Sunbeams
	ujxeUXRD7M: 'orange',
	// Woodland Lake
	lSC95pRkNc: 'purple',
	// Zen Ocean
	dFOvOCZToC: 'orange',
	// Suspended Droplets
	Jzc622sfOd: 'purple',
	// Wind in the Pines
	xMe8wBlA0: 'green',
	// Celestial White Noise
	G6jVx1VYm: 'blue',
	// Open Ocean
	'44ia9mwqUt': 'blue',
	// Plains of Wheat
	zja8JNEaS6: 'green',
	// Rhythmic Waves
	q6S8Nk21QW: 'blue',
	// Into the Horizon
	'08y03gWoY8': 'blue',
	// Under the Surface
	WymZ239Fnu: 'blue',
	// Passing Clouds
	hjTo81Axtx: 'blue',
	// Falling Snowflakes
	QtydmVydKW: 'blue',
	// Snowflakes in Moonlight
	F06IKipv6u: 'blue',
	// Gleaming Sunshine
	'6wR4PIYRLZ': 'green',
	// Silent Clouds
	xf0OOjwvuo: 'blue',
	// Radiating Energy
	wkPKcMLGPI: 'blue',
	// Gently Flowing Stream
	'2XVAtgp6ei': 'blue',
	// Flying Above the Clouds
	JuDHNIaQIC: 'blue',
	// Moving Cloudbreak
	dqP8CcrmNd: 'orange',
	// Falling Sundrops
	PKoI2dY4uC: 'orange',
};
