import styled from 'styled-components';

import { Button } from '@calm-web/design-system';

import lockIcon from 'icons/lock-outline.svg';

export const Wrapper = styled.form<{ centerFormElements: boolean }>`
	padding-top: 20px;
	text-align: ${p => (p.centerFormElements ? 'center' : 'left')};
	width: 100%;
`;

export const SubmitButton = styled(Button)`
	margin-top: 24px;
`;

export const LockIcon = styled(lockIcon)`
	background: white;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	margin-right: 8px;

	& path {
		fill: black;
	}
`;

export const SecureTransactionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 21px;
`;
