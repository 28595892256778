import {
	sleepDailyCommitmentMessages,
	troubleSleepingMessages,
	fallStayAsleepMessages,
	stopsSleepingMessages,
	miracleWorkerMessages,
	sleepNowLaterMessages,
	contentPreferencesMessages,
	onboardingMessages,
} from '../../ConfigData/MessageSets';
import {
	initialTransitionChildren,
	preferencesTransitionChildren,
	troubleSleepingResponse,
} from '../../ConfigData/PollSets';
import {
	contentPreferencesTiles,
	fallStayAsleepTiles,
	sleepNowLaterTiles,
	stopsSleepingTiles,
	troubleSleepingTiles,
} from '../../ConfigData/QuestionTileSets';
import { SignupScreens } from '../../PreSignupProvider/types';

export const sleepPreSignupScreens: SignupScreens = {
	start: {
		componentName: 'TransitionScreen',
		analyticsName: 'FTUE : Transition',
		transitionType: 'initial_transition_sleep',
		nextScreen: 'troubleSleeping',
		isDark: true,
		renderChildren: initialTransitionChildren,
		hasSkip: true,
	},
	troubleSleeping: {
		componentName: 'PollWithResponse',
		nextScreen: 'troubleSleepingSelected',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'trouble_sleeping_frequency',
		percentComplete: 16.67,
		tiles: troubleSleepingTiles,
		question: troubleSleepingMessages.question,
	},
	troubleSleepingSelected: {
		componentName: 'PollWithResponse',
		nextScreen: 'fallStayAsleep',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'trouble_sleeping_frequency',
		percentComplete: 16.67,
		tiles: troubleSleepingTiles,
		question: troubleSleepingMessages.question,
		isSelectedScreen: true,
		createResponse: troubleSleepingResponse,
	},
	fallStayAsleep: {
		componentName: 'PollSingleClick',
		nextScreen: 'stopsSleeping',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'fall_or_stay_asleep',
		percentComplete: 33.33,
		question: fallStayAsleepMessages.question,
		tiles: fallStayAsleepTiles,
	},
	stopsSleeping: {
		componentName: 'PollMultiClick',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'stops_sleeping',
		nextScreen: 'miracleWorker',
		percentComplete: 50,
		question: stopsSleepingMessages.question,
		tiles: stopsSleepingTiles,
		cta: onboardingMessages.cta,
	},
	miracleWorker: {
		componentName: 'TestimonialScreen',
		analyticsName: 'FTUE : Transition',
		transitionType: 'miracle_worker_testimonial',
		nextScreen: 'sleepNowLater',
		percentComplete: 50,
		quote: miracleWorkerMessages.quote,
		author: miracleWorkerMessages.author,
		isDark: false,
	},
	sleepNowLater: {
		componentName: 'PollSingleClick',
		nextScreen: 'preferencesTransition',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'sleep_now_or_later',
		percentComplete: 66.67,
		question: sleepNowLaterMessages.question,
		tiles: sleepNowLaterTiles,
		cta: sleepNowLaterMessages.cta,
	},
	preferencesTransition: {
		componentName: 'TransitionScreen',
		nextScreen: 'contentPreferences',
		analyticsName: 'FTUE : Transition',
		transitionType: 'sleep_preferences',
		isDark: true,
		renderChildren: preferencesTransitionChildren,
	},
	contentPreferences: {
		componentName: 'PollMultiClick',
		nextScreen: 'dailyCommitment',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'sleep_content_preferences',
		question: contentPreferencesMessages.question,
		percentComplete: 83.33,
		tiles: contentPreferencesTiles,
		cta: onboardingMessages.cta,
	},
	dailyCommitment: {
		componentName: 'TestimonialScreen',
		analyticsName: 'FTUE : Transition',
		transitionType: 'daily_commitment_testimonial',
		percentComplete: 83.33,
		quote: sleepDailyCommitmentMessages.quote,
		author: sleepDailyCommitmentMessages.author,
		isDark: false,
	},
	ineligible: {
		componentName: 'Ineligible',
		analyticsName: 'Free Access - Ineligible',
		backButtonHidden: true,
		showNav: true,
	},
};
