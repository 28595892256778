import { useState } from 'react';

import { useTimeout } from './useTimeout';

export function useMountToggle(timeout = 200): boolean {
	const [isMounting, setIsMounting] = useState(true);

	useTimeout(() => {
		setIsMounting(false);
	}, timeout);

	return isMounting;
}
