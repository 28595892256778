import { rgba } from 'polished';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text, PaletteColor, FontSizes, FontWeights } from '@calm-web/design-system';

export const Wrapper = styled(Text).attrs(() => ({
	el: 'p',
	noMargin: true,
	size: FontSizes.sm,
	textAlign: 'center',
	weight: FontWeights.Demi,
}))<{
	$color: PaletteColor;
}>`
	background: ${p => rgba(palette(p.$color)(p), 0.1)};
	border-radius: 10px;
	color: ${p => palette(p.$color)};
	display: inline-block;
	margin: 15px auto;
	padding: 5px 12px;
	text-align: center;
`;
