import styled from 'styled-components';
import { palette } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.div<{ sizeTheme: 'large' | 'small' }>`
	background-color: transparent;
	height: 2px;
	overflow: visible;
	width: ${p => (p.sizeTheme === 'large' ? '100%' : '36px')};

	${minWidth('tablet')} {
		width: 100%;
	}
`;

export const RangeBar = styled.input`
	appearance: none;
	background-color: transparent;
	cursor: pointer;
	display: block;
	height: 2px;
	margin: 0;
	overflow: visible;
	padding-bottom: 3px;
	padding-top: 3px;
	position: relative;
	top: -5px;
	width: 100%;

	:disabled {
		cursor: not-allowed;
	}

	::-webkit-slider-runnable-track {
		background: transparent;
		height: 100%;
		width: 100%;
	}

	::-webkit-slider-thumb {
		appearance: none;
		background: #3bbeec;
		border: 2px rgba(59, 160, 236, 1) solid;
		border-radius: 50%;
		box-sizing: content-box;
		display: block;
		height: 8px;
		opacity: 0;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		width: 8px;

		${minWidth('tablet')} {
			border: 4px rgba(59, 160, 236, 1) solid;
			opacity: 1;
		}
	}

	::-moz-range-progress {
		background: #3bbeec;
	}

	::-moz-range-track {
		background: ${palette('white')};
	}

	::-moz-range-thumb {
		appearance: none;
		margin: 0;
		height: 14px;
		width: 14px;
		background: rgba(59, 190, 236, 1);
		border-radius: 100%;
		border: 0;
		display: block;
		opacity: 0;
		transition: background-color 150ms;

		${minWidth('tablet')} {
			opacity: 1;
		}
	}

	::-ms-fill-lower {
		background: #3bbeec;
	}

	::-ms-fill-upper {
		background: rgba(255, 255, 255, 0.4);
	}
`;

export const ProgressBar = styled.div<{ percentProgress: number }>`
	background: #3bbeec;
	cursor: pointer;
	height: 2px;
	left: 0px;
	position: relative;
	top: 0px;
	width: calc(${p => p.percentProgress}% + 1px);
`;

export const UnfinishedProgressBar = styled.div`
	background: ${palette('white')};
	cursor: pointer;
	height: 2px;
	left: 0px;
	overflow: hidden;
	position: relative;
	top: 0px;
	width: 100%;
`;
