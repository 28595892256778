import { defineMessages } from 'react-intl';

export default defineMessages({
	appleSignInCta: {
		id: 'auth.appleSignIn.cta',
		defaultMessage: 'Sign in with Apple',
		description: 'Cta for apple sign in button',
	},
	appleContinueCta: {
		id: 'auth.appleContinue.cta',
		defaultMessage: 'Continue with Apple',
		description: 'Cta for apple continue button',
	},
});
