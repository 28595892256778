import { useContext, useEffect, useState } from 'react';

import { Notice } from '@calm-web/design-system';

import { FormStateContext } from '@/context/FormStateContextProvider';
import { FORM_STATES } from '@/hooks/forms';

import { Wrapper } from './styles';

const AuthNotice = () => {
	const [noticeMessage, setNoticeMessage] = useState('');
	const { formState, formSuccess, formError } = useContext(FormStateContext);

	function clearNotice(): void {
		setNoticeMessage('');
	}

	useEffect(
		() => {
			clearNotice();
			if (formError && formState === FORM_STATES.failure) {
				setNoticeMessage(formError);
			}
			if (formSuccess && formState === FORM_STATES.success) {
				setNoticeMessage(formSuccess);
			}
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formState],
	);

	if (noticeMessage === '') return null;

	return (
		<Wrapper role="alert" aria-live="polite" data-testid="login-tooltip">
			<Notice
				isSuccess={formState !== FORM_STATES.failure && formState !== FORM_STATES.idle}
				onClick={clearNotice}
				aria-describedby="auth-form"
			>
				{noticeMessage}
			</Notice>
		</Wrapper>
	);
};

export default AuthNotice;
