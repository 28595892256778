import { defineMessages } from 'react-intl';

export default defineMessages({
	cardNumber: {
		id: 'redesign.purchase.creditCardForm.cardNumber',
		defaultMessage: 'Card Number',
		description: 'CC Number',
	},
	cardNumberError: {
		id: 'redesign.purchase.creditCardForm.cardNumberError',
		defaultMessage: 'Card Number is required!',
		description: 'CC Number Required',
	},
	expiryEmptyError: {
		id: 'redesign.purchase.creditCardForm.expiryEmptyError',
		defaultMessage: 'Exp (MM/YY) is required!',
		description: 'CC Expiry Required',
	},
	expiryShortPlaceholder: {
		id: 'redesign.purchase.creditCardForm.expiryShortPlaceholder',
		defaultMessage: 'Exp (MM/YY)',
		description: 'CC Expiry Placeholder',
	},
	cvcEmptyError: {
		id: 'redesign.purchase.creditCardForm.cvcEmptyError',
		defaultMessage: 'CVC is required!',
		description: 'CC CVC Required',
	},
	cvcShortPlaceholder: {
		id: 'redesign.purchase.creditCardForm.cvcShortPlaceholder',
		defaultMessage: 'CVC',
		description: 'CC CVC Placeholder',
	},
});
