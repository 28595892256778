import { Wrapper } from './styles';
import { SceneVideoBackgroundProps } from './types';

const SceneVideoBackground = ({ isVisible, selectedScene }: SceneVideoBackgroundProps) => {
	const videoUrl = selectedScene.video?.url;

	return (
		<Wrapper isVisible={isVisible} data-testid="scene-video-background">
			{videoUrl && (
				<video
					loop
					muted
					playsInline
					autoPlay
					disablePictureInPicture
					controlsList="nodownload noplaybackrate"
					key={videoUrl}
				>
					<source
						src={videoUrl}
						type={selectedScene.video?.content_type}
						placeholder={selectedScene.image.download_url}
					/>
				</video>
			)}
		</Wrapper>
	);
};

export default SceneVideoBackground;
