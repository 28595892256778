export const ALREADY_TRIALING = { type: 'already-trialing' } as const;
export const ALREADY_SUBSCRIBED = { type: 'already-subscribed' } as const;
export const PREVIOUSLY_TRIALED = { type: 'previously-trialed' } as const;

export const subscribePurchaseErrors = [
	ALREADY_TRIALING.type,
	ALREADY_SUBSCRIBED.type,
	PREVIOUSLY_TRIALED.type,
] as const;

export type SubscribePurchaseError = (typeof subscribePurchaseErrors)[number];

export const CalendarUnits = {
	MONTHS: 'months',
	DAYS: 'days',
};
