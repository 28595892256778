import React, {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useMemo,
	useState,
} from 'react';

import { Loader } from '@calm-web/design-system';

import { FullScreenLoaderWrapper } from './styles';

export interface AuthContextState {
	isAuthenticating: boolean;
	setIsAuthenticating: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextState>({
	isAuthenticating: false,
	setIsAuthenticating: () => {},
});

export const useAuthContext = (): AuthContextState => useContext(AuthContext);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const value = useMemo(
		() => ({
			isAuthenticating,
			setIsAuthenticating,
		}),
		[isAuthenticating],
	);

	return (
		<AuthContext.Provider value={value}>
			{isAuthenticating && (
				<FullScreenLoaderWrapper>
					<Loader color="white" />
				</FullScreenLoaderWrapper>
			)}
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
