import styled from 'styled-components';

import lockIcon from 'icons/lock-outline.svg';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 21px;
`;

export const LockIcon = styled(lockIcon)`
	background: white;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	margin-right: 8px;

	& path {
		fill: black;
	}
`;
