import { PaymentType } from '@/components/purchase/PurchaseForm/types';
import { useAnalytics } from '@/hooks/analytics';
import { formatError } from '@/utils/formatErrors';

import { usePaymentFormValues } from './usePaymentFormValues';

interface SanitizedError {
	[key: string]: string | boolean;
}

interface OnPurchaseErrorArgs {
	err: { [key: string]: string | boolean };
	analyticsPrefix?: string;
	paymentType?: PaymentType;
}

interface OnPurchaseError {
	({ err, analyticsPrefix }: OnPurchaseErrorArgs): SanitizedError;
}

interface UsePurchaseError {
	(): OnPurchaseError;
}

export const usePurchaseError: UsePurchaseError = () => {
	const { logEvent } = useAnalytics();
	const { paymentFormCurrency } = usePaymentFormValues();

	const onPurchaseError: OnPurchaseError = ({
		err,
		analyticsPrefix = 'Subscribe : Purchase : Form',
		paymentType = 'credit_card',
	}) => {
		const sanitizedError: SanitizedError = {
			...err,
			calmCode: err?.calmCode || err?.code,
		};
		const paymentError = sanitizedError?.didTimeout ? 'timeout' : sanitizedError.calmCode;
		logEvent({
			eventName: `${analyticsPrefix} : Error`,
			eventProps: {
				error: paymentError,
				currency: paymentFormCurrency,
				payment_type: paymentType,
				...formatError(sanitizedError),
			},
		});

		return sanitizedError;
	};

	return onPurchaseError;
};
