import styled from 'styled-components';
import { palette } from 'styled-tools';

import { ChipButton, minWidth } from '@calm-web/design-system';

export const Wrapper = styled(ChipButton)`
	&&& {
		margin-bottom: 12px;
		text-align: left;
		width: 100%;
		position: relative;

		${minWidth('desktop')} {
			height: 100%;
			padding: 2px 16px;
			width: 100%;
		}

		&:focus {
			&:before {
				opacity: 0;
			}
		}
	}
`;

export const PlanSkuInterior = styled.div`
	color: ${palette('blackAlpha80')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 100%;
`;

export const TrialBadge = styled.div`
    background: ${palette('gradientRainbow6_dark')};
    position: absolute;
    top: -15px;
    right: 20px;
    padding: 6px 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    max-height: 33px;
    z-index: 2;
}
`;

export const PlanSkuLeft = styled.div``;

export const PlanSkuRight = styled.div``;
