import { defineMessages } from 'react-intl';

const messages = defineMessages({
	quizResultsHeading: {
		id: 'quizResults.heading3',
		defaultMessage: 'Try Calm Premium with a {trialLengthDays}-day free trial',
		description: 'Heading for quiz results',
	},
	quizResultsSubheading: {
		id: 'quizResults.subheading2',
		defaultMessage: 'Get started with a {trialLengthDays}-day free trial of Calm Premium.',
		description: 'Subheading for quiz results',
	},
	sleep: {
		id: 'quizResults.sleep',
		defaultMessage: 'sleep',
		description: 'translation for the word sleep in the quiz results',
	},
	stress: {
		id: 'quizResults.stress',
		defaultMessage: 'stress',
		description: 'translation for the word stress in the quiz results',
	},
	recImage: {
		id: 'quizResults.recImage',
		defaultMessage: 'Recommended content image',
		description: 'alt text for recommended content image',
	},
	quizRecommendationOpener: {
		id: 'quizResults.recOpener',
		defaultMessage: 'Based on your answers, we recommend:',
		description: 'Opener for recommended content',
	},
	stressQuizResult: {
		id: 'quizResults.stressQuizResult2',
		defaultMessage: 'Relax your Mind and 7 Days of Managing Stress',
		description: 'Stress Quiz Result',
	},
	sleepQuizResult: {
		id: 'quizResults.sleepQuizResult2',
		defaultMessage: '432 Hz. for Sleep and Rain on Leaves',
		description: 'Sleep Quiz Result',
	},
});

export default messages;
