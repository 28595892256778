import { Prices, SET_PRICE, SET_PRICES, SetPriceAction, SetPricesAction } from './types';

export function setPrices(prices: Prices): SetPricesAction {
	return {
		type: SET_PRICES,
		payload: prices,
	};
}

export function updatePrice(updatedPrice: Partial<Prices>): SetPriceAction {
	return {
		type: SET_PRICE,
		payload: updatedPrice,
	};
}
