import { defineMessages } from 'react-intl';

export default defineMessages({
	marketingOptIn: {
		id: 'login.marketingOptIn',
		defaultMessage: 'I want to hear about offers and promos.',
		description: 'Label for a checkbox that lets users opt in to marketing emails.',
	},
	marketingOptInButtonLabel: {
		id: 'marketingOptIn.optIn',
		defaultMessage: 'Click the button below if you want to hear about offers and promos.',
		description: 'Label for a button that lets users opt in to marketing emails.',
	},
	noEmailAssociated: {
		id: 'marketingOptIn.noEmail',
		defaultMessage:
			'There is no email currently associated with your account. You can update this in your profile.',
		description:
			"if the user has no email attached to the account, and tries to sign up for marketing emails, we need to let them know we can't send them.",
	},
});
