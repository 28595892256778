import { FBLoginResponse, FacebookSignInToken } from '../facebook/types';

const MAX_RETRIES = 10;
const RETRY_DELAY = 200;

export async function getFacebookSignInToken(retries = 0): Promise<FacebookSignInToken | null> {
	if (retries > MAX_RETRIES) return null;

	if (window?.FB) {
		return new Promise(resolve => {
			window.FB.login(
				() => {
					window.FB.getLoginStatus((response: FBLoginResponse) => {
						if (response.status === 'connected') {
							const tokenExpiration = new Date(
								new Date().getTime() + 1000 * response.authResponse.expiresIn,
							).toISOString();
							resolve({
								token: response.authResponse.accessToken,
								user_id: response.authResponse.userID,
								token_expiration: tokenExpiration,
							});
						} else {
							resolve({ error: 'cancelled' });
						}
					});
				},
				{
					scope: 'email,user_age_range,user_birthday,user_gender',
				},
			);
		});
	}

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			getFacebookSignInToken(retries + 1)
				.then(resolve)
				.catch(reject);
		}, RETRY_DELAY);
	});
}
