import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { setNotification } from '@/store/notification/actions';
import { copyText } from '@/utils/copyText';

import CheckIcon from 'icons/check-simple.svg';
import LinkIcon from 'icons/link-outline.svg';

import messages from './messages';
import { Input, Label, Wrapper } from './styles';
import { CopyLinkFieldProps } from './types';

const CopyLinkField = ({
	url,
	colorTheme = 'light',
	className,
	onClick,
	icon = LinkIcon,
	label,
	successMessage,
	notificationBackgroundColor,
	notificationPlacement,
}: CopyLinkFieldProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const handleOnClick = async () => {
		await copyText(url);
		onClick();
		dispatch(
			setNotification({
				backgroundColor: notificationBackgroundColor ?? 'rgba(0, 0, 0, 0.6)',
				position: notificationPlacement ?? 'bottom',
				children: (
					<>
						<CheckIcon />
						{successMessage ?? formatMessage(messages.successNotification)}
					</>
				),
			}),
		);
	};

	useEffect(
		() => {
			return () => {
				dispatch(setNotification(null));
			};
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<Wrapper className={className}>
			<Input
				noValidation
				name="copy-link"
				Icon={icon}
				value={url}
				onChange={() => null}
				onClick={handleOnClick}
				placeholder={label ?? formatMessage(messages.label)}
				label={label ?? formatMessage(messages.label)}
				LabelComponent={props => <Label {...props} $colorTheme={colorTheme} />}
			/>
		</Wrapper>
	);
};

export default CopyLinkField;
