import { ReactNode, createContext, useMemo, useState, useContext } from 'react';

import { Pack, PackCardItem } from '@/components/app/cards/PacksList/types';
import { Guide } from '@/store/guide/types';
import { Program } from '@/store/program/types';

import { PacksContextProps } from './types';

export const PacksContext = createContext<PacksContextProps>({
	currentGuide: null,
	setCurrentGuide: () => {},
	currentProgram: null,
	setCurrentProgram: () => {},
	currentPack: null,
	setCurrentPack: () => {},
	pageTitle: '',
	setPageTitle: () => {},
	pageSubtitle: '',
	setPageSubtitle: () => {},
	currentPackItem: null,
	setCurrentPackItem: () => {},
});

export const usePacksContext = () => {
	const value = useContext(PacksContext);
	return value;
};

export const PacksProvider = ({ children }: { children?: ReactNode }) => {
	const [currentProgram, setCurrentProgram] = useState<Program | null>(null);
	const [currentGuide, setCurrentGuide] = useState<Guide | null>(null);
	const [currentPack, setCurrentPack] = useState<Pack | null>(null);
	const [pageTitle, setPageTitle] = useState<string | null>(null);
	const [pageSubtitle, setPageSubtitle] = useState<string | null>(null);
	const [currentPackItem, setCurrentPackItem] = useState<PackCardItem | null>(null);

	const value = useMemo(
		() => ({
			currentGuide,
			setCurrentGuide,
			currentProgram,
			setCurrentProgram,
			currentPack,
			setCurrentPack,
			pageTitle,
			setPageTitle,
			pageSubtitle,
			setPageSubtitle,
			currentPackItem,
			setCurrentPackItem,
		}),
		[currentGuide, currentPack, currentProgram, pageTitle, pageSubtitle, currentPackItem, setCurrentPackItem],
	);

	return <PacksContext.Provider value={value}>{children}</PacksContext.Provider>;
};

export default PacksProvider;
