import { Locale } from '@/store/locale/types';

export interface CallToAction {
	action_uri: string;
	name: string;
	require_oauth?: boolean;
	metadata?: {
		grace_period_expired?: boolean;
	};
}

export interface Device {
	calm_identifier?: string;
	language?: Locale;
	facebook_permissions?: string[];
	app_rate_prompt_delay?: number;
	isMobile?: boolean;
	os?: OS | null;
	deviceId?: string;
	ip_country?: string | null;
	requires_gdpr_choice?: boolean | null;
	requires_gdpr?: boolean | null;
	call_to_action?: CallToAction | null;
	isZoomApp?: boolean;
}

export const SET_DEVICE = 'SET_DEVICE';

interface SetDeviceAction {
	type: typeof SET_DEVICE;
	payload: Device;
}

export type DeviceActionTypes = SetDeviceAction;

// This is the library we use to detect OS:
// https://hgoebl.github.io/mobile-detect.js/doc/MobileDetect.html
export type OS =
	| 'AndroidOS'
	| 'BlackBerryOS'
	| 'PalmOS'
	| 'SymbianOS'
	| 'WindowsMobileOS'
	| 'WindowsPhoneOS'
	| 'iOS'
	| 'iPadOS'
	| 'MeeGoOS'
	| 'MaemoOS'
	| 'JavaOS'
	| 'webOS'
	| 'badaOS'
	| 'BREWOS';
