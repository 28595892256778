import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { IconButton, units } from '@calm-web/design-system';

import ChevronLeftIcon from 'icons/chevron-left.svg';

export const Wrapper = styled.div<{ lightText: boolean }>`
	color: ${palette('gray7')};
	text-align: center;
	${ifProp(
		'lightText',
		css`
			color: ${palette('white')};
		`,
	)}
`;

export const BackIcon = styled(IconButton).attrs({
	Icon: ChevronLeftIcon,
	color: 'gray5',
})`
	position: absolute;
	left: 0;
	top: 0;
	padding: ${units(2)};
	height: ${units(8)};
	width: ${units(8)};
	div {
		height: ${units(4)};
		width: ${units(4)};
		svg {
			height: ${units(4)};
			width: ${units(4)};
		}
	}
`;

export const SocialSignupWrapper = styled.ul`
	list-style-type: none;
	padding: 0;
`;
