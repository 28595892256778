import { validate } from '@/utils/ui/validator';

import { CustomerAddress } from '../PurchaseForm/types';

export const getRequiresPostalCode = (countryCode: string) => {
	return ['US', 'CA'].includes(countryCode?.toUpperCase() ?? '');
};

export const getIsPostalCodeValid = (customerAddress: CustomerAddress) => {
	if (customerAddress.country && !getRequiresPostalCode(customerAddress.country)) {
		return true;
	}

	if (
		customerAddress.country &&
		customerAddress.postalCode &&
		getRequiresPostalCode(customerAddress.country)
	) {
		if (customerAddress.country.toUpperCase() === 'CA' && validate('postal', customerAddress.postalCode)) {
			return true;
		}

		if (customerAddress.country.toUpperCase() === 'US' && validate('zip', customerAddress.postalCode)) {
			return true;
		}
	}

	return false;
};
