import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useMemo,
	useState,
	useEffect,
} from 'react';

import { User } from '@/store/user/types';

type InjectedOnAuthSuccess = (user: User) => Promise<void>;

export interface AuthModalContextState {
	isModalOpen: boolean;
	setIsModalOpen: Dispatch<SetStateAction<boolean>>;
	overrides: { injectedOnAuthSuccess: InjectedOnAuthSuccess; source?: string };
	setOverrides: (overrides: { injectedOnAuthSuccess: InjectedOnAuthSuccess; source?: string }) => void;
}

export const AuthModalContext = createContext<AuthModalContextState>({
	isModalOpen: false,
	setIsModalOpen: () => {},
	overrides: {
		injectedOnAuthSuccess: () => Promise.resolve(),
	},
	setOverrides: () => {},
});

export const useAuthModalContext = ({
	injectedOnAuthSuccess,
	source,
}: { injectedOnAuthSuccess?: InjectedOnAuthSuccess; source?: string } = {}): AuthModalContextState => {
	const value = useContext(AuthModalContext);
	const { setOverrides } = value;
	useEffect(() => {
		if (injectedOnAuthSuccess !== undefined) {
			const sourceOverride = source ? { source } : {};
			setOverrides({ injectedOnAuthSuccess, ...sourceOverride });
		}
	}, [injectedOnAuthSuccess, setOverrides, source]);

	return value;
};

const AuthModalContextProvider = ({ children }: { children: ReactNode }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [overrides, setOverrides] = useState({ injectedOnAuthSuccess: (_user: User) => Promise.resolve() });
	const value = useMemo(
		() => ({
			isModalOpen,
			setIsModalOpen,
			overrides,
			setOverrides,
		}),
		[isModalOpen, overrides],
	);

	return <AuthModalContext.Provider value={value}>{children}</AuthModalContext.Provider>;
};

export default AuthModalContextProvider;
