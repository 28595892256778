import { AxiosResponse } from 'axios';
import qs from 'query-string';

import apiRequest from '@/utils/apiRequest';

export function getDependents({ subscriptionId }: { subscriptionId: string | null }): Promise<AxiosResponse> {
	return apiRequest({
		endpoint: `b2b/dependents?${qs.stringify({
			b2b_partner_subscription_id: subscriptionId,
		})}`,
		method: 'GET',
	});
}
