import styled from 'styled-components';

import Thumbnail from '@/components/ui/Thumbnail';

export const ThumbnailWrapper = styled(Thumbnail)`
	margin: auto;
`;

export const ImageWrapper = styled.div`
	height: 230px;
	width: 100%;
	position: relative;
`;
