import { retryUntilAvailable } from '@/hooks/analytics/retryUntilAvailable';
import { LogEventArgs } from '@/hooks/analytics/types';

import { calmLogger } from '../calmLogger';

export const initSegmentUser = async (userId?: string) => {
	retryUntilAvailable(
		() => window.analytics.identify(userId),
		() => Boolean(window.analytics),
	).catch(error => calmLogger.error('Error in initSegmentUser', {}, error));
};

export const logSegmentEvent = async ({ eventName, eventProps }: LogEventArgs) => {
	retryUntilAvailable(
		() => window.analytics.track(eventName, eventProps),
		() => Boolean(window.analytics),
	).catch(error => calmLogger.error('Error in logSegmentEvent', {}, error));
};
