import { AnyAction } from 'redux';

import { ComplianceLevel, REQUIRE_GDPR_MODAL, SET_COMPLIANCE_LEVEL } from './types';

const initialState: ComplianceLevel = REQUIRE_GDPR_MODAL;

export function complianceLevelReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): ComplianceLevel {
	switch (action.type) {
		case SET_COMPLIANCE_LEVEL:
			return action.payload;
		default:
			return state;
	}
}
