import axios from 'axios';

import { userDataFromBrowser } from '@/utils/apiRequest';
import createReqHeaders from '@/utils/apiRequest/createReqHeaders';
import { pxTestHeaders } from '@/utils/apiRequest/perimeterx';
import { JSONValue } from '@/utils/types';

import getUrl from '../getUrl';

interface ApiFeatureFlag {
	name: string;
	value: JSONValue;
}

interface ApiFeatureFlagResponse {
	feature_flags: ApiFeatureFlag[];
}

export async function initFeatureFlags(flags?: string[]): Promise<ApiFeatureFlagResponse | null> {
	try {
		const userData = userDataFromBrowser();
		const headers: Record<string, string> = createReqHeaders({
			customHeaders: {
				...pxTestHeaders,
			},
			deviceId: userData.deviceId ?? '',
			userToken: userData.userToken,
			calmIdentifier: userData.calmIdentifier,
			path: userData.path ?? undefined,
		});

		const endpoint =
			flags && Boolean(flags?.length) ? `feature-flags?filter[name]=${flags.join(',')}` : 'feature-flags';

		const result = await axios.get<ApiFeatureFlagResponse>(`${getUrl('api')}/${endpoint}`, {
			timeout: 15000,
			headers,
		});
		return result?.data;
	} catch (err) {
		return null;
	}
}
