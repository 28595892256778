import { MessageDescriptor, useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics';
import { useAuth } from '@/hooks/auth/useAuth';
import { useAuthFormModeState } from '@/hooks/store';
import { useIsGDPRDevice, useIsUKGDPRDevice } from '@/hooks/user';
import genericMessages from '@/messages/messages';
import { AuthFormMode } from '@/store/authFormMode/types';

import { Wrapper } from './styles';
import { AuthTermsProps } from './types';

const AuthTerms = ({ signupTermsText, loginTermsText, lightText }: AuthTermsProps) => {
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();
	const isUKGDPRDevice = useIsUKGDPRDevice();
	const isGDPRDevice = useIsGDPRDevice();
	const useShortTerms = isGDPRDevice || isUKGDPRDevice;
	const { logEvent } = useAnalytics();
	const { isAuth0Enabled } = useAuth();

	const getDefaultTermsMessages = (): {
		[mode in AuthFormMode]: {
			[isShort in 'short' | 'normal']: MessageDescriptor;
		};
	} => {
		if (isAuth0Enabled) {
			return {
				signup: {
					normal: genericMessages.signupTermsV2,
					short: genericMessages.signupTermsShortV2,
				},
				login: {
					normal: genericMessages.loginTermsV2,
					short: genericMessages.loginTermsV2,
				},
			};
		}

		return {
			signup: {
				normal: genericMessages.signupTerms,
				short: genericMessages.signupTermsShort,
			},
			login: {
				normal: genericMessages.loginTerms2,
				short: genericMessages.loginTerms2,
			},
		};
	};

	const defaultTermsMessage = formatMessage(
		getDefaultTermsMessages()[authFormMode][useShortTerms ? 'short' : 'normal'],
		{
			terms: (
				<a
					href="/terms"
					target="_blank"
					onClick={() =>
						logEvent({
							eventName: `Login Form : Terms : Clicked`,
							eventProps: {
								mode: authFormMode,
							},
						})
					}
				>
					{formatMessage(genericMessages.terms)}
				</a>
			),
			privacypolicylink: (...chunks): JSX.Element => (
				<a
					href="/privacy"
					target="_blank"
					onClick={() =>
						logEvent({
							eventName: `Login Form : Privacy Policy : Clicked`,
							eventProps: {
								mode: authFormMode,
							},
						})
					}
				>
					{chunks}
				</a>
			),
		},
	);
	const customTermsMessages = authFormMode === 'login' ? loginTermsText : signupTermsText;

	const termsMessage = customTermsMessages ?? defaultTermsMessage;

	return (
		<Wrapper className="loginTerms" lightText={lightText}>
			{termsMessage}
		</Wrapper>
	);
};

export default AuthTerms;
