import { AxiosPromise } from 'axios';
import { ParsedUrlQuery } from 'querystring';

import { createPricesEndpoint } from '@/server/initState/getPrices';
import apiRequest from '@/utils/apiRequest';

export function getPrices(query: ParsedUrlQuery): AxiosPromise {
	let sanitizedQuery = { ...query };
	if (query.guestPass && query.coupon) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { coupon, lifetime_coupon, ...rest } = query;
		sanitizedQuery = { ...rest };
	}

	const endpoint = createPricesEndpoint(sanitizedQuery, sanitizedQuery.coupon);

	return apiRequest({
		endpoint,
		method: 'GET',
	});
}
