import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { useCallback } from 'react';

export interface RouterPush {
	(
		pathname: string,
		queryParams?: ParsedUrlQueryInput,
		options?: {
			shallow?: boolean;
			locale?: string | false;
			scroll?: boolean;
		},
	): Promise<void>;
}

export const filterQueryParams = (
	queryParams: ParsedUrlQueryInput,
	pathname?: string,
): ParsedUrlQueryInput => {
	// filter out null or undefined query params
	const filteredQueryParams = Object.entries(queryParams).reduce((accu, [key, value]) => {
		if (typeof value !== 'undefined' && value !== null && !pathname?.includes(`[${key}]`)) {
			return {
				...accu,
				[key]: value,
			};
		}
		return accu;
	}, {});
	return filteredQueryParams;
};

export const useRouterPush = (): RouterPush => {
	const { push, query, pathname: _pathname } = useRouter();

	const routerPush: RouterPush = useCallback(
		async (pathname, queryParams = {}, options = {}) => {
			const urlInfo = {
				pathname,
				query: filterQueryParams(
					{
						...query,
						...queryParams,
					},
					_pathname,
				),
			};

			await push(urlInfo, urlInfo, options);
		},
		[query, _pathname, push],
	);

	return routerPush;
};
