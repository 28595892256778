import { AnyAction } from 'redux';

import { Guide, SET_GUIDE } from './types';

const initialState: Guide | null = null;

export function guideReducer(state = initialState, action: AnyAction = { type: undefined }): Guide | null {
	switch (action.type) {
		case SET_GUIDE:
			return action.payload;
		default:
			return state;
	}
}
