const AuthFormModes = ['login', 'signup'] as const;
export type AuthFormMode = (typeof AuthFormModes)[number];
export function isAuthFormMode(value: string): value is AuthFormMode {
	return AuthFormModes.includes(value as AuthFormMode);
}

export const SET_AUTH_FORM_MODE = 'SET_AUTH_FORM_MODE';

export interface SetAuthFormModeAction {
	type: typeof SET_AUTH_FORM_MODE;
	payload: AuthFormMode;
}

export type AuthFormModeTypes = SetAuthFormModeAction;
