import { AnyAction } from 'redux';

import { ServerSide, SET_SERVER_SIDE } from './types';

const initialState: ServerSide = false;

export function serverSideReducer(state = initialState, action: AnyAction = { type: undefined }): ServerSide {
	switch (action.type) {
		case SET_SERVER_SIDE:
			return action.payload;
		default:
			return state;
	}
}
