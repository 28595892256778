import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import SadFaceSvg from '@/../public/_n/images/sad-face.svg';
import { Wrapper, SadFaceContainer } from '@/components/app/redeemSubscription/screens/ErrorScreen/styles';
import { BodyHeaderText, DescriptionText } from '@/components/app/redeemSubscription/styles';
import { calmLogger } from '@/utils/calmLogger';

import { messages } from './messages';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TeamsAuthErrorPage: React.FC<any> = props => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		calmLogger.info('Unable to get JWT token from teams', { ...props });
	}, [props]);

	return (
		<Wrapper isTeams>
			<SadFaceContainer>
				<SadFaceSvg role="img" aria-hidden />
			</SadFaceContainer>
			<BodyHeaderText>{formatMessage(messages.teamsAuthenticationErrorTitle)}</BodyHeaderText>
			<DescriptionText>{formatMessage(messages.teamsAuthenticationErrorBody1)}</DescriptionText>
			<DescriptionText>{formatMessage(messages.teamsAuthenticationErrorBody2)}</DescriptionText>
			<DescriptionText>
				{formatMessage(messages.teamsAuthenticationErrorBody3, {
					supportLink: (
						<a target="_blank" href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer">
							support.calm.com/hc/en-us?solvvyProvidedByEmployer
						</a>
					),
				})}
			</DescriptionText>
		</Wrapper>
	);
};

export default TeamsAuthErrorPage;
