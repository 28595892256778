import { ConfigResponse } from '@zoom/appssdk';

import { Device } from '@/store/device/types';
import { User } from '@/store/user/types';
import { Zoom } from '@/store/zoom/types';

const configNotLoaded = 'config_not_loaded';
const pendingParticipantQuery = 'pending_participant_query';

export const maybeGetZoomLogEventProps = (
	device: Device,
	zoom: Zoom | null,
):
	| {
			source: string;
			zoom: {
				zoomClientVersion: ConfigResponse['clientVersion'] | typeof configNotLoaded;
				zoomRunningContext: ConfigResponse['runningContext'] | typeof configNotLoaded;
				zoomBrowserVersion: ConfigResponse['browserVersion'] | typeof configNotLoaded;
				meetingParticipantCount: number | typeof pendingParticipantQuery;
			};
	  }
	| Record<string, never> =>
	device.isZoomApp
		? {
				source: 'zoom',
				zoom: {
					zoomClientVersion: zoom?.config?.clientVersion ?? configNotLoaded,
					zoomRunningContext: zoom?.config?.runningContext ?? configNotLoaded,
					zoomBrowserVersion: zoom?.config?.browserVersion ?? configNotLoaded,
					meetingParticipantCount: zoom?.meetingParticipantCount ?? pendingParticipantQuery,
				},
		  }
		: {};

export enum ZoomSub {
	B2B = 'B2B',
	B2B_DEPENDENT = 'B2B_Dependent',
	B2B_TEAMS = 'B2B_Teams',
	DTC = 'DTC',
	INVALID = 'INVALID',
}

export const getValidSubscriptionType = (user: User | null) => {
	if (!user || !user?.subscription?.valid) return ZoomSub.INVALID;
	if (user.subscription?.type === 'b2b') return ZoomSub.B2B;
	if (user.subscription?.type === 'b2b_dependent') return ZoomSub.B2B_DEPENDENT;
	if (user.subscription?.type === 'team') return ZoomSub.B2B_TEAMS;
	return ZoomSub.DTC;
};
