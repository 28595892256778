import { useIntl } from 'react-intl';

import { useLoginForm } from '@/hooks/forms';
import { useAuthFormModeState } from '@/hooks/store';
import genericMessages from '@/messages/messages';

import messages from './messages';
import { Cta, Wrapper } from './styles';
import { ModeToggleProps } from './types';

const ModeToggle = ({ isB2B }: ModeToggleProps) => {
	const { formatMessage } = useIntl();
	const { onModeToggle } = useLoginForm();
	const authFormMode = useAuthFormModeState();

	const isB2BVersion = isB2B;
	const loginToggle = isB2BVersion
		? formatMessage(messages.b2bLoginToggle)
		: formatMessage(messages.loginToggle);
	const signupToggle = isB2BVersion
		? formatMessage(messages.b2bSignupToggle)
		: formatMessage(messages.signupToggle);

	return (
		<Wrapper className="loginToggleWrapper" isInlineStyles={isB2BVersion}>
			{authFormMode === 'login' ? loginToggle : signupToggle}
			<Cta onClick={onModeToggle} data-testid="mode-toggle-button" isInlineStyles={isB2BVersion}>
				{authFormMode === 'login'
					? formatMessage(genericMessages.signup)
					: formatMessage(genericMessages.login)}
			</Cta>
		</Wrapper>
	);
};

export default ModeToggle;
