import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { setUser } from '@/store/user/actions';
import { useSetCalmToken } from '@/teams-calm/src/components/TeamsAuthWrapper';
import { clearExpiringCalmTokenForTeams } from '@/utils/app/msftTeams';
import { calmLogger } from '@/utils/calmLogger';
import { removeAllTeamsUserCookies } from '@/utils/privacyCookies';
import { JSONValue } from '@/utils/types';

export default function useLogoutofTeams(): [
	() => void,
	{
		loading: boolean;
		error: Error | undefined;
	},
] {
	const setCalmToken = useSetCalmToken();
	const dispatch = useDispatch();
	const apiRequest = useApi();
	const { logEventAsync } = useAnalytics();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | undefined>();

	const logUserOut = async (): Promise<void> => {
		try {
			setLoading(true);
			setError(undefined);
			await apiRequest({
				endpoint: 'ms-teams/logout',
				method: 'DELETE',
			});
			setCalmToken('');
			removeAllTeamsUserCookies();
			dispatch(setUser(null));
			clearExpiringCalmTokenForTeams();
		} catch (error) {
			try {
				await logEventAsync({
					eventName: 'Calm Teams: Logout: Unable to log Teams user out.',
					eventProps: { error: error as JSONValue },
				});
			} catch (error) {
				calmLogger.error('Error in teams-calm useLogoutOfTeams', {}, error);
			}
			setError(error);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	return [
		logUserOut,
		{
			loading,
			error,
		},
	];
}
