import { defineMessages } from 'react-intl';

export default defineMessages({
	home: {
		id: 'app.components.sidebar.homeTitle',
		defaultMessage: 'Home',
		description: 'Navigation link to go the homepage feed',
	},
	sleep: {
		id: 'app.components.sidebar.sleepTitle',
		defaultMessage: 'Sleep',
		description: 'Navigation link to go the Sleep feed',
	},
	meditate: {
		id: 'app.components.sidebar.meditateTitle',
		defaultMessage: 'Meditate',
		description: 'Navigation link to go the Meditate feed',
	},
	music: {
		id: 'app.components.sidebar.musicTitle',
		defaultMessage: 'Music',
		description: 'Navigation link to go the Music feed',
	},
	wisdom: {
		id: 'app.components.sidebar.wisdomTitle',
		defaultMessage: 'Wisdom',
		description: 'Navigation link to go the Wisdom feed',
	},
	forWork: {
		id: 'app.components.sidebar.forWorkTitle',
		defaultMessage: 'For Work',
		description: 'Navigation link to go the For Work feed',
	},
	movement: {
		id: 'app.components.sidebar.movementTitle',
		defaultMessage: 'Movement',
		description: 'Navigation link to go the Movement feed',
	},
	kids: {
		id: 'app.components.sidebar.kidsTitle',
		defaultMessage: 'Calm Kids',
		description: 'Navigation link to go the Kids feed',
	},
	profile: {
		id: 'app.components.sidebar.profileTitle',
		defaultMessage: 'Profile',
		description: "Navigation link to go to a user's profile",
	},
	privacyPolicy: {
		id: 'app.sidebar.privacyPolicy',
		defaultMessage: 'Privacy Policy',
		description: 'Link to privacy policy',
	},
	navAriaLabel: {
		id: 'app.components.sidebar.navAriaLabel',
		defaultMessage: 'main app',
		description: 'Main app navigation aria label',
	},
	logoAriaLabel: {
		id: 'generics.calmLogoAriaLabel2',
		defaultMessage: 'Calm Logo - Link to calm.com Homepage',
		description: 'A link that takes you to the homepage',
	},
	scenesLinkText: {
		id: 'app.ui.scenesLink',
		defaultMessage: 'Scenes',
		description: 'The title of the scenes selection page',
	},
	searchLinkText: {
		id: 'app.ui.searchLink.sidebar',
		defaultMessage: 'Search',
		description: 'The title of the search selection page',
	},
});
