import { defineMessages } from 'react-intl';

export default defineMessages({
	signInCta: {
		id: 'auth.googleSignIn.cta',
		defaultMessage: 'Sign in with Google',
		description: 'google signin button text',
	},
	signupCta: {
		id: 'auth.googleSignup.cta',
		defaultMessage: 'Sign up with Google',
		description: 'google signup button text',
	},
	continueCta: {
		id: 'auth.googleContinue.cta',
		defaultMessage: 'Continue with Google',
		description: 'google continue button text',
	},
});
