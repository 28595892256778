import { useIntl } from 'react-intl';

import { FontSizes, FontWeights } from '@calm-web/design-system';

import { CalendarUnits } from '@/hooks/purchase/purchase';
import { usePurchaseParamsState } from '@/hooks/store';

import messages from './messages';
import { ConfirmButton, Copy } from './styles';
import { PaymentDetailsModalProps } from './types';

const PaymentDetailsModal = ({ onCloseClick }: PaymentDetailsModalProps) => {
	const { formatMessage } = useIntl();
	const { purchaseType } = usePurchaseParamsState();
	const trialLength =
		purchaseType?.units === CalendarUnits.MONTHS
			? formatMessage(messages.trialMonths, {
					number: purchaseType?.duration,
			  })
			: formatMessage(messages.trialDays, {
					number: purchaseType?.duration,
			  });

	return (
		<div role="dialog">
			<Copy el="p" color="gray7" size={FontSizes.base} weight={FontWeights.Light}>
				{formatMessage(messages.trialInfo, { trialLength })}
			</Copy>
			<Copy el="p" color="gray7" size={FontSizes.base} weight={FontWeights.Light}>
				{formatMessage(messages.cancel, { trialLength })}
			</Copy>
			<ConfirmButton backgroundColor="blue2" onPress={onCloseClick}>
				{formatMessage(messages.confirmButtonCta)}
			</ConfirmButton>
		</div>
	);
};

export default PaymentDetailsModal;
