import { useContext } from 'react';

import { MarketingBanner } from '@calm-web/design-system';

import { ShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import { useThemeState } from '@/hooks/store';

const ShareContentBanner = ({
	showShareContent,
	onPress,
}: {
	showShareContent: boolean;
	onPress: () => void;
}) => {
	const { cta, heading, subheading } = useContext(ShareContext);

	const theme = useThemeState();

	if (theme.webAppLayout !== 'player') {
		return null;
	}

	return (
		<>
			{showShareContent && (
				<MarketingBanner
					headingCopy={heading}
					subheadingCopy={subheading}
					buttonCopy={cta}
					onPress={onPress}
				/>
			)}
		</>
	);
};

export default ShareContentBanner;
