import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { usePacksContext } from '@/components/app/layout/PacksProvider';
import LazyVideoOverlay from '@/components/ui/LazyVideoOverlay';
import { useThemeState } from '@/hooks/store';
import { calmLogger } from '@/utils/calmLogger';

import { TransportContainer } from '../AudioPlayer/styles';
import { MediaPlayerContext } from '../MediaPlayerProvider';
import PlayerUpsell from '../PlayerUpsell';

const VideoPlayer = () => {
	const router = useRouter();

	const { setMediaEl, onStop, hideControls, autoplayOnLoad } = useContext(MediaPlayerContext);
	const { currentGuide, currentProgram } = usePacksContext();
	const { isTeamsApp } = useThemeState();

	useEffect(() => {
		router.beforePopState(() => {
			onStop(true).catch(error => calmLogger.error('Error in VideoPlayer onStop', {}, error));
			return true;
		});
		// Clean up the beforePopState handler once we leave the VideoPlayer
		return () => {
			router.beforePopState(() => true);
		};
	}, [onStop, router]);

	if (!currentGuide?.asset?.url) {
		return null;
	}

	if (!currentGuide?.is_free) {
		return (
			<TransportContainer sizeTheme="large">
				<PlayerUpsell isVideo program={currentProgram} />
			</TransportContainer>
		);
	}

	return (
		<LazyVideoOverlay
			withCredentials
			src={currentGuide.asset?.url}
			type="application/x-mpegURL"
			title={currentGuide.short_title || currentGuide.title}
			onClose={() => onStop()}
			setMediaEl={setMediaEl}
			hideControls={hideControls}
			autoplay={autoplayOnLoad}
			// TODO: re-enable this once the seeking bug in LiveShareMedia is fixed
			hideSeekbar={isTeamsApp}
		/>
	);
};

export default VideoPlayer;
