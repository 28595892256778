import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text } from '@calm-web/design-system';

export const Wrapper = styled.div`
	text-align: center;
`;

export const Preheading = styled(Text).attrs({
	el: 'p',
	styleAs: 'h5',
})`
	color: ${palette('gray8')};
	font-weight: 300;
	margin-bottom: 8px;
`;

export const Heading = styled.h2`
	color: ${palette('gray8')};
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 24px;
`;

export const PriceContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
`;

export const SalePrice = styled.div`
	color: ${palette('gray8')};
	font-size: 36px;
	font-weight: 500;
	margin: 0 8px;
`;

export const OriginalPrice = styled.del`
	color: ${palette('errorRed')};
	font-size: 31.5px;
	font-weight: 300;
	margin: 0 8px;
	text-decoration: line-through;
`;
