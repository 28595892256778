import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Loader from '@/components/Loader';
import { FormStateContext } from '@/context/FormStateContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { useAuth } from '@/hooks/auth/useAuth';
import { FORM_ACTIONS } from '@/hooks/forms';
import { useSetupUserAfterAuth } from '@/hooks/forms/useSetupUserAfterAuth';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import { useAuthFormModeState } from '@/hooks/store';
import { User } from '@/store/user/types';
import { useIsTeamsNativeApp } from '@/utils/app/msftTeams';
import { formatError } from '@/utils/formatErrors';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import TeamsGoogleAuthButton from './TeamsGoogleAuthButton';
import messages from './messages';
import { Icon, Wrapper } from './styles';
import { GoogleAuthButtonProps, GoogleButtonProps } from './types';

const GoogleButton = ({ buttonText, contentAlignment, hideMessage = false, onClick }: GoogleButtonProps) => {
	const { isAuth0Enabled } = useAuth();
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();

	const getButtonCta = () => {
		if (buttonText) {
			return buttonText;
		}

		if (isAuth0Enabled) {
			return authFormMode === 'login'
				? formatMessage(messages.signInCta)
				: formatMessage(messages.continueCta);
		}

		return formatMessage(messages.continueCta);
	};

	const getContentAlignment = () => {
		if (isAuth0Enabled) {
			return 'center';
		}

		return contentAlignment ?? hideMessage ? 'center' : 'left';
	};

	const buttonCta = getButtonCta();
	const _contentAlignment = getContentAlignment();
	const bgColor = 'white';
	const textColor = isAuth0Enabled ? 'black' : 'gray7';
	const borderColor = isAuth0Enabled ? 'gray3' : 'gray2';

	return (
		<Wrapper
			backgroundColor={bgColor}
			contentAlignment={_contentAlignment}
			data-testid="google-auth-button"
			onPress={onClick}
			role="link"
			$noPadding={hideMessage}
			textColor={textColor}
			borderColor={borderColor}
		>
			<Icon $noMargin={hideMessage} />
			{hideMessage ? <VisuallyHidden>{buttonCta}</VisuallyHidden> : buttonCta}
		</Wrapper>
	);
};

/**
 * @deprecated This will be removed when auth0 migration is completed in favor of BrowserGoogleAuthButton.
 */
const BrowserGoogleAuthButtonDeprecated = ({
	onAuthSuccess,
	buttonText,
	marketingOptIn,
	contentAlignment,
	hideMessage = false,
}: GoogleAuthButtonProps) => {
	const { logEvent } = useAnalytics();
	const apiRequest = useApi();
	const authFormMode = useAuthFormModeState();
	const setupUserAfterAuth = useSetupUserAfterAuth();
	const guestPassCode = useGuestPassRequestCode();

	const [auth, setAuth] = useState<gapi.auth2.GoogleAuth>();
	const buttonEl = useRef<HTMLDivElement>(null);
	const { formDispatch, setFormError, setFormSuccess } = useContext(FormStateContext);

	const onAuth = async (googleUser: gapi.auth2.GoogleUser) => {
		const authResponse = googleUser.getAuthResponse();

		try {
			const { data } = await apiRequest<User>({
				endpoint: 'auth/google',
				method: 'POST',
				body: {
					token: authResponse.id_token,
					marketing_opt_in: marketingOptIn,
					guest_pass_code: guestPassCode,
				},
			});

			logEvent({
				eventName: 'Login Form : Google : Success',
				eventProps: {
					mode: authFormMode,
				},
			});

			await setupUserAfterAuth(data, CalmAuthMethods.Google);

			formDispatch({ type: FORM_ACTIONS.requestSuccess });
			if (onAuthSuccess) {
				onAuthSuccess(data);
			}
		} catch (err) {
			const error = err?.data?.error;
			logEvent({
				eventName: 'Login Form : Google : Error',
				eventProps: {
					mode: authFormMode,
					...formatError(error),
				},
			});

			setFormError(error);
			formDispatch({ type: FORM_ACTIONS.requestFailure });
		}
	};

	const onError = (error: string) => {
		logEvent({
			eventName: 'Login Form : Google : Cancelled',
			eventProps: {
				error,
				mode: authFormMode,
			},
		});
		setFormSuccess(null);
		setFormError(null);
		formDispatch({ type: FORM_ACTIONS.requestCancelled });
	};

	const onPress = () => {
		(auth as gapi.auth2.GoogleAuth)
			.signIn({})
			.then(googleUser => onAuth(googleUser))
			.catch(error => onError(error));

		logEvent({
			eventName: 'Login Form : Google : Clicked',
			eventProps: {
				mode: authFormMode,
			},
		});
	};

	const onGoogleAuthLoaded = () => {
		if (!window.gapi || !buttonEl?.current) return;

		window.gapi.load('auth2', async () => {
			const authInstance = window.gapi.auth2.init({
				client_id: process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID,
				ux_mode: 'popup',
			});
			setAuth(authInstance);
		});
	};

	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID) return;

		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/api:client.js';
		script.async = true;
		script.defer = true;
		script.onload = onGoogleAuthLoaded;
		document.body.appendChild(script);
	}, []);

	if (!process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID) return null;

	return (
		<div ref={buttonEl}>
			<GoogleButton
				buttonText={buttonText}
				contentAlignment={contentAlignment}
				hideMessage={hideMessage}
				onClick={onPress}
			/>
		</div>
	);
};

const BrowserGoogleAuthButton = (props: GoogleAuthButtonProps) => {
	const { contentAlignment, buttonText, hideMessage, onAuthSuccess, marketingOptIn } = props;

	const { authenticate, isAuth0Enabled } = useAuth();

	if (!isAuth0Enabled) {
		return <BrowserGoogleAuthButtonDeprecated {...props} />;
	}

	const onClick = () => {
		authenticate({ authMethod: CalmAuthMethods.Google, marketingOptIn, onAuthSuccess });
	};

	return (
		<GoogleButton
			contentAlignment={contentAlignment}
			buttonText={buttonText}
			hideMessage={hideMessage}
			onClick={onClick}
		/>
	);
};

export default function GoogleAuthButton(props: GoogleAuthButtonProps) {
	const { isTeamsNativeApp, isLoading: isTeamsLoading } = useIsTeamsNativeApp();
	if (isTeamsLoading) {
		return <Loader color="gray1" />;
	}
	if (isTeamsNativeApp) {
		return <TeamsGoogleAuthButton {...props} />;
	}
	return <BrowserGoogleAuthButton {...props} />;
}
