import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.a`
	background: ${palette('transparentBlackBackground')};
	border-radius: 50%;
	display: inline-block;
	height: 48px;
	flex: 0 0 48px;
	margin-left: auto;
	overflow: hidden;
	transition: ${theme('animation.transition')};
	width: 48px;

	& svg {
		height: 48px;
		padding: 12px;
		width: 48px;

		& path {
			fill: ${palette('white')};
		}
	}

	${minWidth('desktop')} {
		&:hover {
			background: ${palette('midnightBlue')};
		}
	}
`;
