export type Service = 'www' | 'www-app' | 'api' | 'web-api' | 'voucher';

function getUrl(service: Service): string | undefined {
	switch (service) {
		case 'www-app':
			return `${process.env.NEXT_PUBLIC_WWW_URL}${process.env.NEXT_PUBLIC_WWW_APP_PATH}`;
		case 'api':
			return `${process.env.NEXT_PUBLIC_WWW_URL}${process.env.NEXT_PUBLIC_WEBAPI_ROUTE}/authproxy`;
		case 'web-api':
			return `${process.env.NEXT_PUBLIC_WWW_URL}${process.env.NEXT_PUBLIC_WEBAPI_ROUTE}`;
		case 'voucher':
			return process.env.NEXT_PUBLIC_VOUCHER_ENDPOINT;
		case 'www':
		default:
			return process.env.NEXT_PUBLIC_WWW_URL;
	}
}

export default getUrl;
