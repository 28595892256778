import { ReactNode, createContext, useMemo, useState } from 'react';

import { useThemeState } from '@/hooks/store';
import { useElementDimensions } from '@/hooks/ui/useElementDimensions';

import { FixedBottom } from '../FixedBottomContainer';
import { CookiePreferencesBannerContextProps } from './types';

export const COOKIE_PREFERENCES_BANNER_ID = 'cookie-preferences-banner';

export const CookiePreferencesBannerContext = createContext<CookiePreferencesBannerContextProps>({
	isOpen: false,
	setIsOpen: () => {},
	canBeShown: false,
	setCanBeShown: () => {},
	isAnnounced: false,
	setIsAnnounced: () => {},
	isForciblyShown: false,
	setIsForciblyShown: () => {},
	fixedBottomHeight: 0,
});

const CookiePreferencesBannerProvider = ({ children }: { children?: ReactNode }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [canBeShown, setCanBeShown] = useState<boolean>(false);
	const [isAnnounced, setIsAnnounced] = useState<boolean>(false);
	const [isForciblyShown, setIsForciblyShown] = useState<boolean>(false);

	const [fixedBottomContainerRef, fixedBottomDimensions] = useElementDimensions();

	const theme = useThemeState();

	const value = useMemo(
		() => ({
			isOpen,
			setIsOpen,
			canBeShown,
			setCanBeShown,
			isAnnounced,
			setIsAnnounced,
			isForciblyShown,
			setIsForciblyShown,
			fixedBottomHeight: fixedBottomDimensions.height,
		}),
		[fixedBottomDimensions, isAnnounced, isForciblyShown, canBeShown, isOpen],
	);

	return (
		<CookiePreferencesBannerContext.Provider value={value}>
			{children}
			<FixedBottom
				canBeShown={canBeShown}
				isForciblyShown={isForciblyShown}
				isWebAppLayout={!!theme?.webAppLayout}
				innerRef={fixedBottomContainerRef}
			/>
		</CookiePreferencesBannerContext.Provider>
	);
};

export default CookiePreferencesBannerProvider;
