import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Wrapper = styled.div<{
	isVisible: boolean;
}>`
	height: 100vh;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	opacity: ${p => (p.isVisible ? '1' : '0')};
	transition: ${theme('animation.transition')};

	& video {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		object-fit: cover;
	}
`;
