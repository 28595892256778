import styled from 'styled-components';

import { fontSize, FontSizes } from '@calm-web/design-system';

export const Wrapper = styled.div`
	${fontSize(FontSizes.lg)};
	padding-top: 2rem;
	text-align: center;
	width: 100%;
`;

export const CVVImage = styled.img`
	margin-top: 0.75rem;
	width: 100%;
`;
