import { useState } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { Subscription, User } from '@/store/user/types';
import { PromiseWithCalmError } from '@/utils/promiseWithCalmError';

import { initLoggableSubscriptionDetails } from './usePurchaseSuccess';

type GiftPartner = 'The Dodo';

interface Gift {
	certificate: string;
	code: string;
	type: string;
	duration: number;
	partner?: GiftPartner;
}

interface PurchaseGiftParams {
	coupon: string;
	email: string;
	quantity: number;
	stripeToken: string;
}

interface PurchaseGift {
	(params: PurchaseGiftParams): PromiseWithCalmError;
}

interface ApplyGiftParams {
	partner: string;
	tracking?: string;
}

interface ApplyGift {
	(user: User | null, params: ApplyGiftParams): PromiseWithCalmError;
}

interface RedeemGift {
	(user: User | null, code: string, pin?: string): PromiseWithCalmError;
}

export interface GiftHook {
	redeemGift: RedeemGift;
	hasRedeemed: boolean;
	applyGift: ApplyGift;
	hasApplied: boolean;
	purchaseGift: PurchaseGift;
}

export function useGift(): GiftHook {
	const [hasRedeemed, setHasRedeemed] = useState(false);
	const [hasApplied, setHasApplied] = useState(false);
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();

	const purchaseGift: PurchaseGift = async params => {
		try {
			logEvent({
				eventName: 'Gift : Purchase : Purchasing',
				eventProps: {
					coupon: params.coupon,
					quantity: params.quantity,
				},
			});
			await apiRequest({
				endpoint: '/gift/purchase',
				method: 'POST',
				body: params,
			});
			logEvent({
				eventName: 'Gift : Purchase : Success',
				eventProps: {
					quantity: params.quantity,
					currency: 'USD',
					gift_value: 6999,
				},
			});
			return { success: null };
		} catch (err) {
			logEvent({
				eventName: 'Gift : Purchase : Error',
				eventProps: { error: err },
			});
			return { error: err };
		}
	};

	const applyGift: ApplyGift = async (user, params) => {
		if (!user?.token) {
			return { error: { calmCode: 'no_session' } };
		}
		if (hasApplied) {
			return { error: { calmCode: 'gift_already_granted' } };
		}
		try {
			const { data } = await apiRequest({
				endpoint: 'gift/apply',
				method: 'POST',
				body: params,
			});
			setHasApplied(true);

			logEvent({
				eventName: 'Trial : Redeem : Success',
			});
			return { success: data as unknown as Gift };
		} catch (err) {
			logEvent({
				eventName: 'Trial : Redeem : Error',
				eventProps: {
					error: err,
				},
			});
			return { error: err };
		}
	};

	const redeemGift: RedeemGift = async (user, code, pin) => {
		if (!user?.token) {
			return { error: { calmCode: 'no_session' } };
		}
		if (hasRedeemed) {
			return { error: { calmCode: 'already_subscribed' } };
		}
		const body = code.length >= 16 ? { code, pin } : { code };
		try {
			const { data } = await apiRequest<{
				subscription: Subscription;
				gift: Gift;
			}>({
				endpoint: 'gift/redeem',
				method: 'POST',
				body,
			});
			setHasRedeemed(true);
			const subscriptionDetails = initLoggableSubscriptionDetails(data.subscription);
			logEvent({
				eventName: 'Gift : Redeem : Success',
				eventProps: subscriptionDetails,
			});
			return { success: data as unknown as Gift };
		} catch (err) {
			logEvent({
				eventName: 'Gift : Redeem : Error',
				eventProps: {
					error: err,
				},
			});
			return { error: err };
		}
	};

	return {
		purchaseGift,
		redeemGift,
		hasRedeemed,
		applyGift,
		hasApplied,
	};
}
