import { MessageDescriptor, useIntl } from 'react-intl';

import { AccountScreenConfig } from '@/components/SimplifiedSignup/Account/types';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { useLoginForm } from '@/hooks/forms';
import { useAuthFormModeState } from '@/hooks/store';
import genericMessages from '@/messages/messages';

import messages from './messages';
import { Cta, Wrapper } from './styles';

const ModeToggle = ({
	loginModeText,
	signupModeText,
}: {
	loginModeText?: MessageDescriptor;
	signupModeText?: MessageDescriptor;
}) => {
	const { formatMessage } = useIntl();
	const { onModeToggle } = useLoginForm();
	const authFormMode = useAuthFormModeState();
	const { activeScreenConfig = {} } = useSimplifiedSignupContext();
	const { authFormToggleText } = activeScreenConfig as AccountScreenConfig;

	return (
		<Wrapper>
			{authFormToggleText &&
				formatMessage(authFormToggleText, {
					mode:
						authFormMode === 'login'
							? formatMessage(genericMessages.login)
							: formatMessage(genericMessages.signup),
				})}{' '}
			{authFormMode === 'login' ? formatMessage(messages.loginToggle) : formatMessage(messages.signupToggle)}{' '}
			<Cta onClick={onModeToggle} data-testid="mode-toggle-button">
				{authFormMode === 'login'
					? formatMessage(genericMessages.signup)
					: formatMessage(genericMessages.login)}
			</Cta>
		</Wrapper>
	);
};

export default ModeToggle;
