import { PartnerDetails } from '@/components/dtcPartnerships/shared/types';

import messages from './messages';
import { DTCPartnerData } from './types';

/**
 * DTC Lead Gen Partnership Temporary List
 *
 * Use the `partners` variable below to add any hardcoded data we need while waiting on the BE services to store terms and logo links.
 *
 * To add a partner, copy this format:
 *
 * [PARTNER_SLUG]: {
 *   partnerTerms: string - string of terms to be added on the welcome screen,
 *   logo: string - path to the logo image,
 *   endDate: string,
 *   messages: {
 *      headline: messages.defaultTitle,
 *      subheadline: null,
 *   },
 * }
 *
 */

/**
 *
 * @param name Partner Name as its configured in Partner Portal
 * @param getDate getDate format function that is passed in from the hook
 * @returns The DTCPartnerData object to be spread over the page info
 */

const getDTCPartners = (partnerData: PartnerDetails): DTCPartnerData => {
	const partners: { [name: string]: DTCPartnerData } = {
		'adidas-1m': {
			partnerTerms: 'Available for current adiClub members.',
			logo: '/_n/images/dtc-partners/adidas.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		'adidas-3m': {
			partnerTerms: 'Available for current adiClub members.',
			logo: '/_n/images/dtc-partners/adidas.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		'adidas-6m': {
			partnerTerms: 'Available for current adiClub members.',
			logo: '/_n/images/dtc-partners/adidas.png',
			messages: {
				headline: messages.defaultTitle,
				subheadline: null,
				activate: messages.adidasActivate6m,
			},
		},
		'adidas-12m': {
			partnerTerms: 'Available for current adiClub members.',
			logo: '/_n/images/dtc-partners/adidas.png',
			messages: {
				headline: messages.defaultTitle,
				subheadline: null,
				activate: messages.adidasActivate12m,
			},
		},
		'ally-12m': {
			messages: {
				activate: messages.ally_12m_headline,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		'bluelightcard-intro': {
			partnerTerms: 'Must be a BlueLightCard member to redeem.',
			logo: '/_n/images/dtc-partners/BLC_Logo_Primary_Contained.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		bluelightcard: {
			partnerTerms: 'Must be a BlueLightCard member to redeem.',
			logo: '/_n/images/dtc-partners/BLC_Logo_Primary_Contained.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		'dds-intro': {
			partnerTerms: 'Must be a Defence Discount Service member to redeem.',
			logo: '/_n/images/dtc-partners/DDS.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		dds: {
			partnerTerms: 'Must be a Defence Discount Service member to redeem.',
			logo: '/_n/images/dtc-partners/DDS.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		spafinder: {
			partnerTerms: 'Spafinder purchase required.',
			logo: '/_n/images/dtc-partners/spafinder.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		trip: {
			partnerTerms: 'Requires a purchase on drink-trip.com to redeem.',
			logo: '/_n/images/dtc-partners/Trip_Final_logo.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		'thrive-market': {
			partnerTerms: 'Available for current U.S. Thrive Market members.',
			logo: '/_n/images/dtc-partners/thrive-market.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		'xbox-game-pass': {
			partnerTerms: 'Must be Xbox Game Pass member to qualify.',
			logo: '/_n/images/dtc-partners/xbox_gamepass.png',
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		maybelline: {
			messages: {
				activate: messages.maybelline_headline,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		tmobiletuesdays: {
			messages: {
				activate: messages.tmobile_headline,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
		default: {
			messages: {
				activate: null,
				headline: messages.defaultTitle,
				subheadline: null,
			},
		},
	};
	return partners[partnerData.slug] ?? partners.default;
};

export default getDTCPartners;
