import { defineMessages } from 'react-intl';

import { CopyItem } from '@/components/valuePropList';

//****************** Sleep Messages
export const initialTransitionMessages = defineMessages({
	title: {
		id: 'onboarding.sleep.initialTransitionScreen.title',
		defaultMessage: "A great night's sleep is waiting.",
		description: 'A title for a transiton screen propmting the user to use Calm',
	},
	paragraph1: {
		id: 'onboarding.sleep.initialTransitionScreen.paragraph1',
		defaultMessage: 'Just a few quick questions so we can personalize your experience.',
		description: 'this describes the benefits of using the calm app',
	},
});

export const troubleSleepingMessages = defineMessages({
	question: {
		id: 'onboarding.sleep.trouble.question',
		defaultMessage: 'How often do you have trouble sleeping?',
		description: 'Question for trouble sleeping screen',
	},
	answerOccasionally: {
		id: 'onboarding.sleep.trouble.occasionally',
		defaultMessage: 'Occasionally',
		description: 'Answer for occasionally',
	},
	answerFrequently: {
		id: 'onboarding.sleep.trouble.frequently',
		defaultMessage: 'Frequently',
		description: 'Answer for frequently',
	},
	answerEveryNight: {
		id: 'onboarding.sleep.trouble.everyNight',
		defaultMessage: 'Every Night',
		description: 'Answer for every night',
	},
	responseGoodHands: {
		id: 'onboarding.sleep.trouble.goodHands',
		defaultMessage: "You're in good hands.",
		description: 'First part of response for trouble screen',
	},
	responseLibrary: {
		id: 'onboarding.sleep.trouble.library',
		defaultMessage:
			'Our library of Sleep Stories, soundscapes, music, and other audio-guided content is designed to help you fall asleep fast every night.',
		description: 'Second part of response for trouble screen',
	},
});

export const fallStayAsleepMessages = defineMessages({
	question: {
		id: 'onboarding.sleep.asleep.question',
		defaultMessage: 'What do you have trouble with when sleeping?',
		description: 'Question for fall stay asleep screen',
	},
	answerFalling: {
		id: 'onboarding.sleep.asleep.falling',
		defaultMessage: 'Falling asleep',
		description: 'Answer for falling asleep',
	},
	answerStaying: {
		id: 'onboarding.sleep.asleep.staying',
		defaultMessage: 'Staying asleep',
		description: 'Answer for staying asleep',
	},
	answerBoth: {
		id: 'onboarding.sleep.asleep.both',
		defaultMessage: 'Both',
		description: 'Answer for both asleep',
	},
});

export const sleepNowLaterMessages = defineMessages({
	question: {
		id: 'onboarding.sleep.nowLater.question',
		defaultMessage: 'Are you trying to sleep right now?',
		description: 'Question for sleep now later screen',
	},
	cta: {
		id: 'onboarding.sleep.nowLater.cta',
		defaultMessage: "We'll recommend different options if you're looking to fall sleep now.",
		description: 'Cta for sleep now later screen',
	},
	answerYes: {
		id: 'onboarding.sleep.nowLater.yes',
		defaultMessage: "Yes, I'm ready to sleep",
		description: 'Answer for yes',
	},
	answerNo: {
		id: 'onboarding.sleep.nowLater.no',
		defaultMessage: 'Not right now',
		description: 'Answer for no',
	},
});

export const preferencesTransitionMessages = defineMessages({
	title: {
		id: 'onboarding.sleep.preferencesTransition.title',
		defaultMessage: 'Share a few more details about your preferences.',
		description: 'A title for a transiton screen propmpting the user to use Calm',
	},
	paragraph: {
		id: 'onboarding.sleep.preferencesTransition.paragraph',
		defaultMessage: 'That way, we can recommend the best content for you.',
		description: 'Cta for user to continue the sleep onboarding flow',
	},
});

export const stopsSleepingMessages = defineMessages({
	question: {
		id: 'onboarding.sleep.stopsSleeping.question',
		defaultMessage: 'Which of the following stops you from sleeping most often?',
		description: 'Question for stops sleeping screen',
	},
	answerEvents: {
		id: 'onboarding.sleep.stopsSleeping.answerEvents',
		defaultMessage: 'Worries about future events',
		description: 'Answer for events',
	},
	answerSleeping: {
		id: 'onboarding.sleep.stopsSleeping.answerSleeping',
		defaultMessage: 'Worries about not sleeping',
		description: 'Answer for sleeping',
	},
	answerDiscomfort: {
		id: 'onboarding.sleep.stopsSleeping.answerDiscomfort',
		defaultMessage: 'Bodily discomfort or pain',
		description: 'Answer for discomfort',
	},
	answerStress: {
		id: 'onboarding.sleep.stopsSleeping.answerStress',
		defaultMessage: 'Stress from the day',
		description: 'Answer for stress',
	},
	answerNoise: {
		id: 'onboarding.sleep.stopsSleeping.answerNoise',
		defaultMessage: 'Noise or other distractions',
		description: 'Answer for noise',
	},
	answerOther: {
		id: 'onboarding.sleep.stopsSleeping.answerOther',
		defaultMessage: 'Other',
		description: 'Answer for other',
	},
});

export const miracleWorkerMessages = defineMessages({
	quote: {
		id: 'onboarding.sleep.miracleWorker.quote',
		defaultMessage:
			'I had a sleeping problem and hated going to sleep. But then I found Calm where I can just relax and listen to calming stories. It works every time!! This app is a miracle worker and helps me sleep like nothing else does!',
		description: 'Quote from testimonial',
	},
	author: {
		id: 'onboarding.sleep.miracleWorker.author',
		defaultMessage: 'Cassandra J.',
		description: 'Author for testimonial',
	},
});

export const contentPreferencesMessages = defineMessages({
	question: {
		id: 'onboarding.sleep.contentPreferences.question',
		defaultMessage: 'What type of content are you interested in?',
		description: 'Question for content preferences screen',
	},
	answerStories: {
		id: 'onboarding.sleep.contentPreferences.stories',
		defaultMessage: 'Bedtime stories',
		description: 'Answer for bedtime stories',
	},
	answerNature: {
		id: 'onboarding.sleep.contentPreferences.nature',
		defaultMessage: 'Nature sounds',
		description: 'Answer for nature sounds',
	},
	answerSoundscapes: {
		id: 'onboarding.sleep.contentPreferences.soundscapes',
		defaultMessage: 'Sleep soundscapes',
		description: 'Answer for sleep soundscapes',
	},
	answerMusic: {
		id: 'onboarding.sleep.contentPreferences.music',
		defaultMessage: 'Relaxing music',
		description: 'Answer for relaxing music',
	},
	answerMeditations: {
		id: 'onboarding.sleep.contentPreferences.meditations',
		defaultMessage: 'Sleep meditations',
		description: 'Answer for sleep meditations',
	},
});

export const sleepDailyCommitmentMessages = defineMessages({
	quote: {
		id: 'onboarding.sleep.dailyCommitment.quote',
		defaultMessage:
			'I’ve always been the kind of person who struggles falling asleep. I didn’t think that the sleep stories or the sleep music would help me, but boy was I wrong.',
		description: 'Quote from testimonial',
	},
	author: {
		id: 'onboarding.sleep.dailyCommitment.author',
		defaultMessage: 'Mark S.',
		description: 'Author for testimonial',
	},
});

//****************** End of Sleep Messages

//****************** Stress Messages
export const experienceStressMessages = defineMessages({
	question: {
		id: 'signup.edu.experience.question',
		defaultMessage: 'How does stress usually show up for you?',
		description: 'Question for stress source screen',
	},
	answerAnxious: {
		id: 'signup.edu.experience.anxious',
		defaultMessage: 'Anxious thoughts',
		description: 'Answer for anxious thoughts',
	},
	answerDiscomfort: {
		id: 'signup.edu.experience.discomfort',
		defaultMessage: 'Physical discomfort',
		description: 'Answer for discomfort',
	},
	answerMoodiness: {
		id: 'signup.edu.experience.moodiness',
		defaultMessage: 'Moodiness',
		description: 'Answer for moodiness',
	},
	answerSleeping: {
		id: 'signup.edu.experience.sleeping',
		defaultMessage: 'Difficulty sleeping',
		description: 'Answer for sleeping',
	},
});

export const meditationExperienceMessages = defineMessages({
	question: {
		id: 'signup.edu.meditation.question',
		defaultMessage: "What's your experience with meditation?",
		description: 'Question for meditation experience screen',
	},
	answerNone: {
		id: 'signup.edu.meditation.none',
		defaultMessage: 'None',
		description: 'Answer for none',
	},
	answerFew: {
		id: 'signup.edu.meditation.few',
		defaultMessage: "I've tried it a few times",
		description: 'Answer for a few',
	},
	answerLot: {
		id: 'signup.edu.meditation.lot',
		defaultMessage: "I've meditated a lot",
		description: 'Answer for a lot',
	},
	responseRecognize: {
		id: 'signup.edu.meditation.recognize',
		defaultMessage:
			'Recognizing how you feel is an important part of mindfulness, so we’ll keep checking in with you.',
		description: 'Response for recognizing how you feel',
	},
	responseHealthier: {
		id: 'signup.edu.meditation.healthier',
		defaultMessage: 'Congrats on taking your first step to a happier and healthier you.',
		description: 'Response if a user taps "none"',
	},
	responseEasy: {
		id: 'signup.edu.meditation.easy',
		defaultMessage: "We'll make it easy for you to learn the basics of meditation.",
		description: 'Second paragraph response if a user taps "none"',
	},
	responseDeepen: {
		id: 'signup.edu.meditation.deepen',
		defaultMessage: "Great! We'll help you deepen your practice and build a lasting meditation habit.",
		description: 'Response if a user taps "few times"',
	},
	responseEvolve: {
		id: 'signup.edu.meditation.evolve',
		defaultMessage: "Great! We'll help you continue to deepen and evolve your practice.",
		description: 'Response if a user taps "a lot"',
	},
});

export const timePreferenceMessages = defineMessages({
	question: {
		id: 'signup.edu.time.question',
		defaultMessage: 'When would you like to meditate?',
		description: 'Question for time preference screen',
	},
	cta: {
		id: 'signup.edu.time.cta',
		defaultMessage:
			'It’s helpful to set aside time as part of your existing routine, like after waking up or before going to bed.',
		description: 'Cta for time preference screen',
	},
	answerMorning: {
		id: 'signup.edu.time.morning',
		defaultMessage: 'Morning',
		description: 'Answer for morning',
	},
	answerAfternoon: {
		id: 'signup.edu.time.afteroon',
		defaultMessage: 'Afternoon',
		description: 'Answer for afteroon',
	},
	answerNight: {
		id: 'signup.edu.time.night',
		defaultMessage: 'Night',
		description: 'Answer for night',
	},
});

export const stressSourceMessages = defineMessages({
	question: {
		id: 'signup.edu.stress.question',
		defaultMessage: "What's typically the biggest source of stress for you?",
		description: 'Question for stress source screen',
	},
	answerMoney: {
		id: 'signup.edu.stress.money',
		defaultMessage: 'Money',
		description: 'Answer for money',
	},
	answerWork: {
		id: 'signup.edu.stress.work',
		defaultMessage: 'Work or school',
		description: 'Answer for work or school',
	},
	answerHealth: {
		id: 'signup.edu.stress.health',
		defaultMessage: 'Health',
		description: 'Answer for health',
	},
	answerRelationships: {
		id: 'signup.edu.stress.relationships',
		defaultMessage: 'Relationships',
		description: 'Answer for relationships',
	},
	answerFamily: {
		id: 'signup.edu.stress.family',
		defaultMessage: 'Family responsibilities',
		description: 'Answer for family',
	},
	answerOther: {
		id: 'signup.edu.stress.other',
		defaultMessage: 'Other',
		description: 'Answer for other',
	},
});

export const moodPulseMessages = defineMessages({
	question: {
		id: 'signup.edu.mood.question',
		defaultMessage: 'How have you been feeling lately?',
		description: 'Question for mood screen',
	},
	answerGood: {
		id: 'signup.edu.mood.good',
		defaultMessage: 'Good',
		description: 'Answer for good',
	},
	answerStressed: {
		id: 'signup.edu.mood.stressed',
		defaultMessage: 'Stressed',
		description: 'Answer for stressed',
	},
	answerSad: {
		id: 'signup.edu.mood.sad',
		defaultMessage: 'Sad',
		description: 'Answer for sad',
	},
	answerIndifferent: {
		id: 'signup.edu.mood.indifferent',
		defaultMessage: 'Indifferent',
		description: 'Answer for indifferent',
	},
	responseRecognize: {
		id: 'signup.edu.mood.recognize',
		defaultMessage:
			'Recognizing how you feel is an important part of mindfulness, so we’ll keep checking in with you.',
		description: 'Response for recognizing how you feel',
	},
	responseGlad: {
		id: 'signup.edu.mood.glad',
		defaultMessage: 'Glad to hear it.',
		description: 'Response if a user taps "Good"',
	},
	responseOk: {
		id: 'signup.edu.mood.ok',
		defaultMessage: "That's OK.",
		description: 'Response if a user taps anything besides "Good"',
	},
});

export const pacingMessages = defineMessages({
	question: {
		id: 'onboarding.stress.pacing.question',
		defaultMessage: 'Your plan, designed to work at your pace.',
		description: 'Question for pacing screen',
	},
	cta: {
		id: 'onboarding.stress.pacing.cta',
		defaultMessage: 'Knowing you, which pace do you prefer?',
		description: 'Question for pacing screen',
	},
	fast: {
		id: 'onboarding.stress.pacing.fast',
		defaultMessage: 'As fast as possible',
		description: 'Answer for As fast as possible',
	},
	slowSteady: {
		id: 'onboarding.stress.pacing.slowSteady',
		defaultMessage: 'Slow and steady',
		description: 'Answer for Slow and steady',
	},
	somewhereInBetween: {
		id: 'onboarding.stress.pacing.somewhereInBetween',
		defaultMessage: 'Somewhere in between',
		description: 'Answer for Somewhere in between',
	},
});

export const stateOfMindMessages = defineMessages({
	question: {
		id: 'onboarding.stress.stateOfMind.question',
		defaultMessage: 'Let’s better understand your current state of mind.',
		description: 'Question for state of mind screen',
	},
	cta: {
		id: 'onboarding.stress.stateOfMind.cta',
		defaultMessage: 'At this moment, how motivated are you to reduce your stress?',
		description: 'Cta for state of mind screen',
	},
	ready: {
		id: 'onboarding.stress.stateOfMind.ready',
		defaultMessage: "I'm ready",
		description: "Answer for I'm ready",
	},
	hopeful: {
		id: 'onboarding.stress.stateOfMind.hopeful',
		defaultMessage: 'Feeling hopeful',
		description: 'Answer for Feeling hopeful',
	},
	cautious: {
		id: 'onboarding.stress.stateOfMind.cautious',
		defaultMessage: "I'm cautious",
		description: "Answer for I'm cautious",
	},
});

export const stressDailyCommitmentMessages = defineMessages({
	quote: {
		id: 'onboarding.stress.dailyCommitment.quote',
		defaultMessage:
			'By committing to a mere 10 minutes of meditation a day, I have observed a significant change to the way my brain works and handles day-to-day tasks and situations.',
		description: 'Quote from testimonial',
	},
	author: {
		id: 'onboarding.stress.dailyCommitment.author',
		defaultMessage: 'Mark J.',
		description: 'Author for testimonial',
	},
});

export const qualityOfLifeMessages = defineMessages({
	quote: {
		id: 'onboarding.stress.qualityOfLifeMessages.quote',
		defaultMessage:
			'Calm has had an incredible impact on my mental health and overall quality of life. It has changed my perspectives and state of mind.',
		description: 'Quote from testimonial',
	},
	author: {
		id: 'onboarding.stress.qualityOfLifeMessages.author',
		defaultMessage: 'Cassandra J.',
		description: 'Author for testimonial',
	},
});

//****************** End of Stress Messages

//****************** Shared Messages
export const paymentFormMessages = defineMessages({
	trialFlowHeading: {
		id: 'paymentForm.trialFlow.heading',
		defaultMessage: "Let's get started.",
		description: 'Heading instructing user to fill out payment form',
	},
	trialFlowSubheading: {
		id: 'paymentForm.trialFlow.subheading',
		defaultMessage: 'No commitment. Cancel anytime.',
		description: 'Subheading explaining that a user can cancel prior to getting charged',
	},
	trialFlowPaymentDetailsHeading: {
		id: 'paymentForm.trialFlow.paymentDetailsHeading',
		defaultMessage: 'Please add payment details to start your trial.',
		description: 'Telling user how to start trial',
	},
	trialFlowPaymentFormCtaCopy: {
		id: 'paymentForm.trialFlow.trialFlowPaymentFormCtaCopy',
		defaultMessage: 'Start Your Trial',
		description: 'Button copy for user to submit payment info',
	},
	trialFlowPaymentFormTrialLength: {
		id: 'paymentForm.trialFlow.trialFlowPaymentFormTrialLength',
		defaultMessage: 'Start your {trialLengthInDays}-day free trial',
		description: 'Length of trial',
	},
});

export const onboardingMessages = defineMessages({
	title: {
		id: 'signup.goals.title',
		defaultMessage: 'What brings you to Calm?',
		description: 'Title for the goals screen',
	},
	cta: {
		id: 'signup.goals.cta',
		defaultMessage: 'Select all that apply',
		description: 'Cta for the goals screen',
	},
	sleepTitle: {
		id: 'signup.goals.sleepTitle',
		defaultMessage: 'Better sleep',
		description: 'Sleep tile title',
	},
	anxietyTitle: {
		id: 'signup.goals.anxietyTitle',
		defaultMessage: 'Reduce anxiety',
		description: 'Anxiety tile title',
	},
	stressTitle: {
		id: 'signup.goals.stressTitle',
		defaultMessage: 'Reduce stress',
		description: 'Stress tile title',
	},
	happinessTitle: {
		id: 'signup.goals.happinessTitle',
		defaultMessage: 'Increase happiness',
		description: 'Happiness tile title',
	},
	performanceTitle: {
		id: 'signup.goals.performanceTitle',
		defaultMessage: 'Improve performance',
		description: 'Performance tile title',
	},
	gratitudeTitle: {
		id: 'signup.goals.gratitudeTitle',
		defaultMessage: 'Develop gratitude',
		description: 'Gratitude tile title',
	},
	selfesteemTitle: {
		id: 'signup.goals.selfesteemTitle',
		defaultMessage: 'Build self-esteem',
		description: 'Self esteem tile title',
	},
	unlockMore: {
		id: 'signup.cta.unlockMore',
		defaultMessage: 'Unlock More {contentType}',
		description: 'Title for the goals screen',
	},
});

export const upsellCopy = defineMessages({
	heading: {
		id: 'onboarding.sleep.upsell.heading',
		defaultMessage: 'Fall asleep. Stay asleep.',
		description: 'Heading for upsell',
	},
	subheading: {
		id: 'onboarding.sleep.upsell.subheading',
		defaultMessage: 'Try Calm Premium for Free',
		description: 'Subheading for upsell',
	},
	upsellHeading: {
		id: 'onboarding.sleep.upsell.subheading',
		defaultMessage: 'Try Calm Premium for Free',
		description: 'Subheading for upsell',
	},
	sleepStoriesBullet: {
		id: 'onboarding.sleep.upsell.sleepStoriesBullet',
		defaultMessage: 'Sleep Stories',
		description: 'Sleep stories bullet point',
	},
	sleepStoriesBulletBody: {
		id: 'onboarding.sleep.upsell.sleepStoriesBulletBody',
		defaultMessage: 'Fall asleep fast with our huge library of soothing sleep stories.',
		description: 'Sleep stories bullet point',
	},
	limitedTimeCallout: {
		id: 'freeTrialPlans.planPicker.freeTrial.banner',
		defaultMessage: 'Limited Time Offer',
		description: 'Free trial banner',
	},
	natureSoundsBullet: {
		id: 'onboarding.sleep.upsell.natureSoundsBullet',
		defaultMessage: 'Nature sounds',
		description: 'Nature sounds bullet point',
	},
	sleepSoundscapesBullet: {
		id: 'onboarding.sleep.upsell.sleepSoundscapesBullet',
		defaultMessage: 'Sleep soundscapes',
		description: 'Sleep soundscapes bullet point',
	},
	relaxingMusicBullet: {
		id: 'onboarding.sleep.upsell.relaxingMusicBullet',
		defaultMessage: 'Relaxing music',
		description: 'Relaxing music bullet point',
	},
	sleepMeditationsBullet: {
		id: 'onboarding.sleep.upsell.sleepMeditationsBullet',
		defaultMessage: 'Sleep meditations',
		description: 'Sleep meditations bullet point',
	},
	extendedTrialHeading: {
		id: 'signup.upsell.extendedTrialHeading',
		defaultMessage: 'Try Calm Premium for {br} {amount} days',
		description: 'Inform the user how many days long their trial is',
	},
	includedBasic: {
		id: 'signup.premium.included.basic',
		defaultMessage: 'Included in your basic membership',
		description: 'Heading for basic package',
	},
	includedPremium: {
		id: 'signup.premium.included.premium',
		defaultMessage: 'Your premium membership includes',
		description: 'Heading for premium package',
	},
	limitedAccess: {
		id: 'signup.premium.included.basic.limitedAccess',
		defaultMessage: 'Limited access to content',
		description: 'Limited access to content bullet point',
	},
	weeklyContent: {
		id: 'signup.premium.included.premium.weeklyContent',
		defaultMessage: 'New content every week',
		description: 'Weekly content bullet point',
	},
	cancelAnytime: {
		id: 'signup.premium.included.premium.cancelAnytime',
		defaultMessage: 'Cancel anytime',
		description: 'Cancel anytime bullet point',
	},
	unlimitedContent: {
		id: 'signup.premium.included.premium.unlimitedContent',
		defaultMessage: 'Unlimited access to content',
		description: 'Unlimited content bullet point',
	},
	guidedMeditations: {
		id: 'signup.premium.included.premium.guidedMeditations',
		defaultMessage: '100+ guided meditations',
		description: 'Guided meditations bullet point',
	},
	soothingSleepStories: {
		id: 'signup.premium.included.premium.soothingSleepStories',
		defaultMessage: 'Soothing Sleep Stories',
		description: 'Sleep stories bullet point',
	},
	natureSounds: {
		id: 'signup.premium.included.premium.natureSounds',
		defaultMessage: 'Calming nature sounds',
		description: 'Nature sounds bullet point',
	},
	exclusiveMusic: {
		id: 'signup.premium.included.premium.exclusiveMusic',
		defaultMessage: 'Exclusive music',
		description: 'Exclusive music bullet point',
	},
	StressReliefHeader: {
		id: 'signup.premium.included.premium.StressReliefHeader',
		defaultMessage: 'Get in-the-moment relief from stress and anxiety',
		description: 'Exclusive music bullet point',
	},
	StressReliefBody: {
		id: 'signup.premium.included.premium.StressReliefBody',
		defaultMessage: 'Expert-led sessions and grounding exercises to quickly calm your mind',
		description: 'Exclusive music bullet point',
	},
	SleepHeader: {
		id: 'signup.premium.included.premium.SleepHeader',
		defaultMessage: 'Fall (and stay) asleep naturally',
		description: 'Exclusive music bullet point',
	},
	SleepBody: {
		id: 'signup.premium.included.premium.SleepBody',
		defaultMessage: 'Sleep Stories and meditations designed to keep you relaxed through the night',
		description: 'Exclusive music bullet point',
	},
	GuidanceHeader: {
		id: 'signup.premium.included.premium.GuidanceHeader',
		defaultMessage: 'Learn with guidance from the best',
		description: 'Exclusive music bullet point',
	},
	GuidanceBody: {
		id: 'signup.premium.included.premium.GuidanceBody',
		defaultMessage: 'Our instructors make mindfulness easy and welcoming, even for beginners',
		description: 'Exclusive music bullet point',
	},
});

export const basicLineItems: Array<CopyItem> = [{ id: 'limitedAccess', body: upsellCopy.limitedAccess }];

export const premiumLineItems: Array<CopyItem> = [
	{ body: upsellCopy.sleepStoriesBullet, id: 'sleepStoriesBullet' },
	{ body: upsellCopy.natureSoundsBullet, id: 'natureSoundsBullet' },
	{ body: upsellCopy.sleepSoundscapesBullet, id: 'sleepSoundscapesBullet' },
	{ body: upsellCopy.relaxingMusicBullet, id: 'relaxingMusicBullet' },
	{ body: upsellCopy.sleepMeditationsBullet, id: 'sleepMeditationsBullet' },
	{ body: upsellCopy.weeklyContent, id: 'weeklyContent' },
	{ body: upsellCopy.cancelAnytime, id: 'cancelAnytime' },
];

export const simplifiedFTUEPremiumLineItems = [
	upsellCopy.unlimitedContent,
	upsellCopy.guidedMeditations,
	upsellCopy.soothingSleepStories,
	upsellCopy.natureSounds,
	upsellCopy.exclusiveMusic,
	upsellCopy.weeklyContent,
	upsellCopy.cancelAnytime,
];

export const extendedTrialPromotions = ['14_day_free_trial', '30_day_free_trial'];
//****************** End of Shared Messages

//****************** UA Custom Flows

//****** Stress and Anxiety Messages
export const stressAndAnxietyMessages = defineMessages({
	heroHeading: {
		id: 'hero.heading.copy.v2',
		defaultMessage: 'Feeling Anxious?',
		description: 'Hero heading',
	},
	heroBody: {
		id: 'hero.body.copy.v2',
		defaultMessage:
			'Get the science-backed relaxation app helping thousands to experience life-changing results* including:',
		description: 'Hero heading',
	},
	heroBodyValue0: {
		id: 'hero.body.copy.value1',
		defaultMessage: 'higher sleep quality.',
		description: 'a changing display of the values of Calm',
	},
	heroBodyValue1: {
		id: 'hero.body.copy.value2',
		defaultMessage: 'decreased stress levels.',
		description: 'a changing display of the values of Calm',
	},
	heroBodyValue2: {
		id: 'hero.body.copy.value3',
		defaultMessage: 'improved mental health.',
		description: 'a changing display of the values of Calm',
	},
	heroBodyValue3: {
		id: 'hero.body.copy.value4',
		defaultMessage: 'improved anxiety.',
		description: 'a changing display of the values of Calm',
	},
	question1: {
		id: 'onboarding.stress.trouble.question',
		defaultMessage: 'How often do you feel stressed?',
		description: 'Question for stress flow',
	},
	question1sub: {
		id: 'onboarding.stress.trouble.subquestion3',
		defaultMessage: "No pressure, there's no wrong answer 🙂",
		description: 'Question for stress flow',
	},
	question2sub: {
		id: 'onboarding.sleep.trouble.subquestion2b',
		defaultMessage: "You've come to the right place for help!",
		description: 'Question for sleep flow',
	},
	question2: {
		id: 'onboarding.stress.manage.question2',
		defaultMessage: 'How do you manage your stress now?',
		description: 'Question for stress flow',
	},
	question3: {
		id: 'onboarding.stress.goals.question',
		defaultMessage: 'How would you like to feel every day?',
		description: 'Question for stress flow',
	},
	question3sub: {
		id: 'onboarding.stress.trouble.question3a',
		defaultMessage: "Tip: Imagine if you didn't feel anxious or stressed.",
		description: 'Question for stress flow',
	},
	heroPrimaryCTA: {
		id: 'onboarding.stress.PrimaryCTA.label.v2',
		defaultMessage: 'Join Now',
		description: 'Primary CTA for stress flow',
	},
	heroSecondaryCTA: {
		id: 'onboarding.stress.CTA.label.v2',
		defaultMessage: 'Take our quiz',
		description: 'Question for stress flow',
	},
	valueProp1Head: {
		id: 'onboarding.stress.valueProp1Head',
		defaultMessage: 'Experience lasting benefits',
		description: 'Value prop 1 heading',
	},
	valueProp1Body: {
		id: 'onboarding.stress.valueProp1Body',
		defaultMessage: '81% of Calm users reported less stress after using the Calm app.*',
		description: 'Value prop 1 body',
	},
	valueProp2Head: {
		id: 'onboarding.stress.valueProp2Head',
		defaultMessage: 'Get immediate relief',
		description: 'Value prop 2 heading',
	},
	valueProp2Body: {
		id: 'onboarding.stress.valueProp2Body',
		defaultMessage:
			'Reduce stress and anxiety in the moment with easy meditations and simple breathing exercises.',
		description: 'Value prop 2 body',
	},
	valueProp3Head: {
		id: 'onboarding.stress.valueProp3Head',
		defaultMessage: 'Feel supported throughout your day',
		description: 'Value prop 3 heading',
	},
	valueProp3Body: {
		id: 'onboarding.stress.valueProp3Body',
		defaultMessage:
			"Receive guided content tailored to help with any of life's stressors, from work, parenting, finances, and more. ",
		description: 'Value prop 3 body',
	},
	valuePropHeader: {
		id: 'onboarding.stress.valuePropHeader',
		defaultMessage: 'Start finding relief today with Calm Premium.',
		description: 'Value prop header',
	},
	valuePropBacking: {
		id: 'onboarding.stress.valuePropBacking',
		defaultMessage:
			'*Based on a study using survey data from general Calm subscribers who used the app more than five times per week.',
		description: 'Value prop backing',
	},
	slide1Title: {
		id: 'homepage.mentalHealthTools.slide2.title',
		defaultMessage: 'Stress & Anxiety',
		description: 'Title for mental health tools slide',
	},
	slide1Heading: {
		id: 'homepage.mentalHealthTools.slide2.heading',
		defaultMessage: 'Get in-the-moment relief from everyday stress and anxiety.',
		description: 'Heading for mental health tools slide',
	},
	slide1Copy: {
		id: 'homepage.mentalHealthTools.slide2.copy',
		defaultMessage:
			'Our clinical experts and mindfulness teachers are here to support you, with a wide range of programs for work stress, acute anxiety, negative thoughts, and more.',
		description: 'Copy for mental health tools slide',
	},
	slide1ContentTitle: {
		id: 'homepage.mentalHealthTools.slide2.contentTitle',
		defaultMessage: 'Overcome Stress and Anxiety',
		description: "Title of slide's previewable content",
	},
	slide1ContentSubheading: {
		id: 'homepage.mentalHealthTools.slide2.contentSubheading',
		defaultMessage: '11 tools to reclaim your calm',
		description: "Information for slide's previewable content",
	},
	slide1ContentAuthor: {
		id: 'homepage.mentalHealthTools.slide2.contentAuthor',
		defaultMessage: 'Narrated by  Dr. Julie Smith',
		description: "Author of slide's previewable content",
	},
	slide2Title: {
		id: 'homepage.mentalHealthTools.slide1.title',
		defaultMessage: 'Sleep',
		description: 'Title for mental health tools slide',
	},
	slide2Heading: {
		id: 'homepage.mentalHealthTools.slide1.heading',
		defaultMessage: 'Join millions of sound sleepers worldwide.',
		description: 'Heading for mental health tools slide',
	},
	slide2Copy: {
		id: 'homepage.mentalHealthTools.slide1.copy',
		defaultMessage:
			'Our 300+ Sleep Stories, sleep meditations, and calming music will guide you off to sleep quickly and naturally. Just press play and drift away.',
		description: 'Copy for mental health tools slide',
	},
	slide2ContentTitle: {
		id: 'homepage.mentalHealthTools.slide1.contentTitle',
		defaultMessage: 'Dream with Me',
		description: "Title of slide's previewable content",
	},
	slide2ContentSubheading: {
		id: 'homepage.mentalHealthTools.slide1.contentSubheading',
		defaultMessage: 'Fall asleep and fall in love with the dreamy voice of Harry Styles.',
		description: "Information for slide's previewable content",
	},
	slide2ContentAuthor: {
		id: 'homepage.mentalHealthTools.slide1.contentAuthor',
		defaultMessage: 'Narrated by Harry Styles',
		description: "Author of slide's previewable content",
	},
	slide3Title: {
		id: 'homepage.mentalHealthTools.slide3.title',
		defaultMessage: 'Focus',
		description: 'Title for mental health tools slide',
	},
	slide3Heading: {
		id: 'homepage.mentalHealthTools.slide3.heading',
		defaultMessage: 'Tame the distraction dragon.',
		description: 'Heading for mental health tools slide',
	},
	slide3Copy: {
		id: 'homepage.mentalHealthTools.slide3.copy',
		defaultMessage:
			'Sharpen your focus with soundscapes and music carefully crafted to help you tune out distractions and tap into a state of flow.',
		description: 'Copy for mental health tools slide',
	},
	slide3ContentTitle: {
		id: 'homepage.mentalHealthTools.slide3.contentTitle',
		defaultMessage: 'Finding Focus',
		description: "Title of slide's previewable content",
	},
	slide3ContentSubheading: {
		id: 'homepage.mentalHealthTools.slide3.contentSubheading',
		defaultMessage: 'Practice simply paying attention',
		description: "Information for slide's previewable content",
	},
	slide3ContentAuthor: {
		id: 'homepage.mentalHealthTools.slide3.contentAuthor',
		defaultMessage: 'Narrated by Jeff Warren',
		description: "Author of slide's previewable content",
	},
	slide4Title: {
		id: 'homepage.mentalHealthTools.slide4.title',
		defaultMessage: 'Relaxation',
		description: 'Title for mental health tools slide',
	},
	slide4Heading: {
		id: 'homepage.mentalHealthTools.slide4.heading',
		defaultMessage: "We don't call it Calm for nothing.",
		description: 'Heading for mental health tools slide',
	},
	slide4Copy: {
		id: 'homepage.mentalHealthTools.slide4.copy',
		defaultMessage:
			'Open the app and enter a sanctuary of rest and relaxation, from soothing soundscapes to calming music to hundreds of titles for deep and peaceful sleep.',
		description: 'Copy for mental health tools slide',
	},
	slide4ContentTitle: {
		id: 'homepage.mentalHealthTools.slide4.contentTitle',
		defaultMessage: 'Rain on Leaves',
		description: "Title of slide's previewable content",
	},
	slide4ContentSubheading: {
		id: 'homepage.mentalHealthTools.slide4.contentSubheading',
		defaultMessage: 'Soothing sounds of rainfall',
		description: "Information for slide's previewable content",
	},
	slide5Title: {
		id: 'homepage.mentalHealthTools.slide5.title',
		defaultMessage: 'Building Habits',
		description: 'Title for mental health tools slide',
	},
	slide5Heading: {
		id: 'homepage.mentalHealthTools.slide5.heading',
		defaultMessage: "You're in the right place.",
		description: 'Heading for mental health tools slide',
	},
	slide5Copy: {
		id: 'homepage.mentalHealthTools.slide5.copy',
		defaultMessage:
			'Our daily programs make it easy to get started and stay on track, and our instructors are here for you every step of the way. Choose from 4 daily programs, each designed to fit into your lifestyle in practical ways.',
		description: 'Copy for mental health tools slide',
	},
	slide5ContentTitle: {
		id: 'homepage.mentalHealthTools.slide5.contentTitle',
		defaultMessage: 'Own the Day',
		description: "Title of slide's previewable content",
	},
	slide5ContentSubheading: {
		id: 'homepage.mentalHealthTools.slide5.contentSubheading',
		defaultMessage: 'Tiny choices and small actions that can lead to lifelong change',
		description: "Information for slide's previewable content",
	},
	slide5ContentAuthor: {
		id: 'homepage.mentalHealthTools.slide5.contentAuthor',
		defaultMessage: 'Narrated by Jay Shetty',
		description: "Author of slide's previewable content",
	},
	slide6Title: {
		id: 'homepage.mentalHealthTools.slide6.title',
		defaultMessage: 'Physical Wellbeing',
		description: 'Title for mental health tools slide',
	},
	slide6Heading: {
		id: 'homepage.mentalHealthTools.slide6.heading',
		defaultMessage: 'A healthier relationship with your body is possible.',
		description: 'Heading for mental health tools slide',
	},
	slide6Copy: {
		id: 'homepage.mentalHealthTools.slide6.copy',
		defaultMessage:
			'Start with the Daily Move, a fresh mindful movement video released each day, as well as guided audio sessions for walking and pain',
		description: 'Copy for mental health tools slide',
	},
	slide6ContentTitle: {
		id: 'homepage.mentalHealthTools.slide6.contentTitle',
		defaultMessage: 'Mindful Walking',
		description: "Title of slide's previewable content",
	},
	slide6ContentSubheading: {
		id: 'homepage.mentalHealthTools.slide6.contentSubheading',
		defaultMessage: 'A guided meditation for walking mindfully',
		description: "Information for slide's previewable content",
	},
	slide6ContentAuthor: {
		id: 'homepage.mentalHealthTools.slide6.contentAuthor',
		defaultMessage: 'Narrated by Tamara Levitt',
		description: "Author of slide's previewable content",
	},
});

export const stressAnxietyPollAnswers = defineMessages({
	answerOccasionally: {
		id: 'onboarding.sleep.trouble.occasionally',
		defaultMessage: 'Occasionally',
		description: 'Answer for occasionally',
	},
	answerFrequently: {
		id: 'onboarding.sleep.trouble.frequently',
		defaultMessage: 'Frequently',
		description: 'Answer for frequently',
	},
	answerEveryDay: {
		id: 'onboarding.sleep.trouble.everyDay',
		defaultMessage: 'Every Day',
		description: 'Answer for every Day',
	},
	never: {
		id: 'onboarding.sleep.trouble.never',
		defaultMessage: 'Never',
		description: 'Answer for never',
	},
	dontKnowHow: {
		id: 'onboarding.sleep.trouble.dontKnowHow',
		defaultMessage: "I don't know how to",
		description: 'Answer for not knowing how to manage stress',
	},
	tryDifferentThings: {
		id: 'onboarding.sleep.trouble.tryDifferentThings',
		defaultMessage: 'I try different mindfulness techniques',
		description: 'Answer for not knowing how to manage stress',
	},
	noneOfTheAbove: {
		id: 'onboarding.sleep.trouble.noneOfTheAbove',
		defaultMessage: 'None of the above',
		description: 'Answer for none of the above',
	},
	allOfTheAbove: {
		id: 'onboarding.sleep.trouble.allOfTheAbove',
		defaultMessage: 'All of the above',
		description: 'Answer for how I want to feel',
	},
	distract: {
		id: 'onboarding.sleep.trouble.distract',
		defaultMessage: 'I distract myself',
		description: 'Answer for how to manage stress',
	},
	calmAndHappy: {
		id: 'onboarding.sleep.trouble.calmAndHappy',
		defaultMessage: 'Calm and happy',
		description: 'Answer for how I want to feel',
	},
	inControl: {
		id: 'onboarding.sleep.trouble.inControl',
		defaultMessage: 'In control of my life',
		description: 'Answer for how I want to feel',
	},
	morePresent: {
		id: 'onboarding.sleep.trouble.morePresent',
		defaultMessage: 'More present',
		description: 'Answer for how I want to feel',
	},
});

//****** Sleep Messages
export const sleepMessages = defineMessages({
	question1sub: {
		id: 'onboarding.stress.trouble.subquestion1a',
		defaultMessage: "No pressure, there's no wrong answer 🙂",
		description: 'Question for stress flow',
	},
	question2sub: {
		id: 'onboarding.sleep.trouble.subquestion2b',
		defaultMessage: "You've come to the right place for help!",
		description: 'Question for sleep flow',
	},
	heroHeading: {
		id: 'sleep.focused.hero.heading.v2',
		defaultMessage: 'Improve Your Sleep Quality & Reduce Stress',
		description: 'Hero heading for sleep flow',
	},
	heroBody: {
		id: 'sleep.focused.hero.body.v2',
		defaultMessage:
			'Explore Sleep Stories®, sounds, and guided meditations to join the thousands of people that are experiencing:',
		description: 'Hero sub heading',
	},
	heroBodyValue0: {
		id: 'sleep.focused.hero.body.value1',
		defaultMessage: 'higher sleep quality.',
		description: 'a changing display of the values of Calm',
	},
	heroBodyValue1: {
		id: 'sleep.focused.hero.body.value2',
		defaultMessage: 'better moods.',
		description: 'a changing display of the values of Calm',
	},
	heroBodyValue2: {
		id: 'sleep.focused.hero.body.value3',
		defaultMessage: 'waking up more refreshed.',
		description: 'a changing display of the values of Calm',
	},
	heroBodyValue3: {
		id: 'sleep.focused.hero.body.value4',
		defaultMessage: 'better concentration.',
		description: 'a changing display of the values of Calm',
	},
	question1: {
		id: 'onboarding.sleep.trouble.question1a',
		defaultMessage: 'Do you often struggle to sleep?',
		description: 'Question for sleep flow',
	},
	question2: {
		id: 'onboarding.sleep.manage.question2',
		defaultMessage: 'Have you tried to improve your sleep?',
		description: 'Question for sleep flow',
	},
	question3: {
		id: 'onboarding.sleep.goals.questionB',
		defaultMessage: 'How would you like to wake up?',
		description: 'Question for sleep flow',
	},
	question3sub: {
		id: 'onboarding.sleep.trouble.subquestion3a',
		defaultMessage: "Tip: Imagine if you didn't struggle sleeping.",
		description: 'Question for stress flow',
	},
	heroSecondaryCTA: {
		id: 'onboarding.sleep.CTA.label.v2',
		defaultMessage: 'Take our Sleep Quiz',
		description: 'Question for sleep flow',
	},
	valueProp1Head: {
		id: 'onboarding.sleep.valueProp1Head',
		defaultMessage: 'Proven results',
		description: 'Value prop 1 heading',
	},
	valueProp1Body: {
		id: 'onboarding.sleep.valueProp1Body',
		defaultMessage: '73% of Calm users reported improved sleep quality.*',
		description: 'Value prop 1 body',
	},
	valueProp2Head: {
		id: 'onboarding.sleep.valueProp2Head',
		defaultMessage: 'Experience immediate relief',
		description: 'Value prop 2 heading',
	},
	valueProp2Body: {
		id: 'onboarding.sleep.valueProp2Body',
		defaultMessage: 'Overcome restless nights with calming meditations and soothing Sleep Stories.',
		description: 'Value prop 2 body',
	},
	valueProp3Head: {
		id: 'onboarding.sleep.valueProp3Head',
		defaultMessage: 'Feel supported day and night',
		description: 'Value prop 3 heading',
	},
	valueProp3Body: {
		id: 'onboarding.sleep.valueProp3Body',
		defaultMessage: 'Receive personalized activities that improve your daily routine for better sleep.',
		description: 'Value prop 3 body',
	},
	valuePropHeader: {
		id: 'onboarding.sleep.valuePropHeader',
		defaultMessage: 'Start sleeping better tonight with Calm Premium.',
		description: 'Value prop header',
	},
	valuePropBacking: {
		id: 'onboarding.sleep.valuePropBacking',
		defaultMessage:
			'*Based on a study using survey data from general Calm subscribers who used the app more than five times per week.',
		description: 'Value prop backing',
	},
	toolsHeading: {
		id: 'onboarding.sleep.tools.heading',
		defaultMessage: 'Find Your Favorite Way to Fall Asleep',
		description: 'Heading for tools section',
	},
	toolsSubheading: {
		id: 'onboarding.sleep.tools.subheading',
		defaultMessage:
			'Listen, drift, dream. Discover our many options to help you relax, destress, and achieve better sleep. New content added monthly.',
		description: 'Subheading for tools section',
	},
	slide1Title: {
		id: 'focusedFlows.mentalHealthTools.slide1.title',
		defaultMessage: 'Sleep Stories',
		description: 'Title for mental health tools slide',
	},
	slide1Heading: {
		id: 'focusedFlows.mentalHealthTools.slide1.heading',
		defaultMessage: 'Join millions of sound sleepers worldwide.',
		description: 'Heading for mental health tools slide',
	},
	slide1Copy: {
		id: 'focusedFlows.mentalHealthTools.slide1.copy.v2',
		defaultMessage:
			'Enjoy high-quality audio featuring music, sound effects, and celebrity narrators like Harry Styles in a wide selection of sleep stories suitable for all ages.',
		description: 'Copy for mental health tools slide',
	},
	slide1ContentTitle: {
		id: 'focusedFlows.mentalHealthTools.slide1.contentTitle',
		defaultMessage: 'Dream with Me',
		description: "Title of slide's previewable content",
	},
	slide1ContentSubheading: {
		id: 'focusedFlows.mentalHealthTools.slide1.contentSubheading',
		defaultMessage: 'Fall asleep and fall in love with the dreamy voice of Harry Styles.',
		description: "Information for slide's previewable content",
	},
	slide1ContentAuthor: {
		id: 'focusedFlows.mentalHealthTools.slide1.contentAuthor',
		defaultMessage: 'Narrated by Harry Styles',
		description: "Author of slide's previewable content",
	},
	slide2Title: {
		id: 'focusedFlows.mentalHealthTools.slide2.title',
		defaultMessage: 'Meditations',
		description: 'Title for mental health tools slide',
	},
	slide2Heading: {
		id: 'focusedFlows.mentalHealthTools.slide2.heading',
		defaultMessage: 'Get in-the-moment relief from everyday stress and anxiety.',
		description: 'Heading for mental health tools slide',
	},
	slide2Copy: {
		id: 'focusedFlows.mentalHealthTools.slide2.copy',
		defaultMessage:
			'Release tension and stress to prepare your body for sleep—meditations help your nervous system shift out of fight or flight mode into a relaxation for an optimal sleep experience.',
		description: 'Copy for mental health tools slide',
	},
	slide2ContentTitle: {
		id: 'focusedFlows.mentalHealthTools.slide2.contentTitle',
		defaultMessage: 'Overcome Stress and Anxiety',
		description: "Title of slide's previewable content",
	},
	slide2ContentSubheading: {
		id: 'focusedFlows.mentalHealthTools.slide2.contentSubheading',
		defaultMessage: '11 tools to reclaim your calm',
		description: "Information for slide's previewable content",
	},
	slide2ContentAuthor: {
		id: 'focusedFlows.mentalHealthTools.slide2.contentAuthor',
		defaultMessage: 'Narrated by  Dr. Julie Smith',
		description: "Author of slide's previewable content",
	},
	slide3Title: {
		id: 'focusedFlows.mentalHealthTools.slide3.title.v2',
		defaultMessage: 'Music',
		description: 'Title for mental health tools slide',
	},
	slide3Heading: {
		id: 'focusedFlows.mentalHealthTools.slide3.heading.v2',
		defaultMessage: 'Tame the distraction dragon.',
		description: 'Heading for mental health tools slide',
	},
	slide3Copy: {
		id: 'focusedFlows.mentalHealthTools.slide3.copy.v2',
		defaultMessage:
			'Sonically engineered for headphones to promote nervous system regulation through rhythmic, soothing sounds for a deeper sleep experience.',
		description: 'Copy for mental health tools slide',
	},
	slide3ContentTitle: {
		id: 'focusedFlows.mentalHealthTools.slide3.contentTitle',
		defaultMessage: 'Finding Focus',
		description: "Title of slide's previewable content",
	},
	slide3ContentSubheading: {
		id: 'focusedFlows.mentalHealthTools.slide3.contentSubheading',
		defaultMessage: 'Practice simply paying attention',
		description: "Information for slide's previewable content",
	},
	slide3ContentAuthor: {
		id: 'focusedFlows.mentalHealthTools.slide3.contentAuthor',
		defaultMessage: 'Narrated by Jeff Warren',
		description: "Author of slide's previewable content",
	},
	slide4Title: {
		id: 'focusedFlows.mentalHealthTools.slide4.title.v2',
		defaultMessage: 'Soundscapes',
		description: 'Title for mental health tools slide',
	},
	slide4Heading: {
		id: 'focusedFlows.mentalHealthTools.slide4.heading.v2',
		defaultMessage: "We don't call it Calm for nothing.",
		description: 'Heading for mental health tools slide',
	},
	slide4Copy: {
		id: 'focusedFlows.mentalHealthTools.slide4.copy.v2',
		defaultMessage:
			'Listen to our soothing soundscapes to calm your mind from falling rain to ocean waves. In addition to nature, Calm has a diverse library of immersive sounds to help you sleep, focus, or relax.',
		description: 'Copy for mental health tools slide',
	},
	slide4ContentTitle: {
		id: 'focusedFlows.mentalHealthTools.slide4.contentTitle',
		defaultMessage: 'Rain on Leaves',
		description: "Title of slide's previewable content",
	},
	slide4ContentSubheading: {
		id: 'focusedFlows.mentalHealthTools.slide4.contentSubheading',
		defaultMessage: 'Soothing sounds of rainfall',
		description: "Information for slide's previewable content",
	},
});

export const sleepPollAnswers = defineMessages({
	answerOccasionally: {
		id: 'onboarding.sleep.trouble.occasionally',
		defaultMessage: 'Occasionally',
		description: 'Answer for occasionally',
	},
	answerFrequently: {
		id: 'onboarding.sleep.trouble.frequently',
		defaultMessage: 'Frequently',
		description: 'Answer for frequently',
	},
	answerEveryDay: {
		id: 'onboarding.sleep.trouble.everyDay',
		defaultMessage: 'Every Day',
		description: 'Answer for every Day',
	},
	never: {
		id: 'onboarding.sleep.trouble.never',
		defaultMessage: 'Never',
		description: 'Answer for never',
	},
	dontKnowHow: {
		id: 'onboarding.sleep.trouble.dontKnowHow',
		defaultMessage: "I don't know how to",
		description: 'Answer for not knowing how to manage stress',
	},
	tryDifferentThings: {
		id: 'onboarding.sleep.trouble.tryDifferentThings',
		defaultMessage: 'I try different mindfulness techniques',
		description: 'Answer for not knowing how to manage stress',
	},
	noneOfTheAbove: {
		id: 'onboarding.sleep.trouble.noneOfTheAbove',
		defaultMessage: 'None of the above',
		description: 'Answer for none of the above',
	},
	allOfTheAbove: {
		id: 'onboarding.sleep.trouble.allOfTheAbove',
		defaultMessage: 'All of the above',
		description: 'Answer for how I want to feel',
	},
	sleepingAids: {
		id: 'onboarding.sleep.trouble.sleepingAids',
		defaultMessage: 'I rely on sleeping aids',
		description: 'Answer for how to manage sleep',
	},
	energized: {
		id: 'onboarding.sleep.trouble.energized',
		defaultMessage: 'Energized and alert',
		description: 'Answer for how I want to feel',
	},
	betterMood: {
		id: 'onboarding.sleep.trouble.mood',
		defaultMessage: 'In a better mood',
		description: 'Answer for how I want to feel',
	},
	lessStressed: {
		id: 'onboarding.sleep.trouble.lessStressed',
		defaultMessage: 'Less stressed',
		description: 'Answer for how I want to feel',
	},
});
