import type { ToggleProps } from '@react-types/checkbox';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Checkbox, CheckboxAlign } from '@calm-web/design-system';

import { useIsGDPRDevice, useIsUKGDPRDevice } from '@/hooks/user';

import messages from './messages';

const MarketingOptIn = ({ onChange }: Pick<ToggleProps, 'onChange'>): React.ReactElement | null => {
	const isGDPRDevice = useIsGDPRDevice();
	const isUKGDPRDevice = useIsUKGDPRDevice();
	const [isSelected, setIsSelected] = useState(!isGDPRDevice);

	const displayCheckbox = isGDPRDevice || isUKGDPRDevice;
	const { formatMessage } = useIntl();
	useEffect(() => {
		onChange?.(isSelected);
	}, [onChange, isSelected]);

	if (!displayCheckbox) {
		return null;
	}

	return (
		<Checkbox
			name="marketing-opt-in"
			onChange={val => {
				setIsSelected(val.currentTarget.checked);
				onChange?.(val.currentTarget.checked);
			}}
			checked={isSelected}
			alignment={CheckboxAlign.Center}
		>
			{formatMessage(messages.marketingOptIn)}
		</Checkbox>
	);
};

export default MarketingOptIn;
