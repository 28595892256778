import { ComplianceLevel } from '@/store/complianceLevel/types';
import { CalmLanguage } from '@/utils/language';
import { JSONValue } from '@/utils/types';

export type SubscriptionDetails = {
	renews_at: string;
};

export type StripeDetails = {
	began?: string | null;
	canceled_at?: string | null;
	coupon?: string | null;
	currency: string;
	customer_id: string;
	expires?: string | null;
	id: string;
	is_canceled: boolean;
	is_free_trial: boolean;
	is_refunded: boolean;
	is_renewable: boolean;
	original_amount: number;
	payment_processor: 'stripe';
	plan_duration: string;
	plan: string;
	promotion?: string | null;
	purchaser_platform?: string | null;
	renews_at?: string | null;
	status?: string | null;
	trial_end?: string | null;
	trial_start?: string | null;
	will_renew: boolean;
};

export type CollegeInfo = {
	id: string;
	is_active: boolean;
	name: string;
	colloquial_name: string;
	email_domains: string[];
};

export type GiftDetails = {
	type: string;
	partner?: string;
	college?: CollegeInfo;
	amount?: number | null;
	began?: string | null;
	expires?: string | null;
	id: string;
	is_lifetime: boolean;
	is_renewable: boolean;
	plan_duration: string;
	will_renew: boolean;
};

export type B2BDetails = {
	id: string;
	partner_id: string;
	partner_user_id: string;
	partner_slug: string;
	partner_name: string;
	plan: string;
	plan_duration: string;
	is_renewable: boolean;
	will_renew: boolean;
	has_dependent_support: boolean;
	max_dependents_per_user: number;
};

export type IosDetails = {
	began?: string | null;
	expires?: string | null;
	free_trial_length_in_days: number | undefined;
	id: string;
	is_canceled: boolean;
	is_free_trial: boolean;
	in_free_trial_window?: boolean;
	is_in_billing_retry_period: boolean;
	is_renewable: boolean;
	original_transaction_id: string | undefined;
	payment_processor: 'itunes';
	plan_duration: string;
	product_id?: string | null;
	purchaser_platform: 'ios';
	will_renew: boolean;
	free_trial_began?: string | null;
	free_trial_ended?: string | null;
};

export type AndroidDetails = {
	began?: string | null;
	expires?: string | null;
	free_trial_length_in_days: number | undefined;
	free_trial_began?: string | null;
	free_trial_ended?: string | null;
	id: string;
	is_canceled: boolean;
	is_free_trial: boolean;
	in_free_trial_window?: boolean;
	is_renewable: boolean;
	payment_processor: 'google_play';
	plan_duration: string;
	product_id?: string | null;
	purchaser_platform: 'android';
	renews_at?: string | null;
	subscription_notification_type?: string;
	will_renew: boolean;
};

export type FamilyPlanDetails = {
	id: string;
	expires: string;
	plan: 'family_plan_1y_autorenew';
	is_canceled: boolean;
	renews_at: string;
	family_id: string;
	is_admin: boolean;
};

export type BraintreeDetails = {
	began?: string | null;
	canceled_at?: string | null;
	coupon?: string | null;
	currency: string;
	customer_id: string;
	expires?: string | null;
	id: string;
	is_canceled: boolean;
	is_free_trial: boolean;
	is_refunded: boolean;
	is_renewable: boolean;
	original_amount: number;
	payment_processor: 'braintree';
	plan_duration: string;
	plan: string;
	promotion?: string | null;
	purchaser_platform?: string | null;
	renews_at?: string | null;
	status?: string | null;
	trial_end?: string | null;
	trial_start?: string | null;
	will_renew: boolean;
};

export type VoucherDetails = {
	began: string;
	expires?: string | null;
	id: string;
	is_renewable: boolean;
	partner: string;
	payment_processor: 'voucher';
	plan_duration: string;
	will_renew: boolean;
};

export type TeamDetails = {
	began: string;
	expires?: string | null;
	id: string;
	is_renewable: boolean;
	plan_duration: string;
	plan?: string | null;
	renews_at?: string | null;
	status?: string | null;
	team: {
		id: string;
		admin_user_id: string;
		name: string;
		slug: string;
	};
	will_renew: boolean;
};

export type SubscriptionType =
	| 'ios'
	| 'android'
	| 'team'
	| 'stripe'
	| 'braintree'
	| 'gift'
	| 'incomm'
	| 'invite_coupon'
	| 'b2b'
	| 'b2b_dependent'
	| 'voucher'
	| 'family'
	| 'free_access'
	| 'blackhawk';

export type SubscriptionKeys =
	| 'family_details'
	| 'stripe_details'
	| 'gift_details'
	| 'b2b_details'
	| 'b2b_dependent_details'
	| 'ios_details'
	| 'android_details'
	| 'team_details'
	| 'braintree_details'
	| 'incomm_details'
	| 'blackhawk_details'
	| 'invite_coupon_details'
	| 'voucher_details'
	| 'free_access_details';

export type B2BDependentDetails = {
	began: string;
	expires: string;
	is_canceled: boolean;
	id: string;
	partner_id: string;
	partner_slug: string;
	type: SubscriptionType;
	b2b_admin_details: {
		id: string;
		email: string;
		name?: string;
	};
};

type Iso8601String = string;

export type Subscription = {
	type: SubscriptionType;
	expires: Iso8601String;
	valid: boolean;
	user_id: string;
	subscription_plan: string;
	plan_duration: string;
	will_renew: boolean;
	is_renewable: boolean;
	is_canceled: boolean;
	is_in_billing_retry_period: boolean;
	in_free_trial_window: boolean;
	is_refunded: boolean;
	free_trial_began?: Iso8601String | null;
	free_trial_ended?: Iso8601String | null;
	free_trial_length_in_days: number | undefined;
	has_ever_done_free_trial: boolean;
	has_ever_had_ios_subscription: boolean;
	has_ever_had_android_subscription: boolean;
	is_free: boolean;
	is_samsung_linked?: boolean;
	began: string;
	is_lifetime: boolean;
	coupon_used?: string | null;
	payment_processor?: string | null;
	purchaser_platform?: string | null;
	stripe_details?: StripeDetails;
	gift_details?: GiftDetails;
	b2b_details?: B2BDetails;
	family_details?: FamilyPlanDetails;
	ios_details?: IosDetails;
	b2b_dependent_details?: B2BDependentDetails;
	android_details?: AndroidDetails;
	team_details?: TeamDetails;
	braintree_details?: BraintreeDetails;
	incomm_details?: SubscriptionDetails;
	blackhawk_details?: SubscriptionDetails;
	invite_coupon_details?: SubscriptionDetails;
	voucher_details?: VoucherDetails;
	free_access_details?: Partial<SubscriptionDetails>;
};

export interface User {
	created_at?: Date;
	email?: string; // Email is not necessarily guaranteed to come from Facebook logins, or old Calm accounts
	name?: string; // Name was not always required for Calm users
	error?: JSONValue;
	id?: string;
	token?: string;
	calm_identifier?: string;
	subscription?: Subscription;
	is_hipaa_compliant?: boolean;
	compliance_level?: ComplianceLevel;
	language?: CalmLanguage;
	has_opted_in_limited_data_use?: boolean;
	enterprise?: {
		name: string;
		is_healthcare: boolean;
	};
	was_created?: boolean;
	student_status?: string;
	current_auth_type?: 'auth0' | 'calm';
}

export const SET_USER = 'SET_USER';

export interface SetUserAction {
	type: typeof SET_USER;
	payload: User | null;
}
export type UserActionTypes = SetUserAction;
