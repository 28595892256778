import { defineMessages } from 'react-intl';

export default defineMessages({
	hero: {
		id: 'campaigns.hero',
		defaultMessage: 'Find Your Calm',
		description: 'Title text on the homepage',
	},
	heroUpgrade: {
		id: 'campaigns.heroUpgrade',
		defaultMessage: 'Upgrade to Calm Lifetime',
		description: 'Title text on the homepage',
	},
	heroSubtitle: {
		id: 'campaigns.heroSubtitle',
		defaultMessage: 'Sleep more. Stress less. Live better',
		description: 'Subtitle text on the homepage',
	},
	heroSubtitleUpgrade: {
		id: 'campaigns.heroSubtitleUpgrade',
		defaultMessage: 'Unlimited access forever',
		description: 'Subtitle text on the homepage',
	},
	signupDiscountHeading: {
		id: 'campaigns.signup.signupDiscountHeading',
		defaultMessage: 'Get {percentOff}% off',
		description: 'Discount messaging for signup button',
	},
	signupPriceHeading: {
		id: 'campaigns.signup.signupPriceHeading',
		defaultMessage: 'Get Calm for {amount}',
		description: 'Price messaging for signup button',
	},
});
