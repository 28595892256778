import { useIntl } from 'react-intl';

import messages from '@/components/authForm/ForgotPassword/messages';
import { useAnalytics } from '@/hooks/analytics';
import { postForgotPassword } from '@/utils/endpoints';

import { InputField } from './types';

export function useForgotPasswordSubmit() {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();

	async function onSubmit({
		value,
		isValid,
	}: Pick<InputField, 'value' | 'isValid'>): Promise<Record<string, string>> {
		logEvent({
			eventName: 'Login Form : Forgot Password : Clicked',
		});

		if (!isValid) {
			logEvent({
				eventName: 'Login Form : Forgot Password : Error',
				eventProps: {
					error: formatMessage(messages.passwordResetEmail),
				},
			});
			return { error: formatMessage(messages.passwordResetEmail) };
		}

		try {
			await postForgotPassword(value);
			logEvent({
				eventName: 'Login Form : Forgot Password : Success',
			});
			return {
				success: `${formatMessage(messages.emailSuccess)} ${value}`,
			};
		} catch (err) {
			logEvent({
				eventName: 'Login Form : Forgot Password : Error',
				eventProps: {
					error: formatMessage(messages.passwordReset),
				},
			});
			return { error: formatMessage(messages.passwordReset) };
		}
	}

	return onSubmit;
}
