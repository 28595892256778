import { defineMessages } from 'react-intl';

export default defineMessages({
	expandedHandoffText: {
		id: 'app.mobileHandoff.expandedBody',
		defaultMessage: "Scan this QR code to use Calm's mobile app.",
		description: 'Instructions on what scanning the code does',
	},
	handoffText: {
		id: 'app.mobileHandoff.text',
		defaultMessage: "Scan this QR code to use Calm's mobile app.",
		description: 'Instructions on what scanning the code does',
	},
	collapsedHandoffText: {
		id: 'app.mobileHandoff.collapsedBody',
		defaultMessage: 'Use Mobile App',
		description: 'Label to describe what the button context is',
	},
	headerHandoffText: {
		id: 'app.mobileHandoff.tabletHeader',
		defaultMessage: 'Open Mobile App',
		description: 'Label to describe what the button context is',
	},
	close: {
		id: 'generics.close',
		defaultMessage: 'Close',
		description: 'close button',
	},
});
