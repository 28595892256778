import { defineMessages } from 'react-intl';

export default defineMessages({
	cardName: {
		id: 'redesign.purchase.creditCardForm.cardName',
		defaultMessage: 'Name on Credit Card',
		description: 'CC Name Prompt',
	},
	firstName: {
		id: 'redesign.purchase.creditCardForm.nameOnCard',
		defaultMessage: 'Name on Card',
		description: 'CC Name on Card',
	},
	firstNameError: {
		id: 'generics.nameOnCardError',
		defaultMessage: 'Name on Card is required!',
		description: 'CC Name Required',
	},
	expiryPlaceholder: {
		id: 'redesign.purchase.creditCardForm.expiryPlaceholder',
		defaultMessage: 'Exp (MM / YY)',
		description: 'CC Expiry Prompt',
	},
	cvcPlaceholder: {
		id: 'redesign.purchase.creditCardForm.cvcPlaceholder',
		defaultMessage: 'CVC',
		description: 'CC CVC Prompt',
	},
	postalCode: {
		id: 'redesign.purchase.creditCardForm.postalCode',
		defaultMessage: 'Billing Zip Code',
		description: 'CC Zip Prompt',
	},
	failedCaptcha: {
		id: 'redesign.purhcase.creditCardForm.failedCaptcha',
		defaultMessage: 'Oops! Something went wrong. Please try again.',
		description: 'CC Captcha Failed',
	},
	startFreeTrial: {
		id: 'redesign.purchase.creditCardForm.button.startFreeTrial2',
		defaultMessage: 'Start Your Trial',
		description: 'Default credit card form button text',
	},
	useCard: {
		id: 'redesign.purchase.creditCardForm.button.useCard',
		defaultMessage: 'Get Calm Premium',
		description: 'Button text for already subbed',
	},
	success: {
		id: 'redesign.purchase.creditCardForm.button.success',
		defaultMessage: 'Success',
		description: 'Button text for successful purchase',
	},
	ccFormHeading: {
		id: 'creditCardForm.ccFormHeading',
		defaultMessage: 'Or, use your credit or debit card:',
		description: 'Heading instructing user to enter credit card info',
	},
	totalDue: {
		id: 'creditCardForm.totalDue',
		defaultMessage: 'Total due today',
		description: "A line which adds up a user's purchase total",
	},
	secureTransaction: {
		id: 'creditCardForm.secureTransaction',
		defaultMessage: 'All transactions are secure and encrypted.',
		description: 'Text ensuring the user that their credit card will be processed securely',
	},
	ccButtonLabel: {
		id: 'purchase.paymentTypeSelector.ccButtonLabel',
		defaultMessage: 'Credit or Debit',
		description: 'Label for button to select credit card form',
	},
});
