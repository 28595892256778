import { SignupScreens } from '../../PreSignupProvider/types';

export const secondFreeTrialSignupScreens: SignupScreens = {
	start: {
		componentName: 'AccountSetup',
		analyticsName: 'Login Form',
		nextScreen: 'upsell',
		backButtonHidden: true,
		showNav: true,
	},
	upsell: {
		componentName: 'PremiumUpsell',
		analyticsName: 'Free Trial',
		nextScreen: 'mobileHandoff',
		backButtonHidden: true,
	},
	mobileHandoff: {
		componentName: 'MobileHandoff',
		analyticsName: 'Mobile Handoff',
		backButtonHidden: true,
	},
	hdyhau: {
		componentName: 'Hdyhau',
		analyticsName: 'Hdyhau',
		nextScreen: 'mobileHandoff',
		backButtonHidden: true,
	},
};
