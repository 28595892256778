import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights } from '@calm-web/design-system';

import chevronLeft from 'icons/chevron-left-aligned.svg';

export const Wrapper = styled.a`
	align-items: center;
	color: ${palette('white')};
	display: flex;
	margin-bottom: 40px;
`;

export const Icon = styled(chevronLeft)`
	height: 30px;
	width: 30px;

	& path {
		fill: ${palette('white')};
	}
`;

export const Copy = styled.div`
	${fontWeight(FontWeights.Demi)}
	text-decoration: underline;
`;
