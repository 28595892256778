import { useRouter } from 'next/router';
import { MouseEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';

import IconGradientBorder from '@/components/app/sidebar/IconGradientBorder';
import { useLocale } from '@/hooks/locale/useLocale';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import { Icon, Label, Wrapper } from './styles';
import { SidebarMenuItemProps } from './types';

const SidebarMenuItem = ({
	title,
	IconOutline,
	IconFilled,
	colors,
	route,
	supportedLocales,
	id,
	onClick: _onClick,
}: SidebarMenuItemProps) => {
	const { formatMessage } = useIntl();
	const { asPath, pathname } = useRouter();
	const { calmLanguage } = useLocale();
	const [isHovering, setIsHovering] = useState(false);
	const routerPush = useRouterPush();

	if (supportedLocales && !supportedLocales.includes(calmLanguage)) {
		return null;
	}

	const onMouseEnter = () => {
		setIsHovering(true);
	};

	const onMouseLeave = () => {
		setIsHovering(false);
	};

	const isActive = () => {
		if (pathname.includes('profile') && route.includes('profile')) {
			return true;
		}
		return asPath === route || pathname === route;
	};

	const onClick: MouseEventHandler<HTMLAnchorElement> = async e => {
		e.preventDefault();
		if (_onClick) {
			_onClick();
		}
		await routerPush(route);
	};

	return (
		<Wrapper
			href={route}
			isActive={isActive()}
			aria-current={isActive() ? 'page' : undefined}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<IconGradientBorder id={id} colors={colors} isActive={isActive() || isHovering}>
				<Icon>{isActive() ? <IconFilled /> : <IconOutline />}</Icon>
			</IconGradientBorder>
			<Label>{formatMessage(title)}</Label>
		</Wrapper>
	);
};

export default SidebarMenuItem;
