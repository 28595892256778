import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontWeights, FormInput } from '@calm-web/design-system';

export const Wrapper = styled.form`
	padding: 20px 0;
`;

export const TextWrapper = styled.div`
	margin: 0.75rem 0;
	text-align: center;
`;

export const ExpressCheckoutWrapper = styled.div`
	margin: 0.75rem 0;
`;

export const NoticeWrapper = styled.div`
	margin-top: 1rem;
`;

export const NameOnCardInput = styled(FormInput)`
	margin-bottom: 0.75rem;

	& label {
		color: ${palette('gray5')};
		font-size: 1rem;
		font-weight: ${FontWeights.Medium};
	}

	& input {
		border: 2px ${palette('gray2')} solid;
		border-width: 2px !important;
		border-radius: 6px;
		color: ${palette('gray5')};
		font-size: 1rem;
		font-weight: ${FontWeights.Medium};
		height: 66px;
	}
`;
