import { useIntl } from 'react-intl';

import { usePricesState, usePurchaseParamsState } from '@/hooks/store';
import messages from '@/messages/messages';
import { Prices } from '@/store/prices/types';

import { useFormattedCurrency } from './useFormattedCurrency';

type UseFormattedPriceWithTax = (price: number, currency?: string) => string;

export const shouldShowTaxAmount = (countryCode: string | null | undefined, prices: Prices) => {
	const countriesToShowTaxIn = ['us', 'ca'];

	// this is primarily for local and dev environment testing - the logic won't directly match
	// until we fix maxmind returning correct country code on these environments
	if (!countryCode || countryCode === 'unknown' || countryCode === '') {
		return ['usd', 'cad'].includes(prices?.pricing_format?.currency.toLowerCase());
	}

	return countriesToShowTaxIn.includes(countryCode.toLowerCase());
};

export const useFormattedPriceWithTax: UseFormattedPriceWithTax = (price, currency) => {
	const formatCurrency = useFormattedCurrency();
	const { formatMessage } = useIntl();
	const { billingCountryCode } = usePurchaseParamsState();
	const prices = usePricesState();

	const priceWithCurrency = formatCurrency(price, currency);

	if (!shouldShowTaxAmount(billingCountryCode, prices) || price === 0) {
		return priceWithCurrency;
	}

	return `${priceWithCurrency} + ${formatMessage(messages.applicableTax)}`;
};
