import { createContext, ReactNode, useContext } from 'react';

import { ActivityData } from '@/teams-calm/bots/prompts/types';

export const TeamsUserContext = createContext<ActivityData | null>(null);

export const useTeamsUserContext = (): ActivityData | null => {
	const value = useContext(TeamsUserContext);
	return value;
};

const TeamsUserContextProvider = ({ value, children }: { value?: ActivityData; children: ReactNode }) => {
	return <TeamsUserContext.Provider value={value ?? null}>{children}</TeamsUserContext.Provider>;
};

export default TeamsUserContextProvider;
