import { ImageProps } from 'next/legacy/image';
import { ReactNode } from 'react';

import Image from '../NextImage';
import { Wrapper } from './styles';

/**
 * Displays a Background Image
 */
function FullscreenBackground({
	src,
	className,
	objectPosition,
	objectFit = 'cover',
}: {
	src: string;
	className?: string;
	children?: ReactNode;
	objectPosition?: ImageProps['objectPosition'];
	objectFit?: ImageProps['objectFit'];
}): JSX.Element {
	return (
		<Wrapper className={className}>
			<Image src={src} alt="" objectPosition={objectPosition} objectFit={objectFit} layout="fill" />
		</Wrapper>
	);
}

export default FullscreenBackground;
