import { useStripe } from '@stripe/react-stripe-js';
import { CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { usePaymentFormValues } from '@/hooks/purchase/usePaymentFormValues';

import { ApplePayIcon, Wrapper } from './styles';
import { ApplePayButtonProps } from './types';

const ApplePayButton = ({
	children,
	onClick: _onClick,
	isLoading,
	isDisabled,
	requestInfo,
	onCancel,
	onSubmit,
	onSuccess,
	onError,
	setIsApplePayAvailable,
}: ApplePayButtonProps) => {
	const stripe = useStripe();
	const { paymentFormPrice, paymentFormCurrency } = usePaymentFormValues();

	const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>();
	const [paymentResult, setPaymentResult] = useState<CanMakePaymentResult | null>();

	const initPaymentRequest = async () => {
		if (!stripe) return;

		const pr = stripe.paymentRequest({
			country: 'US',
			currency: paymentFormCurrency.toLocaleLowerCase(),
			total: {
				label: 'Calm Premium',
				amount: paymentFormPrice,
			},
			requestPayerName: true,
			requestPayerEmail: true,
			requestShipping: true,
		});

		try {
			const result = await pr.canMakePayment();

			if (result) {
				setIsApplePayAvailable(true);
				setPaymentRequest(pr);
				setPaymentResult(result);

				pr.on('shippingaddresschange', async ev => {
					ev.updateWith({
						status: 'success',
						shippingOptions: [
							{
								id: 'digital-subscription',
								label: 'Digital Subscription',
								detail: 'Arrives immediately',
								amount: 0,
							},
						],
						total: {
							label: 'Calm Premium',
							amount: paymentFormPrice,
						},
					});
				});

				pr.on('paymentmethod', async ev => {
					const { paymentMethod, shippingAddress } = ev;

					if (!paymentMethod) {
						return;
					}

					const data = await onSubmit({
						requestInfo,
						paymentType: 'apple_pay',
						token: paymentMethod,
						customerAddress: {
							postalCode: shippingAddress?.postalCode,
							region: shippingAddress?.region,
							country: shippingAddress?.country,
						},
					});

					if (data?.error) {
						ev.complete('fail');
						onError(data.error);
					} else {
						ev.complete('success');
						await onSuccess(data.success?.subscription ?? data.success, 'apple_pay');
					}
				});

				pr.on('cancel', () => onCancel({}, 'apple_pay'));
			} else {
				setPaymentRequest(null);
				setIsApplePayAvailable(false);
			}
		} catch (err) {
			setPaymentResult(null);
			setIsApplePayAvailable(false);
		}
	};

	const onClick = () => {
		if (paymentRequest) {
			_onClick();
			paymentRequest.show();
		}
	};

	useEffect(
		() => {
			initPaymentRequest().catch(() => {
				setIsApplePayAvailable(false);
			});
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stripe],
	);

	if (!process.env.NEXT_PUBLIC_APPLE_CLIENT_ID || !process.env.NEXT_PUBLIC_APPLE_REDIRECT_PATH) {
		return null;
	}

	if (!paymentResult?.applePay) {
		return null;
	}

	return (
		<Wrapper
			fullWidth
			isLoading={isLoading}
			backgroundColor="black"
			onPress={onClick}
			isDisabled={isDisabled}
		>
			{children}
			<ApplePayIcon />
		</Wrapper>
	);
};

export default ApplePayButton;
