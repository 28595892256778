export interface FBLibrary {
	getLoginStatus: (callback: (response: FBLoginResponse) => void) => void;
	init: (options: { appId: string; xfbml: boolean; version: string }) => void;
	login: (callback: () => void, options: { scope: string }) => void;
}

export interface FBLoginResponse {
	status: 'connected' | 'not_authorized' | 'unknown';
	authResponse: {
		accessToken: string;
		expiresIn: number;
		signedRequest: string;
		userID: string;
	};
}

export type FacebookSignInTokenSuccess = {
	token: string;
	user_id: string;
	token_expiration: string; // ISO-8601 string
};

export interface FacebookSignInTokenError {
	error: 'cancelled';
}
export const isFacebookSignInTokenError = (value: FacebookSignInToken): value is FacebookSignInTokenError =>
	'error' in value;

export type FacebookSignInToken = FacebookSignInTokenSuccess | FacebookSignInTokenError;
