import { StyledComponent } from 'styled-components';

import { AccessibleOnChange } from '@calm-web/design-system';

import { JSONObject } from '@/utils/types';

export type FieldName = string;
export type InputNames = readonly FieldName[];

export interface InputField {
	value: string;
	isTouched: boolean;
	isValid: boolean;
	isBlurred: boolean;
	Icon: React.FC | undefined;
	errorMessage?: string;
}

export interface InputFields {
	[fieldName: string]: InputField;
}

export type InitialValues<T extends FieldName> = Partial<Record<T, string | null>>;

export interface InputActions {
	onChange: AccessibleOnChange;
	onBlur: (e: React.FocusEvent<HTMLInputElement>, eventProps?: JSONObject) => void;
	onFocus: React.FocusEventHandler<HTMLInputElement>;
	onClear: () => void;
}

export type InputIcons = Record<
	FieldName,
	StyledComponent<React.ComponentType, object, object, string | number | symbol>
>;
