import { ReactNode } from 'react';
import { injectIntl } from 'react-intl';

import { SubscribePurchaseError } from '@/components/purchase/utils/Purchase';
import messages from '@/messages/messages';

import { Link, Subtitle, Title, Wrapper } from './styles';
import { PaymentErrorProps } from './types';

const PaymentError = ({ intl: { formatMessage }, errorType }: PaymentErrorProps) => {
	const paymentMessages: Record<SubscribePurchaseError, Record<string, string | ReactNode>> = {
		'already-subscribed': {
			title: formatMessage(messages.alreadySubscribedTitle),
			subtitle: formatMessage(messages.alreadySubscribedSubtitle),
		},
		'already-trialing': {
			title: formatMessage(messages.alreadyTrialingTitle),
			subtitle: formatMessage(messages.alreadyTrialingSubtitle),
		},
		'previously-trialed': {
			title: formatMessage(messages.alreadyTrialedTitle),
			subtitle: formatMessage(messages.alreadyTrialedSubtitle, {
				here: <Link href="/subscribe">{formatMessage(messages.here)}</Link>,
			}),
		},
	};

	return (
		<Wrapper>
			<Title>{paymentMessages[errorType].title}</Title>
			<Subtitle>{paymentMessages[errorType].subtitle}</Subtitle>
			<Subtitle>
				{formatMessage(messages.learnMore, {
					here: <Link href="/profile/manage-subscription">{formatMessage(messages.here)}</Link>,
				})}
			</Subtitle>
		</Wrapper>
	);
};

export default injectIntl(PaymentError);
