import type { Subscription, User } from '@/store/user/types';
import getUrl from '@/utils/getUrl';

export function parsePartnerDetails(user: User | null): { partnerId?: string; partnerName?: string } {
	const partnerId = user?.subscription?.b2b_details?.partner_id;
	const partnerName = user?.subscription?.b2b_details?.partner_name;
	return { partnerId, partnerName };
}

export function getPartnerSlugFromSubscription(subscription: Subscription | undefined): string | undefined {
	if (!subscription) {
		return undefined;
	}
	if (subscription.b2b_details) {
		return subscription.b2b_details.partner_slug;
	}
	if (subscription.b2b_dependent_details) {
		return subscription.b2b_dependent_details.partner_slug;
	}
	return undefined;
}

export function getSubscribeLink(subscription: Subscription | undefined): string | undefined {
	const partnerSlug = getPartnerSlugFromSubscription(subscription);
	return partnerSlug ? `${getUrl('www')}/b2b/${partnerSlug}/subscribe` : undefined;
}
