import { BannerText, PartnerLogo, StyledMessageBannerRow, TextWrap } from './styles';

const MessageBannerRow: React.FC<{ bannerMessage: string; bannerLogo?: string }> = ({
	bannerLogo,
	bannerMessage,
}) => {
	return (
		<StyledMessageBannerRow>
			<TextWrap>
				<BannerText>
					{bannerLogo && <PartnerLogo src={bannerLogo} alt="Partner Logo" />}
					<span>{bannerMessage}</span>
				</BannerText>
			</TextWrap>
		</StyledMessageBannerRow>
	);
};

export default MessageBannerRow;
