import { AnyAction } from 'redux';

import { Locale, SET_LOCALE } from './types';

const initialState: Locale | null = null;

export function localeReducer(state = initialState, action: AnyAction = { type: undefined }): Locale | null {
	switch (action.type) {
		case SET_LOCALE:
			return action.payload;
		default:
			return state;
	}
}
