import debounce from 'lodash/debounce';
import { RefObject, useEffect, useRef, useState } from 'react';

export function useElementDimensions(): [RefObject<HTMLDivElement>, { height: number; width: number }] {
	const elementRef = useRef<HTMLDivElement>(null);
	const [elementDimensions, setElementDimensions] = useState({
		height: 0,
		width: 0,
	});

	const setDimensions = debounce(() => {
		if (elementRef?.current) {
			const width = elementRef.current.clientWidth;
			const height = elementRef.current.clientHeight;
			setElementDimensions({ height, width });
		}
	}, 500);

	useEffect(
		() => {
			setDimensions();
			window.addEventListener('resize', setDimensions);
			return (): void => window.removeEventListener('resize', setDimensions);
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return [elementRef, elementDimensions];
}
