import { useRouter } from 'next/router';
import { useState } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import { Device } from '@/store/device/types';
import { Coupon, Prices } from '@/store/prices/types';
import { PlanCadence, isValidPlan } from '@/store/purchaseParams/types';
import isUSPricing from '@/utils/prices/isUSPricing';

import { useDeviceState, usePricesState } from '../store';
import { useFreeTrial } from './useFreeTrial';

export type PlanSku = {
	duration: string;
	id: 'monthly' | 'yearly';
	originalPrice?: number | null;
	price: number;
	pricePerMonth: number;
	active: boolean;
	dueToday?: number;
	trialLength?: number;
};

const getYearlyOriginalPrice = (prices: Prices, device: Device) => {
	if (isUSPricing(prices, device)) {
		return prices?.current?.yearly === prices?.original?.yearly
			? prices?.current?.monthly * 12
			: prices?.original?.yearly;
	}

	return prices?.original?.yearly;
};

export const usePlanSkuToggle = () => {
	const { query } = useRouter();
	const { logEvent } = useAnalytics();
	const [activePlan, _setActivePlan] = useState<PlanSku['id']>(
		typeof query.plan === 'string' && ['yearly', 'monthly'].includes(query.plan)
			? (query.plan as PlanSku['id'])
			: 'yearly',
	);

	const setActivePlan = (id: PlanSku['id']) => {
		logEvent({
			eventName: 'Subscription Plan : Selected',
			eventProps: {
				plan_id: id,
			},
		});
		_setActivePlan(id);
	};

	const prices = usePricesState();
	const device = useDeviceState();
	const userCanTrial = useFreeTrial();
	const getCouponByPlan = (plan: PlanCadence): Coupon | undefined =>
		isValidPlan(plan) ? prices?.coupons?.[plan] : undefined;

	const annualMonthlyBreakdown = prices?.current?.yearly / 12;

	const skus: PlanSku[] = [
		{
			duration: 'Yearly',
			id: 'yearly',
			originalPrice: getYearlyOriginalPrice(prices, device),
			price: prices?.current?.yearly,
			pricePerMonth: annualMonthlyBreakdown,
			active: activePlan === 'yearly',
			dueToday: userCanTrial && !getCouponByPlan('yearly') ? 0 : prices?.current?.yearly,
		},
		{
			duration: 'Monthly',
			id: 'monthly',
			price: prices?.current?.monthly,
			pricePerMonth: prices?.current?.monthly,
			active: activePlan === 'monthly',
			dueToday: prices?.current?.monthly,
		},
	];

	return {
		activePlan,
		setActivePlan,
		skus,
	};
};
