import { SignupScreens } from '../../PreSignupProvider/types';
import { sleepPreSignupScreens } from '../Control/Sleep-Control';

export const simplifiedSleepSignupScreens: SignupScreens = {
	start: sleepPreSignupScreens.goals,
	account: {
		...sleepPreSignupScreens.account,
		nextScreen: 'upsell',
	},
	upsell: sleepPreSignupScreens.upsell,
	payment: sleepPreSignupScreens.payment,
	hdyhau: sleepPreSignupScreens.hdyhau,
	mobileHandoff: sleepPreSignupScreens.mobileHandoff,
};
