import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useIntl } from 'react-intl';

import purchaseMessages from '../messages';
import { Icon, Wrapper } from './styles';

const Amex = 'https://assets.calm.com/c49a4247984b3732a4af50a3390aa978.png';
const Discover = 'https://assets.calm.com/6c0a386a00307f87db7bea366cca35f5.png';
const Mastercard = 'https://assets.calm.com/6f6cdc29ee2e22e06b1ac029cb52ef71.png';
const Visa = 'https://assets.calm.com/3ddc4a4d25c946e8ad7e6998f30fd4e3.png';

type Props = {
	centerFormElements?: boolean;
};

const CreditCardIcons = ({ centerFormElements }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper centerFormElements={centerFormElements}>
			<VisuallyHidden>{formatMessage(purchaseMessages.ccFormCardTypes)}</VisuallyHidden>
			<Icon aria-hidden src={Visa} />
			<Icon aria-hidden src={Mastercard} />
			<Icon aria-hidden src={Amex} />
			<Icon aria-hidden src={Discover} />
		</Wrapper>
	);
};

export default CreditCardIcons;
