import { SignupScreens } from '../../PreSignupProvider/types';
import { stressPreSignupScreens } from '../Control/Stress-Control';

export const simplifiedStressSignupScreens: SignupScreens = {
	start: stressPreSignupScreens.goals,
	account: {
		...stressPreSignupScreens.account,
		nextScreen: 'upsell',
	},
	upsell: stressPreSignupScreens.upsell,
	payment: stressPreSignupScreens.payment,
	hdyhau: stressPreSignupScreens.hdyhau,
	mobileHandoff: stressPreSignupScreens.mobileHandoff,
};
