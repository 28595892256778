import { Token } from '@stripe/stripe-js';
import { useCallback, useEffect, useRef, useState } from 'react';

import { usePaymentFormValues } from '@/hooks/purchase/usePaymentFormValues';
import { usePricesState, usePurchaseParamsState, useUserState } from '@/hooks/store';
import { PlanCadence } from '@/store/purchaseParams/types';
import { userCanTrial } from '@/utils/subscriptions';

import { CustomerAddress } from '../PurchaseForm/types';
import { baseCardPaymentMethod, baseRequest, cardPaymentMethod } from './data';
import { GooglePayButtonWrapper, LoadingButton, Wrapper } from './styles';
import { GooglePayButtonProps } from './types';

const GooglePayButton = ({
	children,
	onClick: _onClick,
	onSubmit,
	isLoading,
	isDisabled,
	onCancel,
	requestInfo,
	onSuccess,
	onError,
	setIsGooglePayAvailable,
	shouldUsePaymentFormPrice = false,
	buttonBackgroundColor = 'black',
}: GooglePayButtonProps) => {
	const { paymentFormCurrency, paymentFormPrice } = usePaymentFormValues();

	const prices = usePricesState();
	const { plan, purchaseType } = usePurchaseParamsState();
	const user = useUserState();

	const buttonElMonthly = useRef<HTMLDivElement>(null);
	const buttonElYearly = useRef<HTMLDivElement>(null);
	const buttonElLifetime = useRef<HTMLDivElement>(null);

	const [isVisible, setIsVisible] = useState(true);

	let capturedPrice = '0.00';
	let capturedPlan: PlanCadence = plan;

	const onClick = () => {
		if (!isDisabled) {
			_onClick();
		}
	};

	const processGoogleRequest = async (token: Token, customerAddress: CustomerAddress) => {
		const data = await onSubmit({
			requestInfo,
			token,
			paymentType: 'google_pay',
			customerAddress,
			plan: capturedPlan,
		});
		if (data?.error) {
			onError(data.error);
		} else {
			await onSuccess(data.success?.subscription ?? data.success, 'google_pay');
		}
	};

	const onPaymentDataChanged: google.payments.api.PaymentDataChangedHandler = async () => {
		return {
			newTransactionInfo: {
				totalPriceStatus: 'FINAL',
				totalPriceLabel: 'Total',
				totalPrice: capturedPrice,
				currencyCode: paymentFormCurrency,
				countryCode: 'US',
			},
		};
	};

	const onPaymentAuthorized: google.payments.api.PaymentAuthorizedHandler = async paymentData => {
		const stripeToken = JSON.parse(paymentData?.paymentMethodData?.tokenizationData?.token);
		try {
			await processGoogleRequest(stripeToken, {
				postalCode: paymentData.shippingAddress?.postalCode,
				region: paymentData.shippingAddress?.administrativeArea,
				country: paymentData.shippingAddress?.countryCode,
			});
			return { transactionState: 'SUCCESS' };
		} catch (err) {
			// Close anyway - our API will display the correct error messages
			return { transactionState: 'SUCCESS' };
		}
	};

	const getGooglePaymentsClient = () => {
		return new window.google.payments.api.PaymentsClient({
			environment: process.env.NEXT_PUBLIC_CALM_ENV === 'production' ? 'PRODUCTION' : 'TEST',
			paymentDataCallbacks: {
				onPaymentAuthorized,
				onPaymentDataChanged,
			},
		});
	};

	const getPrice = useCallback(
		(sku: PlanCadence) => {
			if (shouldUsePaymentFormPrice) {
				return (paymentFormPrice / 100).toString();
			}

			const hasFreeTrial = userCanTrial(user) && purchaseType?.type === 'freetrial';
			return hasFreeTrial ? '0.00' : (prices.current[sku] / 100).toString();
		},
		[shouldUsePaymentFormPrice, user, purchaseType?.type, prices, paymentFormPrice],
	);

	const onGooglePaymentButtonClicked = async (sku: PlanCadence) => {
		if (!process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID) return;

		const price = getPrice(sku);
		// I know...
		capturedPrice = price;
		capturedPlan = sku;
		// :(
		const paymentDataRequest: google.payments.api.PaymentDataRequest = {
			...baseRequest,
			allowedPaymentMethods: [cardPaymentMethod],
			transactionInfo: {
				totalPriceStatus: 'ESTIMATED',
				totalPriceLabel: 'Total',
				totalPrice: price,
				currencyCode: paymentFormCurrency,
				countryCode: 'US',
			},
			merchantInfo: {
				merchantId: process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID,
				merchantName: 'Calm',
			},
			shippingAddressRequired: true,
			callbackIntents: ['PAYMENT_AUTHORIZATION', 'SHIPPING_ADDRESS'],
		};

		const paymentClient = getGooglePaymentsClient();

		try {
			await paymentClient.loadPaymentData(paymentDataRequest);
		} catch (err) {
			onCancel(err, 'google_pay');
		}
	};

	const addPaymentButtons = () => {
		if (!buttonElMonthly?.current || !buttonElYearly?.current) return;

		const paymentClient = getGooglePaymentsClient();

		[
			['monthly', buttonElMonthly],
			['yearly', buttonElYearly],
			['lifetime', buttonElLifetime],
		].forEach(sku => {
			const [buttonPlan, buttonEl] = sku as [PlanCadence, React.MutableRefObject<HTMLDivElement>];
			const button = paymentClient.createButton({
				onClick: () => onGooglePaymentButtonClicked(buttonPlan),
				buttonColor: buttonBackgroundColor,
				buttonType: 'plain',
				buttonSizeMode: 'fill',
			});
			button.className = 'google-pay-button';
			buttonEl.current.appendChild(button);
			if (buttonPlan !== plan) {
				buttonEl.current.hidden = true;
			}
		});
	};

	const onGooglePayLoaded = async () => {
		const paymentClient = getGooglePaymentsClient();

		const isReadyToPayRequest: google.payments.api.IsReadyToPayRequest = {
			...baseRequest,
			existingPaymentMethodRequired: true,
			allowedPaymentMethods: [baseCardPaymentMethod],
		};

		const isReadyToPay = await paymentClient.isReadyToPay(isReadyToPayRequest);

		if (isReadyToPay?.paymentMethodPresent) {
			addPaymentButtons();
			setIsGooglePayAvailable(true);
		} else {
			setIsVisible(false);
			setIsGooglePayAvailable(false);
		}
	};

	useEffect(
		() => {
			if (!process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID) return;

			const script = document.createElement('script');
			script.src = 'https://pay.google.com/gp/p/js/pay.js';
			script.async = true;
			script.onload = onGooglePayLoaded;
			document.body.appendChild(script);
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		if (buttonElMonthly.current && buttonElYearly.current) {
			if (plan === 'monthly') {
				buttonElMonthly.current.hidden = false;
				buttonElYearly.current.hidden = true;
			} else {
				buttonElMonthly.current.hidden = true;
				buttonElYearly.current.hidden = false;
			}
		}
	}, [plan, buttonElMonthly, buttonElYearly]);

	if (
		!process.env.NEXT_PUBLIC_STRIPE_TOKEN ||
		!process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID ||
		!isVisible
	) {
		return null;
	}

	return (
		<Wrapper onClick={onClick}>
			{isLoading ? (
				<LoadingButton
					fullWidth
					isLoading
					borderColor="gray2"
					loaderColor={buttonBackgroundColor === 'black' ? 'white' : 'gray2'}
					backgroundColor={buttonBackgroundColor === 'black' ? 'black' : 'white'}
					isDisabled={isDisabled}
				/>
			) : (
				<>
					<GooglePayButtonWrapper
						isDisabled={isDisabled}
						ref={buttonElYearly}
						activePlan={plan}
						buttonPlan={'yearly'}
					>
						{children}
					</GooglePayButtonWrapper>
					<GooglePayButtonWrapper
						isDisabled={isDisabled}
						ref={buttonElMonthly}
						activePlan={plan}
						buttonPlan={'monthly'}
					>
						{children}
					</GooglePayButtonWrapper>
					<GooglePayButtonWrapper
						isDisabled={isDisabled}
						ref={buttonElLifetime}
						activePlan={plan}
						buttonPlan={'lifetime'}
					>
						{children}
					</GooglePayButtonWrapper>
				</>
			)}
		</Wrapper>
	);
};

export default GooglePayButton;
