import { useIntl } from 'react-intl';

import { FontSizes, Text } from '@calm-web/design-system';

import { useUserState } from '@/hooks/store';

import messages from './messages';
import { Email, Wrapper } from './styles';
import { LoggedInAsProps } from './types';

const LoggedInAs = ({ email, className }: LoggedInAsProps) => {
	const { formatMessage } = useIntl();
	const user = useUserState();

	return (
		<Wrapper className={className}>
			<Text el="p" size={FontSizes.base}>
				{formatMessage(messages.loggedIn)}
			</Text>
			<Email el="p">{email || user?.email}</Email>
		</Wrapper>
	);
};

export default LoggedInAs;
