import { useCallback, useContext } from 'react';

import { PacksContext } from '@/components/app/layout/PacksProvider';
import { useApi } from '@/hooks/api';
import { Guide } from '@/store/guide/types';
import { Program } from '@/store/program/types';
import { getISOTimestamp } from '@/utils/app/getISOTimestamp';
import { calmLogger } from '@/utils/calmLogger';

import { useScenes } from './useScenes';
import { TimesListenedList } from './useTimesListened';

interface TrackSessionArgs {
	duration: number;
	wasCompleted: boolean;
	timesListened: TimesListenedList | null;
}

interface UseTrackSession {
	({ duration, wasCompleted, timesListened }: TrackSessionArgs): Promise<void>;
}

export const useTrackSession = (guide: Guide | null, program: Program | null): UseTrackSession => {
	const apiRequest = useApi();
	const { currentPack } = useContext(PacksContext);
	const { selectedScene } = useScenes();

	const trackSession: UseTrackSession = useCallback(
		async ({ duration, wasCompleted, timesListened }) => {
			try {
				const body = [
					{
						duration,
						logged_at: getISOTimestamp(new Date()),
						guide_id: guide?.id,
						guide_variant_id: guide?.variant_id,
						program_id: program?.id,
						program_variant_id: program?.variant_id,
						pack_id: currentPack?.id,
						scene_id: selectedScene?.id,
						was_completed: wasCompleted,
						times_listened: timesListened,
					},
				];

				await apiRequest({
					body,
					method: 'POST',
					endpoint: 'session',
				});
			} catch (err) {
				// TODO: Temp fix to prevent 400 error from blocking JS execution
				calmLogger.error('Error in useTrackSession apiRequest', {}, err);
			}
		},
		[
			apiRequest,
			currentPack?.id,
			guide?.id,
			guide?.variant_id,
			program?.id,
			program?.variant_id,
			selectedScene?.id,
		],
	);

	return trackSession;
};
