import styled from 'styled-components';

import { Card, minWidth } from '@calm-web/design-system';

export const PartnerValidationWrapper = styled(Card)`
	padding: 28px 20px;
	border-radius: 20px;
	background-color: white;
	width: 100%;

	${minWidth('tablet')} {
		padding: 48px;
		width: 700px;
	}
`;
