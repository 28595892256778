import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import CalmMobileHeader from '@/components/app/layout/MobileHeader/CalmMobileHeader';
import ScenesLink from '@/components/app/ui/ScenesLink';
import SearchLink from '@/components/app/ui/SearchLink';
import AuthModal from '@/components/authForm/AuthModal';
import useHeaderButtons from '@/hooks/app/useHeaderButtons/useHeaderButtons';

import { ButtonWrapper, Container, Wrapper, IconsWrapper } from './styles';
import { HeaderProps } from './types';

const Header = ({ isNavOpen, setIsNavOpen, appHeaderProps }: HeaderProps) => {
	const { formatMessage } = useIntl();
	const router = useRouter();

	const { buttonConfig } = useHeaderButtons();

	const onAuthSuccess = async (): Promise<void> => {
		router.reload();
	};

	return (
		<>
			<Wrapper>
				<CalmMobileHeader isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
				<Container>
					<IconsWrapper>
						<SearchLink />
						<ScenesLink />
					</IconsWrapper>
					<ButtonWrapper>
						{buttonConfig?.firstButton && (
							<Button onPress={buttonConfig.firstButton.onPress} backgroundColor="transparentWhiteBackground">
								{formatMessage(buttonConfig.firstButton.copy)}
							</Button>
						)}
						<Button onPress={buttonConfig.secondButton.onPress} backgroundColor="white" textColor="black">
							{formatMessage(buttonConfig.secondButton.copy)}
						</Button>
					</ButtonWrapper>
				</Container>
			</Wrapper>
			<AuthModal onAuthSuccess={onAuthSuccess} />
		</>
	);
};

export default Header;
