import { Device } from '@/store/device/types';
import { getCookie, setCookie } from '@/utils/cookies';
import { getDeviceDataFromUserAgentString } from '@/utils/device';
import { getDevice } from '@/utils/endpoints';

export const initDevice = async (): Promise<Device> => {
	const ua = window?.navigator?.userAgent;
	const isZoomApp = ua.includes('ZoomApps');
	const deviceBasic = {
		deviceId: getCookie('x-device-id'),
		...getDeviceDataFromUserAgentString(ua),
		isZoomApp,
	};

	try {
		const { data }: { data: Device } = await getDevice();
		const device = {
			...deviceBasic,
			...data,
		};

		if (device?.calm_identifier) {
			setCookie('calm-id', device.calm_identifier);
		}

		return device;
	} catch (err) {
		return deviceBasic;
	}
};
