import type videojs from 'video.js';

import { ContentType, MediaEl } from '@/components/app/contentPlayer/MediaPlayerProvider/types';

export const isAudioMediaEl = (mediaEl: MediaEl): mediaEl is HTMLAudioElement => {
	return mediaEl ? mediaEl instanceof HTMLAudioElement : false;
};

export const isVideoMediaEl = (mediaEl: MediaEl): mediaEl is videojs.Player => {
	return mediaEl ? mediaEl.hasOwnProperty('player_') : false;
};

export const getCurrentTime = (mediaEl: MediaEl, contentType: ContentType): number => {
	if (contentType === 'audio' && isAudioMediaEl(mediaEl)) {
		return mediaEl.currentTime;
	} else if (contentType === 'video' && isVideoMediaEl(mediaEl)) {
		return mediaEl.currentTime();
	}

	return 0;
};
