import messages from '@/components/app/sidebar/Sidebar/messages';
import { SidebarMenuItemProps } from '@/components/app/sidebar/SidebarMenuItem/types';
import { Scene } from '@/hooks/app/useSelectedScene';

import MovementIconFilled from 'icons/body-filled.svg';
import MovementIconOutline from 'icons/body-outline.svg';
import CoffeeIconFilled from 'icons/coffee-filled.svg';
import CoffeeIconOutline from 'icons/coffee-outline.svg';
import FlagKidsIconFilled from 'icons/flag-kids-filled.svg';
import FlagKidsIconOutline from 'icons/flag-kids-outline.svg';
import HomeIconFilled from 'icons/home-filled.svg';
import HomeIconOutline from 'icons/home-outline.svg';
import WisdomIconOutline from 'icons/masterclass-outline.svg';
import MeditateIconFilled from 'icons/meditate-filled.svg';
import MeditateIconOutline from 'icons/meditate-outline.svg';
import MusicIconFilled from 'icons/music-filled.svg';
import MusicIconOutline from 'icons/music-outline.svg';
import ProfileIconFilled from 'icons/profile-filled.svg';
import ProfileIconOutline from 'icons/profile-outline.svg';
import SleepIconFilled from 'icons/sleep-filled.svg';
import SleepIconOutline from 'icons/sleep-outline.svg';

// Copy-pasted from design-system
// For some reason, importing from design-system causes tests to fail
// (likely due to import order changing intersection observation mocking?)
const CalmGradients = [
	['#75F094', '#75B2F0'],
	['#75F0F0', '#7575F0'],
	['#75B2F0', '#B275F0'],
	['#9191FF', '#F075C7'],
	['#B275F0', '#F0758A'],
	['#F0758A', '#F0A875'],
	['#F39871', '#F0D975'],
	['#FFC52B', '#FF8B3A'],
	['#A0EB67', '#57E779'],
];

export const defaultScene: Scene = {
	audio: {
		content_type: 'audio/mp4',
		download_url: 'https://assets.calm.com/9177564bcdf6e86056d98cc2a6501e9f.m4a',
		duration: 600.022,
		id: '9177564bcdf6e86056d98cc2a6501e9f',
		size: 9731147,
		url: 'https://assets.calm.com/9177564bcdf6e86056d98cc2a6501e9f.m4a',
	},
	created_at: '2019-05-07T21:13:37.537Z',
	english_title_for_analytics: 'Jasper Lake',
	gradient_end_color: '#3935d0',
	gradient_start_color: '#3c9eda',
	id: 'ZFlV8dbxPd',
	image: {
		content_type: 'image/jpeg',
		dominant_colors: ['#779cbf', '#525833', '#d5d1ce'],
		download_url: 'https://assets.calm.com/6b6500c525aa1be40280feaedb894901.jpeg',
		height: 220,
		id: '6b6500c525aa1be40280feaedb894901',
		size: 26207,
		url: 'https://assets.calm.com/6b6500c525aa1be40280feaedb894901.jpeg',
		width: 640,
	},
	is_default: true,
	offsets: {
		x: 0.5,
		y: 0.5,
	},
	time_period: 'day',
	title: 'Jasper Lake',
	updated_at: '2019-05-07T22:56:35.743Z',
	video: {
		content_type: 'video/mp4',
		duration: 2.009,
		id: 'aaf764eade43946661391ae8204118bc',
		size: 3376021,
		url: 'https://s3.amazonaws.com/calm-assets-videos/hls/aaf764eade43946661391ae8204118bc/aaf764eade43946661391ae8204118bc.mp4',
	},
};

export const menuItems: Omit<SidebarMenuItemProps, 'id'>[] = [
	{
		title: messages.home,
		route: '/app',
		colors: CalmGradients[0],
		IconOutline: HomeIconOutline,
		IconFilled: HomeIconFilled,
	},
	{
		title: messages.meditate,
		route: '/app/meditate',
		colors: CalmGradients[1],
		IconOutline: MeditateIconOutline,
		IconFilled: MeditateIconFilled,
	},
	{
		title: messages.sleep,
		route: '/app/sleep',
		colors: CalmGradients[2],
		IconOutline: SleepIconOutline,
		IconFilled: SleepIconFilled,
	},
	{
		title: messages.music,
		route: '/app/music',
		colors: CalmGradients[3],
		IconOutline: MusicIconOutline,
		IconFilled: MusicIconFilled,
	},
	{
		title: messages.forWork,
		route: '/app/work',
		colors: CalmGradients[4],
		IconOutline: CoffeeIconOutline,
		IconFilled: CoffeeIconFilled,
	},
	{
		title: messages.wisdom,
		route: '/app/wisdom',
		colors: CalmGradients[5],
		IconOutline: WisdomIconOutline,
		IconFilled: WisdomIconOutline,
		supportedLocales: 'en',
	},
	{
		title: messages.kids,
		route: '/app/kids',
		colors: CalmGradients[6],
		IconOutline: FlagKidsIconOutline,
		IconFilled: FlagKidsIconFilled,
	},
	{
		title: messages.movement,
		route: '/app/movement',
		colors: CalmGradients[7],
		IconOutline: MovementIconOutline,
		IconFilled: MovementIconFilled,
		supportedLocales: ['en', 'de', 'fr'],
	},
	{
		title: messages.profile,
		route: '/app/profile',
		colors: CalmGradients[8],
		IconOutline: ProfileIconOutline,
		IconFilled: ProfileIconFilled,
	},
];

export const teamsMenuItems: Omit<SidebarMenuItemProps, 'id'>[] = [
	{
		title: messages.home,
		route: '/teams-calm/app',
		colors: CalmGradients[0],
		IconOutline: HomeIconOutline,
		IconFilled: HomeIconFilled,
	},
	{
		title: messages.meditate,
		route: '/teams-calm/app/meditate',
		colors: CalmGradients[1],
		IconOutline: MeditateIconOutline,
		IconFilled: MeditateIconFilled,
	},
	{
		title: messages.sleep,
		route: '/teams-calm/app/sleep',
		colors: CalmGradients[2],
		IconOutline: SleepIconOutline,
		IconFilled: SleepIconFilled,
	},
	{
		title: messages.music,
		route: '/teams-calm/app/music',
		colors: CalmGradients[3],
		IconOutline: MusicIconOutline,
		IconFilled: MusicIconFilled,
	},
	{
		title: messages.forWork,
		route: '/teams-calm/app/work',
		colors: CalmGradients[4],
		IconOutline: CoffeeIconOutline,
		IconFilled: CoffeeIconFilled,
	},
	{
		title: messages.wisdom,
		route: '/teams-calm/app/wisdom',
		colors: CalmGradients[5],
		IconOutline: WisdomIconOutline,
		IconFilled: WisdomIconOutline,
		supportedLocales: 'en',
	},
	{
		title: messages.kids,
		route: '/teams-calm/app/kids',
		colors: CalmGradients[6],
		IconOutline: FlagKidsIconOutline,
		IconFilled: FlagKidsIconFilled,
	},
	{
		title: messages.movement,
		route: '/teams-calm/app/movement',
		colors: CalmGradients[7],
		IconOutline: MovementIconOutline,
		IconFilled: MovementIconFilled,
		supportedLocales: ['en', 'de', 'fr'],
	},
	{
		title: messages.profile,
		route: '/teams-calm/app/profile',
		colors: CalmGradients[8],
		IconOutline: ProfileIconOutline,
		IconFilled: ProfileIconFilled,
	},
];
