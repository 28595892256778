import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, FontWeights, Button, Text } from '@calm-web/design-system';

import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useUserState } from '@/hooks/store';
import { useMaxWidth } from '@/hooks/ui/useMaxWidth';
import { setAuthFormMode } from '@/store/authFormMode/actions';

import messages from './messages';
import { UpsellFooterContent, UpsellFooterWrapper } from './styles';

export const UPSELL_FOOTER_ID = 'upsell-footer';

const UpsellFooter = () => {
	const { formatMessage } = useIntl();
	const user = useUserState();
	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const { logEvent } = useAnalytics();
	const [hasMaxWidth] = useMaxWidth('tablet');

	const dispatch = useDispatch();

	function pressFooterCTA() {
		dispatch(setAuthFormMode('signup'));
		setIsModalOpen(true);
		logEvent({ eventName: 'Upsell Footer : Signup : Click' });
	}

	if (user) {
		return null;
	}

	if (hasMaxWidth) {
		return null;
	}

	return (
		<UpsellFooterWrapper id={UPSELL_FOOTER_ID}>
			<UpsellFooterContent>
				<Text color="black" size={FontSizes.base} weight={FontWeights.Medium}>
					{formatMessage(messages.upsellCopy)}
				</Text>
				<Button role="button" backgroundColor="accessibleBrandGradient" onPress={pressFooterCTA}>
					{formatMessage(messages.upsellButton)}
				</Button>
			</UpsellFooterContent>
		</UpsellFooterWrapper>
	);
};

export default UpsellFooter;
