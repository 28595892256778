import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { Button, ModalWidth, Modal, Text, D2CTheme, FontSizes, FontWeights } from '@calm-web/design-system';

import bannerImage from '@/../public/_n/images/share-free-content/free-access-modal-banner.jpg';
import LimitedTimeCallout from '@/components/PreSignupFlow/Components/LimitedTimeCallout';
import UpsellBullet from '@/components/PreSignupFlow/Components/UpsellBullet';
import NextImage from '@/components/image/NextImage';
import { useAnalytics } from '@/hooks/analytics';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { getCookie, removeCookie } from '@/utils/cookies';

import { messages } from './messages';
import { Banner, Container, Copy, Bullets } from './styles';

export const SHARE_FREE_ACCESS_MODAL_COOKIE = 'show-share-free-access-modal';

const ShareContentModal = () => {
	const { formatMessage } = useIntl();
	const routerPush = useRouterPush();
	const { pathname } = useRouter();
	const { logEvent } = useAnalytics();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRouting, setIsRouting] = useState(false);

	const onModalClose = () => {
		logEvent({
			eventName: 'Screen : Exited',
			eventProps: {
				screen: 'Modal',
				type: 'Free Access Share',
			},
		});
		removeCookie(SHARE_FREE_ACCESS_MODAL_COOKIE);
		setIsModalOpen(false);
	};

	const onPress = async () => {
		logEvent({
			eventName: 'Modal : Proceeded',
			eventProps: {
				type: 'Free Access Share',
			},
		});

		setIsRouting(true);
		removeCookie(SHARE_FREE_ACCESS_MODAL_COOKIE);
		await routerPush('/signup-flow');
	};

	useEffect(() => {
		if (isModalOpen) {
			logEvent({
				eventName: 'Screen : Viewed',
				eventProps: {
					screen: 'Modal',
					type: 'Free Access Share',
				},
			});
		}
	}, [logEvent, isModalOpen]);

	useEffect(() => {
		const showShareModal = getCookie(SHARE_FREE_ACCESS_MODAL_COOKIE) === 'true';
		setIsModalOpen(showShareModal);
	}, [pathname]);

	useEffect(() => {
		if (isModalOpen) {
			removeCookie(SHARE_FREE_ACCESS_MODAL_COOKIE);
		}
	}, [isModalOpen]);

	return (
		<ThemeProvider theme={D2CTheme}>
			<Modal
				canClose
				noPadding
				isOpen={isModalOpen}
				closeModal={onModalClose}
				width={ModalWidth.Extra}
				aria-label={formatMessage(messages.specialOffer)}
			>
				<Banner>
					<NextImage layout="fill" objectFit="cover" src={bannerImage} />
				</Banner>
				<Container>
					<LimitedTimeCallout color="blue2">{formatMessage(messages.specialOffer)}</LimitedTimeCallout>
					<Copy>
						<Text noMargin color="text" el="h2" size={FontSizes['2xl']} weight={FontWeights.Demi}>
							{formatMessage(messages.heading, { num: 5 })}
						</Text>
						<Text noMargin color="text" el="h3" size={FontSizes.base} weight={FontWeights.Regular}>
							{formatMessage(messages.subheading)}
						</Text>
					</Copy>
					<Bullets>
						<UpsellBullet color="blue2" textColor="text" copy={messages.bullet1} />
						<UpsellBullet color="blue2" textColor="text" copy={messages.bullet2} />
						<UpsellBullet color="blue2" textColor="text" copy={messages.bullet3} />
					</Bullets>
					<Button fullWidth backgroundColor="blue2" textColor="white" onPress={onPress} isLoading={isRouting}>
						{formatMessage(messages.cta)}
					</Button>
				</Container>
			</Modal>
		</ThemeProvider>
	);
};

export default ShareContentModal;
