import { AnyAction } from 'redux';

import { SET_ZOOM, Zoom } from './types';

const initialState: Zoom | null = null;

export function zoomReducer(state = initialState, action: AnyAction = { type: undefined }): Zoom | null {
	switch (action.type) {
		case SET_ZOOM:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
