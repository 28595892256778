import { NextConfig } from 'next';
import { ImageLoader, ImageLoaderProps } from 'next/legacy/image';

import { calmLogger } from './calmLogger';

type NextImageConfig = Exclude<NextConfig['images'], undefined>;

const calmLoader = ({ src, width }: ImageLoaderProps): string | undefined => {
	let parsedSrc;
	try {
		parsedSrc = new URL(src);
	} catch (err) {
		calmLogger.error(
			'Failed to parse src on `next/image`, if using relative image it must start with a leading slash "/" or be an absolute URL (http:// or https://)',
			{ src },
			err,
		);
		return undefined;
	}
	if (!['assets.calm.com', 'assets-prod-usw.calm.com'].includes(parsedSrc.hostname)) {
		return undefined;
	}
	const pathnamePieces = parsedSrc.pathname.split('/');
	if (pathnamePieces.length === 3) {
		const transformPath = pathnamePieces[1];
		const srcWidthString = transformPath.split('x')[0];
		try {
			// the calm assets service will upsize images,
			// so we have to take the Math.min ourselves client-side
			const srcWidth = parseInt(srcWidthString, 10);
			pathnamePieces[1] = `${Math.min(srcWidth, width)}`;
		} catch (err) {
			calmLogger.warn('Attempted to transform an unrecognized assets.calm.com url', { src });
		}
	} else {
		pathnamePieces.splice(1, 0, `${width}`);
	}
	parsedSrc.pathname = pathnamePieces.join('/');
	return parsedSrc.toString();
};

const loader: ImageLoader = ({ src, width: _width, quality }) => {
	const width = Math.floor(_width);

	if (src.includes('assets.calm.com') || src.includes('assets-prod-usw.calm.com')) {
		const calmUrl = calmLoader({ src, width, quality });
		if (calmUrl) {
			return calmUrl;
		}
	}

	// Fall back to the default next loader
	// Note: with our current domains whitelist, this will never get called.
	// So to test it, you'll have to comment out one of the above
	// Note: we have to hard-code the config here (from apps/www/next.config.js)
	// as it's not available at runtime
	const config = {
		domains: [
			'assets-prod-usw.calm.com',
			'assets.calm.com',
			'images.freeimages.com', // for development
			'localhost',
			'calm-assets.s3.amazonaws.com',
		],
		path: '/_next/image',
	};
	return defaultLoader({ config, src, width: _width, quality });
};

export default loader;

// this function taken directly from next/src/shared/lib/router/utils/parse-path.ts
function parsePath(path: string) {
	const hashIndex = path.indexOf('#');
	const queryIndex = path.indexOf('?');
	const hasQuery = queryIndex > -1 && (hashIndex < 0 || queryIndex < hashIndex);

	if (hasQuery || hashIndex > -1) {
		return {
			pathname: path.substring(0, hasQuery ? queryIndex : hashIndex),
			query: hasQuery ? path.substring(queryIndex, hashIndex > -1 ? hashIndex : undefined) : '',
			hash: hashIndex > -1 ? path.slice(hashIndex) : '',
		};
	}

	return { pathname: path, query: '', hash: '' };
}

// this function taken directly from next/src/shared/lib/router/utils/remove-trailing-slash.ts
export function removeTrailingSlash(route: string) {
	return route.replace(/\/$/, '') || '/';
}

// this function taken directly from next/src/client/normalize-trailing-slash.ts
const normalizePathTrailingSlash = (path?: string) => {
	if (!path?.startsWith('/') || process.env.__NEXT_MANUAL_TRAILING_SLASH) {
		return path;
	}

	const { pathname, query, hash } = parsePath(path);
	if (process.env.__NEXT_TRAILING_SLASH) {
		if (/\.[^/]+\/?$/.test(pathname)) {
			return `${removeTrailingSlash(pathname)}${query}${hash}`;
		} else if (pathname.endsWith('/')) {
			return `${pathname}${query}${hash}`;
		} else {
			return `${pathname}/${query}${hash}`;
		}
	}

	return `${removeTrailingSlash(pathname)}${query}${hash}`;
};

// this function taken directly from packages/next/src/client/legacy/image.tsx
// with one small addition: $UNSAFE_forceDomainCheck for testing purposes
// and normalizeSrc to work around a bug in their code which makes requests to localhost (should be fixed by Next 13.6)
function defaultLoader({
	config,
	src,
	width,
	quality,
}: ImageLoaderProps & {
	config: Pick<NextImageConfig, 'domains' | 'dangerouslyAllowSVG' | 'path' | 'remotePatterns'>;
}) {
	if (process.env.NODE_ENV !== 'production') {
		const missingValues = [];

		// these should always be provided but make sure they are
		if (!src) missingValues.push('src');
		if (!width) missingValues.push('width');

		if (missingValues.length > 0) {
			throw new Error(
				`Next Image Optimization requires ${missingValues.join(
					', ',
				)} to be provided. Make sure you pass them as props to the \`next/image\` component. Received: ${JSON.stringify(
					{
						src,
						width,
						quality,
					},
				)}`,
			);
		}

		if (src.startsWith('//')) {
			throw new Error(
				`Failed to parse src "${src}" on \`next/image\`, protocol-relative URL (//) must be changed to an absolute URL (http:// or https://)`,
			);
		}

		if (!src.startsWith('/') && config.domains) {
			let parsedSrc: URL;
			try {
				parsedSrc = new URL(src);
			} catch (err) {
				calmLogger.error(
					'Failed to parse src on `next/image`, if using relative image it must start with a leading slash "/" or be an absolute URL (http:// or https://)',
					{ src },
					err,
				);
				throw new Error(
					`Failed to parse src "${src}" on \`next/image\`, if using relative image it must start with a leading slash "/" or be an absolute URL (http:// or https://)`,
				);
			}
			if (process.env.NODE_ENV !== 'test' && !config.domains.includes(parsedSrc.hostname)) {
				throw new Error(
					`Invalid src prop (${src}) on \`next/image\`, hostname "${parsedSrc.hostname}" is not configured under images in your \`next.config.js\`\n` +
						`See more info: https://nextjs.org/docs/messages/next-image-unconfigured-host`,
				);
			}
		}
	}
	if (src.endsWith('.svg') && !config.dangerouslyAllowSVG && process.env.NODE_ENV !== 'test') {
		// Special case to make svg serve as-is to avoid proxying
		// through the built-in Image Optimization API.
		return src;
	}
	return `${normalizePathTrailingSlash(config.path)}?url=${encodeURIComponent(src)}&w=${width}&q=${
		quality || 75
	}`;
}
