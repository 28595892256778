import { AnyAction } from 'redux';

import { Device, SET_DEVICE } from './types';

const initialState: Device = {};

export function deviceReducer(state = initialState, action: AnyAction = { type: undefined }): Device {
	switch (action.type) {
		case SET_DEVICE:
			return action.payload;
		default:
			return state;
	}
}
