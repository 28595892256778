import { ParsedUrlQuery } from 'querystring';

import { Prices } from '@/store/prices/types';
import { getPrices } from '@/utils/endpoints';

export async function initPrices({ query }: { query: ParsedUrlQuery }): Promise<Prices | null> {
	try {
		const { data } = await getPrices(query);
		return data;
	} catch (err) {
		return null;
	}
}
