import { defineMessages } from 'react-intl';

const messages = defineMessages({
	ariaLoginLabel: {
		id: 'ui.authForm.loginAriaLabel',
		defaultMessage: 'Logged in successfully',
		description: 'Aria label for success status',
	},
	ariaSignupLabel: {
		id: 'ui.authForm.signupAriaLabel',
		defaultMessage: 'Account created successfully',
		description: 'Aria label for success status',
	},
});

export default messages;
