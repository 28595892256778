import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useAnalytics } from '@/hooks/analytics';
import { usePurchaseParamsState, useGuideState, useUserState } from '@/hooks/store';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import apiRequest from '@/utils/apiRequest';
import { userCanSubscribe, userCanTrial } from '@/utils/subscriptions';

interface TokenVerificationResponse {
	status?: 'valid';
	url?: string | null;
	share_token?: string | null;
	name?: string;
}

type useShareVerifyToken = (
	content_identifier?: string | null,
	shareToken?: string | null,
) => {
	isShareContentRecipient: boolean;
	isFetchingShare: boolean;
	shareSenderFirstName?: string;
};

export const useShareVerifyToken: useShareVerifyToken = (contentIdentifier = null, shareToken = null) => {
	const { logEvent } = useAnalytics();
	const user = useUserState();
	const guide = useGuideState();
	const purchaseParams = usePurchaseParamsState();
	const purchaseType = purchaseParams.purchaseType;

	const shouldFetch = contentIdentifier && shareToken && userCanSubscribe(user);

	const [isFetching, setIsFetching] = useState(Boolean(shouldFetch));
	const dispatch = useDispatch();

	const validateEndpoint = 'content/share/validate';

	function logTokenVerification(res: TokenVerificationResponse): void {
		logEvent({
			eventName: 'Deeplink : Share : Attributed',
			eventProps: {
				share_token: shareToken || null,
				token_validated: res.status === 'valid',
				content_id: contentIdentifier,
				title: guide?.title || '',
			},
		});
	}

	function logTokenVerificationError(res: TokenVerificationResponse): void {
		logEvent({
			eventName: 'Deeplink : Share : Attributed : Error Dialog',
			eventProps: {
				share_token: shareToken || null,
				token_validated: res.status === 'valid',
				content_id: contentIdentifier,
				title: guide?.title || '',
			},
		});
	}

	function updateUserPurchaseParams() {
		if (userCanTrial(user)) {
			dispatch(
				setPurchaseParams({
					...purchaseParams,
					promotion: '30_day_free_trial',
					purchaseType: {
						...purchaseType,
						type: 'freetrial',
						duration: 30,
					},
				}),
			);
		}
	}

	const { data } = useSWR<TokenVerificationResponse>(
		shouldFetch ? validateEndpoint : null,
		async (endpoint: string) => {
			setIsFetching(true);
			try {
				const response = await apiRequest<TokenVerificationResponse>({
					endpoint,
					method: 'POST',
					body: {
						share_token: shareToken,
						content_identifier: contentIdentifier,
					},
				});
				setIsFetching(false);
				logTokenVerification(response.data);
				updateUserPurchaseParams();
				return response.data;
			} catch (error) {
				logTokenVerificationError(error);
				return {};
			}
		},
		{ revalidateOnFocus: false, errorRetryCount: 0 },
	);

	return {
		isShareContentRecipient: (data?.status === 'valid' && !isFetching) || false,
		isFetchingShare: isFetching,
		shareSenderFirstName: data?.name,
	};
};
