import { Wrapper } from './styles';
import { IconGradientBorderProps } from './types';

// TODO: This component could use some cleanup / simplification
// It's purpose is to create a border around an SVG that inherets a
// gradient background and border. Lifted directly from www-app
const IconGradientBorder = ({ children, id, colors, isActive, size = 48 }: IconGradientBorderProps) => {
	return (
		<Wrapper size={size}>
			<svg style={{ position: 'absolute' }} width={`${size}px`} height={`${size}px`} viewBox="0 0 52 52">
				<defs>
					{isActive ? (
						<circle id={`${id}-a`} cx="26" cy="26" r="26" />
					) : (
						<path
							// eslint-disable-next-line max-len
							d="M26 52C11.64 52 0 40.36 0 26S11.64 0 26 0s26 11.64 26 26-11.64 26-26 26zm0-2c13.255 0 24-10.745 24-24S39.255 2 26 2 2 12.745 2 26s10.745 24 24 24z"
							id={`${id}-a`}
						/>
					)}
					<linearGradient x1="0%" y1="0%" y2="100%" id={`${id}-c`}>
						<stop stopColor={colors[0]} offset="0%" />
						<stop stopColor={colors[1]} offset="100%" />
					</linearGradient>
				</defs>
				<g fill="none" fillRule="evenodd">
					<mask id={`${id}-b`} fill="#fff">
						<use xlinkHref={`#${id}-a`} />
					</mask>
					<use fill="#D8D8D8" xlinkHref={`#${id}-a`} />
					<g mask={`url(#${id}-b)`} fill={`url(#${id}-c)`}>
						<path d="M0 0h52v52H0z" />
					</g>
				</g>
			</svg>
			{children}
		</Wrapper>
	);
};

export default IconGradientBorder;
