import { StyledIconButton, Wrapper } from './styles';
import { TransportIconProps } from './types';

export { TransportIconSpacer } from './styles';

const TransportIcon = ({
	alignment,
	icon,
	onClick,
	sizeTheme = 'large',
	isPrimary = false,
	width = '20%',
	'aria-label': ariaLabel,
}: TransportIconProps) => {
	return (
		<Wrapper $alignment={alignment} $width={width} $sizeTheme={sizeTheme} $isPrimary={isPrimary}>
			<StyledIconButton
				onPress={onClick}
				$isPrimary={isPrimary}
				$sizeTheme={sizeTheme}
				color="white"
				Icon={icon}
				aria-label={ariaLabel}
			/>
		</Wrapper>
	);
};

export default TransportIcon;
