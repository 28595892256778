import styled from 'styled-components';
import { palette } from 'styled-tools';

export const Wrapper = styled.div`
	width: 100%;
	text-align: center;
`;

export const Title = styled.h3`
	margin-bottom: 1rem;
`;

export const Subtitle = styled.div`
	margin-bottom: 0.5rem;
`;

export const Link = styled.a`
	color: ${palette('blue2')};
	text-decoration: underline;
`;
