import Link from 'next/link';
import { useIntl } from 'react-intl';

import SearchIcon from 'icons/search-icon.svg';

import messages from './messages';
import { Wrapper } from './styles';

const SearchLink = ({ className }: { className?: string }) => {
	const { formatMessage } = useIntl();

	return (
		<Link passHref href="/app/search" legacyBehavior>
			<Wrapper aria-label={formatMessage(messages.linkText)}>
				<SearchIcon />
			</Wrapper>
		</Link>
	);
};

export default SearchLink;
