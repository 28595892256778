import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	TextButton,
	minWidth,
	units,
	fontWeight,
	FontWeights,
	fontSize,
	FontSizes,
} from '@calm-web/design-system';

export const Wrapper = styled.div<{ isInlineStyles?: boolean }>`
	width: 100%;
	margin-top: 24px;

	${ifProp(
		'isInlineStyles',
		css`
			margin-top: 0;
			${fontWeight(FontWeights.Demi)}
			${fontSize(FontSizes.base)}
			line-height: 150%;
			color: ${palette('blackAlpha60')};
			width: unset;

			${minWidth('tablet')} {
				font-size: ${units(1.78)};
			}
		`,
	)}
`;

export const Cta = styled(TextButton)<{ isInlineStyles?: boolean }>`
	margin-left: 10px;
	color: ${palette('text')};

	${ifProp(
		'isInlineStyles',
		css`
			margin-left: ${units(0.5)};
			${fontWeight(FontWeights.Demi)}
			${fontSize(FontSizes.base)}
			line-height: 150%;
			color: ${palette('blackAlpha60')};

			${minWidth('tablet')} {
				font-size: ${units(1.78)};
			}
		`,
	)}
`;
