import { FlowConfig } from '../../Context/types';
import Control from '../Control/Flow';

const ControlFlow: FlowConfig = {
	activeScreenKey: 'account',
	activeScreenConfig: Control.screens.account,
	name: 'Simplified Signup Modal',
	pageName: 'sisu-modal',
	plan: 'yearly',
	hasFreeTrial: true,
	screens: {
		...Control.screens,
		account: {
			...Control.screens.account,
			showHeader: false,
			brandingPosition: 'left',
			hasPremiumSubCallback: (_routerPush, setIsModalOpen) => {
				if (setIsModalOpen) setIsModalOpen(false);
			},
		},
		payment: {
			...Control.screens.payment,
			limitedTimeOffer: false,
			showHeader: false,
		},
		handoff: {
			...Control.screens.handoff,
			showHeader: false,
		},
	},
	isWithinModal: true,
};

export default ControlFlow;
