import { useIntl } from 'react-intl';

import messages from './messages';
import { OrLineLeft, OrLineRight, OrText, Wrapper } from './styles';

const OrDivider = () => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper className="loginOrDivider">
			<OrLineLeft />
			<OrText>{formatMessage(messages.orDivider)}</OrText>
			<OrLineRight />
		</Wrapper>
	);
};

export default OrDivider;
