import styled from 'styled-components';

import { Button as DesignSystemButton, Text } from '@calm-web/design-system';

export const Copy = styled(Text)`
	line-height: 1.5;
	margin-bottom: 18px;
`;

export const ConfirmButton = styled(DesignSystemButton)`
	width: 100%;
`;
