import { defineMessages } from 'react-intl';

export default defineMessages({
	desktopCta: {
		id: 'login.forgotPassword.desktopCta',
		defaultMessage: 'Forgot your password?',
		description: 'Desktop question for forgot password',
	},
	passwordResetEmail: {
		id: 'login.errors.passwordResetEmailInvalid',
		defaultMessage: 'Please provide a valid email first',
		description: 'Error message when user clicks Forgot password without email',
	},
	emailSuccess: {
		id: 'login.forgotPassword.emailSent',
		defaultMessage: 'Email sent to',
		description: 'Generic message when user successfully resets their password',
	},
	passwordReset: {
		id: 'login.errors.passwordReset',
		defaultMessage: 'Sorry, there was a problem resetting your password',
		description: 'Generic error when password is not successfully reset',
	},
});
