import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

export const Wrapper = styled(Text).attrs({
	el: 'p',
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	color: rgba(0, 0, 0, 0.6);
	margin: 2rem 0 !important;

	& a {
		color: ${palette('blue3')};
	}
`;
