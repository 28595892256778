import { authentication } from '@microsoft/teams-js';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { useFormStateContext } from '@/context/FormStateContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { FORM_ACTIONS } from '@/hooks/forms';
import { useSetupUserAfterAuth } from '@/hooks/forms/useSetupUserAfterAuth';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import { useAuthFormModeState } from '@/hooks/store';
import { User } from '@/store/user/types';
import getUrl from '@/utils/getUrl';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import messages from './messages';
import { Icon, Wrapper } from './styles';
import { GoogleAuthButtonProps } from './types';

export default function TeamsGoogleAuthButton({
	onAuthSuccess,
	buttonText,
	marketingOptIn,
	contentAlignment,
	hideMessage = false,
}: GoogleAuthButtonProps) {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const apiRequest = useApi();
	const authFormMode = useAuthFormModeState();
	const setupUserAfterAuth = useSetupUserAfterAuth();
	const guestPassCode = useGuestPassRequestCode();

	const buttonEl = useRef<HTMLDivElement>(null);
	const { formDispatch, setFormError, setFormSuccess } = useFormStateContext();

	const onError = (error: string) => {
		logEvent({
			eventName: 'Login Form : Google : Cancelled',
			eventProps: {
				error,
				mode: authFormMode,
			},
		});
		setFormSuccess(null);
		setFormError(null);
		formDispatch({ type: FORM_ACTIONS.requestCancelled });
	};

	const onPress = () => {
		const googleId = process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID;
		const url = `${getUrl('www')}/teams-calm/google-auth-end`;
		authentication
			.authenticate({
				url: `${getUrl(
					'www',
				)}/teams-calm/google-auth-start?oauthRedirectMethod={oauthRedirectMethod}&authId={authId}&hostRedirectUrl=${url}&googleId=${googleId}`,
				isExternal: true,
			})
			.then(async idToken => {
				if (idToken) {
					const { data } = await apiRequest<User>({
						endpoint: 'auth/google',
						method: 'POST',
						body: {
							token: idToken,
							marketing_opt_in: marketingOptIn,
							guest_pass_code: guestPassCode,
						},
					});

					logEvent({
						eventName: 'Login Form : Google : Success',
						eventProps: {
							mode: authFormMode,
						},
					});

					await setupUserAfterAuth(data, CalmAuthMethods.Google);

					formDispatch({ type: FORM_ACTIONS.requestSuccess });
					if (onAuthSuccess) {
						onAuthSuccess(data);
					}
				}
			})
			.catch(reason => {
				onError(reason);
			});

		logEvent({
			eventName: 'Login Form : Google : Clicked',
			eventProps: {
				mode: authFormMode,
			},
		});
	};

	if (!process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID) return null;

	const buttonCta = buttonText || formatMessage(messages.continueCta);

	return (
		<div ref={buttonEl}>
			<Wrapper
				backgroundColor="white"
				contentAlignment={contentAlignment ?? hideMessage ? 'center' : 'left'}
				textColor="gray7"
				data-testid="google-auth-button"
				onPress={onPress}
				role="link"
				$noPadding={hideMessage}
			>
				<Icon $noMargin={hideMessage} />
				{hideMessage ? <VisuallyHidden>{buttonCta}</VisuallyHidden> : buttonCta}
			</Wrapper>
		</div>
	);
}
