import isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { useFeatureFlagsState } from '@/hooks/store';
import { setFeatureFlags } from '@/store/feature-flags/actions';
import { FeatureFlags } from '@/store/feature-flags/types';
import { JSONValue } from '@/utils/types';

interface ApiFeatureFlag {
	name: string;
	value: JSONValue;
}

interface ApiFeatureFlagResponse {
	feature_flags: ApiFeatureFlag[];
}

/**
 * Usage:
    const flagInfo = useFeatureFlags('b2b-user-onboarding-fixture', 'amex-intl-promotion-launch');
    if (flagInfo.data['b2b-user-onboarding-fixture'] === 'enabled') { ... }
 *
 * Notes:
   * You may request flags that have no value. In that case, `flagInfo.data['nonce']` will be `undefined`
   * Flag values can be any JSON value, including `null`, arrays, and rich (JSON) objects
   * For render efficiency reasons, it may return more flags than you ask for
 */

export const useFeatureFlags = (
	...flags: string[]
): {
	flagValues: FeatureFlags;
	flagLoading: boolean;
	flagError?: Error;
} => {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const featureFlags = useFeatureFlagsState();

	const apiEndpoint = ((): string => {
		if (flags) {
			return `feature-flags?filter[name]=${flags.join(',')}`;
		}
		return 'feature-flags';
	})();
	const { data, error } = useSWR<ApiFeatureFlagResponse, Error>(
		apiEndpoint,
		async (endpoint: string) => {
			const response = await apiRequest<ApiFeatureFlagResponse>({
				endpoint,
				method: 'GET',
			});
			if (!response.data) {
				throw new Error('Not able to fetch feature flags');
			}
			return response.data;
		},
		{ errorRetryCount: 0 },
	);

	const newFeatureFlags = useMemo(() => {
		let _newFeatureFlags = featureFlags;
		if (data && !error) {
			const apiFlags = data?.feature_flags;
			_newFeatureFlags = apiFlags.reduce(
				(acc, apiFlag) => ({
					...acc,
					[apiFlag.name]: apiFlag.value,
				}),
				featureFlags,
			);
		}
		return _newFeatureFlags;
	}, [data, error, featureFlags]);
	useEffect(() => {
		if (!isEqual(newFeatureFlags, featureFlags)) {
			dispatch(setFeatureFlags(newFeatureFlags));
		}
	}, [dispatch, featureFlags, newFeatureFlags]);

	return {
		flagValues: newFeatureFlags,
		flagError: error,
		flagLoading: !data && !error,
	};
};

export enum FeatureFlagNames {
	B2B_EWB_GROUP_CODES = 'b2b-ewb-group-codes',
	B2B_REVAMP_REDEMP_FLOW = 'b2b-revamp-redemption-flow',
}
