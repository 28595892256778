import { AnyAction } from 'redux';

import { AuthFormMode, SET_AUTH_FORM_MODE } from './types';

const initialState: AuthFormMode = 'signup';

export function authFormModeReducer(
	state = initialState,
	action: AnyAction = { type: undefined },
): AuthFormMode {
	switch (action.type) {
		case SET_AUTH_FORM_MODE:
			return action.payload;
		default:
			return state;
	}
}
