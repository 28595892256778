import styled from 'styled-components';

export const Wrapper = styled.div<{ centerFormElements?: boolean }>`
	text-align: ${p => (p.centerFormElements ? 'center' : 'left')};
	margin-bottom: 16px;
	width: 100%;

	img:not(:first-child) {
		margin-left: 8px;
	}
`;

export const Icon = styled.img`
	height: 24px;
	width: 40px;
`;
