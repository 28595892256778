import 'css/fonts.css';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';

import { DesignSystemProvider, CalmTheme } from '@calm-web/design-system';

import AnalyticsContextProvider from '@/components/app/layout/AnalyticsContextProvider';
import AppLayout from '@/components/app/layout/AppLayout';
import { AppHeaderProps } from '@/components/app/layout/AppLayout/types';
import AppProviders from '@/components/app/layout/AppProviders';
import CookiePreferencesBannerProvider from '@/components/app/layout/CookiePreferencesBannerProvider';
import OfferBannerProvider from '@/components/app/layout/OfferBannerProvider';
import IntlWrapper from '@/components/layout/IntlWrapper';
import AuthContextProvider from '@/context/auth/AuthContextProvider';
import { useStore } from '@/hooks/store/useStore';
import { AppInitialState } from '@/store';

const MyApp = ({
	Component,
	pageProps,
}: AppProps<{
	initialState: AppInitialState;
	appHeaderProps: AppHeaderProps;
}>) => {
	const store = useStore(pageProps.initialState);

	return (
		<>
			<Head>
				<link rel="shortcut icon" type="image/x-icon" href="/_n/favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			<Provider store={store}>
				<IntlWrapper>
					<DesignSystemProvider theme={CalmTheme}>
						<AnalyticsContextProvider>
							<AuthContextProvider>
								<CookiePreferencesBannerProvider>
									<AppProviders>
										<OfferBannerProvider>
											<AppLayout appHeaderProps={pageProps.appHeaderProps}>
												<Component {...pageProps} />
											</AppLayout>
										</OfferBannerProvider>
									</AppProviders>
								</CookiePreferencesBannerProvider>
							</AuthContextProvider>
						</AnalyticsContextProvider>
					</DesignSystemProvider>
				</IntlWrapper>
			</Provider>
		</>
	);
};

export default MyApp;
