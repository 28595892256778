import styled, { css } from 'styled-components';
import { ifNotProp } from 'styled-tools';

import { Card, minWidth } from '@calm-web/design-system';

import _ValuePropsList from '@/components/valuePropList';

export const Wrapper = styled.div<{ $isForMobile: boolean }>`
	display: ${p => (p.$isForMobile ? 'block' : 'none')};

	${minWidth('desktop')} {
		display: ${p => (p.$isForMobile ? 'none' : 'block')};
	}
`;

export const ValuePropContainer = styled.div<{ showHeader: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	max-width: 700px;
	margin: 0;

	${ifNotProp(
		'showHeader',
		css`
			margin-top: 60px;
		`,
	)}

	${minWidth('desktop')} {
		display: block;
		margin: 0 auto;
		margin-top: 40px;
		max-width: 420px;
		h1 {
			text-align: left;
		}
		text-align: left;
	}
`;

export const PartnerValidationWrapper = styled(Card)`
	padding: 48px;
	border-radius: 20px;
	background-color: white;
`;

export const BasicPropsList = styled(_ValuePropsList)`
	padding-top: 0;
	padding-bottom: 0;
`;

export const PremiumPropsList = styled(_ValuePropsList)`
	padding-top: 0;
`;

export const ValuePropComparisonContainer = styled.div`
	margin-bottom: 20px;
`;

export const ValuePropsList = styled(_ValuePropsList)`
	border-top: 1px rgba(0, 0, 0, 0.2) solid;
	padding-bottom: 32px;
	padding-top: 16px;

	${minWidth('desktop')} {
		border-top: none;
		padding-bottom: inherit;
		padding-top: inherit;
	}
`;
