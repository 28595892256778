import { defineMessages } from 'react-intl';

const messages = defineMessages({
	offerHasEnded: {
		id: 'sisu.offerHasEnded',
		defaultMessage:
			"Unfortunately, this offer has ended but don't worry! {br} Click below and save 40% off of your first year of Calm Premium!",
		description: 'Message displayed when the offer has ended.',
	},
	getCalm: {
		id: 'sisu.getCalm',
		defaultMessage: 'Get Calm Premium for 40% off',
		description: 'CTA on button to send user to Calm Premium subscription page.',
	},
});
export default messages;
