import { usePricesState, usePurchaseParamsState } from '@/hooks/store';
import { isValidPlan } from '@/store/purchaseParams/types';

export function useCoupon(): {
	isCoupon: boolean;
	isLifetime: boolean;
	discount: number | undefined;
} {
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const plan = purchaseParams?.plan;
	const isCoupon = isValidPlan(plan) && Boolean(prices?.coupons?.[plan]);
	const isLifetime = purchaseParams?.plan === 'lifetime';
	const discount = isValidPlan(plan) ? prices?.coupons?.[plan]?.percent_off : undefined;

	return {
		isCoupon,
		isLifetime,
		discount,
	};
}
