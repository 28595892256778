import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics';
import { useGuestPassRequestCode } from '@/hooks/guestPass/useGuestPassRequestCode';
import { useAuthFormModeState } from '@/hooks/store';
import { User } from '@/store/user/types';
import { postFacebookAuth } from '@/utils/endpoints';
import { FBLibrary, FacebookSignInTokenSuccess, isFacebookSignInTokenError } from '@/utils/facebook/types';
import { formatError } from '@/utils/formatErrors';
import { getFacebookSignInToken } from '@/utils/forms/getFacebookSignInToken';
import { parseLoginError } from '@/utils/forms/parseLoginError';
import { CalmAuthMethods } from '@/utils/privacyCookies';

import { useSetupUserAfterAuth } from './useSetupUserAfterAuth';

declare global {
	interface Window {
		fbAsyncInit: () => void;
		FB: FBLibrary;
	}
}

export function useAuthFbUser() {
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();
	const setupUserAfterAuth = useSetupUserAfterAuth();
	const guestPassCode = useGuestPassRequestCode();
	const { logEvent } = useAnalytics();

	async function authUser(
		facebookSignInTokenSuccess: FacebookSignInTokenSuccess,
		marketingOptIn: boolean,
	): Promise<{ success: User } | { error: string }> {
		if (!authFormMode) {
			return { error: formatMessage(parseLoginError({})) };
		}

		try {
			const { data } = await postFacebookAuth({
				...facebookSignInTokenSuccess,
				marketing_opt_in: marketingOptIn,
				guest_pass_code: guestPassCode,
			});

			logEvent({
				eventName: 'Login Form : Facebook : Success',
				eventProps: {
					mode: authFormMode,
				},
			});

			await setupUserAfterAuth(data, CalmAuthMethods.Facebook);

			return { success: data };
		} catch (err) {
			const error = err?.data?.error;
			logEvent({
				eventName: 'Login Form : Facebook : Error',
				eventProps: {
					mode: authFormMode,
					...formatError(error),
				},
			});
			return { error: formatMessage(parseLoginError(error)) };
		}
	}

	return authUser;
}

export function useFacebookSubmit(): (
	marketingOptIn: boolean,
) => Promise<{ success: User } | { error: string } | undefined> {
	const { formatMessage } = useIntl();

	const { logEvent } = useAnalytics();
	const authFormMode = useAuthFormModeState();
	const authUser = useAuthFbUser();

	function initializeFBSDK(): void {
		if (!process.env.NEXT_PUBLIC_FACEBOOK_APP_ID) return;

		window.fbAsyncInit = () => {
			window.FB.init({
				appId: `${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}`,
				xfbml: true,
				version: 'v6.0',
			});
		};
	}

	function loadFBSDKScript(): void {
		// Load the SDK asynchronously
		const id = 'facebook-jssdk';
		const fjs = document.getElementsByTagName('script')[0];
		if (!fjs?.parentNode || document.getElementById(id)) return;
		const js = document.createElement('script');
		js.id = id;
		js.src = '//connect.facebook.net/en_US/sdk.js';
		fjs.parentNode.insertBefore(js, fjs);
	}

	async function onSubmit(
		marketingOptIn: boolean,
	): Promise<{ success: User } | { error: string } | undefined> {
		if (!authFormMode) {
			return { error: formatMessage(parseLoginError({})) };
		}

		logEvent({
			eventName: 'Login Form : Facebook : Clicked',
			eventProps: {
				mode: authFormMode,
			},
		});

		const response = await getFacebookSignInToken();

		if (!response || isFacebookSignInTokenError(response)) {
			logEvent({
				eventName: 'Login Form : Facebook : Cancelled',
				eventProps: {
					mode: authFormMode,
				},
			});
			return undefined;
		}

		const userAuth = await authUser(response, marketingOptIn);
		return userAuth;
	}

	useEffect(() => {
		loadFBSDKScript();
		initializeFBSDK();
	}, []);

	return onSubmit;
}
