import { defineMessages } from 'react-intl';

export default defineMessages({
	heading: {
		id: 'purchase.paymentTypeSelector.heading',
		defaultMessage: 'Choose a payment method:',
		description: 'Heading for payment selector',
	},
	testHeading: {
		id: 'paymentTypeSelector.heading',
		defaultMessage: 'Use an express option:',
		description: 'Heading for where user selects a third party payment provider',
	},
	ccButtonLabel: {
		id: 'purchase.paymentTypeSelector.ccButtonLabel',
		defaultMessage: 'Credit or Debit',
		description: 'Label for button to select credit card form',
	},
	or: {
		id: 'purchase.paymentTypeSelector.or',
		defaultMessage: 'Or',
		description: 'a seperating word to express choice',
	},
});
