import { useStripe } from '@stripe/react-stripe-js';
import { Stripe, Token } from '@stripe/stripe-js';

import { PaymentType } from '@/components/purchase/PurchaseForm/types';
import { useAnalytics } from '@/hooks/analytics';
import { useApi } from '@/hooks/api';
import { usePurchaseParamsState } from '@/hooks/store';
import { Service } from '@/utils/getUrl';
import { PromiseWithCalmError } from '@/utils/promiseWithCalmError';

import { usePurchaseError } from './usePurchaseError';

export type PurchaseInfo =
	| {
			email: string;
			name: string;
			coupon?: string | null;
			freeTrial: boolean;
			stripeToken: Token;
	  }
	| {
			calm_ellis_token: string;
			stripe_token: Token;
			plan: string;
	  };

interface OnPurchaseSubmitArgs {
	endpoint: string;
	// TODO: remove Record<string, unknown>
	purchaseInfo: PurchaseInfo | Record<string, unknown>;
	analyticsPrefix?: string;
	paymentType: PaymentType;
	service?: Service;
}

export interface OnPurchaseSubmit {
	({ purchaseInfo, endpoint, analyticsPrefix }: OnPurchaseSubmitArgs): PromiseWithCalmError;
}

interface UsePurchaseSubmit {
	(): OnPurchaseSubmit;
}

// Stripe seems to be always returning PaymentIntentResult as the type, so I'm forcing
// the correct type based on their docs here, with optional params for function switching
// https://stripe.com/docs/js/setup_intents/confirm_card_setup
interface StripeIntentResult {
	paymentIntent?: {
		id: string;
	};
	setupIntent?: {
		id: string;
	};
	paymentMethodId?: string;
	error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// eslint-disable-next-line @typescript-eslint/ban-types
function get3dSecurePaymentMethod(type: string, stripe: Stripe): Function {
	switch (type) {
		case 'setupIntent':
			return stripe?.confirmCardSetup;
		case 'paymentIntentAction':
			return stripe?.handleCardAction;
		case 'paymentIntent':
		default:
			return stripe?.confirmCardPayment;
	}
}

export const usePurchaseSubmit: UsePurchaseSubmit = () => {
	const purchaseParams = usePurchaseParamsState();
	const { logEvent } = useAnalytics();
	const apiRequest = useApi();
	const onPurchaseError = usePurchaseError();
	const stripe = useStripe();

	async function handleSecureAuthentication(
		requestError: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		analyticsPrefix: string,
		paymentType: PaymentType,
	): PromiseWithCalmError {
		const baseError = requestError?.data?.error?.info || {};
		try {
			const { client_secret } = baseError;
			if (!client_secret) {
				throw new Error('missing Stripe secure auth client_secret on auth error');
			}
			const { type } = baseError;
			if (!type) {
				throw new Error('missing Stripe error type on secure auth error');
			}
			if (stripe) {
				const paymentMethod = get3dSecurePaymentMethod(type, stripe);
				const result = (await paymentMethod(client_secret)) as StripeIntentResult;
				const requestBody = {
					error: result.error,
					payment_intent_id: result.paymentIntent?.id ?? null,
					setup_intent_id: result.setupIntent?.id ?? null,
					payment_method_id: result.paymentMethodId,
				};
				const { data } = await apiRequest({
					endpoint: 'subscription/intent',
					method: 'POST',
					body: requestBody,
				});
				return { success: data };
			}
			// This error is literally impossible to get, unless stripe somehow yeets itself off the page
			throw new Error('unknown payment method');
		} catch (err) {
			const calmError = onPurchaseError({
				analyticsPrefix,
				paymentType,
				err: err?.data?.error ?? err,
			});
			return { error: calmError };
		}
	}

	const onPurchaseSubmit = async <T>({
		purchaseInfo,
		endpoint,
		paymentType = 'credit_card',
		analyticsPrefix = 'Subscribe : Purchase : Form',
		service = 'api',
	}: OnPurchaseSubmitArgs) => {
		const requestInfo = {
			...purchaseParams,
			...purchaseInfo,
		};
		try {
			const { data } = await apiRequest<T>({
				endpoint,
				method: 'POST',
				body: requestInfo,
				timeout: 30000,
				service,
			});

			logEvent({
				eventName: `${analyticsPrefix} : Posted`,
				eventProps: {
					endpoint,
				},
			});

			return { success: data };
		} catch (err) {
			if (err?.data?.error?.code === 'requires_action') {
				return handleSecureAuthentication(err, analyticsPrefix, paymentType);
			}
			const error = onPurchaseError({
				analyticsPrefix,
				paymentType,
				err: err?.data?.error,
			});
			return { error };
		}
	};

	return onPurchaseSubmit;
};
