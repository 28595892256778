import { useDeviceState } from '@/hooks/store';

export const UKGDPRCountries = ['gb'];
export function useIsUKGDPRDevice(): boolean {
	const { ip_country: ipCountry } = useDeviceState();

	if (!ipCountry) {
		return false;
	}

	if (!UKGDPRCountries.includes(ipCountry)) {
		return false;
	}
	return true;
}
