import Agent, { HttpsAgent } from 'agentkeepalive';
import axios, { AxiosResponse } from 'axios';
import { IncomingMessage } from 'http';
import nookies from 'nookies';
import { parse } from 'url';
import uuid from 'uuid/v4';

import getUrl from '@/utils/getUrl';

import { getClientIp } from '../../server/utils/getClientIp';
import initBrowserLanguage from '../../server/utils/initBrowserLanguage';
import { BrowserLanguage } from '../../store/browserLanguage/types';
import { getCookie, setCookie } from '../../utils/cookies';
import { JSONObject } from '../../utils/types';
import createReqHeaders from './createReqHeaders';

export const apiProxyPrefix = '/webapi/authproxy';

interface Args {
	endpoint: string;
	method: 'POST' | 'GET' | 'PUT';
	customHeaders?: Record<string, string | undefined>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	body?: any;
	userData?: RequestUserData | null;
	agents?: Record<string, Agent | HttpsAgent> | null;
	noCache?: boolean;
	excludedHeaders?: string[];
}

const win = (typeof window !== 'undefined' ? window : {}) as Window;

// Throw an error on any response 400 or above
// We consider these to be fatal errors from the server
axios.interceptors.response.use(
	response => response,
	error => {
		const isNested = Boolean(error?.response?.status);
		const httpStatus = isNested ? error?.response?.status : error?.status;

		if (httpStatus && httpStatus >= 400) {
			return Promise.reject(isNested ? error.response : error);
		}

		return Promise.resolve(isNested ? error.response : error);
	},
);

export type RequestUserData = {
	path: string | undefined;
	userToken: string | undefined;
	deviceId: string | undefined;
	calmIdentifier: string | undefined;
	browserLanguage?: BrowserLanguage;
	userIp?: string;
	platform?: 'www' | 'msft-teams';
	cookies: Record<string, string> | undefined;
};

export function userDataFromRequest({
	req,
	deviceId,
	calmIdentifier,
	calmUserToken,
	platform,
}: {
	req: IncomingMessage;
	deviceId: string;
	calmIdentifier?: string;
	calmUserToken?: string;
	platform?: 'www' | 'msft-teams';
}): RequestUserData {
	const cookies = nookies.get({ req });

	const cookieToken = cookies['calm-user-token'] || calmUserToken;
	let queryToken;
	if (req.url) {
		const parsedUrl = new URL(req.url, `https://${req.headers.host}`);
		queryToken = parsedUrl.searchParams.get('token') ?? undefined;
	}
	const userToken = cookieToken || queryToken;

	const userIp = getClientIp(req);
	const browserLanguage = initBrowserLanguage(req);

	const { pathname } = parse(req.url as string, true);

	return {
		deviceId,
		calmIdentifier,
		userToken,
		userIp,
		browserLanguage,
		platform,
		cookies,
		path: pathname ?? undefined,
	};
}

export type BrowserUserData = {
	deviceId: string;
	calmIdentifier: string | undefined;
	userToken: string | undefined;
	path: string;
	cookies: undefined;
};

export function userDataFromBrowser(): BrowserUserData {
	const deviceIdCookieValue = getCookie('x-device-id');
	const userToken = getCookie('calm-user-token');
	const calmIdentifier = getCookie('calm-id');

	const deviceId: string = deviceIdCookieValue || uuid();
	if (deviceId !== deviceIdCookieValue) {
		setCookie('x-device-id', deviceId);
	}

	return {
		deviceId,
		userToken,
		calmIdentifier,
		path: win?.location?.href,
		cookies: undefined,
	};
}

export const isServerMaintenanceResponse = (input: { status: number }): boolean => {
	// This is a unique HTTP code we have chosen to represent server maintenance
	return input.status === 542;
};

export type UserData = RequestUserData | BrowserUserData;

// withCredentials is set to true so that we send cookies along with requests in headers
// which is needed for _px3 cookie used by perimeterX
// See https://codewithhugo.com/pass-cookies-axios-fetch-requests/#pass-cookies-with-requests-in-axios
const apiRequest = <T = JSONObject>({
	endpoint,
	method,
	body,
	customHeaders,
	userData = null,
	agents = null,
	noCache = false,
	excludedHeaders = [],
}: Args): Promise<AxiosResponse<T>> => {
	const url = `${getUrl('api')}/${endpoint}`;
	const browserUserData = userDataFromBrowser();
	const noCacheHeaders = noCache
		? {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0',
		  }
		: {};
	const headers = createReqHeaders({
		customHeaders,
		deviceId: userData?.deviceId ?? browserUserData.deviceId,
		userToken: userData?.userToken ?? browserUserData.userToken,
		platform: userData?.platform,
		calmIdentifier: userData?.calmIdentifier ?? browserUserData.calmIdentifier,
		path: userData?.path ?? browserUserData.path,
		userIp: userData?.userIp,
		browserLanguage: userData?.browserLanguage,
		cookies: userData?.cookies,
		...noCacheHeaders,
		excludedHeaders,
	});

	const timestampParams = noCache ? { timestamp: new Date().getTime() } : {};
	const request = {
		method,
		url,
		timeout: 15000,
		headers,
		data: body,
		withCredentials: true,
		params: { ...timestampParams },
	};
	const axiosArgs = {
		...request,
		...(agents ?? {}),
	};
	return axios(axiosArgs);
};

export default apiRequest;
