import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@/store';
import { BrowserLanguage } from '@/store/browserLanguage/types';
import { Locale } from '@/store/locale/types';
import { getCalmLanguage } from '@/utils/language';

export function useLocaleState(): Locale | null {
	return useSelector((state: AppState) => state.locale);
}
export function useBrowserLanguageState(): BrowserLanguage | null {
	return useSelector((state: AppState) => state.browserLanguage);
}

export function useLocale(): {
	localeState: Locale | null;
	browserLanguageState: BrowserLanguage | null;
	routerLocale: string | null;
	calmLanguage: Locale;
} {
	const localeState = useLocaleState();
	const browserLanguageState = useBrowserLanguageState();
	const router = useRouter();

	const browserCalmLanguage = useMemo(
		() => (browserLanguageState ? getCalmLanguage(browserLanguageState) : undefined),
		[browserLanguageState],
	);
	const routerCalmLanguage = useMemo(
		() => (router?.locale ? getCalmLanguage(router?.locale) : undefined),
		[router?.locale],
	);

	return useMemo(() => {
		return {
			localeState: localeState,
			browserLanguageState: browserLanguageState,
			routerLocale: router?.locale ?? null,
			calmLanguage: localeState ?? routerCalmLanguage ?? browserCalmLanguage ?? 'en',
		};
	}, [localeState, browserLanguageState, browserCalmLanguage, router?.locale, routerCalmLanguage]);
}
