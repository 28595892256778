import { AxiosPromise } from 'axios';

import apiRequest from '@/utils/apiRequest';

export function postForgotPassword(email: string): AxiosPromise {
	return apiRequest({
		endpoint: 'password_reset',
		method: 'POST',
		body: { email },
	});
}
