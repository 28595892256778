import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Button } from '@calm-web/design-system';

export const Wrapper = styled.div<{
	isDisabled: boolean;
}>`
	height: 52px;
	opacity: ${(props): string => (props.isDisabled ? '0.75' : '1')};
	pointer-events: ${(props): string => (props.isDisabled ? 'none' : 'auto')};
	position: relative;
	transition: opacity ${theme('animation.transition')};
	width: 100%;

	@media screen and (min-width: 475px) {
		height: 55px;
	}
`;

export const LoadingButton = styled(Button)`
	left: 0;
	height: 100%;
	margin-bottom: 5px;
	position: absolute;
	top: 0;
	z-index: 101; // The PayPal button iFrame is autoset to z-index 100
`;
