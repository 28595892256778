import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import AuthForm from '@/components/authForm/AuthForm';
import { useAuthModalContext } from '@/context/AuthModalContextProvider';
import { useAuthFormModeState } from '@/hooks/store';
import messages from '@/messages/messages';
import { User } from '@/store/user/types';

import { AuthModalProps } from './types';

const AuthModal = ({ hasBackButton, ...props }: AuthModalProps) => {
	const { formatMessage } = useIntl();
	const { isModalOpen, setIsModalOpen, overrides } = useAuthModalContext();

	const authFormMode = useAuthFormModeState();
	const source = overrides.source;

	function closeModal(): void {
		if (setIsModalOpen) {
			setIsModalOpen(false);
		}
	}

	const enhancedAuthSuccess = async (user: User) => {
		if (overrides.injectedOnAuthSuccess) {
			await overrides.injectedOnAuthSuccess(user);
		}
		if (props.onAuthSuccess) {
			return props.onAuthSuccess(user);
		}
	};

	return (
		<Modal
			canClose
			isOpen={isModalOpen}
			closeModal={closeModal}
			showBackButton={false}
			title={
				authFormMode === 'login' ? formatMessage(messages.loginTitle) : formatMessage(messages.signupTitle)
			}
		>
			<AuthForm
				{...props}
				onAuthSuccess={enhancedAuthSuccess}
				hasBackButton={hasBackButton ?? true}
				source={source}
			/>
		</Modal>
	);
};

export default AuthModal;
