import { SignupScreens } from '../../PreSignupProvider/types';

export const signupPaymentScreens: SignupScreens = {
	start: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		overridePremiumUserRouting: true,
	},
	upsell: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
	},
};
