import { defineMessages } from 'react-intl';

export default defineMessages({
	success: {
		id: 'paymentElementsForm.formMessages.success',
		defaultMessage: 'Your payment was successful',
		description: 'Form message for successful purchase',
	},
	processing: {
		id: 'paymentElementsForm.formMessages.processing',
		defaultMessage: 'Your payment is processing.',
		description: 'Form message for purchase that is still processing',
	},
	unsuccessful: {
		id: 'paymentElementsForm.formMessages.unsuccessful',
		defaultMessage: 'Your payment was not successful, please try again.',
		description: 'Form message for unsuccessful purchase',
	},
	fallback: {
		id: 'paymentElementsForm.formMessages.fallback',
		defaultMessage: 'Something went wrong.',
		description: 'Fallback form message for a failed purchase',
	},
	or: {
		id: 'paymentElementsForm.options.or',
		defaultMessage: 'Or',
		description: 'The word "Or" distinguishing payment options',
	},
	applePayPaymentDescription: {
		id: 'paymentElementsForm.applePay.paymentDescription',
		defaultMessage:
			'You will not be charged today. "Amount pending" will apply only if you keep your Calm subscription after the free trial',
		description: 'An explanation for the "Amount pending" text when paying with Apple Pay',
	},
});
