import { useIntl } from 'react-intl';

import { Button, Modal, Notice } from '@calm-web/design-system';

import genericMessages from '@/messages/messages';

import { BodyText } from '../../styles';
import messages from './messages';
import { ButtonContainer, ModalHeading, ModalWrapper } from './styles';
import { ListItemModalProps } from './types';

const RemoveMemberModal = ({
	isOpen = false,
	isLoading = false,
	isErrored = false,
	onCancelClick,
	onRemoveClick,
	headingText,
	bodyText,
	errorText,
	confirmText,
}: ListItemModalProps) => {
	const { formatMessage } = useIntl();

	return (
		<Modal isOpen={isOpen} canClose={false} closeModal={onCancelClick} aria-label={headingText}>
			<ModalWrapper id="remove-item-form">
				<ModalHeading>{headingText}</ModalHeading>
				<BodyText>{bodyText}</BodyText>
				<ButtonContainer $isErrored={isErrored}>
					<Button
						backgroundColor="white"
						borderColor="gray6"
						textColor="gray8"
						onPress={onCancelClick}
						isDisabled={isLoading}
					>
						{formatMessage(genericMessages.cancel)}
					</Button>
					<Button
						backgroundColor="buttonFuschia"
						textColor="white"
						onPress={onRemoveClick}
						isLoading={isLoading}
					>
						{confirmText ?? formatMessage(messages.removeCta)}
					</Button>
				</ButtonContainer>
				{isErrored && (
					<Notice isSuccess={false} describedBy="remove-item-form">
						{errorText}
					</Notice>
				)}
			</ModalWrapper>
		</Modal>
	);
};

export default RemoveMemberModal;
