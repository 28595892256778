import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { Button, minWidth } from '@calm-web/design-system';

import { PlanCadence } from '@/store/purchaseParams/types';

export const Wrapper = styled.div`
	margin-bottom: 8px;
	position: relative;
`;

export const LoadingButton = styled(Button)`
	height: 55px;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 1;
`;

export const GooglePayButtonWrapper = styled.div<{
	isDisabled: boolean;
	activePlan: PlanCadence;
	buttonPlan: PlanCadence;
}>`
	opacity: ${(props): string => (props.isDisabled ? '0.75' : '1')};
	position: relative;
	pointer-events: ${(props): string => (props.isDisabled ? 'none' : 'auto')};
	transition: ${theme('animation.transition')};

	& .google-pay-button button {
		border: 1px ${palette('gray2')} solid;
		box-shadow: none;
		border-radius: 100px;
		height: 55px;
		padding: 14px 15% !important;
		width: 100%;

		${minWidth('tablet')} {
			height: 52px;
		}

		${minWidth('desktop')} {
			height: 55px;
			padding: 14px 15% !important;
		}

		&:after {
			content: '';
			margin: 0;
			opacity: 0;
		}

		&.focus {
			outline: none;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: ${theme('buttons.focusRingWidth')} solid ${palette('focusRingDark')};
				border-radius: ${theme('buttons.borderRadius')};
				transition: margin ${theme('animation.transition')}, opacity ${theme('animation.transition')};
				margin: ${theme('buttons.focusRingDistance')};
				opacity: 1;
			}
		}
	}
`;
