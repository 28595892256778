import { useIntl } from 'react-intl';

import { FocusRingVariants } from '@calm-web/design-system';

import messages from '@/components/purchase/CreditCardForm/messages';
import { usePurchaseParamsState } from '@/hooks/store';

import { SubmitButton } from './styles';

const PaymentElementsFormSubmitButton: React.FC<{
	ctaCopy?: string;
	isDisabled: boolean;
	isLoading: boolean;
}> = ({ ctaCopy, isDisabled, isLoading }) => {
	const purchaseParams = usePurchaseParamsState();
	const { formatMessage } = useIntl();

	const getButtonText = (): string => {
		if (ctaCopy) {
			return ctaCopy;
		}

		if (purchaseParams?.purchaseType?.type === 'freetrial' && purchaseParams.plan !== 'monthly') {
			return formatMessage(messages.startFreeTrial);
		}

		return formatMessage(messages.useCard);
	};

	return (
		<SubmitButton
			fullWidth
			backgroundColor="buttonBlue"
			textColor="white"
			focusRingVariant={FocusRingVariants.Dark}
			type="submit"
			isDisabled={isDisabled}
			isLoading={isLoading}
		>
			{getButtonText()}
		</SubmitButton>
	);
};

export default PaymentElementsFormSubmitButton;
