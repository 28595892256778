import { useUserState } from '@/hooks/store';

export const useUpsellUserState = () => {
	const user = useUserState();

	if (!user) return 'loggedOut';
	if (!user.subscription?.valid && !user.subscription?.has_ever_done_free_trial) return 'neverTrialed';
	if (user.subscription && user.subscription.has_ever_done_free_trial && !user.subscription.valid)
		return 'churnedAndTrialed';

	return 'activeCalmPremiumUser';
};
