import { createContext, Dispatch, ReactNode, SetStateAction, useMemo } from 'react';

import { ContentType, PlayerMode } from '@/components/app/contentPlayer/MediaPlayerProvider/types';
import { Scene, useSelectedScene } from '@/hooks/app/useSelectedScene';
import { useSelectedSceneAudio } from '@/hooks/app/useSelectedSceneAudio';

export const SceneContext = createContext<{
	sceneVolume: number;
	setSceneVolume: Dispatch<SetStateAction<number>>;
	scenes: Scene[];
	selectedScene: Scene | undefined;
	setSelectedScene: Dispatch<SetStateAction<Scene | undefined>>;
}>({
	sceneVolume: 1,
	setSceneVolume: () => {},
	scenes: [],
	selectedScene: undefined,
	setSelectedScene: () => {},
});

const SceneProvider = ({
	playerMode,
	contentType,
	children,
}: {
	children?: ReactNode;
	contentType: ContentType;
	playerMode: PlayerMode;
}) => {
	const { scenes, selectedScene, setSelectedScene } = useSelectedScene();
	const { sceneVolume, setSceneVolume } = useSelectedSceneAudio({
		selectedScene,
		playerMode,
		contentType,
	});

	const value = useMemo(
		() => ({
			sceneVolume,
			setSceneVolume,
			scenes,
			selectedScene,
			setSelectedScene,
		}),
		[sceneVolume, scenes, selectedScene, setSceneVolume, setSelectedScene],
	);

	return <SceneContext.Provider value={value}>{children}</SceneContext.Provider>;
};

export default SceneProvider;
