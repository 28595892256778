import { MessageDescriptor } from 'react-intl';

import { PollChildren, PollResponse } from '../PreSignupProvider/types';
import { Title } from '../styles';
import {
	meditationExperienceMessages,
	moodPulseMessages,
	initialTransitionMessages,
	preferencesTransitionMessages,
	troubleSleepingMessages,
} from './MessageSets';

export const moodPulseResponse: PollResponse = (selectedTile, formatMessage) => {
	if (!selectedTile?.title) return null;

	return (
		<p>
			{formatMessage(selectedTile?.title as MessageDescriptor) === formatMessage(moodPulseMessages.answerGood)
				? formatMessage(moodPulseMessages.responseGlad)
				: formatMessage(moodPulseMessages.responseOk)}
			<br />
			<br />
			{formatMessage(moodPulseMessages.responseRecognize)}
		</p>
	);
};

export const meditationExperienceChildren: PollResponse = (selectedTile, formatMessage) => {
	if (!selectedTile?.title) return null;

	const response = {
		[formatMessage(meditationExperienceMessages.answerNone)]: (
			<>
				{formatMessage(meditationExperienceMessages.responseHealthier)}
				<br />
				<br />
				{formatMessage(meditationExperienceMessages.responseEasy)}
			</>
		),
		[formatMessage(meditationExperienceMessages.answerFew)]: formatMessage(
			meditationExperienceMessages.responseDeepen,
		),
		[formatMessage(meditationExperienceMessages.answerLot)]: formatMessage(
			meditationExperienceMessages.responseEvolve,
		),
	};

	return <p>{response[formatMessage(selectedTile.title as MessageDescriptor)]}</p>;
};

export const initialTransitionChildren: PollChildren = formatMessage => {
	return (
		<>
			<Title>{formatMessage(initialTransitionMessages.title)}</Title>
			<p>{formatMessage(initialTransitionMessages.paragraph1)}</p>
			<br />
		</>
	);
};

export const troubleSleepingResponse: PollResponse = (selectedTile, formatMessage) => {
	if (!selectedTile?.title) return null;

	return (
		<p>
			{formatMessage(troubleSleepingMessages.responseGoodHands)}
			<br />
			<br />
			{formatMessage(troubleSleepingMessages.responseLibrary)}
		</p>
	);
};

export const preferencesTransitionChildren: PollChildren = formatMessage => (
	<>
		<Title>{formatMessage(preferencesTransitionMessages.title)}</Title>
		<p>{formatMessage(preferencesTransitionMessages.paragraph)}</p>
	</>
);
