import styled from 'styled-components';
import { palette } from 'styled-tools';

export const Wrapper = styled.div`
	align-items: center;
	display: flex;
`;

export const CheckboxWrapper = styled.div`
	height: 12px;
	margin-left: 12px;
	margin-top: 4px;
	position: relative;
	width: 12px;

	& input {
		height: 12px;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 12px;
	}

	& input + div::before {
		border: 2px ${palette('white')} solid;
		border-radius: 4px;
		content: '';
		cursor: pointer;
		height: 12px;
		left: 0;
		position: absolute;
		top: 0;
		width: 12px;
	}

	input:checked + div::before {
		background: ${palette('white')};
	}
`;
