import { AnyAction } from 'redux';

import { AppLoaded, SET_APP_LOADED } from './types';

const initialState: AppLoaded = false;

export function appLoadedReducer(state = initialState, action: AnyAction = { type: undefined }): AppLoaded {
	switch (action.type) {
		case SET_APP_LOADED:
			return action.payload;
		default:
			return state;
	}
}
