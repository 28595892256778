import accepts from 'accepts';
import { IncomingMessage } from 'http';

import { BrowserLanguage } from '../../store/browserLanguage/types';

const initBrowserLanguage = (req: IncomingMessage): BrowserLanguage => {
	return accepts(req).languages()[0];
};

export default initBrowserLanguage;
